import React, { Fragment, Component } from "react";
import MainNavbar from "../navigation/MainNavbar";
import { Button, Modal, Alert } from "react-bootstrap";
import Questions from "../navigation/navigation-tabs/Questions";
import LogoutModal from "../modals/LogoutModal";
import AdminPanel from "../navigation/navigation-tabs/AdminPanel";
import Account from "../navigation/navigation-tabs/Account";
import Users from "../navigation/navigation-tabs/Users";
import Evaluations from "../navigation/navigation-tabs/Evaluations";
import Approvals from "../navigation/navigation-tabs/Approvals";
import ArrangingTests from "../navigation/navigation-tabs/ArrangingTests";
import AllTests from "../navigation/navigation-tabs/AllTests";
import $ from "jquery";
import Statistics from "../navigation/navigation-tabs/Statistics";

class LoggedPages extends Component {
    state = {
        changedValuesInFormes: false,
        showConfirmEscapeModal: false,
        windowHeight: 0,
        navigation: "", //dane zawierające nazwę navu i podstrony do ewentualnego przekierowania po zaakceptowaniu modala
        currentPage: {
            nav: this.props.redirectTo ? this.props.redirectTo.nav : "myAccount", //wyświetlenie aktualnego nav'u
            subpage: this.props.redirectTo ? this.props.redirectTo.subpage : "myAccount", //wyświetlenie aktualnej podstrony
            params: this.props.redirectTo ? this.props.redirectTo.params : "", //parametr dodatkowy do wyświetlenie opowiedniej podstrony
        },

        previousPage: [],
        blockSendingEmails: false,
        notification: null,
        showLogoutModal: false,
        questionsList: null,
        evaluationsList: null,
        usersList: null,
        approvalsList: null,
        settingsData: this.props.appParams,
        myAccountData: null,
        approvedQuestionsList: null,
        pilotageTests: null,
        normTestsList: null,
        finalTestsList: null,
        allTestsList: null,
        showSmallWindowModal: false,
    };

    handleChangeNavAndPage = (page, prevPage, resetList) => {
        let prevPageArray = null;
        if (prevPage) prevPageArray = [...this.state.previousPage, prevPage];
        else prevPageArray = [...this.state.previousPage];
        if (resetList) {
            this.setState({ [resetList]: null }, () => {
                this.setState({
                    previousPage: prevPageArray,
                    currentPage: page,
                    showConfirmEscapeModal: false,
                    changedValuesInFormes: false,
                });
            });
        } else
            this.setState({
                previousPage: prevPageArray,
                currentPage: page,
                showConfirmEscapeModal: false,
                changedValuesInFormes: false,
            });
    };

    //sprawdzanie, czy potrzebne jest wyświetlanie modala do potwierdzenia opuszczenia widoku, kiedy w jakimkolwiek formularzu nastąpiły jakieś niezapisane zmiany
    handleChangeValuesInFormes = () => this.setState({ changedValuesInFormes: true });
    handleCancelChangeValuesInFormes = () => this.setState({ changedValuesInFormes: false });

    handleBackToPreviousPage = () => {
        const { previousPage } = this.state;
        const prevPageArray = [...previousPage];
        this.setState({ currentPage: prevPageArray[prevPageArray.length - 1] }, () => {
            prevPageArray.splice(-1, 1);
            this.setState({ previousPage: prevPageArray });
        });
    };

    handleDownloadQuestionsList = async (controllerURL) => {
        const { fetchURL, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/${controllerURL ? controllerURL : "questions-list"}`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const questionsList = fetchedData.questionsList;
                        this.setState({ questionsList: questionsList });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.handleDownloadQuestionsList(controllerURL), 4000);
        }
    };

    componentDidMount() {
        if ($(window).width() < 1600 || $(window).height() < 754)
            setTimeout(() => {
                this.setState({ showSmallWindowModal: true });
            }, 200);
    }

    render() {
        const {
            changedValuesInFormes,
            showConfirmEscapeModal,
            currentPage,
            navigation,
            notification,
            showLogoutModal,
            questionsList,
            evaluationsList,
            usersList,
            approvalsList,
            settingsData,
            myAccountData,
            pilotageTests,
            approvedQuestionsList,
            //showSmallWindowModal,
            normTestsList,
            finalTestsList,
        } = this.state;
        const { loggedUserPrivilages, fetchURL, onLogout, onShowAlert, appParams, onChangeAppParams, onShowNotLoggedPages } = this.props;
        //console.log(currentPage.nav, currentPage.subpage);
        return (
            <Fragment>
                <main role="main" id="homeContainer">
                    <MainNavbar
                        currentPage={currentPage}
                        onChangeNavAndPage={this.handleChangeNavAndPage}
                        changedValuesInFormes={changedValuesInFormes}
                        onShowConfirmModal={(navigation) => {
                            this.setState({
                                showConfirmEscapeModal: true,
                                navigation: navigation,
                            });
                        }}
                        loggedUserPrivilages={loggedUserPrivilages}
                        onShowLogoutModal={() => this.setState({ showLogoutModal: true })}
                        appParams={appParams}
                    />

                    <Fragment>
                        <Questions
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            questionsList={questionsList}
                            onStoreDataList={(list) => this.setState({ questionsList: list })}
                            onDownloadQuestionsList={this.handleDownloadQuestionsList}
                            onRefreshEvaluationList={(list) => this.setState({ evaluationsList: list })}
                        />

                        <Evaluations
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            evaluationsList={evaluationsList}
                            onStoreDataList={(list) => this.setState({ evaluationsList: list })}
                            onDownloadQuestionsList={this.handleDownloadQuestionsList}
                            onDownloadApprovalsList={() => this.setState({ approvalsList: null })}
                        />

                        <Approvals
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            approvalsList={approvalsList}
                            onStoreDataList={(list) => this.setState({ approvalsList: list })}
                            onDownloadQuestionsList={this.handleDownloadQuestionsList}
                        />

                        <ArrangingTests
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            approvedQuestionsList={approvedQuestionsList}
                            onStoreDataList={(list) => this.setState({ approvedQuestionsList: list })}
                        />

                        <AllTests
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            pilotageTests={pilotageTests}
                            normTestsList={normTestsList}
                            finalTestsList={finalTestsList}
                            allTestsList={this.state.allTestsList}
                            onStoreDataList={(list) => this.setState({ pilotageTests: list })}
                            onStoreTestList={(list) => this.setState({ allTestsList: list })}
                            onStoreNormDataList={(list) => this.setState({ normTestsList: list })}
                            onStoreFinalDataList={(list) => this.setState({ finalTestsList: list })}
                        />

                        <AdminPanel
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            onChangeAppParams={onChangeAppParams}
                            settingsData={settingsData}
                            onStoreDataList={(list) => this.setState({ settingsData: list })}
                        />

                        <Users
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            usersList={usersList}
                            onStoreDataList={(list) => this.setState({ usersList: list })}
                            onEraseUsersList={() => {
                                this.setState({ usersList: null });
                            }}
                        />

                        <Account
                            currentPage={currentPage}
                            fetchURL={fetchURL}
                            onChangeValuesInForms={this.handleChangeValuesInFormes}
                            onCancelChangeValuesInForms={this.handleCancelChangeValuesInFormes}
                            onShowAlert={onShowAlert}
                            appParams={appParams}
                            onShowNotLoggedPages={onShowNotLoggedPages}
                            myAccountData={myAccountData}
                            onStoreDataList={(list) => this.setState({ myAccountData: list })}
                        />

                        <Statistics
                            currentPage={currentPage}
                            fetchURL={fetchURL}
                            onBackToPreviousPage={this.handleBackToPreviousPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={this.handleChangeNavAndPage}
                        />
                    </Fragment>
                </main>

                {notification && (
                    <Alert variant="dark" onClose={() => this.setState({ notification: null })} dismissible className="alert-notification">
                        {/* <Alert.Heading>Oh snap! You got an error!</Alert.Heading> */}
                        {notification.type === "emails" && <p>Wysłanie wiadomości zostało ukończone. Wysłano emaile do {notification.users === 1 ? "1 osoby." : `${notification.users} osób.`}</p>}
                    </Alert>
                )}

                {showConfirmEscapeModal && (
                    <Modal show={showConfirmEscapeModal} onHide={() => this.setState({ showConfirmEscapeModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Potwierdzenie opuszczenia widoku</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Nastąpiły zmiany w formularzu. Czy jesteś pewien, że chcesz opuścić ten widok?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={() => this.handleChangeNavAndPage(navigation, null, null)}>
                                Tak, idź dalej
                            </Button>
                            <Button variant="primary" type="button" onClick={() => this.setState({ showConfirmEscapeModal: false })}>
                                Nie, zostań
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                {/* showSmallWindowModal && (
                    <Modal show={showSmallWindowModal} onHide={() => this.setState({ showSmallWindowModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Mała rozdzielczość</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                                Uwaga. Okno Twojej przeglądarki jest zbyt małe na poprawne wyświetlanie aplikacji. Do poprawnego wyświetlania zawartości aplikacji wymagana jest rozdzielczość okna
                                minimum <b>1600x754px</b>.
                            </p>
                            <p>Istnieje ryzyko, że nie wszystkie elementy aplikacji będą poprawnie wyświetlone.</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" type="button" onClick={() => this.setState({ showSmallWindowModal: false })}>
                                Zamknij
                            </Button>
                        </Modal.Footer>
                    </Modal>
                ) */}

                {showLogoutModal && <LogoutModal onLogout={onLogout} show={showLogoutModal} onClose={() => this.setState({ showLogoutModal: false })} />}
            </Fragment>
        );
    }
}

export default LoggedPages;
