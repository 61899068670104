import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faPollH, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ApprovalsListTable extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortApprovalName",
        showSendApprovalForApprovalModal: false,
        selectedApproval: null,
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { currentSortType, currentSortingColumn } = this.state;
        const { approvalsList, displayNOR, onChangeNavAndPage, appParams } = this.props;

        const sortTypes = {
            up: {
                fnYes: (a, b) => a.qr_yes - b.qr_yes,
                fnNo: (a, b) => a.qr_no - b.qr_no,
                fnCond: (a, b) => a.qr_cond - b.qr_cond,
                fnApprovalName: (a, b) => {
                    if (a.question_name.toUpperCase() > b.question_name.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnYes: (a, b) => b.qr_yes - a.qr_yes,
                fnNo: (a, b) => b.qr_no - a.qr_no,
                fnCond: (a, b) => b.qr_cond - a.qr_cond,
                fnApprovalName: (a, b) => {
                    if (a.question_name.toUpperCase() > b.question_name.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnYes: (a, b) => a,
                fnNo: (a, b) => a,
                fnCond: (a, b) => a,
                fnApprovalName: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (currentSortingColumn === "sortYes") sortedData = approvalsList.sort(sortTypes[currentSortType].fnYes);
        else if (currentSortingColumn === "sortNo") sortedData = approvalsList.sort(sortTypes[currentSortType].fnNo);
        else if (currentSortingColumn === "sortCond") sortedData = approvalsList.sort(sortTypes[currentSortType].fnCond);
        else if (currentSortingColumn === "sortApprovalName") sortedData = approvalsList.sort(sortTypes[currentSortType].fnApprovalName);
        else sortedData = approvalsList.sort(sortTypes[currentSortType].fnApprovalName);

        const currentPageParams = { nav: "approvals", subpage: "approvalsList", params: "" };
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th rowSpan={2} className="darker-border">
                                L.p.
                            </th>
                            <th rowSpan={2} className="darker-border">
                                <span className="align-middle">Nazwa pytania</span>
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortApprovalName" onClick={() => this.handleSorting("sortApprovalName")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortApprovalName" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                            <th rowSpan={2} className="darker-border">
                                Część testu
                            </th>
                            <th rowSpan={2} className="darker-border">
                                Typ pytania
                            </th>
                            <th rowSpan={2} className="darker-border">
                                Kategoria pytania
                            </th>
                            <th colSpan={4} className="text-center darker-border">
                                Ocena poziomu trudności pytań
                            </th>
                            <th colSpan={3} className="text-center darker-border">
                                Liczba kwalifikacji pytań
                            </th>
                            <th rowSpan={2} className="darker-border">
                                Status zatwierdzenia pytania
                            </th>
                            {appParams.map((e, j) => (
                                <Fragment key={j}>{e.param_code === "QUESTIONS_STATUS" && e.param_value === true && <th rowSpan={2}>Akcje</th>}</Fragment>
                            ))}
                        </tr>
                        <tr className="text-center">
                            <th>Śr. ocen</th>
                            <th>1</th>
                            <th>2</th>
                            <th className="darker-border">3</th>
                            <th>
                                Tak
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortYes" onClick={() => this.handleSorting("sortYes")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortYes" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>
                                Nie
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortNo" onClick={() => this.handleSorting("sortNo")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortNo" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th className="darker-border">
                                Warun.
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortCond" onClick={() => this.handleSorting("sortCond")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortCond" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td className="darker-border">{i + 1}</td>
                                    <td className="darker-border">{p.question_name}</td>
                                    <td className="darker-border">{p.subtest_type_name}</td>
                                    <td className="darker-border">{p.question_type_name}</td>
                                    <td className="darker-border">{p.question_category_name}</td>
                                    <td className="text-center">{p.qd_average.toFixed(2)}</td>
                                    <td className="text-center">{p.qd_1}</td>
                                    <td className="text-center">{p.qd_2}</td>
                                    <td className="text-center darker-border">{p.qd_3}</td>
                                    <td className="text-center">{p.qr_yes}</td>
                                    <td className="text-center">{p.qr_no}</td>
                                    <td className="text-center darker-border">{p.qr_cond}</td>
                                    <td className="darker-border">
                                        {p.question_status_code === "ACCEPTED" ? (
                                            <span className="text-success">{p.question_status_name}</span>
                                        ) : p.question_status_code === "REJECTED" ? (
                                            <span className="text-danger">{p.question_status_name}</span>
                                        ) : (
                                            <span className="opacity-4">Brak</span>
                                        )}
                                    </td>
                                    {appParams.map((e, j) => (
                                        <Fragment key={j}>
                                            {e.param_code === "QUESTIONS_STATUS" && e.param_value === true && (
                                                <td className="text-center">
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`doApproval${i}`}>Zatwierdź pytanie</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    onChangeNavAndPage({ nav: "approvals", subpage: "doApproval", params: { questionID: p.question_id } }, currentPageParams, null)
                                                                }
                                                                className="mx-1 btn-list"
                                                            >
                                                                <FontAwesomeIcon icon={faPollH} />
                                                            </Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                </td>
                                            )}
                                        </Fragment>
                                    ))}
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default ApprovalsListTable;
