import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import NotAllowed from "../../other/NotAllowed";
import TestDetailsTable from "./TestDetailsTable";
import $ from "jquery";

class TestDetails extends Component {
    state = {
        loading: true,
        noPermission: false,
        testObjectDetails: null,
        testInstances: null,
        testQuestionAndAnswers: null,
        activeTab: null,
    };

    getTestDetails = async (testObjectID, testType) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            testObjectID: testObjectID,
        };
        try {
            const url = `${fetchURL}/get-${testType}-test-details`;
            const response = await fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else {
                        this.setState({
                            testObjectDetails: fetchedData.testObjectDetails,
                            testInstances: fetchedData.testInstances,
                            testQuestionAndAnswers: fetchedData.testQuestionAndAnswers,
                            activeTab: fetchedData.testInstances[0].subtest_type_name,
                            loading: false,
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `Nie udało się pobrać danych (${err}).` });
            setTimeout(this.getTestDetails(testObjectID, testType), 4000);
        }
    };

    componentDidUpdate() {
        if (this.state.testQuestionAndAnswers && this.state.testInstances) {
            $("#testInstancesTabs .tab-content").css(
                "height",
                `${
                    $(window).height() -
                    $("#homeContainer > nav.navbar").outerHeight() -
                    $("#navbarTestDetails").outerHeight() -
                    $("#testDetailsContent").outerHeight() -
                    $("#testInstancesTabs > nav.nav").outerHeight() -
                    16
                }px`
            );
        }
    }

    componentDidMount() {
        this.getTestDetails(this.props.currentPage.params.testObjectID, this.props.currentPage.params.testType);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { loading, noPermission, testObjectDetails, testInstances, testQuestionAndAnswers, activeTab } = this.state;
        const { onBackToPreviousPage, onChangeNavAndPage, currentPage } = this.props;
        let percentageOfCorrectAnswers1 = 0;
        let percentageOfCorrectAnswers2 = 0;
        let countCorrectAnswers1 = 0;
        let countCorrectAnswers2 = 0;
        if (testQuestionAndAnswers) {
            if (testQuestionAndAnswers.length === 1) {
                //pojedynczy test
                const amountOfAnswers = testQuestionAndAnswers[0].length;
                testQuestionAndAnswers[0].forEach((e) => {
                    if (e.checked_answer === e.correct_answer) countCorrectAnswers1++;
                });
                percentageOfCorrectAnswers1 = (countCorrectAnswers1 * 100) / amountOfAnswers;
            } else {
                //test PJM i PL
                const amountOfAnswers1 = testQuestionAndAnswers[0].length;
                const amountOfAnswers2 = testQuestionAndAnswers[1].length;
                testQuestionAndAnswers[0].forEach((e) => {
                    if (e.checked_answer === e.correct_answer) countCorrectAnswers1++;
                });
                testQuestionAndAnswers[1].forEach((e) => {
                    if (e.checked_answer === e.correct_answer) countCorrectAnswers2++;
                });
                percentageOfCorrectAnswers1 = (countCorrectAnswers1 * 100) / amountOfAnswers1;
                percentageOfCorrectAnswers2 = (countCorrectAnswers2 * 100) / amountOfAnswers2;
            }
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                {noPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        <Container fluid id="navbarTestDetails">
                            <Row className="py-3">
                                <NavDetails header="Szczegóły testu" onChangeView={() => this.setState({ loading: false }, () => onBackToPreviousPage())} />
                                <Col></Col>
                            </Row>
                        </Container>
                        <Container>
                            {!loading && testQuestionAndAnswers && testInstances ? (
                                <Fragment>
                                    <Row id="testDetailsContent">
                                        <Col xs={12}>
                                            <p className="h4 mb-3">Dane testu</p>
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <Row>
                                                <Col xs={7}>Imię podmiotu testowego</Col>
                                                <Col xs={5}>
                                                    <b>{testObjectDetails[0].to_name}</b>
                                                </Col>
                                                <Col xs={7}>Miesiąc urodzenia podmiotu testowego</Col>
                                                <Col xs={5}>
                                                    <b>{testObjectDetails[0].to_birth_month.toLowerCase()}</b>
                                                </Col>
                                                <Col xs={7}>Rok urodzenia podmiotu testowego</Col>
                                                <Col xs={5}>
                                                    <b>{testObjectDetails[0].to_birth_year}</b>
                                                </Col>
                                                <Col xs={7}>Klasa podmiotu testowego</Col>
                                                <Col xs={5}>
                                                    <b>{testObjectDetails[0].to_grade_name}</b>
                                                </Col>
                                                {testInstances[0].subtest_type_name !== "PL" && (
                                                    <>
                                                        <Col xs={7}>Stopień niedosłuchu</Col>
                                                        <Col xs={5}>
                                                            <b>{testObjectDetails[0].to_hl_name ? testObjectDetails[0].to_hl_name : "Brak"}</b>
                                                        </Col>
                                                        <Col xs={7}>Protezowanie</Col>
                                                        <Col xs={5}>
                                                            <b>{testObjectDetails[0].to_prosthesis_name ? testObjectDetails[0].to_prosthesis_name : "Brak"}</b>
                                                        </Col>
                                                    </>
                                                )}
                                                {currentPage.params.testType === "all" && (
                                                    <>
                                                        <Col xs={7}>Badanie przeprowadził/-ła</Col>
                                                        <Col xs={5}>
                                                            <b>{testObjectDetails[0].user_fullname}</b>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <Row>
                                                {testInstances.length === 2 ? (
                                                    <>
                                                        <Col xs={7}>Status testu PJM</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[0].ti_stat_name}</b>
                                                        </Col>
                                                        <Col xs={7}>Status testu PL</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[1].ti_stat_name}</b>
                                                        </Col>
                                                        {testInstances[0].ti_stat_code === "DONE" && (
                                                            <>
                                                                <Col xs={7}>Poprawność wykonania testu PJM</Col>
                                                                <Col xs={5}>
                                                                    <b>{percentageOfCorrectAnswers1.toFixed(0)}%</b>
                                                                </Col>
                                                            </>
                                                        )}
                                                        {testInstances[1].ti_stat_code === "DONE" && (
                                                            <>
                                                                <Col xs={7}>Poprawność wykonania testu PL</Col>
                                                                <Col xs={5}>
                                                                    <b>{percentageOfCorrectAnswers2.toFixed(0)}%</b>
                                                                </Col>
                                                            </>
                                                        )}
                                                        <Col xs={7}>Data rozpoczęcia testu PJM i PL</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[0].ti_start_date}</b>
                                                        </Col>
                                                        <Col xs={7}>Data zakończenia testu PJM</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[0].ti_end_date ? testInstances[0].ti_end_date : <span className="opacity-4">Brak</span>}</b>
                                                        </Col>
                                                        <Col xs={7}>Data zakończenia testu PL</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[1].ti_end_date ? testInstances[1].ti_end_date : <span className="opacity-4">Brak</span>}</b>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Col xs={7}>Status testu</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[0].ti_stat_name}</b>
                                                        </Col>
                                                        {testInstances[0].ti_stat_code === "DONE" && (
                                                            <>
                                                                <Col xs={7}>Poprawność wykonania testu</Col>
                                                                <Col xs={5}>
                                                                    <b>{percentageOfCorrectAnswers1.toFixed(0)}%</b>
                                                                </Col>
                                                            </>
                                                        )}
                                                        <Col xs={7}>Data rozpoczęcia testu</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[0].ti_start_date}</b>
                                                        </Col>
                                                        <Col xs={7}>Data zakończenia testu</Col>
                                                        <Col xs={5}>
                                                            <b>{testInstances[0].ti_end_date ? testInstances[0].ti_end_date : <span className="opacity-4">Brak</span>}</b>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>
                                        </Col>

                                        <Col xs={12}>
                                            <p className="h4 my-3">Wyniki testu</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} id="testInstancesTabs">
                                            {testInstances.length === 2 ? (
                                                <Tabs id="tabTests" activeKey={activeTab} onSelect={(k) => this.setState({ activeTab: k })}>
                                                    <Tab eventKey="PJM" title="PJM">
                                                        <TestDetailsTable testQADetails={testQuestionAndAnswers[0]} onChangeNavAndPage={onChangeNavAndPage} currentPageParams={currentPageParams} />
                                                    </Tab>
                                                    <Tab eventKey="PL" title="PL">
                                                        <TestDetailsTable testQADetails={testQuestionAndAnswers[1]} onChangeNavAndPage={onChangeNavAndPage} currentPageParams={currentPageParams} />
                                                    </Tab>
                                                </Tabs>
                                            ) : (
                                                <Tabs id="tabTests" activeKey={activeTab} onSelect={(k) => this.setState({ activeTab: k })}>
                                                    <Tab eventKey={testInstances[0].subtest_type_name} title={testInstances[0].subtest_type_name}>
                                                        <TestDetailsTable testQADetails={testQuestionAndAnswers[0]} onChangeNavAndPage={onChangeNavAndPage} currentPageParams={currentPageParams} />
                                                    </Tab>
                                                </Tabs>
                                            )}
                                        </Col>
                                    </Row>
                                </Fragment>
                            ) : (
                                <LoadingProgressBar />
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default TestDetails;
