import React, { Fragment, Component } from "react";
import { Col, Form, Button, Image } from "react-bootstrap";
import EmailInput from "../forms/EmailInput";
import PasswordInput from "../forms/PasswordInput";

class Login extends Component {
    state = {
        user_email: "",
        user_password: "",
    };

    handleCheckValidation = (e) => {
        const { onShowAlert } = this.props;
        e.preventDefault();
        onShowAlert("");
        const { user_email, user_password } = this.state;
        let errorMsg = "";
        if (!user_email) errorMsg += `Pole 'Adres email' jest puste. `;
        if (!user_password) errorMsg += `Pole 'Hasło' jest puste. `;

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            const userdata = {
                email: user_email,
                password: user_password,
            };
            this.loginUser(userdata);
        }
    };

    loginUser = async (loginData) => {
        const { fetchURL, onShowAlert } = this.props;
        onShowAlert({ variant: "info", message: "Logowanie..." });
        this.setState({ mssgAlertDisabledUser: "" });
        const passData = {
            loginData: loginData,
        };
        try {
            const response = await fetch(`${fetchURL}/login`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.props.onLoginSucces(fetchedData.userData);
                        onShowAlert("");
                    } else if (fetchedData.status === "warning") {
                        onShowAlert({ variant: "warning", message: fetchedData.message, contactBtn: { mailTitle: fetchedData.title, mailSender: fetchedData.name, mailAddress: fetchedData.mail } });
                    } else {
                        //jeśli istnieją parametry title i name - wyświetl alert z przyciskiem do formularza kontaktowego, gdzie title będzie tytułem maila, a name - imieniem i nazwiskiem użytkownika
                        if (fetchedData.title && fetchedData.name) {
                            onShowAlert({ variant: "danger", message: fetchedData.message, contactBtn: { mailTitle: fetchedData.title, mailSender: fetchedData.name, mailAddress: fetchedData.mail } });
                        } else onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.loginUser(loginData), 4000);
        }
    };

    render() {
        const { user_email, user_password } = this.state;
        const { onChangeSubpage, fontSize } = this.props;
        return (
            <Fragment>
                <Col lg={{ span: 6, offset: 3 }} className="text-center">
                    <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="my-3" style={{ height: "16rem" }} />
                </Col>
                <Col lg={{ span: 6, offset: 3 }}>
                    <h1 className="mb-3 text-center" style={{ fontSize: `${1.75 * fontSize}rem` }}>
                        Logowanie
                    </h1>
                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                        <EmailInput value={user_email} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} name="user_email" id="userEmail" />
                        <PasswordInput value1={user_password} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} name="user_password" id="userPassword" />

                        <Form.Group controlId="loginSumbit" className="text-center">
                            <Button variant="primary" type="submit" size="lg">
                                Zaloguj się
                            </Button>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} className="text-center mb-3">
                    <Button onClick={() => onChangeSubpage("rememberPass")} variant="link" style={{ fontSize: `${1 * fontSize}rem` }}>
                        zapomniałem/-łam hasła
                    </Button>
                </Col>
                <Col className="text-center" xs={12}>
                    <Button
                        onClick={() => {
                            this.props.onShowAlert("");
                            this.props.onGoToMainPage();
                        }}
                        variant="outline-secondary"
                        size="sm"
                        className="mb-3"
                    >
                        wróć na stronę główną
                    </Button>
                </Col>
            </Fragment>
        );
    }
}

export default Login;
