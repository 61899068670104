import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

class UserStatuses extends Component {
    state = { userStatusesList: [] };

    getUserStatuses = async () => {
        const { fetchURL, defaultValue, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/user-statuses-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === true) {
                        const userStatusesDropdown = fetchedData.userStatusesDropdown;
                        if (defaultValue === "all") {
                            userStatusesDropdown.unshift({
                                key: 0,
                                value: "all",
                                text: "Wszystkie",
                            });
                        }

                        this.setState({ userStatusesList: userStatusesDropdown });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: response.status });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." });
            setTimeout(this.getUserStatuses(), 4000);
        }
    };

    componentDidMount() {
        this.getUserStatuses();
    }

    render() {
        const { userStatusesList } = this.state;
        const { value, onUserStatusesSelected, req } = this.props;
        return (
            <Fragment>
                <Form.Group>
                    <Form.Label>Status użytkownika {req && <span className="required">*</span>}</Form.Label>

                    <Dropdown
                        value={value}
                        id="userStatusesDropdown"
                        className={req ? "form-dropdown mb-3 req" : "form-dropdown mb-3"}
                        placeholder="Kliknij i wybierz"
                        fluid
                        selection
                        options={userStatusesList}
                        onChange={(event, { value }) => {
                            onUserStatusesSelected(event, value);
                        }}
                    />
                </Form.Group>
            </Fragment>
        );
    }
}

export default UserStatuses;
