import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import UsersListTable from "./UsersListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import UserRoles from "../../forms/UserRoles";
import UserStatuses from "../../forms/UserStatuses";
import NotAllowed from "../../other/NotAllowed";

class UsersList extends Component {
    state = {
        showNOR: 25, //NOR = NumberOfRecords
        displayNOR: 25,
        usersList: this.props.usersList ? this.props.usersList : null,
        searchedPhrase: "",
        loading: this.props.usersList ? false : true,
        selectedUserRoles: "all",
        selectedUserStatuses: "all",
        disallowPermission: false,
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getUsersList();
    };

    getUsersList = async () => {
        const { fetchURL, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/users-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response
                    .json()
                    .then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === true) {
                            const usersList = fetchedData.usersList;

                            this.setState({ usersList: usersList }, () => {
                                this.setState({ loading: false });
                            });
                            this.props.onStoreDataList(usersList);
                        } else if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                            this.setState({ disallowPermission: true, loading: false });
                        } else {
                            onShowAlert({ variant: "danger", message: fetchedData.message });
                        }
                    })
                    .then(() => {
                        this.setState({ loading: false });
                    });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUsersList, 4000);
        }
    };

    componentDidMount() {
        if (!this.props.usersList) this.getUsersList();
    }

    render() {
        const { usersList, loading, displayNOR, showNOR, selectedUserRoles, selectedUserStatuses, disallowPermission } = this.state;
        const { fetchURL, onChangeNavAndPage, currentPage } = this.props;

        let filteredUserList = null;
        if (usersList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredUserList = usersList.filter(
                (e) =>
                    (selectedUserRoles === "all" || (e.user_roles_list && e.user_roles_list.includes(selectedUserRoles))) &&
                    (selectedUserStatuses === "all" || e.user_stat_code === selectedUserStatuses)
                /* &&
                    (selectedUserType === "all" || e.user_type_code === selectedUserType) &&
                    (selectedSubtestType === "all" || e.subtest_type_code === selectedSubtestType) &&
                    (selectedUserCat === "all" || e.user_category_code === selectedUserCat) */
            );
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                {loading ? (
                    <div className="mt-4">
                        <LoadingProgressBar />
                    </div>
                ) : disallowPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        <Container fluid className="second-bar">
                            <Fragment>
                                <Row>
                                    <Col>
                                        <Button
                                            className="my-4"
                                            variant="primary"
                                            type="button"
                                            onClick={() => onChangeNavAndPage({ nav: "adminPanel", subpage: "addUser", params: "" }, currentPageParams)}
                                        >
                                            Dodaj użytkownika
                                        </Button>
                                    </Col>
                                    <Col className="text-center">
                                        <p className="h3 my-4" style={{ lineHeight: "38px" }}>
                                            Lista użytkowników
                                        </p>
                                    </Col>
                                    <Col></Col>
                                </Row>
                                <Row>
                                    <Col lg={3} md={5} xs={6}>
                                        <UserRoles
                                            defaultValue="all"
                                            filter
                                            value={selectedUserRoles}
                                            onUserRolesSelected={(event, value) => {
                                                this.setState({ selectedUserRoles: value });
                                            }}
                                            fetchURL={fetchURL}
                                        />
                                    </Col>
                                    <Col lg={3} md={5} xs={6}>
                                        <UserStatuses
                                            defaultValue="all"
                                            value={selectedUserStatuses}
                                            onUserStatusesSelected={(event, value) => {
                                                this.setState({ selectedUserStatuses: value });
                                            }}
                                            fetchURL={fetchURL}
                                        />
                                    </Col>

                                    <Col lg={{ span: 2, offset: 4 }} md={2} xs={12} className="text-left text-lg-right text-md-right mt-md-3 mb-3">
                                        <OverlayTrigger key="resetFiltersTop" placement="top" overlay={<Tooltip id="resetFilters">Resetuj filtry</Tooltip>}>
                                            <Button variant="outline-primary" className="mr-1" onClick={() => this.setState({ selectedUserRoles: "all", selectedUserStatuses: "all" })}>
                                                <FontAwesomeIcon icon={faRedoAlt} />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger key="refreshDataTop" placement="top" overlay={<Tooltip id="refreshData">Odśwież dane</Tooltip>}>
                                            <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync">
                                                <FontAwesomeIcon icon={faSync} />
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Fragment>
                        </Container>
                        <Container fluid className="content-body">
                            {!filteredUserList ? (
                                <LoadingProgressBar />
                            ) : (
                                <Fragment>
                                    {filteredUserList.length > 0 ? (
                                        <Fragment>
                                            <Row>
                                                <Col>
                                                    <div className="table-responsive table-fixed-head">
                                                        <UsersListTable
                                                            usersList={filteredUserList}
                                                            displayNOR={displayNOR}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            fetchURL={fetchURL}
                                                            onRefreshList={this.handleDownloadData}
                                                        />
                                                        <div className="light-border"></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="rest-info py-3">
                                                <Col>
                                                    Załadowano {displayNOR > filteredUserList.length ? filteredUserList.length : displayNOR} z {filteredUserList.length} rekordów.
                                                </Col>
                                                <Col className="text-center">
                                                    {displayNOR >= filteredUserList.length ? (
                                                        <p className="opacity-4">
                                                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                            <span className="px-3">to wszystko</span>
                                                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                        </p>
                                                    ) : (
                                                        <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                            załaduj więcej
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </Fragment>
                                    ) : (
                                        <Row>
                                            <Col className="text-center mt-3 font-weight-bold">Lista jest pusta.</Col>
                                        </Row>
                                    )}
                                </Fragment>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default UsersList;
