import React, { Fragment } from "react";
import MyAccount from "../../pages/MyAccount";
const Settings = (props) => {
    const { fetchURL, loggedUserPrivilages, onChangeValuesInForms, onCancelChangeValuesInForms, currentPage, onShowAlert, myAccountData, onStoreDataList, onShowNotLoggedPages } = props;
    return (
        <Fragment>
            {currentPage.nav === "myAccount" && (
                <Fragment>
                    {currentPage.subpage === "myAccount" && (
                        <MyAccount
                            fetchURL={fetchURL}
                            loggedUserPrivilages={loggedUserPrivilages}
                            onChangeValuesInForms={onChangeValuesInForms}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                            onShowAlert={onShowAlert}
                            myAccountData={myAccountData}
                            onShowNotLoggedPages={onShowNotLoggedPages}
                            onStoreDataList={onStoreDataList}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Settings;
