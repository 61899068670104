import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { faEdit, faFileAlt, faSort, faSortDown, faSortUp, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class EvaluationsListTable extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
        showSendEvaluationForEvaluationModal: false,
        selectedEvaluation: null,
        showComment: false,
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { currentSortType, currentSortingColumn, showComment } = this.state;
        const { evaluationsList, displayNOR, onChangeNavAndPage } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.evaluation_id - b.evaluation_id, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                fnEvaluationName: (a, b) => {
                    if (a.evaluation_name.toUpperCase() > b.evaluation_name.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.evaluation_id - a.evaluation_id,
                fnEvaluationName: (a, b) => {
                    if (a.evaluation_name.toUpperCase() > b.evaluation_name.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnEvaluationName: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (currentSortingColumn === "sortID") sortedData = evaluationsList.sort(sortTypes[currentSortType].fnID);
        else if (currentSortingColumn === "sortEvaluationName") sortedData = evaluationsList.sort(sortTypes[currentSortType].fnEvaluationName);
        else sortedData = evaluationsList.sort(sortTypes[currentSortType].fnID);

        const currentPageParams = { nav: "evaluations", subpage: "evaluationsList", params: "" };

        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>L.p.</th>
                            <th>
                                <span className="align-middle">Nazwa pytania</span>
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortEvaluationName" onClick={() => this.handleSorting("sortEvaluationName")}>
                                    <FontAwesomeIcon icon={currentSortingColumn !== "sortEvaluationName" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort} />
                                </Button>
                            </th>
                            <th>Część testu</th>
                            <th>Typ pytania</th>
                            <th>Kategoria pytania</th>
                            <th>Kwalifikacja pytania do testu</th>
                            <th>Stopień trudności pytania</th>
                            <th>Data wystawienia oceny</th>

                            <th style={{ width: "160px", minWidth: "120px" }}>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{p.question_name}</td>
                                    <td>{p.subtest_type_name}</td>
                                    <td>{p.question_type_name}</td>
                                    <td>{p.question_category_name}</td>
                                    <td>{p.eval_result_name ? p.eval_result_name : <span className="opacity-4">brak</span>}</td>
                                    <td>{p.eval_diff_name ? p.eval_diff_name : <span className="opacity-4">brak</span>}</td>
                                    <td>{p.eval_date ? p.eval_date : <span className="opacity-4">brak</span>}</td>
                                    <td className="text-center">
                                        {p.eval_comment && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`showEvaluationComment${i}`}>Pokaż komentarz</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button variant="outline-primary" className="mx-1 btn-list" onClick={() => this.setState({ showComment: p.eval_comment })}>
                                                        <FontAwesomeIcon icon={faFileAlt} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                        {!p.eval_date ? (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`doEvaluation${i}`}>Oceń pytanie</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => onChangeNavAndPage({ nav: "evaluations", subpage: "doEvaluation", params: { questionID: p.question_id } }, currentPageParams, null)}
                                                        className="mx-1 btn-list"
                                                    >
                                                        <FontAwesomeIcon icon={faStarHalfAlt} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        ) : (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`editEvaluation${i}`}>Edytuj ocenę</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => onChangeNavAndPage({ nav: "evaluations", subpage: "editEvaluation", params: { questionID: p.question_id } }, currentPageParams, null)}
                                                        className="mx-1 btn-list"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>

                {showComment && (
                    <Modal show={showComment} onHide={() => this.setState({ showComment: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Treść komentarza do pytania</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{showComment}</Modal.Body>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default EvaluationsListTable;
