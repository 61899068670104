import React from "react";
import { Col } from "react-bootstrap";

const Indexes = () => {
    return (
        <Col xs={12}>
            <p className="text-small mb-0">
                1 -{" "}
                <i>
                    Jeżeli osoba badana to uczeń/uczennica kształcąca się na I lub III etapie edukacyjnym albo też jest osobą dorosłą, to otrzymany przez nią wynik będzie reprezentowany na najbliższej
                    wiekowo grupie normalizacyjnej. Więcej informacji na temat interpretacji wyniku badania można znaleźć w publikacji adresowanej do diagnostów.
                </i>
            </p>
            <p className="text-small my-2 mb-5">
                2 - <i>Wynik uzyskany przez ucznia jest taki sam lub wyższy od wyniku wszystkich osób badanych danym testem w próbie normalizacyjnej.</i>
            </p>
        </Col>
    );
};

export default Indexes;
