import React, { Fragment, Component } from "react";
import { Col, Form, Button, Image } from "react-bootstrap";
import EmailInput from "../forms/EmailInput";

const validateEmail = (email) => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

class RememberPass extends Component {
    state = { user_email: "" };

    resetPassword = async (passData) => {
        const { fetchURL, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/reset-password`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                onShowAlert({ variant: "info", message: "Resetowanie hasła..." });
                await response.json().then((data) => {
                    //console.log(data);
                    if (data.status === true) onShowAlert({ variant: "success", message: data.message, fading: 2000 });
                    else onShowAlert({ variant: "danger", message: data.message, fading: 2000 });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "success", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." });
            setTimeout(this.resetPassword(passData), 4000);
        }
    };

    handleSendEmail = (e) => {
        const { onShowAlert } = this.props;
        e.preventDefault();
        onShowAlert("");
        const { user_email } = this.state;
        if (!user_email || !validateEmail(user_email)) {
            onShowAlert({ variant: "danger", message: "Pole e-mail jest nieprawidłowe.", fading: 2000 });
        } else {
            const passedData = {
                email: user_email,
            };
            this.resetPassword(passedData);
        }
    };

    render() {
        const { user_email } = this.state;
        const { onChangeSubpage } = this.props;
        return (
            <Fragment>
                <Col lg={{ span: 6, offset: 3 }} className="text-center">
                    <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="my-3" style={{ height: "16rem" }} />
                </Col>
                <Col lg={{ span: 6, offset: 3 }}>
                    <h1 className="h3 mb-3 text-center">Przypomnij mi hasło</h1>
                    <Form onSubmit={this.handleSendEmail} id="rememberPassForm" autoComplete="off">
                        <EmailInput value={user_email} onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })} id="userEmailResetPass" name="user_email" />

                        <Form.Group controlId="rememberPassSumbit" className="text-center">
                            <Button variant="primary" type="submit">
                                resetuj hasło
                            </Button>
                        </Form.Group>
                    </Form>
                </Col>
                <Col xs={12} className="text-center mt-2">
                    <Button onClick={() => onChangeSubpage("login")} variant="outline-secondary" size="sm" className="mb-3 mx-1">
                        wróć do logowania
                    </Button>
                    <Button
                        onClick={() => {
                            this.props.onGoToMainPage();
                        }}
                        variant="outline-secondary"
                        size="sm"
                        className="mb-3 mx-1"
                    >
                        wróć na stronę główną
                    </Button>
                </Col>
            </Fragment>
        );
    }
}

export default RememberPass;
