import React, { Component, Fragment } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import Report from "../pages/reports/Report";
import LoadingSpinnerSm from "../../components/spinners/LoadingSpinnerSm";

class ReportModal extends Component {
    state = {
        loading: true,
        basicData: null,
        detailedData: null,
    };

    getTestObjectData = async (testObjectID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            testObjectID: testObjectID,
        };
        try {
            const response = await fetch(`${fetchURL}/get-test-object-data`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else {
                        onShowAlert("");
                        this.setState({ loading: false, basicData: fetchedData.basicData, detailedData: fetchedData.detailedData });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
        }
    };

    componentDidMount() {
        this.getTestObjectData(this.props.testObjectID);
    }

    render() {
        const { loading, basicData, detailedData } = this.state;
        const { show, onClose, subtestTypeCode } = this.props;

        return (
            <div>
                <Modal show={show} onHide={onClose} id="reportModal">
                    <Modal.Dialog scrollable>
                        <Modal.Body>
                            {loading ? (
                                <Row>
                                    <Col className="text-center">
                                        <p className="h5">Generuję raport</p>

                                        <LoadingSpinnerSm classN="mx-auto" />
                                    </Col>
                                </Row>
                            ) : (
                                <Fragment>
                                    <Report ref={(response) => (this.componentRef = response)} basicData={basicData} detailedData={detailedData} subtestTypeCode={subtestTypeCode} />
                                </Fragment>
                            )}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" type="button" onClick={onClose}>
                                Anuluj
                            </Button>
                            {!loading && (
                                <ReactToPrint
                                    content={() => this.componentRef}
                                    trigger={() => (
                                        <Button variant="primary" type="button" disabled={loading}>
                                            Drukuj do PDF
                                        </Button>
                                    )}
                                    documentTitle={`Raport badania ${basicData.test_date} ${basicData.to_name}`}
                                />
                            )}
                        </Modal.Footer>
                    </Modal.Dialog>
                </Modal>
            </div>
        );
    }
}

export default ReportModal;
