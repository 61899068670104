import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

class TestStatuses extends Component {
    state = {
        testStatusesList: [],
        value: this.props.defaultValue ? this.props.defaultValue : null,
    };

    getTestStatuses = async () => {
        const { fetchURL, defaultValue } = this.props;
        try {
            const response = await fetch(`${fetchURL}/test-statuses`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        let testStatusesList = fetchedData.testStatusesList;
                        if (defaultValue === "all") {
                            testStatusesList.unshift({
                                key: 0,
                                value: "all",
                                text: "Wszystkie",
                            });
                        }
                        this.setState({ testStatusesList: testStatusesList });
                    } else if (fetchedData.status === false) {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status } });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.getTestStatuses(), 4000);
        }
    };

    componentDidMount() {
        this.getTestStatuses();
    }

    componentDidUpdate() {
        if (this.props.defaultValue !== this.state.value) this.setState({ value: this.props.defaultValue });
    }

    render() {
        const { testStatusesList, value } = this.state;
        const { onSelectedTestStatus } = this.props;
        return (
            <Fragment>
                <Form.Group>
                    <Form.Label>Status testu</Form.Label>

                    <Dropdown
                        id="testStatusesDropdown"
                        className="mb-3"
                        placeholder="Wszystkie"
                        fluid
                        value={value}
                        selection
                        options={testStatusesList}
                        onChange={(event, { value }) => {
                            onSelectedTestStatus(event, value);
                        }}
                    />
                </Form.Group>
            </Fragment>
        );
    }
}

export default TestStatuses;
