import { faEye, faFilePdf, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, Component } from "react";
import { Button, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import ReportModal from "../../modals/ReportModal";
import { withRouter } from "react-router-dom";

class TestsListTable extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
        alert: "",
        showContinueTestModal: false,
        testObject: null,
        subtestTypeCode: null,
        showReportModal: false,
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    render() {
        const { showContinueTestModal, testObject, showReportModal, subtestTypeCode } = this.state;
        const { testList, displayNOR, currentPageParams, onChangeNavAndPage, testType, fetchURL, onShowAlert } = this.props;
        let sortedData = testList;
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>L.p.</th>
                            {testType === "all" && <th>Typ testu</th>}
                            <th>Część testu</th>
                            <th>Imię osoby badanej</th>
                            <th>Rok urodzenia osoby badanej</th>
                            <th>Miesiąc urodzenia osoby badanej</th>
                            <th>Klasa osoby badanej</th>
                            <th>Stopień niedosłuchu</th>
                            <th>Protezowanie</th>
                            {testType === "all" && <th>Badanie przeprowadził/-ła</th>}
                            <th>Status testu</th>
                            <th>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    {testType === "all" && (
                                        <td>
                                            {p.test_type_code === "PILOT" ? (
                                                "Test pilotażowy"
                                            ) : p.test_type_code === "NORM" ? (
                                                "Test normalizacyjny"
                                            ) : p.test_type_code === "FINAL" ? (
                                                "Test ostateczny"
                                            ) : (
                                                <span className="opacity-4">Brak danych</span>
                                            )}
                                        </td>
                                    )}
                                    <td>{p.subtests_type_code}</td>
                                    <td>{p.to_name}</td>
                                    <td>{p.to_birth_year}</td>
                                    <td>{p.to_birth_month}</td>
                                    <td>{p.to_grade_name}</td>
                                    <td>{p.to_hl_name ? p.to_hl_name : "Brak"}</td>
                                    <td>{p.to_prosthesis_name ? p.to_prosthesis_name : "Brak"}</td>
                                    {testType === "all" && <td>{p.user_fullname}</td>}
                                    <td>
                                        {p.tests_status_code === "INPROGRESS" ? (
                                            <span className="text-info">Nowy</span>
                                        ) : p.tests_status_code === "PAUSED" ? (
                                            <span className="text-warning">Wstrzymany</span>
                                        ) : p.tests_status_code === "CANCELED" ? (
                                            <span className="text-danger">Anulowany</span>
                                        ) : p.tests_status_code === "DONE" ? (
                                            <span className="text-success">Zakończony</span>
                                        ) : (
                                            <span className="opacity-4">Brak danych</span>
                                        )}
                                    </td>

                                    <td className="text-center">
                                        {(p.tests_status_code === "PAUSED" || p.tests_status_code === "INPROGRESS") && testType !== "all" && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`continueTest${i}`}>Kontynuuj test</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            this.setState({
                                                                showContinueTestModal: true,
                                                                testObject: {
                                                                    objectName: p.to_name,
                                                                    objectBirthMonth: p.to_birth_month,
                                                                    objectBirthYear: p.to_birth_year,
                                                                    testLink: p.to_link,
                                                                },
                                                            })
                                                        }
                                                        className="mx-1 btn-list"
                                                    >
                                                        <FontAwesomeIcon icon={faPlay} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                        {(p.tests_status_code === "DONE" || testType === "all") && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`testDetails${i}`}>Zobacz szczegóły testu</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() =>
                                                            onChangeNavAndPage({ nav: "allTests", subpage: "testDetails", params: { testObjectID: p.to_id, testType: testType } }, currentPageParams)
                                                        }
                                                        className="mx-1 btn-list mb-1"
                                                    >
                                                        <FontAwesomeIcon icon={faEye} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                        {p.tests_status_code === "DONE" && p.test_type_code === "FINAL" && (
                                            <Fragment>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`testDetails${i}`}>Raport badania</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() => this.setState({ showReportModal: true, testObject: p.to_id, subtestTypeCode: p.subtests_type_code })}
                                                            className="mx-1 btn-list mb-1"
                                                        >
                                                            <FontAwesomeIcon icon={faFilePdf} />
                                                        </Button>
                                                    </span>
                                                </OverlayTrigger>
                                            </Fragment>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>

                {showReportModal && (
                    <ReportModal
                        show={showReportModal}
                        onClose={() => this.setState({ showReportModal: false })}
                        testObjectID={testObject}
                        fetchURL={fetchURL}
                        onShowAlert={onShowAlert}
                        subtestTypeCode={subtestTypeCode}
                    />
                )}

                {showContinueTestModal && (
                    <Modal show={showContinueTestModal} onHide={() => this.setState({ showContinueTestModal: false, testObject: null })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Kontynuacja testu</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Czy na pewno chcesz kontynuować test dla następującego obiektu testowego: {testObject.objectName} ({testObject.objectBirthMonth} {testObject.objectBirthYear})?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-primary" type="button" onClick={() => this.setState({ showContinueTestModal: false, testObject: null })}>
                                Nie, zostań
                            </Button>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={() => this.setState({ showContinueTestModal: false, testObject: null }, () => this.props.history.push(`/test/${testObject.testLink}`))}
                            >
                                Tak, kontynuuj
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default withRouter(TestsListTable);
