import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckCircle, faFemale, faPlay } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

import { Button } from "react-bootstrap";

const TestInstructionPL = (props) => {
    const { onChangeTestView, frontURL } = props;
    $(document).ready(function () {
        $("body").addClass("hide-cursor");

        //animacja najazdu powiększenia ekranu
        setTimeout(() => {
            $(".displayLogo").addClass("resize");
            $(".boyComp").fadeOut(500);
        }, 3000);
        //ukrycie logo
        setTimeout(() => {
            $(".displayLogo").fadeOut(500);
            $(".display").fadeIn(0);
        }, 6000);
        //pojawienie się tytułu testu
        setTimeout(() => {
            $("h1").fadeIn(300);
        }, 7000);
        //ukrycie tytułu
        setTimeout(() => {
            $("h1").fadeOut(300);
        }, 10000);
        //pojawienie się testu1
        setTimeout(() => {
            $(".point").fadeIn(0);
            $("#test4").fadeIn(500);
            $(".arrowRight").fadeIn(500);
        }, 10300);
        //pojawienie się myślącego chłopca
        setTimeout(() => {
            $("#boyThinking").fadeIn(500);
        }, 11000);
        //pojawienie się dymków
        setTimeout(() => {
            $(".cloud1").fadeIn(0);
            $(".cloud1").addClass("bounce-cloud");
        }, 13000);
        setTimeout(() => {
            $(".cloud2").fadeIn(0);
            $(".cloud2").addClass("bounce-cloud");
        }, 13500);
        setTimeout(() => {
            $(".cloudQ").fadeIn(0);
            $(".cloudQ").addClass("bounce-cloud");
        }, 14000);
        //zmiana z myślącego chłopca na z pomysłem
        setTimeout(() => {
            $("#boyThinking").fadeOut(0);
            $(".cloud1").removeClass("bounce-cloud");
            $(".cloud2").removeClass("bounce-cloud");
            $(".cloudQ").removeClass("bounce-cloud");
            $(".cloud1").fadeOut(0);
            $(".cloud2").fadeOut(0);
            $(".cloudQ").fadeOut(0);
            $("#boyIdea").fadeIn(0);
            $("#bulb").fadeIn(0);
            $("#bulb").addClass("bounce-cloud");
        }, 16000);
        //przesunięcie kursora na poprawną odpowiedź
        setTimeout(() => {
            $(".point").addClass("moveToTest4CheckAnswer");
            $(".pointClick").addClass("Test4CheckAnswerPos");
        }, 18000);
        //zaznaczenie poprawnej odpowiedzi
        setTimeout(() => {
            $(".point").removeClass("moveToTest4CheckAnswer");
            $(".point").addClass("Test4CheckAnswerPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("#test4 .answerB img").addClass("checked");
            $(".arrowRight").removeClass("disabled");
        }, 20000);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test4CheckAnswerPos");
            $(".pointClick").fadeOut(0);
        }, 20300);
        //przesunięcie kursora na przycisk do następnej planszy
        setTimeout(() => {
            $(".point").removeClass("Test4CheckAnswerPos");
            $(".point").addClass("moveToTest5");
            $(".pointClick").addClass("rightBtnPos");
        }, 21500);
        //klinięcie następnego testu, zniknięcie testu4, zniknięcie chłopca z pomysłem
        setTimeout(() => {
            $(".point").removeClass("moveToTest5");
            $(".point").addClass("rightBtnPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $(".arrowRight").addClass("disabled");

            $("#test4").fadeOut(500);
            $("#boyIdea").fadeOut(50);
            $("#bulb").fadeOut(50);
        }, 23500);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("rightBtnPos");
            $(".pointClick").fadeOut(0);
        }, 23800);
        //pojawienie się testu5 i przycisku cofającego do poprzedniego pytania
        setTimeout(() => {
            $("#test5").fadeIn(500);
            $(".arrowLeft").fadeIn(500);
        }, 24000);
        //pojawienie się myślącego chłopca
        setTimeout(() => {
            $("#boyThinking").fadeIn(500);
        }, 25000);
        //pojawienie się dymków
        setTimeout(() => {
            $(".cloud1").fadeIn(0);
            $(".cloud1").addClass("bounce-cloud");
        }, 27000);
        setTimeout(() => {
            $(".cloud2").fadeIn(0);
            $(".cloud2").addClass("bounce-cloud");
        }, 27500);
        setTimeout(() => {
            $(".cloudQ").fadeIn(0);
            $(".cloudQ").addClass("bounce-cloud");
        }, 28000);
        //zmiana z myślącego chłopca na z pomysłem
        setTimeout(() => {
            $("#boyThinking").fadeOut(0);
            $(".cloud1").removeClass("bounce-cloud");
            $(".cloud2").removeClass("bounce-cloud");
            $(".cloudQ").removeClass("bounce-cloud");
            $(".cloud1").fadeOut(0);
            $(".cloud2").fadeOut(0);
            $(".cloudQ").fadeOut(0);
            $("#boyIdea").fadeIn(0);
            $("#bulb").fadeIn(0);
            $("#bulb").addClass("bounce-cloud");
        }, 30000);
        //przesunięcie kursora na poprawną odpowiedź
        setTimeout(() => {
            $(".point").removeClass("rightBtnPos");
            $(".point").addClass("moveToTest5CheckAnswer");
            $(".pointClick").addClass("Test5CheckAnswerPos");
        }, 32500);
        //zaznaczenie poprawnej odpowiedzi
        setTimeout(() => {
            $(".point").removeClass("moveToTest5CheckAnswer");
            $(".point").addClass("Test5CheckAnswerPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("#test5 .answerA .word").addClass("checked");
            $(".arrowRight").removeClass("disabled");
        }, 34500);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test5CheckAnswerPos");
            $(".pointClick").fadeOut(0);
        }, 34800);
        //przesunięcie kursora na przycisk do następnej planszy
        setTimeout(() => {
            $(".point").removeClass("Test5CheckAnswerPos");
            $(".point").addClass("moveToTest6");
            $(".pointClick").addClass("rightBtnPos");
        }, 36500);
        //klinięcie następnego testu, zniknięcie testu4, zniknięcie chłopca z pomysłem
        setTimeout(() => {
            $(".point").removeClass("moveToTest6");
            $(".point").addClass("rightBtnPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);

            $("#test5").fadeOut(500);
            $(".arrowRight").fadeOut(500);
            $("#boyIdea").fadeOut(50);
            $("#bulb").fadeOut(50);
        }, 39500);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("rightBtnPos");
            $(".pointClick").fadeOut(0);
        }, 39800);
        //pojawienie się testu6 i przycisku cofającego do poprzedniego pytania
        setTimeout(() => {
            $("#test6").fadeIn(500);
            $("#testDone").fadeIn(500);
        }, 40000);
        //pojawienie się myślącego chłopca
        setTimeout(() => {
            $("#boyThinking").fadeIn(500);
        }, 42000);
        //pojawienie się dymków
        setTimeout(() => {
            $(".cloud1").fadeIn(0);
            $(".cloud1").addClass("bounce-cloud");
        }, 44000);
        setTimeout(() => {
            $(".cloud2").fadeIn(0);
            $(".cloud2").addClass("bounce-cloud");
        }, 44500);
        setTimeout(() => {
            $(".cloudQ").fadeIn(0);
            $(".cloudQ").addClass("bounce-cloud");
        }, 45000);
        //zmiana z myślącego chłopca na z pomysłem
        setTimeout(() => {
            $("#boyThinking").fadeOut(0);
            $(".cloud1").removeClass("bounce-cloud");
            $(".cloud2").removeClass("bounce-cloud");
            $(".cloudQ").removeClass("bounce-cloud");
            $(".cloud1").fadeOut(0);
            $(".cloud2").fadeOut(0);
            $(".cloudQ").fadeOut(0);
            $("#boyIdea").fadeIn(0);
            $("#bulb").fadeIn(0);
            $("#bulb").addClass("bounce-cloud");
        }, 47000);
        //pojawienie się ikonek synonimów
        setTimeout(() => {
            $("#femaleIcon1").fadeIn(1000);
        }, 48000);
        setTimeout(() => {
            $("#femaleIcon2").fadeIn(1000);
        }, 49500);
        //przesunięcie kursora na poprawną odpowiedź
        setTimeout(() => {
            $(".point").removeClass("rightBtnPos");
            $(".point").addClass("moveToTest6CheckAnswer");
            $(".pointClick").addClass("Test6CheckAnswerPos");
        }, 51000);
        //zaznaczenie poprawnej odpowiedzi
        setTimeout(() => {
            $(".point").removeClass("moveToTest6CheckAnswer");
            $(".point").addClass("Test6CheckAnswerPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("#test6 .answerC .word").addClass("checked");
            $("#testDone").removeClass("disabled");
        }, 52500);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test6CheckAnswerPos");
            $(".pointClick").fadeOut(0);
        }, 52800);
        //przesunięcie kursora na przycisk do następnej planszy
        setTimeout(() => {
            $(".point").removeClass("Test6CheckAnswerPos");
            $(".point").addClass("moveToTFinishTestPL");
            $(".pointClick").addClass("rightBtnPos");
        }, 54000);

        //koniec testu
        setTimeout(() => {
            $(".point").removeClass("moveToTFinishTestPL");
            $(".point").addClass("rightBtnPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("body").removeClass("hide-cursor");
            $(".instruction").fadeOut(1500);
        }, 56000);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("rightBtnPos");
            $(".pointClick").fadeOut(0);
        }, 56300);
        //pokaż przycisk do startu testu
        setTimeout(() => {
            $("#startTest").fadeIn(300);
        }, 58000);
    });
    return (
        <Fragment>
            <p className="titleInstr pl">PL</p>
            <div className="instruction">
                <div>
                    <img src={`${frontURL}/images/boyCompPL.svg`} className="boyComp" alt="boyComp" />
                    <img src={`${frontURL}/images/displayLogo.svg`} className="displayLogo" alt="displayLogo" />
                    <img src={`${frontURL}/images/display.svg`} className="display" alt="display" style={{ display: "none" }} />
                    <h1 className="text-secondary" style={{ display: "none" }}>
                        PL
                    </h1>
                    <img src={`${frontURL}/images/boyThinkingPL.svg`} id="boyThinking" alt="boyThinking" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/boyIdeaPL.svg`} id="boyIdea" alt="boyIdea" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/bulb.svg`} id="bulb" alt="bulb" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/cloud1.svg`} className="cloud1" alt="cloud1" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/cloud2.svg`} className="cloud2" alt="cloud2" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/cloudQ.svg`} className="cloudQ" alt="cloudQ" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/point.svg`} className="point" alt="point" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/pointClick.svg`} className="pointClick" alt="pointClick" style={{ display: "none" }} />
                    <div className="arrowLeft pl" style={{ display: "none" }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="test" id="test4" style={{ display: "none" }}>
                        <div className="qitem question">
                            <div className="word">
                                <p>cytryna</p>
                            </div>
                        </div>
                        <div className="test-border"></div>
                        <div className="qitem answer pl answerA">
                            <img src="https://test-leksyka.pl/questions/35b.jpg" alt="35b.jpg" />
                        </div>
                        <div className="qitem answer pl answerB">
                            <img src="https://test-leksyka.pl/questions/35d_1.jpg" alt="35d_1.jpg" />
                        </div>
                        <div className="qitem answer pl answerC">
                            <img src="https://test-leksyka.pl/questions/455a_1.jpg" alt="455a_1.jpg" />
                        </div>
                        <div className="qitem answer pl answerD">
                            <img src="https://test-leksyka.pl/questions/r5d_1_1.jpg" alt="r5d_1_1.jpg" />
                        </div>
                    </div>

                    <div className="test" id="test5" style={{ display: "none" }}>
                        <div className="qitem question">
                            <img src="https://test-leksyka.pl/questions/r5d_1_1.jpg" alt="r5d_1_1.jpg" />
                        </div>
                        <div className="test-border"></div>
                        <div className="qitem answer pl answerA">
                            <div className="word">
                                <p>jabłko</p>
                            </div>
                        </div>
                        <div className="qitem answer pl answerB">
                            <div className="word">
                                <p>cytryna</p>
                            </div>
                        </div>
                        <div className="qitem answer pl answerC">
                            <div className="word">
                                <p>banan</p>
                            </div>
                        </div>
                        <div className="qitem answer pl answerD">
                            <div className="word">
                                <p>gruszka</p>
                            </div>
                        </div>
                    </div>

                    <div className="test" id="test6" style={{ display: "none" }}>
                        <div id="femaleIcon1" style={{ display: "none" }}>
                            <FontAwesomeIcon icon={faFemale} />
                        </div>
                        <div id="femaleIcon2" style={{ display: "none" }}>
                            <FontAwesomeIcon icon={faFemale} />
                        </div>

                        <div className="qitem question">
                            <div className="word">
                                <p>pani</p>
                            </div>
                        </div>
                        <div className="test-border"></div>
                        <div className="qitem answer pl answerA">
                            <div className="word">
                                <p>pan</p>
                            </div>
                        </div>
                        <div className="qitem answer pl answerB">
                            <div className="word">
                                <p>mężczyzna</p>
                            </div>
                        </div>
                        <div className="qitem answer pl answerC">
                            <div className="word">
                                <p>kobieta</p>
                            </div>
                        </div>
                        <div className="qitem answer pl answerD">
                            <div className="word">
                                <p>dziadek</p>
                            </div>
                        </div>
                    </div>

                    <div className="arrowRight pl disabled" style={{ display: "none" }}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    <div id="testDone" className="pl disabled" style={{ display: "none" }}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                </div>
            </div>

            <Button variant="secondary" id="startTest" className="pl" onClick={() => onChangeTestView("testView")} style={{ display: "none" }}>
                <FontAwesomeIcon icon={faPlay} />
            </Button>
        </Fragment>
    );
};

export default TestInstructionPL;
