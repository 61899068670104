import React, { Fragment } from "react";
import { Alert, Button, Modal } from "react-bootstrap";

const SendQuestionForEvaluation = (props) => {
    const { show, onHide, alert, onSendQuestion, onHideAndRefresh } = props;
    return (
        <Fragment>
            <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Wyślij do oceny</Modal.Title>
                </Modal.Header>
                <Modal.Body>Czy na pewno chcesz wysłać pytanie do oceny dla sędziów?{alert && <Alert variant={alert.variant}>{alert.message}</Alert>}</Modal.Body>
                <Modal.Footer>
                    {alert ? (
                        <Button variant="outline-secondary" onClick={onHideAndRefresh}>
                            Zamknij
                        </Button>
                    ) : (
                        <Fragment>
                            <Button variant="outline-secondary" type="button" onClick={onHide}>
                                Nie, anuluj
                            </Button>
                            <Button variant="primary" type="button" onClick={onSendQuestion}>
                                Tak, wyślij
                            </Button>
                        </Fragment>
                    )}
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default SendQuestionForEvaluation;
