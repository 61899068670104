import React, { Component } from "react";
import { Col, Button, Nav, Row, Image } from "react-bootstrap";
import $ from "jquery";

class Presentation extends Component {
    state = {};

    componentDidMount() {
        $("#presentation").fadeIn();

        $(window).on("scroll", function (e) {
            if ($(window).scrollTop() > 400) $("#presentationNavbar").addClass("bg");
            else $("#presentationNavbar").removeClass("bg");
        });
    }

    scrollToDetail = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    };

    render() {
        const { onGoToMainPage } = this.props;
        return (
            <Col>
                <Row>
                    <Col lg={{ span: 6, offset: 3 }} className="text-center">
                        <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="my-3" style={{ height: "16rem" }} alt="logo testu leksykalnego PJM PL" />
                    </Col>
                </Row>

                <Row id="presentation" style={{ display: "none" }}>
                    <Col className="text-center" xs={12}>
                        <h1 className="mb-5 mt-4">Test Leksyka PJM-PL</h1>

                        <Nav id="presentationNavbar" className="w-100">
                            <Nav.Item>
                                <Button variant="link" onClick={onGoToMainPage}>
                                    strona główna
                                </Button>
                            </Nav.Item>
                            <Nav.Item>
                                <Button variant="link" onClick={() => this.scrollToDetail("details")}>
                                    Informacje o teście
                                </Button>
                            </Nav.Item>
                            <Nav.Item>
                                <Button variant="link" onClick={() => this.scrollToDetail("test")}>
                                    Jak wygląda test?
                                </Button>
                            </Nav.Item>
                            <Nav.Item>
                                <Button variant="link" onClick={() => this.scrollToDetail("authors")}>
                                    Autorzy
                                </Button>
                            </Nav.Item>
                        </Nav>
                    </Col>

                    <Col id="details" xs={12}>
                        <p className="h3 pt-5 mt-3 text-primary">Informacje o teście</p>
                        <p className="h5 mb-3 mt-5">Co to jest Test Leksyka PJM-PL?</p>
                        <p>
                            Jest to narzędzie diagnostyczne, które może być wykorzystywane przez diagnostów pracujących z uczniami z wadą słuchu. Test ten bada znajomość słów w języku polskim i
                            migowym (PJM) i pozwala określić język preferowany ucznia. Narzędzie zostało przygotowane w ramach projektu „Inkubator Innowacji Społecznych Generator Dostępności w ramach
                            Programu Operacyjnego Wiedza Edukacja Rozwój 2014-2020” współfinansowanego ze środków Europejskiego Funduszu Społecznego.
                        </p>
                        <p className="h5 mb-3">Jak działa Test Leksyka PJM-PL?</p>
                        <p>
                            Test działa za pomocą aplikacji webowej. Badany uczeń może samodzielnie wykonywać zadania testowe na komputerze. By rozwiązywać zadania, uczeń nie potrzebuje pomocy
                            diagnosty, ani osoby znającej język migowy. Test ten jest dostępny on-line i działa w oparciu o przeglądarkę internetową.
                        </p>
                        <p className="h5 mb-3">Kto może korzystać z Testu Leksyka PJM-PL?</p>
                        <p>
                            Test Leksyka PJM-PL nie jest dostępny dla wszystkich. Dostęp do niego może uzyskać diagnosta, który posiada dyplom ukończenia studiów w zakresie surdologopedii,
                            surdopedagogiki. Po zarejestrowaniu się na stronie i udokumentowaniu swoich uprawnień osoba taka otrzyma pełen dostęp do aplikacji z testem, a także do podręcznika
                            opisującego koncepcję i działanie narzędzia. Ponadto będzie mogła za jego pomocą diagnozować uczniów i samodzielnie tworzyć wyniki badań. Wynik testu danego ucznia
                            diagnosta będzie mógł pobrać w postaci zestawienia, w którym zawarte będą następujące informacje:
                            <ul>
                                <li>wynik uzyskany przez ucznia w teście w języku polskim pisanym i w języku migowym;</li>
                                <li>
                                    odniesienie otrzymanego wyniku w teście badającym język migowy na tle grupy normalizacyjnej niesłyszących uczniów, natomiast w teście sprawdzającym znajomość
                                    leksyki w języku polskim wynik będzie ukazany na tle grupy normalizacyjnej uczniów niesłyszących, a także grupy normalizacyjnej uczniów słyszących.
                                </li>
                            </ul>
                        </p>
                        <p>Wynik testu jest zliczany automatycznie po zakończeniu badania.</p>
                    </Col>
                    <Col id="test" xs={12}>
                        <p className="h3 pt-5 mt-3 text-secondary">Jak wygląda test?</p>
                        <p className="h5 mb-3 mt-5">Jak jest zbudowany Test Leksyka PJM-PL?</p>
                        <p>
                            Test składa się z dwóch podtestów:
                            <ul>
                                <li>pierwszy bada leksykę w polskim języku migowym (PJM),</li>
                                <li>drugi w języku polskim pisanym.</li>
                            </ul>
                        </p>
                        <p>Każdy z nich składa się z 50 pytań, które poprzedza animowana instrukcja. Są trzy rodzaje pytań:</p>
                        <p className="h5 mb-3">WERSJA W POLSKIM JĘZYKU MIGOWYM:</p>
                        <p>
                            <ol type="a">
                                <li>
                                    1 obrazek i 4 znaki migowe – do ilustracji trzeba dobrać poprawny znak migowy, który wyświetla się na filmiku;
                                    <Image src="images/test-typ-1.png" fluid alt="przykład testu: 1 obrazek i 4 znaki migowe" />
                                </li>
                                <li>
                                    1 znak migowy i 4 obrazki – do miganego na filmiku znaku migowego trzeba dobrać właściwą ilustrację;
                                    <Image src="images/test-typ-2.png" fluid alt="przykład testu: 1 znak migowy i 4 obrazki" />
                                </li>
                                <li>
                                    1 znak migowy i 4 znaki migowe – do prezentowanego znaku migowego trzeba dobrać drugi znak, który ma synonimiczne znaczenie.
                                    <Image src="images/test-typ-3.png" fluid alt="przykład testu: 1 znak migowy i 4 znaki migowe" />
                                </li>
                            </ol>
                        </p>
                        <p className="h5 mb-3">WERSJA W JĘZYKU POLSKIM PISANYM:</p>
                        <p>
                            <ol type="a">
                                <li>
                                    1 obrazek i 4 słowa – do słowa w języku polskim trzeba dobrać właściwą ilustrację;
                                    <Image src="images/test-typ-4.png" fluid alt="1 obrazek i 4 słowa" />
                                </li>
                                <li>
                                    1 słowo i 4 obrazki – do obrazka trzeba dobrać właściwe słowo
                                    <Image src="images/test-typ-5.png" fluid alt="1 słowo i 4 obrazki" />
                                </li>
                                <li>
                                    1 słowo i 4 słowa – do podanego słowa trzeba dobrać wyraz synonimiczny.
                                    <Image src="images/test-typ-6.png" fluid alt="1 słowo i 4 słowa" />
                                </li>
                            </ol>
                        </p>
                        <p>Po wykonaniu zadania uczeń nie otrzymuje informacji, czy zrobił je dobrze. Taką informację otrzyma diagnosta tuż po zakończeniu testu.</p>
                        <p className="h5 mb-3">Jak długo trwa badanie?</p>
                        <p>Wykonanie całego testu (wersji w języku polskim i migowym) trwa około 45 minut.</p>
                    </Col>
                    <Col id="authors" xs={12}>
                        <p className="h3 pt-5 mb-4 mt-3 text-primary">Autorzy</p>
                        <p className="h5 mb-3">Autorki testu</p>
                        <Row>
                            <Col className="text-center mb-4" xs={12} lg={4}>
                                <Image src="images/Marta_Wrzesniewska-Pietrzak.png" fluid className="border border-primary rounded-circle my-3" alt="Marta Wrześniewska-Pietrzak" />
                                <p className="font-weight-bold h5">prof. UAM dr hab. Marta Wrześniewska-Pietrzak</p>
                                <p className="text-justify">
                                    – liderka grupy opracowującej narzędzie Test Leksyka PJM-PL w ramach projektu Generator Dostępności, językoznawca, glottodydaktyk, surdoglottodydaktyk pracuje na
                                    Uniwersytecie im. Adama Mickiewicza w Poznaniu, zajmuje się naukowo komunikacją i tożsamością g/Głuchych, a także nauczaniem języka polskiego jako obcego
                                    g/Głuchych.
                                </p>
                            </Col>
                            <Col className="text-center mb-4" xs={12} lg={4}>
                                <Image src="images/Anna_Wisniewska-Jankowska.png" fluid className="border border-primary rounded-circle my-3" alt="Anna Wiśniewska-Jankowska" />
                                <p className="font-weight-bold h5">dr Anna Wiśniewska-Jankowska</p>
                                <p className="text-justify">
                                    – pomysłodawczyni testu. Psycholog, surdopedagog i specjalista w dziedzinie psychoterapii dzieci i młodzieży. Na co dzień pracuje w Zespole Poradni
                                    Psychologiczno-Pedagogicznych nr 2 w Poznaniu, gdzie zajmuje się m.in. diagnozą oraz terapią dzieci i młodzieży z wadą słuchu. Łączy praktykę kliniczną z
                                    działalnością naukowo-badawczą, dydaktyczną i popularyzatorską.
                                </p>
                            </Col>

                            <Col className="text-center mb-4" xs={12} lg={4}>
                                <Image src="images/Karolina_Ruta-Korytowska.png" fluid className="border border-primary rounded-circle my-3" alt="Karolina Ruta-Korytowska" />
                                <p className="font-weight-bold h5">dr hab. Karolina Ruta-Korytowska</p>
                                <p className="text-justify">
                                    – członkini grupy opracowującej Test Leksyka PJM-PL w ramach projektu Generator Dostępności, językoznawca, glottodydaktyk, surdoglottodydaktyk, pracuje na
                                    Uniwersytecie im. A. Mickiewicza w Poznaniu. Jej zainteresowania badawczo-naukowe skupiają się wokół komunikacji g/Głuchych, uczenia języka polskiego jako obcego
                                    g/Głuchych, jak również prostej polszczyzny w komunikacji publicznej.
                                </p>
                            </Col>
                        </Row>
                        <p className="h5 my-3">Konsultacja merytoryczna w zakresie PJM</p>
                        <Row>
                            <Col className="text-center mb-4" xs={12} lg={4}>
                                <p className="font-weight-bold h5">Karina Akseńczuk</p>
                                <p className="text-justify">
                                    – tłumaczka i nauczycielka polskiego języka migowego, konsultantka merytoryczna zadań testowych z zakresu PJM; prezeska Fundacji Coda; prywatnie słyszące dziecko
                                    głuchych rodziców (CODA)
                                </p>
                            </Col>
                        </Row>
                        <p className="h5 my-3">Autorzy przygotowujący aplikację Test Leksyka PJM-PL</p>
                        <Row>
                            <Col className="text-center mb-4" xs={12} lg={4}>
                                <p className="font-weight-bold h5">Julia i Paweł Matuszakowie</p>
                                <p className="text-justify">– deweloperzy odpowiedzialni za design i stworzenie aplikacji oraz administrację danych.</p>
                            </Col>
                            <Col className="text-center mb-4" xs={12} lg={4}>
                                <p className="font-weight-bold h5">Gabriela Wołosz</p>
                                <p className="text-justify">
                                    – autorka ilustracji wykorzystanych w teście, artysta plastyk, absolwentka Uniwersytetu Artystycznego im. Magdaleny Abakanowicz w Poznaniu (Wydział Edukacji
                                    Artystycznej, specjalność malarstwo), pasjonuje się tworzeniem ilustracji i dekoracją wnętrz; nauczycielka przedmiotów artystycznych z ponad dziesięcioletnim
                                    stażem.
                                </p>
                            </Col>
                            <Col className="text-center mb-4" xs={12} lg={4}>
                                <p className="font-weight-bold h5">Michał Konwerski</p>
                                <p className="text-justify">– natywny użytkownik języka migowego PJM; osoba migająca znaki PJM w Teście Leksyka PJM-PL.</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        );
    }
}

export default Presentation;
