import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, Dropdown, Tooltip, OverlayTrigger } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import TestsListTable from "./TestsListTable";
import SubtestTypes from "../../forms/SubtestTypes";
import RowsDropdown from "../../forms/RowsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import ObjectHearingLoss from "../../forms/ObjectHearingLoss";
import ObjectProsthesis from "../../forms/ObjectProsthesis";
import TestStatuses from "../../forms/TestStatuses";
import { withRouter } from "react-router-dom";

class FinalTestsList extends Component {
    state = {
        showNOR: this.props.NOR, //NOR = NumberOfRecords
        displayNOR: this.props.NOR,
        testList: this.props.testList ? this.props.testList : null,
        originalFinalTests: null,
        searchedPhrase: "",
        loading: !this.props.testList ? true : false,
        selectedTestStatus: this.props.selectedTestStatus,
        selectedSubtestType: this.props.selectedSubtestType,
        selectedHearingLoss: this.props.selectedHearingLoss,
        selectedProsthesis: this.props.selectedProsthesis,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalFinalTests } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredFinalTests = originalFinalTests;
            //console.log(filteredFinalTests)
            filteredFinalTests = filteredFinalTests.filter(
                (el) =>
                    (el.question_ident_number && el.question_ident_number.toString().toLowerCase().includes(searchedPhrase)) ||
                    el.user_surname.toString().toLowerCase().includes(searchedPhrase) ||
                    el.user_name.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.user_nickname && el.user_nickname.toString().toLowerCase().includes(searchedPhrase)) //el.user_nickname może być nullem
            );
            this.setState({ testList: filteredFinalTests });
        } else {
            this.setState({ testList: originalFinalTests });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getFinalTestsList();
    };

    getFinalTestsList = async () => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            testType: "FINAL",
        };
        try {
            const response = await fetch(`${fetchURL}/final-tests-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const testList = fetchedData.testList;

                        this.setState(
                            {
                                testList: testList,
                                originalFinalTests: testList,
                            },
                            () => {
                                this.setState({ loading: false });
                            }
                        );
                        this.props.onStoreDataList(testList);
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.getFinalTestsList(), 4000);
        }
    };

    componentDidUpdate() {
        if (this.props.NOR !== this.state.showNOR) this.setState({ showNOR: this.props.NOR, displayNOR: this.props.NOR });
        if (this.props.selectedTestStatus !== this.state.selectedTestStatus) this.setState({ selectedTestStatus: this.props.selectedTestStatus });
        if (this.props.selectedSubtestType !== this.state.selectedSubtestType) this.setState({ selectedSubtestType: this.props.selectedSubtestType });
        if (this.props.selectedProsthesis !== this.state.selectedProsthesis) this.setState({ selectedProsthesis: this.props.selectedProsthesis });
        if (this.props.selectedHearingLoss !== this.state.selectedHearingLoss) this.setState({ selectedHearingLoss: this.props.selectedHearingLoss });
    }

    componentDidMount() {
        this.getFinalTestsList("FINAL");
    }

    render() {
        const { testList, loading, displayNOR, showNOR, selectedHearingLoss, selectedSubtestType, selectedTestStatus, selectedProsthesis } = this.state;
        const { fetchURL, onChangeNavAndPage, currentPage, appParams, onShowAlert } = this.props;

        let filteredFinalTests = null;
        if (testList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredFinalTests = testList.filter(
                (e) =>
                    (selectedTestStatus === "all" || e.tests_status_code === selectedTestStatus) &&
                    (selectedSubtestType === "all" || e.subtests_type_code === selectedSubtestType) &&
                    (selectedHearingLoss === "all" || e.to_hl_code === selectedHearingLoss) &&
                    (selectedProsthesis === "all" || e.to_prosthesis_code === selectedProsthesis)
            );
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                <Container fluid className="second-bar">
                    <Fragment>
                        <Row>
                            <Col>
                                {appParams.map(
                                    (e, i) =>
                                        e.param_code === "FINAL_TEST_FILL" &&
                                        e.param_value === true && (
                                            <Dropdown key={i} className="mt-4">
                                                <Dropdown.Toggle variant="primary" id="dropdownNewTest">
                                                    Przeprowadź badanie
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item as="button" id="btnPJM" onClick={() => this.props.history.push(`/test?testType=FINAL&subtestType=PJM`, "_blank")}>
                                                        Nowe badanie PJM
                                                    </Dropdown.Item>

                                                    <Dropdown.Item as="button" id="btnPL" onClick={() => this.props.history.push(`/test?testType=FINAL&subtestType=PL`, "_blank")}>
                                                        Nowe badanie PL
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        as="button"
                                                        id="btnPJM-PL"
                                                        onClick={() => this.props.history.push(`/test?testType=FINAL&subtestType=PJM&testOption=PJM-PL`, "_blank")}
                                                    >
                                                        Nowe badanie PJM + PL
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )
                                )}
                            </Col>
                            <Col className="text-center">
                                <p className="h3 my-4" style={{ lineHeight: "38px" }}>
                                    Badania ostateczne
                                </p>
                            </Col>
                            <Col className="text-right">
                                <OverlayTrigger key="refreshDataTop" placement="top" overlay={<Tooltip id="refreshData">Odśwież dane</Tooltip>}>
                                    <Button variant="outline-secondary" onClick={this.handleDownloadData} className="btn-sync d-lg-none mt-4">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </OverlayTrigger>
                                <Button variant="secondary" onClick={this.handleDownloadData} className="d-lg-inline-block d-none mt-4">
                                    <span>Odśwież dane</span>
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} md={3} xs={5}>
                                <TestStatuses defaultValue={selectedTestStatus} onSelectedTestStatus={this.props.onSelectedTestStatus} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <SubtestTypes defaultValue={selectedSubtestType} onSubtestTypeSelected={this.props.onSelectedSubtestType} fetchURL={fetchURL} bothTests />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <ObjectHearingLoss defaultValue={selectedHearingLoss} onSelectedHearingLoss={this.props.onSelectedHearingLoss} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <ObjectProsthesis defaultValue={selectedProsthesis} onSelectedProsthesis={this.props.onSelectedProsthesis} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={1} md={2} xs={3}>
                                <RowsDropdown onChangeValue={this.props.onChangeNORValue} defaultValue={this.props.NOR} />
                            </Col>

                            <Col lg={{ span: 2, offset: 1 }} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                <OverlayTrigger key="resetFiltersTop" placement="top" overlay={<Tooltip id="resetFilters">Resetuj filtry</Tooltip>}>
                                    <Button variant="outline-primary" className="mr-1" onClick={() => this.props.onResetFilters()}>
                                        <FontAwesomeIcon icon={faRedoAlt} />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
                <Container fluid className="content-body">
                    {loading || !filteredFinalTests ? (
                        <LoadingProgressBar />
                    ) : (
                        <Fragment>
                            {filteredFinalTests.length > 0 ? (
                                <Fragment>
                                    <Row>
                                        <Col>
                                            <p className="mb-3">
                                                Załadowano {displayNOR > filteredFinalTests.length ? filteredFinalTests.length : displayNOR} z {filteredFinalTests.length} badań.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                <TestsListTable
                                                    appParams={appParams}
                                                    testList={filteredFinalTests}
                                                    displayNOR={displayNOR}
                                                    onChangeNavAndPage={onChangeNavAndPage}
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    onRefreshList={this.handleDownloadData}
                                                    currentPageParams={currentPageParams}
                                                    testType="final"
                                                />
                                                <div className="light-border"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            Załadowano {displayNOR > filteredFinalTests.length ? filteredFinalTests.length : displayNOR} z {filteredFinalTests.length} badań.
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredFinalTests.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">to wszystko</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    załaduj więcej
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Fragment>
                            ) : (
                                <Row>
                                    <Col className="text-center mt-3 font-weight-bold">Lista jest pusta.</Col>
                                </Row>
                            )}
                        </Fragment>
                    )}
                </Container>
            </Fragment>
        );
    }
}

export default withRouter(FinalTestsList);
