import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Container, Row, Col, Modal, Button, Table } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import LoadingSpinnerSm from "../../spinners/LoadingSpinnerSm";
import { faFilePdf, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotAllowed from "../../other/NotAllowed";

class UserDetails extends Component {
    state = {
        userData: null,
        userLogs: null,
        loading: true,
        pdfFile: null,
        existingFileData: null,
        showZoomImgModal: false,
        disallowPermission: false,
    };

    getUserLogs = async (userID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            userID: userID,
        };
        try {
            const response = await fetch(`${fetchURL}/user-logs`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.setState({ userLogs: fetchedData.userLogs, loading: false });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUserLogs(userID), 4000);
        }
    };

    getUserData = async (userID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            userID: userID,
        };
        try {
            const response = await fetch(`${fetchURL}/user-details`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ disallowPermission: true });
                    } else if (fetchedData.status === true) {
                        const userData = fetchedData.userData;
                        this.setState({ userData: userData }, () => {
                            if (userData[0].user_diploma_blob_id) {
                                this.getDiplomaData(userData[0].user_diploma_blob_id);
                            } else this.getUserLogs(userID);
                        });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych. " + err });
            this.setState({ loading: false });
            setTimeout(this.getUserData(userID), 4000);
        }
    };

    getDiplomaData = async (fileID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            fileID: fileID,
        };
        try {
            const resBlobData = await fetch(`${fetchURL}/get-blob-data`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (resBlobData.status === 200) {
                await resBlobData.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const fileData = fetchedData.fileData;
                        this.setState({ existingFileData: fileData }, () => {
                            if (fileData[0].blob_file_type === "application/pdf") this.getPDF(fileID);
                            else {
                                fileData[0].blob_content = this.blobToBuffer(fileData[0].blob_content);
                                this.setState({ existingFileData: fileData }, () => this.getUserLogs(this.props.currentPage.params.userID));
                            }
                        });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            setTimeout(this.getDiplomaData(fileID), 4000);
        }
    };

    getPDF = async (fileID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            fileID: fileID,
        };
        try {
            const resp = await fetch(`${fetchURL}/get-blob-content`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(passData),
            });
            if (resp.status === 200) {
                await resp.arrayBuffer().then((resp) => {
                    const file = new Blob([resp], { type: "application/pdf" });
                    this.setState({ pdfFile: file }, () => this.getUserLogs(this.props.currentPage.params.userID));
                });
            } else onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.getPDF(fileID), 4000);
        }
    };

    blobToBuffer = (object) => {
        const blob = object;
        const buffer = Buffer.from(blob);
        const bufferBase64 = buffer.toString("base64");
        return bufferBase64;
    };

    deactivateUser = async () => {
        const { fetchURL, onChangeNavAndPage, onShowAlert } = this.props;
        onShowAlert({ variant: "info", message: "Deaktywuję użytkownika..." });
        this.setState({ showDeactivateUserModal: false });
        const passData = {
            userID: this.state.userData[0].user_id,
        };
        try {
            const response = await fetch(`${fetchURL}/deactivate-user`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onShowAlert({
                            variant: "success",
                            message: `Pomyślnie zdezaktywowano użytkownika ${this.state.userData[0].user_name} ${this.state.userData[0].user_surname}.`,
                            fading: 2000,
                        });
                        onChangeNavAndPage({ nav: "adminPanel", subpage: "usersList", params: "" }, null);
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            setTimeout(this.deactivateUser(), 4000);
        }
    };

    handleSendActivationMailAgain = async () => {
        const { fetchURL, onShowAlert } = this.props;
        onShowAlert({ variant: "info", message: "Wysyłam maila..." });
        const passData = {
            userData: {
                user_id: this.state.userData[0].user_id,
                user_email: this.state.userData[0].user_email,
            },
        };
        try {
            const response = await fetch(`${fetchURL}/send-activation-mail-again`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onShowAlert({ variant: "success", message: `Pomyślnie wysłano wiadomość e-mail.`, fading: 2000 });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            setTimeout(this.deactivateUser(), 4000);
        }
    };

    handleDownloadPdf = () => {
        const file = this.state.pdfFile;
        const fileURL = URL.createObjectURL(file);
        //window.open(fileURL);
        const link = document.createElement("a");
        link.href = fileURL;
        link.download = this.state.existingFileData ? `${this.state.existingFileData[0].blob_filename}` : "Diploma_" + new Date() + ".pdf";
        link.click();
    };

    componentDidMount() {
        this.props.onShowAlert("");
        this.getUserData(this.props.currentPage.params.userID);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { userData, loading, userLogs, existingFileData, showZoomImgModal, disallowPermission } = this.state;
        const { onBackToPreviousPage, currentPage, onChangeNavAndPage } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                <Container fluid>
                    <Row className="py-3">
                        <NavDetails header="Szczegóły użytkownika" onChangeView={() => this.setState({ loading: false }, () => onBackToPreviousPage())} />
                        <Col className="text-right">
                            {loading ? (
                                <div className="float-right">
                                    <LoadingSpinnerSm />
                                </div>
                            ) : (
                                !disallowPermission && (
                                    <Button
                                        onClick={() =>
                                            onChangeNavAndPage({ nav: "adminPanel", subpage: "editUser", params: { userID: userData[0].user_id } }, currentPageParams, null)
                                        }
                                    >
                                        Edytuj
                                    </Button>
                                )
                            )}
                        </Col>
                    </Row>
                </Container>
                <Container>
                    {!loading ? (
                        disallowPermission ? (
                            <NotAllowed />
                        ) : (
                            <Fragment>
                                <Row>
                                    <Col xs={12}>
                                        <p className="h4 mb-3">Dane profilowe</p>
                                    </Col>
                                    <Col lg={4} xs={5}>
                                        <p className="mb-2">Imię i nazwisko</p>
                                        <p className="mb-2">Email</p>
                                        <p className="mb-2">Role</p>
                                        <p className="mb-2">Nazwa ośrodka</p>
                                        <p className="mb-2">Stanowisko</p>
                                        <p className="mb-2">Skan dyplomu</p>
                                    </Col>
                                    <Col lg={8} xs={7}>
                                        <p className="mb-2">
                                            {userData[0].user_name} {userData[0].user_surname}
                                        </p>
                                        <p className="mb-2">{userData[0].user_email}</p>
                                        <p className="mb-2">{userData[0].user_roles_list}</p>
                                        <p className="mb-2">{userData[0].user_institution}</p>
                                        <p className="mb-2">{userData[0].user_position}</p>
                                        <div className="mb-2">
                                            {existingFileData ? (
                                                <Fragment>
                                                    {existingFileData[0].blob_file_type === "application/pdf" ? (
                                                        <Fragment>
                                                            <p className="mb-0 d-inline-block align-middle">
                                                                <span className="text-danger mr-1">
                                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                                </span>
                                                                {existingFileData[0].blob_filename}
                                                            </p>
                                                            <Button variant="outline-secondary" size="sm" onClick={this.handleDownloadPdf} className="d-inline-block ml-2">
                                                                Pobierz pdf
                                                            </Button>
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            <div className="loaded-file mb-2">
                                                                <img
                                                                    src={`data:${existingFileData[0].blob_file_type};base64,${existingFileData[0].blob_content}`}
                                                                    alt={`${existingFileData[0].blob_filename}`}
                                                                />
                                                                <div
                                                                    className="loaded-file-zoom"
                                                                    onClick={() => this.setState({ showZoomImgModal: true })}
                                                                    style={{ top: "auto", bottom: "1rem" }}
                                                                >
                                                                    <div className="loaded-file-zoom-icon">
                                                                        <FontAwesomeIcon icon={faSearchPlus} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-inline-block">
                                                                <p className="mb-0 align-middle ml-2">{existingFileData[0].blob_filename}</p>
                                                            </div>
                                                        </Fragment>
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <span className="opacity-4">brak</span>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="h4 my-3">Dane aktywacyjne</p>
                                    </Col>

                                    <Col lg={4} xs={5}>
                                        <p className="mb-2">Status</p>
                                        <p className="mb-2">Data ost. logowania</p>
                                        <p className="mb-2">Data ost. edycji</p>
                                        <p className="mb-2">Data rejestracji</p>
                                    </Col>
                                    <Col lg={8} xs={7}>
                                        <p className="mb-2">
                                            {userData[0].user_stat_name}{" "}
                                            {userData[0].user_stat_code === "VERIFIED" && (
                                                <Button variant="outline-secondary" size="sm" className="ml-2" onClick={() => this.handleSendActivationMailAgain()}>
                                                    Wyślij maila aktywacyjnego ponownie
                                                </Button>
                                            )}
                                        </p>
                                        <p className="mb-2">{userData[0].last_login_date ? userData[0].last_login_date : <span className="opacity-4">brak</span>}</p>
                                        <p className="mb-2">{userData[0].modify_date ? userData[0].modify_date : <span className="opacity-4">brak</span>}</p>
                                        <p className="mb-2">{userData[0].create_date}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <p className="h4 my-3">Lista modyfikacji</p>
                                        {userLogs.length > 0 ? (
                                            <Fragment>
                                                <Fragment>
                                                    <Table>
                                                        <thead>
                                                            <tr>
                                                                <th>L.p.</th>
                                                                <th>Modyfikacje</th>
                                                                <th>Data modyfikacji</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {userLogs.map((e, i) => (
                                                                <tr key={i}>
                                                                    <td>{i + 1}.</td>
                                                                    <td>{e.user_log_desc}</td>
                                                                    <td>{e.user_log_date}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </Fragment>
                                            </Fragment>
                                        ) : (
                                            <span className="opacity-4">Brak danych</span>
                                        )}
                                    </Col>
                                </Row>
                            </Fragment>
                        )
                    ) : (
                        <LoadingProgressBar />
                    )}
                </Container>

                {showZoomImgModal && (
                    <Modal show={showZoomImgModal} onHide={() => this.setState({ showZoomImgModal: false })} className="zoom-image-modal">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            <img src={`data:${existingFileData[0].blob_file_type};base64,${existingFileData[0].blob_content}`} alt={`${existingFileData[0].blob_filename}`} />
                        </Modal.Body>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default UserDetails;
