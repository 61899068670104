import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

const TypesTable = (props) => {
    const { detailedData, title, number, classStyle, subtitle } = props;

    function displayTable(table) {
        return table.map((p, j) => (
            <Fragment key={j}>
                {p ? (
                    <Col xs={3} className={j + 1 === table.length ? "py-1 bb pt-2" : "py-1 br bb pt-2"}>
                        <FontAwesomeIcon icon={faCheckCircle} className={classStyle} />
                    </Col>
                ) : (
                    <Col xs={3} className={j + 1 === table.length ? "py-1 bb" : "py-1 br bb"}></Col>
                )}
            </Fragment>
        ));
    }

    const headerTable = (
        <Row>
            <Col xs={3} className="th-bg-light br py-1"></Col>
            <Col xs={3} className="th-bg-light br py-1">
                <span className="bold">niższy niż typowy</span>
            </Col>
            <Col xs={3} className="th-bg-light br py-1">
                <span className="bold">typowy</span>
            </Col>
            <Col xs={3} className="th-bg-light py-1">
                <span className="bold">wyższy niż typowy</span>
            </Col>
        </Row>
    );

    return (
        <Fragment>
            <Col xs={12}>
                <p className="h4 my-3">
                    {number}. <span className={classStyle}>{title}</span> {subtitle}
                </p>
            </Col>
            <Col xs={{ span: 10, offset: 1 }}>
                <Row>
                    <Col xs={12}>
                        <p className="h5 text-uppercase text-center">części mowy</p>
                    </Col>
                    <Col xs={12} className="w-100 text-center my-3">
                        {headerTable}

                        {detailedData.map((e, i) => (
                            <Fragment key={i}>
                                {e.result_group.includes("NOUN") && (
                                    <Row>
                                        <Col xs={3} className="py-1 br bb">
                                            rzeczowniki
                                        </Col>
                                        {displayTable(e.normalization_value)}
                                    </Row>
                                )}
                            </Fragment>
                        ))}
                        {detailedData.map((e, i) => (
                            <Fragment key={i}>
                                {e.result_group.includes("VERB") && (
                                    <Row>
                                        <Col xs={3} className="py-1 br bb">
                                            czasowniki
                                        </Col>
                                        {displayTable(e.normalization_value)}
                                    </Row>
                                )}
                            </Fragment>
                        ))}
                        {detailedData.map((e, i) => (
                            <Fragment key={i}>
                                {e.result_group.includes("ADJECTIVE") && (
                                    <Row>
                                        <Col xs={3} className="py-1 br bb">
                                            przymiotniki i przysłówki
                                        </Col>
                                        {displayTable(e.normalization_value)}
                                    </Row>
                                )}
                            </Fragment>
                        ))}
                        {detailedData.map((e, i) => (
                            <Fragment key={i}>
                                {e.result_group.includes("NUMERAL") && (
                                    <Row>
                                        <Col xs={3} className="py-1 br bb">
                                            liczebniki
                                        </Col>
                                        {displayTable(e.normalization_value)}
                                    </Row>
                                )}
                            </Fragment>
                        ))}
                    </Col>
                </Row>
            </Col>
            <Col xs={{ span: 10, offset: 1 }}>
                <Row>
                    <Col xs={12}>
                        <p className="h5 text-uppercase text-center">rodzaje zadań</p>
                    </Col>
                    <Col xs={12} className="w-100 text-center my-3">
                        {headerTable}
                        {detailedData.map((e, i) => (
                            <Fragment key={i}>
                                {((e.result_group.includes("V4I") && e.result_group.includes("PJM")) || (e.result_group.includes("W4I") && e.result_group.includes("PL"))) && (
                                    <Row>
                                        <Col xs={3} className="py-1 br bb">
                                            {e.result_group.includes("PJM") ? "1 znak – 4 obrazki" : "1 słowo – 4 obrazki"}
                                        </Col>
                                        {displayTable(e.normalization_value)}
                                    </Row>
                                )}
                                {((e.result_group.includes("I4V") && e.result_group.includes("PJM")) || (e.result_group.includes("I4W") && e.result_group.includes("PL"))) && (
                                    <Row>
                                        <Col xs={3} className="py-1 br bb">
                                            {e.result_group.includes("PJM") ? "1 obrazek – 4 znaki" : "1 obrazek – 4 słowa"}
                                        </Col>
                                        {displayTable(e.normalization_value)}
                                    </Row>
                                )}
                                {((e.result_group.includes("V4V") && e.result_group.includes("PJM")) || (e.result_group.includes("W4W") && e.result_group.includes("PL"))) && (
                                    <Row>
                                        <Col xs={3} className="py-1 br bb">
                                            <span style={{ fontSize: "0.875rem" }}>
                                                {e.result_group.includes("PJM") ? "synonimy (1 znak – 4 znaki)" : "synonimy (1 słowo – 4 słowa)"}
                                            </span>
                                        </Col>
                                        {displayTable(e.normalization_value)}
                                    </Row>
                                )}
                            </Fragment>
                        ))}
                    </Col>
                </Row>
            </Col>
        </Fragment>
    );
};

export default TypesTable;
