import React, { Fragment, Component } from "react";
import queryString from "query-string";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import AlertBasic from "../alerts/AlertBasic";
import PasswordInput from "../forms/PasswordInput";
import ContactWithAdminModal from "../modals/ContactWithAdminModal";

class ResetPass extends Component {
    state = {
        alert: "",
        showContactModal: false,
        contactAdminData: null,
        user_password: "",
        repeat_user_password: "",
        userEmail: null,
        loading: true,
    };

    handleChangeInputValue = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    setPassword = async (passData) => {
        const { fetchURL, location } = this.props;
        this.setState({ alert: { variant: "info", message: location.pathname === "/created-user" ? "Ustawiam nowe hasło..." : "Próba resetu hasła..." } });
        try {
            const response = await fetch(`${fetchURL}/set-new-password`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((data) => {
                    //console.log(data);
                    if (data.status === true) {
                        window.location.assign("/");
                    } else if (data.status === "expired_link") {
                        this.setState({ alert: { variant: "warning", message: data.message } });
                    } else {
                        this.setState({ alert: { variant: "danger", message: data.message } });
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.setPassword(passData), 4000);
        }
    };

    handleCheckValidation = (e) => {
        e.preventDefault();
        this.setState({ alert: "" }, () => {
            let errorMsg = false;
            const { user_password, repeat_user_password } = this.state;
            if (!user_password || !repeat_user_password) {
                this.setState({ user_password: "", repeat_user_password: "" });
                errorMsg = "Nie wszystkie pola są uzupełnione!";
            } else if (user_password !== repeat_user_password) {
                this.setState({ user_password: "", repeat_user_password: "" });
                errorMsg = "Hasła w formularzu nie są takie same.";
            }

            if (errorMsg) {
                this.setState({
                    alert: { variant: "danger", message: errorMsg, fading: 2000 },
                });
            } else {
                const { location } = this.props;
                const passData = {
                    password: user_password,
                    pathname: location.pathname,
                    url: `${location.pathname}${location.search}`,
                };
                this.setPassword(passData);
            }
        });
    };

    checkCorrectURL = async (passData) => {
        const { fetchURL } = this.props;
        try {
            const response = await fetch(`${fetchURL}/check-correct-url`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.setState({ userEmail: fetchedData.userEmail, loading: false });
                    } else {
                        //jeśli istnieją parametry title i name - wyświetl alert z przyciskiem do formularza kontaktowego, gdzie title będzie tytułem maila, a name - imieniem i nazwiskiem użytkownika
                        if (fetchedData.title && fetchedData.name) {
                            this.setState({
                                alert: { variant: "danger", message: fetchedData.message, contactBtn: true },
                                contactAdminData: { mailTitle: fetchedData.title, mailSender: fetchedData.name, mailAddress: fetchedData.mail },
                            });
                        } else this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.checkCorrectURL(passData), 4000);
        }
    };

    componentDidMount() {
        const { location } = this.props;
        //console.log(location);
        const parsedLocationSearch = queryString.parse(location.search);
        //console.log(parsedLocationSearch);
        if (location.search === "" || parsedLocationSearch.url === null) {
            this.setState({
                alert: {
                    variant: location.pathname === "/created-user" ? "info" : "danger",
                    message:
                        location.pathname === "/created-user"
                            ? "Hasło do tego konta jest już ustawione. Wróć na stronę główną i zaloguj się."
                            : "Link do resetowania hasła jest nieprawidłowy. Jeśli próbujesz zresetować hasło, a wciąż pojawia się ten komunikat, skontaktuj się z administracją.",
                },
            });
        } else {
            const postDataURL = {
                url: `${location.pathname}${location.search}`,
                pathname: `${location.pathname}`,
                generatedString: parsedLocationSearch.url,
            };
            this.checkCorrectURL(postDataURL);
        }
    }
    render() {
        const { showContactModal, alert, contactAdminData, user_password, repeat_user_password, loading } = this.state;
        const { fetchURL } = this.props;
        return (
            <Fragment>
                {alert && (
                    <AlertBasic
                        variant={alert.variant}
                        mssg={alert.message}
                        onCloseAlert={() => this.setState({ alert: "" })}
                        fading={alert.fading}
                        contactBtn={alert.contactBtn}
                        onShowContactModal={() => this.setState({ showContactModal: true, alert: "" })}
                    />
                )}
                {!loading ? (
                    <Container>
                        <Row className="mt-5">
                            <Col lg={{ span: 6, offset: 3 }} xs={12} className="text-center">
                                <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="my-3" style={{ height: "16rem" }} />
                            </Col>
                            <Col lg={{ span: 6, offset: 3 }} xs={12}>
                                <Row>
                                    <Form onSubmit={this.handleCheckValidation} autoComplete="off">
                                        <Col xs={12} className="my-3">
                                            {this.props.location.pathname === "/created-user"
                                                ? "Witaj! Twoje konto zostało poprawnie utworzone! Teraz wystarczy, że ustawisz hasło do swojego konta, by zacząć z niego korzystać. Po poprawnym ustawieniu hasła zostaniesz przekierowany na stronę główną, gdzie będziesz mógł się zalogować."
                                                : "Twoje hasło jest zresetowane. Aby korzystać z konta na nowo wystarczy, że ustawisz nowe hasło w systemie. Wypełnij poniższe pola do ustawienia hasła."}
                                        </Col>
                                        <Col xs={12}>
                                            <PasswordInput
                                                repeatPassInputVisible
                                                value1={user_password}
                                                value2={repeat_user_password}
                                                onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                                name="user_password"
                                            />
                                        </Col>
                                        <Col xs={12} className="text-center">
                                            <Button variant="primary" type="submit">
                                                {this.props.location.pathname === "/created-user" ? "Ustaw hasło" : "Zresetuj"}
                                            </Button>
                                        </Col>
                                    </Form>
                                </Row>
                            </Col>
                            <Col lg={{ span: 6, offset: 3 }} xs={12} className="mt-3"></Col>
                            <Col xs={12} className="text-center">
                                <Link className="btn btn-link" to="/">
                                    wróć na stronę główną
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container>
                        <Row className="mt-5">
                            <Col xs={12} className="text-center mt-5">
                                <Link className="btn btn-outline-secondary" to="/">
                                    wróć na stronę główną
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                )}

                {showContactModal && (
                    <ContactWithAdminModal
                        contactAdminData={contactAdminData}
                        show={showContactModal}
                        onClose={() => this.setState({ showContactModal: false, contactAdminData: null })}
                        fetchURL={fetchURL}
                    />
                )}
            </Fragment>
        );
    }
}

export default ResetPass;
