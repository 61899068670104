import React, { Fragment } from "react";
import ArrangeTest from "../../pages/tests/ArrangeTest";
import QuestionPreview from "../../pages/questions/QuestionPreview";

const ArrangingTests = (props) => {
    const {
        fetchURL,
        onChangeValuesInForms,
        onCancelChangeValuesInForms,
        onBackToPreviousPage,
        onChangeNavAndPage,
        currentPage,
        onShowAlert,
        onStoreDataList,
        questionsList,
        onStoreDataListArrange,
        approvedQuestionsList,
    } = props;
    return (
        <Fragment>
            {currentPage.nav === "arrangingTests" && (
                <Fragment>
                    {(currentPage.subpage === "arrangingPJMPilotageTest" ||
                        currentPage.subpage === "arrangingPLPilotageTest" ||
                        currentPage.subpage === "arrangingPJMFinalTest" ||
                        currentPage.subpage === "arrangingPLFinalTest") && (
                        <ArrangeTest
                            fetchURL={fetchURL}
                            onChangeValuesInForms={onChangeValuesInForms}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            approvedQuestionsList={approvedQuestionsList}
                            questionsList={questionsList}
                            onStoreDataList={onStoreDataList}
                            onStoreDataListArrange={onStoreDataListArrange}
                            testType={
                                currentPage.subpage === "arrangingPJMPilotageTest" || currentPage.subpage === "arrangingPLPilotageTest"
                                    ? "pilot"
                                    : currentPage.subpage === "arrangingPJMFinalTest" || currentPage.subpage === "arrangingPLFinalTest"
                                    ? "final"
                                    : null
                            }
                            subtestType={
                                currentPage.subpage === "arrangingPJMPilotageTest" || currentPage.subpage === "arrangingPJMFinalTest"
                                    ? "PJM"
                                    : currentPage.subpage === "arrangingPLPilotageTest" || currentPage.subpage === "arrangingPLFinalTest"
                                    ? "PL"
                                    : null
                            }
                        />
                    )}
                    {currentPage.subpage === "testPreview" && (
                        <QuestionPreview
                            fetchURL={fetchURL}
                            onChangeValuesInForms={onChangeValuesInForms}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            approvedQuestionsList={approvedQuestionsList}
                            testType={
                                currentPage.subpage === "arrangingPJMPilotageTest" || currentPage.subpage === "arrangingPLPilotageTest"
                                    ? "pilot"
                                    : currentPage.subpage === "arrangingPJMFinalTest" || currentPage.subpage === "arrangingPLFinalTest"
                                    ? "final"
                                    : null
                            }
                            subtestType={
                                currentPage.subpage === "arrangingPJMPilotageTest" || currentPage.subpage === "arrangingPJMFinalTest"
                                    ? "PJM"
                                    : currentPage.subpage === "arrangingPLPilotageTest" || currentPage.subpage === "arrangingPLFinalTest"
                                    ? "PL"
                                    : null
                            }
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default ArrangingTests;
