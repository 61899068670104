import React, { Component, Fragment } from "react";
import { Col, Form, Button, Image, Container, Row, Alert, Modal } from "react-bootstrap";
import EmailInput from "../forms/EmailInput";
import NameInput from "../forms/NameInput";
import PasswordInput from "../forms/PasswordInput";
import TooltipWithDisabledButton from "../other/TooltipWithDisabledButton";
import $ from "jquery";
import { Checkbox } from "semantic-ui-react";

const validateEmail = (email) => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
        $("#userEmail").addClass("is-invalid");
        return "Email jest nieprawidłowy. ";
    } else return "";
};

class Register extends Component {
    state = {
        newUser: [
            {
                user_email: "",
                user_name: "",
                user_password: "",
                repeat_user_password: "",
                user_surname: "",
                user_institution: "",
                user_position: "",
                user_diploma: "",
                user_diploma_file_type: "",
                user_rodo_agreement: false,
                user_role: null,
            },
        ],
        file: null,
        fileURL: null,
        changedValues: false,
        showDiplomaInput: false,
        showRODOModal: false,
    };

    handleUpdateDate = (e) => {
        const { newUser } = this.state;
        const { name, value, id } = e.target;
        const list = [...newUser];
        list[0][name] = value;
        this.setState({ newUser: list, changedValues: true });
        $(`#${id}`).removeClass("is-invalid");
    };

    handleChangeCheckbox = (event, data) => {
        const { newUser } = this.state;
        const list = [...newUser];
        list[0].user_rodo_agreement = data.checked;
        this.setState({ newUser: list, changedValues: true });
        $(`#rodoCheckbox`).removeClass("is-invalid");
    };

    handleRegister = async (e) => {
        const { fetchURL, onShowAlert, appParams } = this.props;
        const { newUser } = this.state;
        e.preventDefault();
        onShowAlert("");
        const errorMsg = [];
        let doInsertDiploma = false;

        if (!newUser[0].user_name) {
            errorMsg.push("Pole 'Imię' jest puste.");
            $("#userName").addClass("is-invalid");
        }

        if (!newUser[0].user_surname) {
            errorMsg.push("Pole 'Nazwisko' jest puste.");
            $("#userSurname").addClass("is-invalid");
        }

        if (!newUser[0].user_institution) {
            errorMsg.push("Pole 'Miejsce pracy' jest puste.");
            $("#userInstitution").addClass("is-invalid");
        }
        if (!newUser[0].user_position) {
            errorMsg.push("Pole 'Stanowisko' jest puste.");
            $("#userPosition").addClass("is-invalid");
        }

        if (!newUser[0].user_password || !newUser[0].repeat_user_password) {
            errorMsg.push("Pole lub pola do wpisania hasła są puste.");
            $(".pass").addClass("is-invalid");
        } else if (newUser[0].user_password !== newUser[0].repeat_user_password) {
            errorMsg.push("Wpisane hasła nie są takie same.");
            $(".pass").addClass("is-invalid");
        }

        if (!newUser[0].user_rodo_agreement) {
            errorMsg.push("Zgoda RODO nie została zaakceptowana.");
            $("#rodoCheckbox").addClass("is-invalid");
        }

        appParams.forEach((e) => {
            if (e.param_code === "OBIGAT_DIPLOMA" && e.param_value === true) {
                doInsertDiploma = true;
                if (!newUser[0].user_diploma) {
                    $("#loadDiploma").addClass("danger");
                    errorMsg.push("Nie wybrano skanu dyplomu. Proszę wybrać plik reprezentujący skan dyplomu.");
                }
            }
        });

        if (newUser[0].user_email !== "") {
            let emailError = "";
            emailError = validateEmail(newUser[0].user_email);
            if (!emailError) {
                const passData = {
                    email: this.state.newUser[0].user_email,
                };
                try {
                    const response = await fetch(`${fetchURL}/check-email-uniqueness`, {
                        credentials: "include",
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(passData),
                    });
                    if (response.status === 200) {
                        await response.json().then((data) => {
                            if (data.status === false) {
                                errorMsg.push(`${data.message}`);
                                $("#userEmail").addClass("is-invalid");
                            }
                        });
                    }
                } catch (err) {
                    onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
                    setTimeout(this.handleRegister, 4000);
                }
            } else errorMsg.push(`${emailError}`);
        } else {
            errorMsg.push(`Pole 'Adres email' jest puste.`);
            $("#userEmail").addClass("is-invalid");
        }
        //console.log(errorMsg);
        if (errorMsg.length > 0)
            onShowAlert({
                variant: "danger",
                message: (
                    <>
                        <p className="mb-1">W formularzu wystąpiły następujące błędy:</p>
                        <ul className="mb-0">
                            {errorMsg.map((e, i) => (
                                <Fragment key={i}>
                                    <li>{e}</li>
                                </Fragment>
                            ))}
                        </ul>
                    </>
                ),
            });
        else {
            onShowAlert({ variant: "info", message: "Zakładanie konta..." });
            if (doInsertDiploma) {
                const imageData = new FormData();
                imageData.append("file", this.state.file);
                this.insertBlob(imageData);
            } else this.register(null);
        }
    };

    insertBlob = async (imageData) => {
        const { fetchURL, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/insert-blob`, {
                credentials: "include",
                method: "POST",
                body: imageData,
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else {
                        const fileID = fetchedData.fileID;
                        this.register(fileID);
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `Błąd połączenia z serwerem. Spróbuję pobrać dane jeszcze raz. (${err})` });
            this.setState({ loading: false });
            setTimeout(this.insertBlob(imageData), 4000);
        }
    };

    register = async (diplomaID) => {
        const { fetchURL, onShowAlert } = this.props;

        const passData = {
            userData: this.state.newUser[0],
            diplomaID: diplomaID,
        };
        try {
            const response = await fetch(`${fetchURL}/register-user`, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((data) => {
                    //console.log(data);
                    if (data.status === true) {
                        onShowAlert({ variant: "success", message: data.message, fading: 4000 });
                        this.setState(
                            {
                                newUser: [
                                    {
                                        user_email: "",
                                        user_name: "",
                                        user_password: "",
                                        repeat_user_password: "",
                                        user_surname: "",
                                        user_institution: "",
                                        user_position: "",
                                        user_diploma: "",
                                        user_diploma_file_type: "",
                                        user_rodo_agreement: false,
                                        user_role: null,
                                    },
                                ],
                                file: null,
                                fileURL: null,
                                changedValues: false,
                            },
                            () => {
                                document.getElementById("diplomaInput").value = null;
                                this.getBasicUserParams();
                            }
                        );
                    } else if (data.status === false) {
                        onShowAlert({ variant: "danger", message: data.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.register(diplomaID), 4000);
        }
    };

    handleUploadFile = (e) => {
        const file = e.target.files[0];
        const fileType = file.type;
        let errorMsg = "";
        /* 1024x1024x16 = 16MB */
        if (file.size > 16777216) errorMsg += "Plik nie może ważyć więcej niż 16MB. ";
        //console.log(file, fileType);
        if (!fileType.includes("image/jpg") && !fileType.includes("image/jpeg") && !fileType.includes("image/png") && !fileType.includes("application/pdf"))
            errorMsg += "Niepoprawny format pliku. Można załadować png, jpg i pdf.";

        const { newUser } = this.state;
        const list = [...newUser];
        list[0].user_diploma = file;
        list[0].user_diploma_file_type = fileType;
        this.setState({ newUser: list, changedValues: true });
        if (errorMsg) this.setState({ alertFileInput: { variant: "danger", mssg: errorMsg } });
        else {
            $("#loadDiploma").removeClass("danger");
            this.setState({ alertFileInput: "", file: file, fileURL: URL.createObjectURL(file) });
        }
    };

    getBasicUserParams = () => {
        const { appParams } = this.props;
        appParams.forEach((e) => {
            if (e.param_code === "OBIGAT_DIPLOMA") {
                if (e.param_value === true) this.setState({ showDiplomaInput: true });
            }
            if (e.param_code === "DEFAULT_ROLE") {
                const { newUser } = this.state;
                const list = [...newUser];
                list[0].user_role = e.param_value;
                this.setState({ newUser: list });
            }
        });
    };

    componentDidMount() {
        this.getBasicUserParams();

        $(`#rodoCheckbox+label`).prepend('<span class="required mr-2">*</span>');
    }

    render() {
        const { newUser, changedValues, alertFileInput, showDiplomaInput, showRODOModal } = this.state;
        const { fontSize } = this.props;
        return (
            <Fragment>
                <Col xs={12} className="text-center">
                    <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="my-3" style={{ height: "16rem" }} />
                    <h1 className="mb-4" style={{ fontSize: `${1.75 * fontSize}rem` }}>
                        Rejestracja
                    </h1>
                </Col>
                <Container>
                    <Form onSubmit={this.handleRegister} autoComplete="off">
                        <Row>
                            <Col lg={6} xs={12}>
                                <EmailInput value={newUser[0].user_email} onChangeValue={this.handleUpdateDate} name="user_email" id="userEmail" />
                            </Col>
                            <Col lg={6} xs={12}>
                                <PasswordInput
                                    repeatPassInputVisible
                                    value1={newUser[0].user_password}
                                    value2={newUser[0].repeat_user_password}
                                    onChangeValue={this.handleUpdateDate}
                                    name="user_password"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} xs={12}>
                                <NameInput value={newUser[0].user_name} onChangeValue={this.handleUpdateDate} req labelTxt="Imię" placeholderTxt="Wpisz imię" name="user_name" id="userName" />
                            </Col>
                            <Col lg={8} xs={12}>
                                <NameInput
                                    value={newUser[0].user_surname}
                                    onChangeValue={this.handleUpdateDate}
                                    req
                                    labelTxt="Nazwisko"
                                    placeholderTxt="Wpisz nazwisko"
                                    name="user_surname"
                                    id="userSurname"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} xs={12}>
                                <NameInput
                                    value={newUser[0].user_institution}
                                    onChangeValue={this.handleUpdateDate}
                                    req
                                    labelTxt="Miejsce pracy"
                                    placeholderTxt="Wpisz nazwę ośrodka"
                                    name="user_institution"
                                    id="userInstitution"
                                />
                            </Col>
                            <Col lg={4} xs={12}>
                                <NameInput
                                    value={newUser[0].user_position}
                                    onChangeValue={this.handleUpdateDate}
                                    req
                                    labelTxt="Stanowisko"
                                    placeholderTxt="Wpisz swoje stanowisko pracy"
                                    name="user_position"
                                    id="userPosition"
                                />
                            </Col>
                        </Row>
                        {showDiplomaInput && (
                            <Row>
                                <Col className="mb-3">
                                    <Form.Label>
                                        Skan dyplomu potwierdzającego posiadanie kwalifikacji z zakresu surdopedagogiki lub surdologopedii <span className="required">*</span>
                                    </Form.Label>
                                    <div id="loadDiploma">
                                        <input type="file" name="picture" onChange={this.handleUploadFile} className="d-block mb-2" id="diplomaInput" />
                                        {alertFileInput && <Alert variant={alertFileInput.variant}>{alertFileInput.mssg}</Alert>}
                                        <p className="text-small">Format pliku: jpg, png lub pdf.</p>
                                    </div>
                                </Col>
                            </Row>
                        )}
                        <Row className="mb-3">
                            <Col>
                                <Checkbox
                                    label="Wyrażam zgodę na przetwarzanie moich danych osobowych podanych w powyższym formularzu w celu umożliwienia korzystania z serwisu Test Leksyka PJM-PL."
                                    onChange={(event, data) => this.handleChangeCheckbox(event, data)}
                                    id="rodoCheckbox"
                                    checked={newUser[0].user_rodo_agreement ? true : false}
                                />

                                <Button variant="link" className="p-0" style={{ marginLeft: "28px" }} onClick={() => this.setState({ showRODOModal: true })}>
                                    Czytaj więcej
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="text-center">
                                <Form.Group controlId="loginSumbit">
                                    {!changedValues ? (
                                        <TooltipWithDisabledButton mssgTooltip="Formularz jest pusty!" btnTxt="Zarejestruj się" tooltipID="tooltipDisabledNotFilled" size="lg" />
                                    ) : (
                                        <Button type="submit" variant="primary" size="lg" className="mb-3">
                                            Zarejestruj się
                                        </Button>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <Col className="text-center" xs={12}>
                    <Button
                        onClick={() => {
                            this.props.onGoToMainPage();
                        }}
                        variant="outline-secondary"
                        size="sm"
                        className="mb-3"
                    >
                        wróć na stronę główną
                    </Button>
                </Col>

                {showRODOModal && (
                    <Modal show={showRODOModal} onHide={() => this.setState({ showRODOModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title style={{ fontSize: `${1.5 * fontSize}rem`, lineHeight: 1 }}>Informacja o przetwarzaniu danych osobowych</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ fontSize: `${1 * fontSize}rem` }}>
                            Administratorem danych osobowych jest firma PASZKO.IT Paweł Matuszak z siedzibą w Poznaniu (61-664), ul. Starowiejska 1G/10. Dane przetwarzane są na podstawie art. 6 ust. 1
                            lit. A ogólnego rozporządzenia o ochronie danych osobowych z 27 kwietnia 2016r. w celu realizacji korzystania z serwisu Test Leksyka PJM-PL. Zakres zbieranych danycy to:
                            imię, nazwisko, adres e-mail, kopia elektroniczna dokumentu poświadczającego wykształcenie, miejsce i stanowisko pracy. Dane będą przechowywane w czasie niezbędnym do
                            zrealizowania celu. Osobie, której dane dotyczą przysługuje prawo dostępu do swoich danych, ich poprawienia, sprostowania, usunięcia, ograniczenia przetwarzania,
                            przesunięcia oraz wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych. Dane nie będą podlegać zautomatyzowanemu przetwarzaniu, w tym
                            profilowaniu. Podanie danych jest dobrowolne, ale niezbędne do realizacji wskazanego celu.
                        </Modal.Body>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default Register;
