import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class UsersListTable extends Component {
    state = {
        currentSort: "default",
        sortingColName: "sortID",
        showSendUserForEvaluationModal: false,
        selectedUser: null,
        alert: "",
    };

    handleSorting = (btnID) => {
        const { currentSort } = this.state;
        let nextSort;
        $(".sort-icon").removeClass("fa-sort-down").removeClass("fa-sort-up").removeClass("fa-sort").addClass("fa-sort");
        if (currentSort === "down") {
            nextSort = "up";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-down")
                .addClass("fa-sort-up");
        } else if (currentSort === "up") {
            nextSort = "default";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort-up")
                .addClass("fa-sort");
        } else if (currentSort === "default") {
            nextSort = "down";
            $("#" + btnID + " .fas")
                .removeClass("fa-sort")
                .addClass("fa-sort-down");
        }

        this.setState({
            currentSort: nextSort,
            sortingColName: btnID,
        });
    };

    render() {
        const { currentSort, sortingColName } = this.state;
        const { usersList, displayNOR, onChangeNavAndPage } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.user_ident_number - b.user_ident_number, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                fnArrivalDate: (a, b) => {
                    if (new Date(a.user_arrival_date) > new Date(b.user_arrival_date)) return 1;
                    else return -1;
                },
                fnSurname: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return 1;
                    else return -1;
                },
                fnNickname: (a, b) => {
                    if (a.user_nickname.toUpperCase() > b.user_nickname.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.user_ident_number - a.user_ident_number,
                fnArrivalDate: (a, b) => {
                    if (new Date(a.user_arrival_date) > new Date(b.user_arrival_date)) return -1;
                    else return 1;
                },
                fnSurname: (a, b) => {
                    if (a.user_surname.toUpperCase() > b.user_surname.toUpperCase()) return -1;
                    else return 1;
                },
                fnNickname: (a, b) => {
                    if (a.user_nickname.toUpperCase() > b.user_nickname.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnArrivalDate: (a, b) => a,
                fnSurname: (a, b) => a,
                fnNickname: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (sortingColName === "sortID") sortedData = usersList.sort(sortTypes[currentSort].fnID);
        else if (sortingColName === "sortArrivalDate") sortedData = usersList.sort(sortTypes[currentSort].fnArrivalDate);
        else if (sortingColName === "sortSurname") sortedData = usersList.sort(sortTypes[currentSort].fnSurname);
        else if (sortingColName === "sortNickname") sortedData = usersList.sort(sortTypes[currentSort].fnNickname);
        else sortedData = usersList.sort(sortTypes[currentSort].fnID);

        const currentPageParams = { nav: "adminPanel", subpage: "usersList", params: "" };

        //console.log(sortedData);
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "9rem", minWidth: "6rem" }}>ID</th>
                            <th>Nazwisko</th>
                            <th>Imię</th>
                            <th>Role</th>
                            <th>Data rejestracji</th>
                            <th>Status</th>

                            <th style={{ width: "200px", minWidth: "170px" }}>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{p.user_id}</td>
                                    <td>{p.user_surname}</td>
                                    <td>{p.user_name}</td>
                                    <td>{p.user_roles_list ? p.user_roles_list : <span className="opacity-4">brak</span>}</td>
                                    <td>{p.create_date}</td>
                                    <td>{p.user_stat_name}</td>
                                    <td className="text-center">
                                        <OverlayTrigger placement="top" overlay={<Tooltip id={`userDetails${i}`}>Szczegóły</Tooltip>}>
                                            <span className="d-inline-block">
                                                <Button
                                                    variant="outline-primary"
                                                    onClick={() => onChangeNavAndPage({ nav: "adminPanel", subpage: "userDetails", params: { userID: p.user_id } }, currentPageParams)}
                                                    className="mx-1 btn-list"
                                                >
                                                    <FontAwesomeIcon icon={faEye} />
                                                </Button>
                                            </span>
                                        </OverlayTrigger>
                                        {p.user_roles_list !== "Superadmin" && (
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={`userEdit${i}`}>Edytuj</Tooltip>}>
                                                <span className="d-inline-block">
                                                    <Button
                                                        variant="outline-primary"
                                                        onClick={() => onChangeNavAndPage({ nav: "adminPanel", subpage: "editUser", params: { userID: p.user_id } }, currentPageParams)}
                                                        className="mx-1 btn-list"
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                </span>
                                            </OverlayTrigger>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
            </Fragment>
        );
    }
}

export default UsersListTable;
