import React, { Fragment } from "react";
import StatisticsView from "../../pages/StatisticsView";

const Statistics = (props) => {
    const { fetchURL, onChangeNavAndPage, currentPage, onBackToPreviousPage, onShowAlert } = props;
    return (
        <Fragment>
            {currentPage.nav === "adminPanel" && (
                <Fragment>
                    {currentPage.subpage === "statisticsView" && (
                        <StatisticsView fetchURL={fetchURL} onChangeNavAndPage={onChangeNavAndPage} currentPage={currentPage} onShowAlert={onShowAlert} onBackToPreviousPage={onBackToPreviousPage} />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Statistics;
