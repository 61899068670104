import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

const RowsDropdown = (props) => {
    const { onChangeValue, defaultValue } = props;
    return (
        <Fragment>
            <Form.Label>Wiersze:</Form.Label>
            <Dropdown
                className="mb-3"
                placeholder="Wybierz"
                fluid
                selection
                defaultValue={defaultValue}
                options={[
                    { key: "1", value: 25, text: 25 },
                    { key: "2", value: 50, text: 50 },
                    { key: "3", value: 100, text: 100 },
                ]}
                onChange={onChangeValue}
            />
        </Fragment>
    );
};

export default RowsDropdown;
