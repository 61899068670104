import React, { Fragment, Component } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
//import $ from "jquery";
import { faEdit, faEye, faPaperPlane, faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendQuestionForEvaluation from "../../modals/SendQuestionForEvaluationModal";

class QuestionsListTable extends Component {
    state = {
        currentSortType: "default",
        currentSortingColumn: "sortID",
        showSendQuestionForEvaluationModal: false,
        selectedQuestion: null,
        alert: "",
    };

    handleSorting = (btnID) => {
        const { currentSortType } = this.state;
        let nextSort;
        if (currentSortType === "down") {
            nextSort = "up";
        } else if (currentSortType === "up") {
            nextSort = "default";
        } else if (currentSortType === "default") {
            nextSort = "down";
        }

        this.setState({
            currentSortType: nextSort,
            currentSortingColumn: btnID,
        });
    };

    sendQuestionForEvaluation = async (questionID) => {
        const { fetchURL, onRefreshEvaluationList } = this.props;
        const passData = {
            qItemID: questionID,
        };
        try {
            const response = await fetch(`${fetchURL}/send-question`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.setState({ alert: { variant: "success", message: fetchedData.message } });
                        onRefreshEvaluationList();
                    } else {
                        this.setState({ loading: false, alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Nie udało się wysłać danych." } });
            setTimeout(this.sendQuestionForEvaluation(questionID), 4000);
        }
    };

    render() {
        const { currentSortType, currentSortingColumn, showSendQuestionForEvaluationModal, alert } = this.state;
        const { questionsList, displayNOR, onChangeNavAndPage, appParams } = this.props;

        const sortTypes = {
            up: {
                fnID: (a, b) => a.question_id - b.question_id, //metoda sortowania (patrz: https://www.w3schools.com/jsref/jsref_sort.asp )
                /* fnArrivalDate: (a, b) => {
                    if (new Date(a.question_arrival_date) > new Date(b.question_arrival_date)) return 1;
                    else return -1;
                }, */
                fnQuestionName: (a, b) => {
                    if (a.question_name.toUpperCase() > b.question_name.toUpperCase()) return 1;
                    else return -1;
                },
            },
            down: {
                fnID: (a, b) => b.question_id - a.question_id,
                /*  fnArrivalDate: (a, b) => {
                    if (new Date(a.question_arrival_date) > new Date(b.question_arrival_date)) return -1;
                    else return 1;
                }, */
                fnQuestionName: (a, b) => {
                    if (a.question_name.toUpperCase() > b.question_name.toUpperCase()) return -1;
                    else return 1;
                },
            },
            default: {
                fnID: (a, b) => a,
                fnQuestionName: (a, b) => a,
            },
        };

        //sortowanie listy
        let sortedData = [];
        if (currentSortingColumn === "sortQuestionName") sortedData = questionsList.sort(sortTypes[currentSortType].fnQuestionName);
        else sortedData = questionsList.sort(sortTypes[currentSortType].fnQuestionName);

        const currentPageParams = { nav: "questions", subpage: "questionsList", params: "" };

        //console.log(sortedData);
        return (
            <Fragment>
                <Table bordered hover size="sm" className="table-list mb-0">
                    <thead>
                        <tr>
                            <th>
                                Nazwa pytania
                                <Button variant="link" className="sort-btn btn-sm ml-1 float-right" id="sortQuestionName" onClick={() => this.handleSorting("sortQuestionName")}>
                                    <FontAwesomeIcon
                                        icon={currentSortingColumn !== "sortQuestionName" ? faSort : currentSortType === "up" ? faSortUp : currentSortType === "down" ? faSortDown : faSort}
                                    />
                                </Button>
                            </th>
                            <th>Część testu</th>
                            <th>Typ pytania</th>
                            <th>Kategoria pytania</th>
                            <th>Status pytania</th>
                            <th>Data utworzenia</th>

                            <th style={{ width: "200px", minWidth: "170px" }}>Akcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedData
                            .map((p, i) => (
                                <tr key={i}>
                                    <td>{p.question_name}</td>
                                    <td>{p.subtest_type_name}</td>
                                    <td>{p.question_type_name}</td>
                                    <td>{p.question_category_name}</td>
                                    <td>{p.question_status_name}</td>
                                    <td>{p.create_date}</td>
                                    <td className="text-center">
                                        {p.question_status_code !== "DELETED" && (
                                            <Fragment>
                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`questionDetails${i}`}>Szczegóły</Tooltip>}>
                                                    <span className="d-inline-block">
                                                        <Button
                                                            variant="outline-primary"
                                                            onClick={() =>
                                                                onChangeNavAndPage({ nav: "questions", subpage: "questionDetails", params: { questionID: p.question_id } }, currentPageParams, null)
                                                            }
                                                            className="mx-1 btn-list"
                                                        >
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Button>
                                                    </span>
                                                </OverlayTrigger>
                                                {(p.question_status_code === "NEW" || p.question_status_code === "EVALUATE") &&
                                                    appParams.map((e, j) => (
                                                        <Fragment key={j}>
                                                            {e.param_code === "QUESTIONS_EDIT" && e.param_value === true && (
                                                                <OverlayTrigger placement="top" overlay={<Tooltip id={`questionEdit${i}`}>Edytuj</Tooltip>}>
                                                                    <span className="d-inline-block">
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            onClick={() =>
                                                                                onChangeNavAndPage(
                                                                                    { nav: "questions", subpage: "editQuestion", params: { questionID: p.question_id } },
                                                                                    currentPageParams,
                                                                                    null
                                                                                )
                                                                            }
                                                                            className="mx-1 btn-list"
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit} />
                                                                        </Button>
                                                                    </span>
                                                                </OverlayTrigger>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                {p.question_status_code === "NEW" && (
                                                    <OverlayTrigger placement="top" overlay={<Tooltip id={`questionSend${i}`}>Wyślij do oceny</Tooltip>}>
                                                        <span className="d-inline-block">
                                                            <Button
                                                                variant="outline-primary"
                                                                onClick={() =>
                                                                    this.setState({ showSendQuestionForEvaluationModal: true, selectedQuestion: { id: p.question_id, name: p.question_name } })
                                                                }
                                                                className="mx-1 btn-list"
                                                            >
                                                                <FontAwesomeIcon icon={faPaperPlane} />
                                                            </Button>
                                                        </span>
                                                    </OverlayTrigger>
                                                )}
                                            </Fragment>
                                        )}
                                    </td>
                                </tr>
                            ))
                            .slice(0, displayNOR)}
                    </tbody>
                </Table>
                {showSendQuestionForEvaluationModal && (
                    <SendQuestionForEvaluation
                        show={showSendQuestionForEvaluationModal}
                        onHide={() => {
                            this.setState({ showSendQuestionForEvaluationModal: false }, () => this.setState({ selectedQuestion: null }));
                            if (alert) this.props.onRefreshList();
                        }}
                        onHideAndRefresh={() => {
                            this.setState({ showSendQuestionForEvaluationModal: false }, () => this.setState({ selectedQuestion: null }));
                            this.props.onRefreshList();
                        }}
                        onSendQuestion={() => this.sendQuestionForEvaluation(this.state.selectedQuestion.id)}
                        alert={alert}
                    />
                )}
            </Fragment>
        );
    }
}

export default QuestionsListTable;
