import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false,
        },
    },
    scales: {
        yAxes: {
            min: 0,
            max: 9,
            ticks: {
                font: {
                    family: "Asap-Regular",
                },
            },
        },
        xAxes: {
            ticks: {
                font: {
                    family: "Asap-Regular",
                },
            },
        },
    },
};

const PJM_PL = (props) => {
    function barData(resultGroup, hearingLossCode) {
        const { detailedData } = props;
        let value = detailedData.filter((e) => e.result_group === resultGroup && e.hearing_loss_code === hearingLossCode);
        value = value[0].normalization_value;
        return value;
    }

    return (
        <Fragment>
            <Col xs={12}>
                {props.subtestTypeCode === "PJM+PL" ? (
                    <p className="h4 my-3">
                        1. <span className="text-primary">POLSKI JĘZYK MIGOWY</span> a <span className="text-secondary">JĘZYK POLSKI</span>
                    </p>
                ) : props.subtestTypeCode === "PL" ? (
                    <p className="h4 my-3">
                        1. <span className="text-secondary">JĘZYK POLSKI</span>
                    </p>
                ) : (
                    <p className="h4 my-3">
                        1. <span className="text-primary">POLSKI JĘZYK MIGOWY</span> – skala staninowa i centylowa
                    </p>
                )}
            </Col>
            <Col xs={7}>
                <Row>
                    <Col xs={12}>
                        <p className="h5 text-uppercase text-center">skala staninowa</p>
                        <Bar
                            options={options}
                            data={{
                                labels:
                                    props.subtestTypeCode === "PJM+PL"
                                        ? ["PJM na tle uczniów z wadą słuchu", "PL na tle uczniów z wadą słuchu", "PL na tle uczniów słyszących"]
                                        : props.subtestTypeCode === "PL"
                                        ? ["PL na tle uczniów z wadą słuchu", "PL na tle uczniów słyszących"]
                                        : ["PJM na tle uczniów z wadą słuchu"],
                                datasets: [
                                    {
                                        data:
                                            props.subtestTypeCode === "PJM+PL"
                                                ? [barData("PJM_TOTAL_STANINE", "LOSS"), barData("PL_TOTAL_STANINE", "LOSS"), barData("PL_TOTAL_STANINE", "HEAR")]
                                                : props.subtestTypeCode === "PL"
                                                ? [barData("PL_TOTAL_STANINE", "LOSS"), barData("PL_TOTAL_STANINE", "HEAR")]
                                                : [barData("PJM_TOTAL_STANINE", "LOSS")],
                                        backgroundColor: props.subtestTypeCode === "PJM+PL" ? ["#38b6ff", "#ff914d", "#ff914d"] : props.subtestTypeCode === "PL" ? ["#ff914d", "#ff914d"] : ["#38b6ff"],
                                    },
                                ],
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={{ span: 4, offset: 1 }} className="mt-5">
                <Row>
                    <Col xs={12}>
                        <p className="h5 text-uppercase text-center">
                            centyl<sup>2</sup>
                        </p>
                    </Col>
                    <Col xs={12} className="w-100 text-center my-3">
                        <Row>
                            <Col xs={8}>
                                <Row>
                                    {props.subtestTypeCode.includes("PJM") && (
                                        <Col xs={12} className="th-bg-light br bb py-1 text-primary">
                                            <span className="bold">PJM</span>
                                            <p className="text-small">na tle uczniów z wadą słuchu</p>
                                        </Col>
                                    )}
                                    {props.subtestTypeCode.includes("PL") && (
                                        <Fragment>
                                            <Col xs={12} className="th-bg-light br bb py-1 text-secondary">
                                                <span className="bold">PL</span>
                                                <p className="text-small">na tle uczniów z wadą słuchu</p>
                                            </Col>
                                            <Col xs={12} className="th-bg-light br py-1 text-secondary">
                                                <span className="bold">PL</span>
                                                <p className="text-small">na tle uczniów słyszących</p>
                                            </Col>
                                        </Fragment>
                                    )}
                                </Row>
                            </Col>
                            <Col xs={4}>
                                <Row>
                                    {props.subtestTypeCode.includes("PJM") && (
                                        <Col xs={12} className="bb" style={{ padding: "0.875rem 0" }}>
                                            {barData("PJM_TOTAL_CENTILE", "LOSS")}
                                        </Col>
                                    )}
                                    {props.subtestTypeCode.includes("PL") && (
                                        <Fragment>
                                            <Col xs={12} className="bb" style={{ padding: "0.875rem 0" }}>
                                                {barData("PL_TOTAL_CENTILE", "LOSS")}
                                            </Col>
                                            <Col xs={12} style={{ padding: "0.875rem 0" }}>
                                                {barData("PL_TOTAL_CENTILE", "HEAR")}
                                            </Col>
                                        </Fragment>
                                    )}
                                </Row>
                            </Col>
                        </Row>
                        <Row></Row>
                    </Col>
                </Row>
            </Col>
        </Fragment>
    );
};

export default PJM_PL;
