import React, { Fragment } from "react";
import { Form } from "react-bootstrap";

const MonthDropdown = (props) => {
    const { onSelectMonth, value, classStyle } = props;
    const monthArray = [
        {
            key: 0,
            value: 1,
            text: "Styczeń",
        },
        {
            key: 1,
            value: 2,
            text: "Luty",
        },
        {
            key: 2,
            value: 3,
            text: "Marzec",
        },
        {
            key: 3,
            value: 4,
            text: "Kwiecień",
        },
        {
            key: 4,
            value: 5,
            text: "Maj",
        },
        {
            key: 5,
            value: 6,
            text: "Czerwiec",
        },
        {
            key: 6,
            value: 7,
            text: "Lipiec",
        },
        {
            key: 7,
            value: 8,
            text: "Sierpień",
        },
        {
            key: 8,
            value: 9,
            text: "Wrzesień",
        },
        {
            key: 9,
            value: 10,
            text: "Październik",
        },
        {
            key: 10,
            value: 11,
            text: "Listopad",
        },
        {
            key: 11,
            value: 12,
            text: "Grudzień",
        },
    ];

    return (
        <Fragment>
            <Form.Label>
                Miesiąc urodzenia osoby badanej <span className="required">*</span>
            </Form.Label>
            <Form.Control as="select" placeholder="Kliknij i wybierz" custom className={`form-dropdown mb-3 req ${classStyle}`} onChange={(e) =>  onSelectMonth(e.target.value)} value={value} id="objectBirthMonth">
                <option value="default">Kliknij i wybierz</option>
                {monthArray.map((e, i) => (
                    <Fragment key={i}>
                        <option value={e.value}>{e.text}</option>
                    </Fragment>
                ))}
            </Form.Control>
        </Fragment>
    );
};

export default MonthDropdown;
