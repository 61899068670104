import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faCheckCircle, faMobileAlt, faPlay } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";

import { Button } from "react-bootstrap";

const TestInstructionPJM = (props) => {
    const { onChangeTestView, frontURL } = props;
    $(document).ready(function () {
        $("body").addClass("hide-cursor");

        //animacja najazdu powiększenia ekranu
        setTimeout(() => {
            $(".displayLogo").addClass("resize");
            $(".boyComp").fadeOut(500);
        }, 3000);
        //ukrycie logo
        setTimeout(() => {
            $(".displayLogo").fadeOut(500);
            $(".display").fadeIn(0);
        }, 6000);
        //pojawienie się tytułu testu (PJM lub PL)
        setTimeout(() => {
            $("h1").fadeIn(300);
        }, 7000);
        //ukrycie tytułu
        setTimeout(() => {
            $("h1").fadeOut(300);
        }, 10000);

        //pojawienie się testu2
        setTimeout(() => {
            $("#test2").fadeIn(500);
            $(".point").fadeIn(0);
            $(".arrowRight").fadeIn(500);
        }, 10300);
        //pojawienie się myślącego chłopca
        setTimeout(() => {
            $("#boyThinking").fadeIn(500);
        }, 11000);
        //pojawienie się dymków
        setTimeout(() => {
            $(".cloud1").fadeIn(0);
            $(".cloud1").addClass("bounce-cloud");
        }, 13000);
        setTimeout(() => {
            $(".cloud2").fadeIn(0);
            $(".cloud2").addClass("bounce-cloud");
        }, 13500);
        setTimeout(() => {
            $(".cloudQ").fadeIn(0);
            $(".cloudQ").addClass("bounce-cloud");
        }, 14000);
        //przesunięcie kursora na odtworzenie filmiku-pytania
        setTimeout(() => {
            $(".point").addClass("moveToTest2Question");
        }, 16000);
        //odtworzenie filmiku-pytania
        setTimeout(() => {
            $(".point").removeClass("moveToTest2Question");
            $(".point").addClass("QuestionPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("twardy").play();
            $(".question .playButton").addClass("play");
        }, 17500);
        setTimeout(() => {
            $(".question .playButton").fadeOut(50);
            document.getElementById("twardy").addEventListener(
                "ended",
                function () {
                    $(".question .playButton").removeClass("play");
                    $(".question .playButton").fadeIn(50);
                },
                false
            );
        }, 17800);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("QuestionPos");
            $(".pointClick").fadeOut(0);
        }, 17900);
        //zmiana z myślącego chłopca na z pomysłem
        setTimeout(() => {
            $("#boyThinking").fadeOut(0);
            $(".cloud1").removeClass("bounce-cloud");
            $(".cloud2").removeClass("bounce-cloud");
            $(".cloudQ").removeClass("bounce-cloud");
            $(".cloud1").fadeOut(0);
            $(".cloud2").fadeOut(0);
            $(".cloudQ").fadeOut(0);
            $("#boyIdea").fadeIn(0);
            $("#bulb").fadeIn(0);
            $("#bulb").addClass("bounce-cloud");
        }, 22000);
        //przesunięcie kursora na poprawną odpowiedź
        setTimeout(() => {
            $(".point").removeClass("QuestionPos");
            $(".point").addClass("moveToTest2AnswerD");
            $(".pointClick").addClass("Test2AnswerDPos");
        }, 25000);
        //zaznaczenie poprawnej odpowiedzi
        setTimeout(() => {
            $(".point").removeClass("moveToTest2AnswerD");
            $(".point").addClass("Test2AnswerDPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("#test2 .answerD img").addClass("checked");
            $(".arrowRight").removeClass("disabled");
        }, 28000);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test2AnswerDPos");
            $(".pointClick").fadeOut(0);
        }, 28200);
        //przesunięcie kursora na przycisk do następnej planszy
        setTimeout(() => {
            $(".point").removeClass("Test2AnswerDPos");
            $(".point").addClass("moveToTest3");
            $(".pointClick").addClass("rightBtnPos");
        }, 30500);

        //zniknięcie testu2, zniknięcie chłopca z pomysłem
        setTimeout(() => {
            $(".point").removeClass("moveToTest3");
            $(".point").addClass("rightBtnPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $(".arrowRight").addClass("disabled");

            $("#test2").fadeOut(500);
            $("#boyIdea").fadeOut(50);
            $("#bulb").fadeOut(50);
        }, 31500);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("rightBtnPos");
            $(".pointClick").fadeOut(0);
        }, 31800);

        //pojawienie się testu1 i przycisku cofającego do poprzedniego pytania
        setTimeout(() => {
            $("#test1").fadeIn(500);
            $(".arrowLeft").fadeIn(500);
        }, 32300);
        //pojawienie się myślącego chłopca
        setTimeout(() => {
            $("#boyThinking").fadeIn(500);
        }, 33000);
        //pojawienie się dymków
        setTimeout(() => {
            $(".cloud1").fadeIn(0);
            $(".cloud1").addClass("bounce-cloud");
        }, 35000);
        setTimeout(() => {
            $(".cloud2").fadeIn(0);
            $(".cloud2").addClass("bounce-cloud");
        }, 35500);
        setTimeout(() => {
            $(".cloudQ").fadeIn(0);
            $(".cloudQ").addClass("bounce-cloud");
        }, 36000);
        //przesunięcie kursora na przycisk filmiku A
        setTimeout(() => {
            $(".point").removeClass("rightBtnPos");
            $(".point").addClass("moveToTest1AnswerA");
            $(".pointClick").addClass("Test1AnswerAPos");
        }, 38000);
        //odtworzenie filmiku A
        setTimeout(() => {
            $(".point").removeClass("moveToTest1AnswerA");
            $(".point").addClass("Test1AnswerAPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("buty").play();
            $(".answerA .playButton").addClass("play");
        }, 39500);
        setTimeout(() => {
            $(".answerA .playButton").fadeOut(50);
            document.getElementById("buty").addEventListener(
                "ended",
                function () {
                    $(".answerA .playButton").removeClass("play");
                    $(".answerA .playButton").fadeIn(50);
                },
                false
            );
        }, 39800);
        //koniec animacji kilknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test1AnswerAPos");
            $(".pointClick").fadeOut(0);
        }, 39900);

        //przesunięcie kursora na przycisk filmiku B
        setTimeout(() => {
            $(".point").removeClass("Test1AnswerAPos");
            $(".point").addClass("moveToTest1AnswerB");
            $(".pointClick").addClass("Test1AnswerBPos");
        }, 44000);
        //odtworzenie filmiku B
        setTimeout(() => {
            $(".point").removeClass("moveToTest1AnswerB");
            $(".point").addClass("Test1AnswerBPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("klopot").play();
            $(".answerB .playButton").addClass("play");
        }, 46000);
        setTimeout(() => {
            $(".answerB .playButton").fadeOut(50);
            document.getElementById("klopot").addEventListener(
                "ended",
                function () {
                    $(".answerB .playButton").removeClass("play");
                    $(".answerB .playButton").fadeIn(50);
                },
                false
            );
        }, 46300);
        //koniec animacji kilknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test1AnswerBPos");
            $(".pointClick").fadeOut(0);
        }, 46500);
        //przesunięcie kursora na przycisk filmiku C
        setTimeout(() => {
            $(".point").removeClass("Test1AnswerBPos");
            $(".point").addClass("moveToTest1AnswerC");
            $(".pointClick").addClass("Test1AnswerCPos");
        }, 51000);
        //odtworzenie filmiku C
        setTimeout(() => {
            $(".point").removeClass("moveToTest1AnswerC");
            $(".point").addClass("Test1AnswerCPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("ksiezyc").play();
            $(".answerC .playButton").addClass("play");
        }, 53000);
        setTimeout(() => {
            $(".answerC .playButton").fadeOut(50);
            document.getElementById("ksiezyc").addEventListener(
                "ended",
                function () {
                    $(".answerC .playButton").removeClass("play");
                    $(".answerC .playButton").fadeIn(50);
                },
                false
            );
        }, 53300);
        //koniec animacji kilknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test1AnswerCPos");
            $(".pointClick").fadeOut(0);
        }, 53500);
        //przesunięcie kursora na przycisk filmiku D
        setTimeout(() => {
            $(".point").removeClass("Test1AnswerCPos");
            $(".point").addClass("moveToTest1AnswerD");
            $(".pointClick").addClass("Test1AnswerDPos");
        }, 58000);
        //odtworzenie filmiku D
        setTimeout(() => {
            $(".point").removeClass("moveToTest1AnswerD");
            $(".point").addClass("Test1AnswerDPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("kino").play();
            $(".answerD .playButton").addClass("play");
        }, 60000);
        setTimeout(() => {
            $(".answerD .playButton").fadeOut(50);
            document.getElementById("kino").addEventListener(
                "ended",
                function () {
                    $(".answerD .playButton").removeClass("play");
                    $(".answerD .playButton").fadeIn(50);
                },
                false
            );
        }, 60300);
        //koniec animacji kilknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test1AnswerDPos");
            $(".pointClick").fadeOut(0);
        }, 60500);
        //zmiana z myślącego chłopca na z pomysłem
        setTimeout(() => {
            $("#boyThinking").fadeOut(0);
            $(".cloud1").removeClass("bounce-cloud");
            $(".cloud2").removeClass("bounce-cloud");
            $(".cloudQ").removeClass("bounce-cloud");
            $(".cloud1").fadeOut(0);
            $(".cloud2").fadeOut(0);
            $(".cloudQ").fadeOut(0);
            $("#boyIdea").fadeIn(0);
            $("#bulb").fadeIn(0);
            $("#bulb").addClass("bounce-cloud");
        }, 65500);
        //przesunięcie kursora na poprawną odpowiedź
        setTimeout(() => {
            $(".point").removeClass("Test1AnswerDPos");
            $(".point").addClass("moveToTest1CheckAnswer");
            $(".pointClick").addClass("Test1CheckAnswerPos");
        }, 68000);
        //zaznaczenie poprawnej odpowiedzi
        setTimeout(() => {
            $(".point").removeClass("moveToTest1CheckAnswer");
            $(".point").addClass("Test1CheckAnswerPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("#test1 .answerC video").addClass("checked");
            $(".arrowRight").removeClass("disabled");
        }, 70000);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test1CheckAnswerPos");
            $(".pointClick").fadeOut(0);
        }, 70300);
        //przesunięcie kursora na przycisk do następnej planszy
        setTimeout(() => {
            $(".point").removeClass("Test1CheckAnswerPos");
            $(".point").addClass("moveToTest2");
            $(".pointClick").addClass("rightBtnPos");
        }, 72000);
        //zniknięcie testu1, zniknięcie chłopca z pomysłem
        setTimeout(() => {
            $(".point").removeClass("moveToTest2");
            $(".point").addClass("rightBtnPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $(".arrowRight").addClass("disabled");
            $(".arrowRight").fadeOut(500);
            $("#test1").fadeOut(500);
            $("#boyIdea").fadeOut(50);
            $("#bulb").fadeOut(50);
        }, 73500);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("rightBtnPos");
            $(".pointClick").fadeOut(0);
        }, 73800);

        //pojawienie się testu3
        setTimeout(() => {
            $("#test3").fadeIn(500);
            $("#testDone").fadeIn(500);
        }, 74300);
        //pojawienie się myślącego chłopca
        setTimeout(() => {
            $("#boyThinking").fadeIn(500);
        }, 75000);
        //pojawienie się dymków
        setTimeout(() => {
            $(".cloud1").fadeIn(0);
            $(".cloud1").addClass("bounce-cloud");
        }, 77000);
        setTimeout(() => {
            $(".cloud2").fadeIn(0);
            $(".cloud2").addClass("bounce-cloud");
        }, 77500);
        setTimeout(() => {
            $(".cloudQ").fadeIn(0);
            $(".cloudQ").addClass("bounce-cloud");
        }, 78000);
        //przesunięcie kursora na odtworzenie filmiku-pytania
        setTimeout(() => {
            $(".point").removeClass("rightBtnPos");
            $(".point").addClass("moveToTest3Question");
            $(".pointClick").addClass("QuestionPos");
        }, 80000);
        //odtworzenie filmiku-pytania
        setTimeout(() => {
            $(".point").removeClass("moveToTest3Question");
            $(".point").addClass("QuestionPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("telefonKomorkowy").play();
            $(".question .playButton").addClass("play");
        }, 82600);
        setTimeout(() => {
            $(".question .playButton").fadeOut(50);
            document.getElementById("telefonKomorkowy").addEventListener(
                "ended",
                function () {
                    $(".question .playButton").removeClass("play");
                    $(".question .playButton").fadeIn(50);
                    $("#mobileIcon1").fadeIn(200);
                },
                false
            );
        }, 82800);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("QuestionPos");
            $(".pointClick").fadeOut(0);
        }, 83000);

        //przesunięcie kursora na przycisk filmiku A
        setTimeout(() => {
            $(".point").removeClass("QuestionPos");
            $(".point").addClass("moveToTest3AnswerA");
            $(".pointClick").addClass("Test3AnswerAPos");
        }, 87000);
        //odtworzenie filmiku A
        setTimeout(() => {
            $(".point").removeClass("moveToTest3AnswerA");
            $(".point").addClass("Test3AnswerAPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("jutro").play();
            $(".answerA .playButton").addClass("play");
        }, 89000);
        setTimeout(() => {
            $(".answerA .playButton").fadeOut(50);
            document.getElementById("jutro").addEventListener(
                "ended",
                function () {
                    $(".answerA .playButton").removeClass("play");
                    $(".answerA .playButton").fadeIn(50);
                },
                false
            );
        }, 89200);
        //koniec animacji kilknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test3AnswerAPos");
            $(".pointClick").fadeOut(0);
        }, 89300);
        //przesunięcie kursora na przycisk filmiku B
        setTimeout(() => {
            $(".point").removeClass("Test3AnswerAPos");
            $(".point").addClass("moveToTest1AnswerB");
            $(".pointClick").addClass("Test1AnswerBPos");
        }, 94000);
        //odtworzenie filmiku B
        setTimeout(() => {
            $(".point").removeClass("moveToTest1AnswerB");
            $(".point").addClass("Test1AnswerBPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            document.getElementById("telefon").play();
            $(".answerB .playButton").addClass("play");
        }, 95500);
        setTimeout(() => {
            $(".answerB .playButton").fadeOut(50);
            document.getElementById("telefon").addEventListener(
                "ended",
                function () {
                    $(".answerB .playButton").removeClass("play");
                    $(".answerB .playButton").fadeIn(50);
                    $("#mobileIcon2").fadeIn(200);
                },
                false
            );
        }, 95700);
        //koniec animacji kilknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test1AnswerBPos");
            $(".pointClick").fadeOut(0);
        }, 95800);

        //zmiana z myślącego chłopca na z pomysłem
        setTimeout(() => {
            $("#boyThinking").fadeOut(0);
            $(".cloud1").removeClass("bounce-cloud");
            $(".cloud2").removeClass("bounce-cloud");
            $(".cloudQ").removeClass("bounce-cloud");
            $(".cloud1").fadeOut(0);
            $(".cloud2").fadeOut(0);
            $(".cloudQ").fadeOut(0);
            $("#boyIdea").fadeIn(0);
            $("#bulb").fadeIn(0);
            $("#bulb").addClass("bounce-cloud");
        }, 101000);
        //przesunięcie kursora na poprawną odpowiedź
        setTimeout(() => {
            $(".point").removeClass("Test1AnswerBPos");
            $(".point").addClass("moveToTest3AnswerB");
            $(".pointClick").addClass("Test3AnswerBPos");
        }, 102500);
        //zaznaczenie poprawnej odpowiedzi
        setTimeout(() => {
            $(".point").removeClass("moveToTest3AnswerB");
            $(".point").addClass("Test3AnswerBPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("#test3 .answerB video").addClass("checked");
            $("#testDone").removeClass("disabled");
        }, 104000);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("Test3AnswerBPos");
            $(".pointClick").fadeOut(0);
        }, 104200);

        //przesunięcie kursora na przycisk do następnej planszy
        setTimeout(() => {
            $(".point").removeClass("Test3AnswerBPos");
            $(".point").addClass("moveToTFinishTest");
            $(".pointClick").addClass("rightBtnPos");
        }, 107000);

        //koniec testu
        setTimeout(() => {
            $(".point").removeClass("moveToTFinishTest");
            $(".point").addClass("rightBtnPos");
            $(".point").fadeOut(0);
            $(".pointClick").fadeIn(0);
            $("body").removeClass("hide-cursor");
            $(".instruction").fadeOut(1500);
        }, 109000);
        //koniec animacji kliknięcia
        setTimeout(() => {
            $(".point").fadeIn(0);
            $(".pointClick").removeClass("rightBtnPos");
            $(".pointClick").fadeOut(0);
        }, 109300);
        //pokaż przycisk do startu testu
        setTimeout(() => {
            $("#startTest").fadeIn(300);
        }, 111000);
    });
    return (
        <Fragment>
            <p className="titleInstr">PJM</p>
            <div className="instruction">
                <div>
                    <img src={`${frontURL}/images/boyComp.svg`} className="boyComp" alt="boyComp" />
                    <img src={`${frontURL}/images/displayLogo.svg`} className="displayLogo" alt="displayLogo" />
                    <img src={`${frontURL}/images/display.svg`} className="display" alt="display" style={{ display: "none" }} />
                    <h1 className="text-primary" style={{ display: "none" }}>
                        PJM
                    </h1>
                    <img src={`${frontURL}/images/boyThinking.svg`} id="boyThinking" alt="boyThinking" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/boyIdea.svg`} id="boyIdea" alt="boyIdea" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/bulb.svg`} id="bulb" alt="bulb" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/cloud1.svg`} className="cloud1" alt="cloud1" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/cloud2.svg`} className="cloud2" alt="cloud2" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/cloudQ.svg`} className="cloudQ" alt="cloudQ" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/point.svg`} className="point" alt="point" style={{ display: "none" }} />
                    <img src={`${frontURL}/images/pointClick.svg`} className="pointClick" alt="pointClick" style={{ display: "none" }} />
                    <div className="arrowLeft" style={{ display: "none" }}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="test" id="test1" style={{ display: "none" }}>
                        <div className="qitem question">
                            <img src="https://test-leksyka.pl/questions/76a2_1.jpg" alt="76a2_1.jpg" />
                        </div>
                        <div className="test-border"></div>
                        <div className="qitem answer answerA">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="buty" muted="muted">
                                <source src="https://test-leksyka.pl/questions/buty_1_1_1_1_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="qitem answer answerB">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="klopot" muted="muted">
                                <source src="https://test-leksyka.pl/questions/klopot_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="qitem answer answerC">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="ksiezyc" muted="muted">
                                <source src="https://test-leksyka.pl/questions/ksiezyc_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="qitem answer answerD">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="kino" muted="muted">
                                <source src="https://test-leksyka.pl/questions/kino_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                    </div>

                    <div className="test" id="test2" style={{ display: "none" }}>
                        <div className="qitem question">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="twardy" muted="muted">
                                <source src="https://test-leksyka.pl/questions/twardy_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="test-border"></div>
                        <div className="qitem answer answerA">
                            <img src="https://test-leksyka.pl/questions/19c_1_1.jpg" alt="19c_1_1.jpg" />
                        </div>
                        <div className="qitem answer answerB">
                            <img src="https://test-leksyka.pl/questions/21d_1.jpg" alt="21d_1.jpg" />
                        </div>
                        <div className="qitem answer answerC">
                            <img src="https://test-leksyka.pl/questions/76b_1.jpg" alt="76b_1.jpg" />
                        </div>
                        <div className="qitem answer answerD">
                            <img src="https://test-leksyka.pl/questions/90_1_1.jpg" alt="90_1_1.jpg" />
                        </div>
                    </div>

                    <div className="test" id="test3" style={{ display: "none" }}>
                        <div id="mobileIcon1" style={{ display: "none" }}>
                            <FontAwesomeIcon icon={faMobileAlt} />
                        </div>
                        <div id="mobileIcon2" style={{ display: "none" }}>
                            <FontAwesomeIcon icon={faMobileAlt} />
                        </div>

                        <div className="qitem question">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="telefonKomorkowy" muted="muted">
                                <source src="https://test-leksyka.pl/questions/telefon_komorkowy.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="test-border"></div>
                        <div className="qitem answer answerA">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="jutro" muted="muted">
                                <source src="https://test-leksyka.pl/questions/jutro_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="qitem answer answerB">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="telefon" muted="muted">
                                <source src="https://test-leksyka.pl/questions/telefon.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="qitem answer answerC">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="codziennie" muted="muted">
                                <source src="https://test-leksyka.pl/questions/codziennie_1_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                        <div className="qitem answer answerD">
                            <div className="blockControls"></div>
                            <div className="playButton">
                                <FontAwesomeIcon icon={faPlay} />
                            </div>
                            <video id="sluchac" muted="muted">
                                <source src="https://test-leksyka.pl/questions/sluchac_1_1.mp4" type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>
                        </div>
                    </div>

                    <div className="arrowRight disabled" style={{ display: "none" }}>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </div>
                    <div id="testDone" className="disabled" style={{ display: "none" }}>
                        <FontAwesomeIcon icon={faCheckCircle} />
                    </div>
                </div>
            </div>

            <Button id="startTest" onClick={() => onChangeTestView("testView")} style={{ display: "none" }}>
                <FontAwesomeIcon icon={faPlay} />
            </Button>
        </Fragment>
    );
};

export default TestInstructionPJM;
