import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Row, Form, Container, Modal } from "react-bootstrap";
import EmailInput from "../../forms/EmailInput";
import NameInput from "../../forms/NameInput";
import TooltipWithDisabledButton from "../../other/TooltipWithDisabledButton";
import $ from "jquery";
import NavDetails from "../../navigation/NavDetails";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import UserRoles from "../../forms/UserRoles";
import UserStatuses from "../../forms/UserStatuses";
import { faFilePdf, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validateEmail = (email) => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(email).toLowerCase())) {
        $("#userEmail").addClass("is-invalid");
        return "Email jest nieprawidłowy. ";
    } else return "";
};

class AddEditUser extends Component {
    state = {
        userData: [
            {
                user_email: "",
                user_name: "",
                user_surname: "",
                user_institution: "",
                user_position: "",
                user_stat_code: "VERIFIED",
            },
        ],
        newFile: null,
        existingFile: null,
        pdfFile: null,
        loading: true,
        alertFileInput: "",
        changedValues: false,
        userRoles: null,
        showZoomImgModal: false,
    };

    modifyUserRoles = async (userID) => {
        const { fetchURL, onShowAlert, currentPage, onChangeNavAndPage } = this.props;
        const { userRoles } = this.state;
        const passData = {
            userRoles: userRoles,
            userID: userID,
            option: currentPage.subpage,
        };
        try {
            const response = await fetch(`${fetchURL}/modify-user-roles`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onShowAlert({ variant: "success", message: fetchedData.message, fading: 2000 });
                        onChangeNavAndPage({ nav: "adminPanel", subpage: "usersList", params: null }, null, "usersList");
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.modifyUserRoles(userID), 4000);
        }
    };

    insertOrUpdateUser = async (fileID) => {
        const { fetchURL, onShowAlert, currentPage } = this.props;
        const { userData, userRoles } = this.state;
        const passData = {
            userData: userData[0],
            option: currentPage.subpage,
            fileID: fileID,
        };
        try {
            const response = await fetch(`${fetchURL}/insert-or-update-user`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    // //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const userID = fetchedData.userID;
                        //aktualizuj role tylko jeśli użytkownik NIE jest superadminem
                        if ((userRoles.length === 1 && userRoles[0] !== "SUPERADMIN") || userRoles.length > 1) this.modifyUserRoles(userID);
                        else {
                            onShowAlert({ variant: "success", message: fetchedData.message, fading: 2000 });
                            this.setState({ changedValues: false });
                            this.props.onCancelChangeValuesInForms();
                        }
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.insertOrUpdateUser(fileID), 4000);
        }
    };

    insertDiploma = async () => {
        const { fetchURL, onShowAlert } = this.props;
        const data = new FormData();
        data.append("file", this.state.newFile);
        try {
            const response = await fetch(`${fetchURL}/insert-blob`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                body: data,
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const fileID = fetchedData.fileID;
                        this.insertOrUpdateUser(fileID);
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.insertDiploma, 4000);
        }
    };

    handleCheckValidation = () => {
        const { userData, userRoles } = this.state;
        const { onShowAlert } = this.props;
        onShowAlert("");
        let errorMsg = "";
        if (userRoles.length === 0) {
            errorMsg += "Nie została przypisana żadna rola. ";
            $(`#userRolesDropdown`).addClass("is-invalid");
        }

        if (!userData[0].user_email || validateEmail(userData[0].user_email)) {
            errorMsg += `Pole "E-mail" zawiera nieprawidłową wartość lub jest puste. `;
            $(`#userEmail`).addClass("is-invalid");
        }

        if (!userData[0].user_name) {
            errorMsg += `Pole "Imię" zawiera nieprawidłową wartość lub jest puste. `;
            $(`#userName`).addClass("is-invalid");
        }
        if (!userData[0].user_surname) {
            errorMsg += `Pole "Nazwisko" zawiera nieprawidłową wartość lub jest puste. `;
            $(`#userSurname`).addClass("is-invalid");
        }
        if (!userData[0].user_institution) {
            errorMsg += `Pole "Nazwa ośrodka" zawiera nieprawidłową wartość lub jest puste. `;
            $(`#userInstitution`).addClass("is-invalid");
        }
        if (!userData[0].user_position) {
            errorMsg += `Pole "Stanowisko" zawiera nieprawidłową wartość lub jest puste. `;
            $(`#userPosition`).addClass("is-invalid");
        }

        if (this.state.alertFileInput || (this.state.file && !this.state.fileType)) errorMsg += `Coś jest nie tak ze skanem dokumentu. `;

        if (!userData[0].user_stat_code) {
            errorMsg += `Pole "Status użytkownika" zawiera nieprawidłową wartość lub jest puste. `;
            $(`#userStatusesDropdown`).addClass("is-invalid");
        }

        if (errorMsg) {
            onShowAlert({
                variant: "danger",
                message: (
                    <>
                        <p>W formularzu wystąpiły następujące błędy:</p>
                        <p>{errorMsg}</p>
                    </>
                ),
            });
        } else {
            onShowAlert({ variant: "info", message: this.props.currentPage.subpage === "editUser" ? "Edytuję użytkownika..." : "Dodaję użytkownika..." });
            //console.log(userData, userRoles, this.state.newFile, this.state.existingFile);
            if (this.state.newFile) {
                this.insertDiploma();
            } else this.insertOrUpdateUser(null);
        }
    };

    handleChangeData = (e) => {
        const { userData } = this.state;
        const { name, value, id } = e.target;
        const list = [...userData];
        list[0][name] = value;
        this.setState({ userData: list, changedValues: true });
        this.props.onChangeValuesInForms();
        $(`#${id}`).removeClass("is-invalid");
    };

    handleUploadFile = (e) => {
        const file = e.target.files[0];
        const { userData } = this.state;
        if (file) {
            const fileType = file.type;
            let errorMsg = "";
            /* 1024x1024x16 = 16MB */
            if (file.size > 16777216) errorMsg += "Plik nie może ważyć więcej niż 16MB. ";
            if (!fileType.includes("image/jpg") && !fileType.includes("image/jpeg") && !fileType.includes("image/png") && !fileType.includes("application/pdf"))
                errorMsg += "Niepoprawny format pliku. Można załadować png, jpg i pdf.";
            //console.log(file, fileType);

            this.setState({ changedValues: true });
            this.props.onChangeValuesInForms();
            if (errorMsg) this.setState({ alertFileInput: { variant: "danger", mssg: errorMsg } });
            else this.setState({ alertFileInput: "", newFile: file, existingFile: null });
        } else {
            const list = [...userData];
            this.setState({ userData: list, changedValues: true });
            this.setState({ file: "", fileURL: "" });
        }
    };

    getUserData = async (userID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            userID: userID,
        };
        try {
            const response = await fetch(`${fetchURL}/user-details`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        let userData = fetchedData.userData;
                        userData = [
                            {
                                user_id: userData[0].user_id,
                                user_email: userData[0].user_email,
                                user_name: userData[0].user_name,
                                user_surname: userData[0].user_surname,
                                user_institution: userData[0].user_institution,
                                user_position: userData[0].user_position,
                                user_stat_code: userData[0].user_stat_code,
                                user_diploma_blob_id: userData[0].user_diploma_blob_id,
                            },
                        ];
                        this.setState({ userData: userData }, () => {
                            this.getUserRoles(userID);
                            if (userData[0].user_diploma_blob_id) {
                                this.getDiplomaData(userData[0].user_diploma_blob_id);
                            } else this.setState({ loading: false });
                        });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUserData(userID), 4000);
        }
    };

    getDiplomaData = async (fileID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            fileID: fileID,
        };
        try {
            const resBlobData = await fetch(`${fetchURL}/get-blob-data`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (resBlobData.status === 200) {
                await resBlobData.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const fileData = fetchedData.fileData;
                        this.setState({ existingFile: fileData }, () => {
                            if (fileData[0].blob_file_type === "application/pdf") this.getPDF(fileID);
                            else {
                                fileData[0].blob_content = this.blobToBuffer(fileData[0].blob_content);
                                this.setState({ existingFile: fileData, loading: false });
                            }
                        });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.getDiplomaData(fileID), 4000);
        }
    };

    getPDF = async (fileID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            fileID: fileID,
        };

        try {
            const resp = await fetch(`${fetchURL}/get-blob-content`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(passData),
            });
            if (resp.status === 200) {
                await resp.arrayBuffer().then((resp) => {
                    const file = new Blob([resp], { type: "application/pdf" });
                    this.setState({ pdfFile: file }, () => this.setState({ loading: false }));
                });
            } else onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.getPDF(fileID), 4000);
        }
    };

    blobToBuffer = (object) => {
        const blob = object;
        const buffer = Buffer.from(blob);
        const blob_content = buffer.toString("base64");
        return blob_content;
    };

    getUserRoles = async (userID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            userID: userID,
        };
        try {
            const response = await fetch(`${fetchURL}/get-user-roles`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const userRoles = fetchedData.userRoles;
                        let userRolesArray = [];
                        userRoles.forEach((e) => {
                            userRolesArray.push(e.user_role_code);
                        });

                        this.setState({ userRoles: userRolesArray });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUserRoles(userID), 4000);
        }
    };

    componentDidMount() {
        const { appParams, currentPage } = this.props;
        if (currentPage.subpage === "editUser") {
            this.getUserData(currentPage.params.userID);
        } else
            appParams.forEach((p) => {
                if (p.param_code === "DEFAULT_ROLE") {
                    this.setState({ userRoles: [p.param_value] }, () => {
                        this.setState({ loading: false });
                    });
                }
            });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { userData, changedValues, loading, alertFileInput, userRoles, newFile, existingFile, showZoomImgModal } = this.state;
        const { onBackToPreviousPage, onCancelChangeValuesInForms, fetchURL, onShowAlert, currentPage } = this.props;

        return (
            <Fragment>
                <Container fluid>
                    <Row className="py-3">
                        <NavDetails
                            header={this.props.currentPage.subpage === "editUser" ? "Edytuj użytkownika" : "Dodaj użytkownika"}
                            changedValues={changedValues}
                            onChangeView={onBackToPreviousPage}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                        />
                        <Col></Col>
                    </Row>
                </Container>
                <Container>
                    {loading ? (
                        <LoadingProgressBar />
                    ) : (
                        <Fragment>
                            <Row>
                                <Col lg={6}>
                                    <EmailInput
                                        value={userData[0].user_email}
                                        onChangeValue={this.handleChangeData}
                                        name="user_email"
                                        id="userEmail"
                                        disabled={currentPage.subpage === "editUser" ? true : false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={4}>
                                    <NameInput value={userData[0].user_name} onChangeValue={this.handleChangeData} req labelTxt="Imię" placeholderTxt="Wpisz imię" name="user_name" id="userName" />
                                </Col>
                                <Col xs={8}>
                                    <NameInput
                                        value={userData[0].user_surname}
                                        onChangeValue={this.handleChangeData}
                                        req
                                        labelTxt="Nazwisko"
                                        placeholderTxt="Wpisz nazwisko"
                                        name="user_surname"
                                        id="userSurname"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={8} xs={12}>
                                    <NameInput
                                        value={userData[0].user_institution}
                                        onChangeValue={this.handleChangeData}
                                        req
                                        labelTxt="Miejsce pracy"
                                        placeholderTxt="Wpisz nazwę ośrodka"
                                        name="user_institution"
                                        id="userInstitution"
                                    />
                                </Col>
                                <Col lg={4} xs={12}>
                                    <NameInput
                                        value={userData[0].user_position}
                                        onChangeValue={this.handleChangeData}
                                        req
                                        labelTxt="Stanowisko"
                                        placeholderTxt="Wpisz swoje stanowisko pracy"
                                        name="user_position"
                                        id="userPosition"
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Label className="d-block">Skan dyplomu</Form.Label>
                                    {existingFile ? (
                                        existingFile[0].blob_file_type === "image/jpeg" || existingFile[0].blob_file_type === "image/png" ? (
                                            <div className="loaded-file mb-2">
                                                <img src={`data:${existingFile[0].blob_file_type};base64,${existingFile[0].blob_content}`} alt={`${existingFile[0].blob_filename}`} />
                                                <div className="loaded-file-zoom" onClick={() => this.setState({ showZoomImgModal: true })} style={{ top: "2rem" }}>
                                                    <div className="loaded-file-zoom-icon">
                                                        <FontAwesomeIcon icon={faSearchPlus} />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : existingFile[0].blob_file_type === "application/pdf" ? (
                                            <p className="mb-2 d-inline-block align-middle">
                                                <span className="text-danger mr-1">
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </span>
                                                {existingFile[0].blob_filename}
                                            </p>
                                        ) : (
                                            <Fragment></Fragment>
                                        )
                                    ) : newFile ? (
                                        newFile.type !== "application/pdf" ? (
                                            <Fragment>
                                                <div className="loaded-file mb-2">
                                                    <img src={URL.createObjectURL(newFile)} alt={newFile.name} />
                                                    <div className="loaded-file-zoom" onClick={() => this.setState({ showZoomImgModal: true })} style={{ top: "2rem" }}>
                                                        <div className="loaded-file-zoom-icon">
                                                            <FontAwesomeIcon icon={faSearchPlus} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="mb-0 ml-2 d-inline-block align-middle">{newFile.name}</p>
                                            </Fragment>
                                        ) : (
                                            <p className="mb-2 d-inline-block align-middle">
                                                <span className="text-danger mr-1">
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </span>
                                                {newFile.name}
                                            </p>
                                        )
                                    ) : (
                                        <Fragment></Fragment>
                                    )}
                                    {/* <input type="file" name="picture" onChange={this.handleUploadFile} className="d-block mb-2" /> */}
                                    <div className="input-group mb-3" style={{ width: "11rem" }}>
                                        <div className="choose-file">
                                            <input
                                                type="file"
                                                className="choose-file-input"
                                                id="chooseNewDiploma"
                                                name="diploma"
                                                onChange={this.handleUploadFile}
                                                accept={"image/x-png,image/jpeg,application/pdf"}
                                            />
                                            <label className="choose-file-label btn btn-secondary" htmlFor="chooseNewDiploma" aria-describedby="chooseFileAddon">
                                                Wybierz nowy plik
                                            </label>
                                        </div>
                                    </div>
                                    {alertFileInput && <Alert variant={alertFileInput.variant}>{alertFileInput.mssg}</Alert>}
                                    <p className="text-small mb-3">Format pliku: jpg, png lub pdf.</p>
                                </Col>
                            </Row>
                            <Row>
                                {userRoles && (
                                    <Fragment>
                                        {((userRoles.length === 1 && userRoles[0] !== "SUPERADMIN") || userRoles) && (
                                            <Col md={6} xs={12}>
                                                <UserRoles
                                                    fetchURL={fetchURL}
                                                    onShowAlert={onShowAlert}
                                                    value={userRoles}
                                                    multiple
                                                    req
                                                    onUserRolesSelected={(event, value) => {
                                                        this.setState({ userRoles: value, changedValues: true });
                                                        this.props.onChangeValuesInForms();
                                                    }}
                                                    noAdminRoles
                                                />
                                            </Col>
                                        )}
                                    </Fragment>
                                )}
                                {currentPage.subpage === "editUser" && (
                                    <Fragment>
                                        <Col md={6} xs={12}>
                                            <UserStatuses
                                                fetchURL={fetchURL}
                                                onShowAlert={onShowAlert}
                                                value={userData[0].user_stat_code}
                                                req
                                                onUserStatusesSelected={(event, value) => {
                                                    const { userData } = this.state;
                                                    const list = [...userData];
                                                    list[0].user_stat_code = value;
                                                    this.setState({ userData: list, changedValues: true });
                                                    this.props.onChangeValuesInForms();
                                                    $(`#userStatusesDropdown`).removeClass("is-invalid");
                                                }}
                                            />
                                        </Col>
                                    </Fragment>
                                )}
                            </Row>
                            <Row>
                                <Col>
                                    {!changedValues ? (
                                        <TooltipWithDisabledButton
                                            mssgTooltip="W formularzu nie nastąpiły żadne zmiany!"
                                            btnTxt={this.props.currentPage.subpage === "editUser" ? "Zapisz zmiany" : "Dodaj"}
                                            tooltipID="addNewUserBtn"
                                            size="md"
                                        />
                                    ) : (
                                        <Button variant="primary" onClick={this.handleCheckValidation}>
                                            {this.props.currentPage.subpage === "editUser" ? "Zapisz zmiany" : "Dodaj"}
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                </Container>

                {showZoomImgModal && (
                    <Modal show={showZoomImgModal} onHide={() => this.setState({ showZoomImgModal: false })} className="zoom-image-modal">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            {newFile ? (
                                <img src={URL.createObjectURL(newFile)} alt={newFile.name} />
                            ) : (
                                <img src={`data:${existingFile[0].blob_file_type};base64,${existingFile[0].blob_content}`} alt={`${existingFile[0].blob_filename}`} />
                            )}
                        </Modal.Body>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default AddEditUser;
