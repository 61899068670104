import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertBasic from "../alerts/AlertBasic";
import $ from "jquery";

class EvaluationDifficulty extends Component {
    state = {
        evaluationDifficultyList: [],
        value: this.props.defaultValue ? this.props.defaultValue : null,
    };

    getEvaluationDifficulty = async () => {
        const { fetchURL, defaultValue } = this.props;
        try {
            const response = await fetch(`${fetchURL}/evaluation-difficulty`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        let evaluationDifficultyList = fetchedData.evaluationDifficultyList;
                        if (defaultValue === "all") {
                            evaluationDifficultyList.unshift({
                                key: 0,
                                value: "all",
                                text: "Wszystkie",
                            });
                        }

                        this.setState({ evaluationDifficultyList: evaluationDifficultyList });
                    } else if (fetchedData.status === false) {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status } });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.getEvaluationDifficulty(), 4000);
        }
    };

    componentDidMount() {
        this.getEvaluationDifficulty();
    }

    render() {
        const { evaluationDifficultyList, alert, value } = this.state;
        const { onEvaluationDifficultySelected, req, labelTxt } = this.props;
        return (
            <Fragment>
                {alert && <AlertBasic variant={alert.variant} mssg={alert.message} onCloseAlert={() => this.setState({ alert: "" })} fading={alert.fading} />}

                <Form.Group>
                    <Form.Label>
                        {labelTxt} {req && <span className="required">*</span>}
                    </Form.Label>

                    <Dropdown
                        id="questionEvalDifficultyDropdown"
                        className="mb-3"
                        placeholder="Kliknij, aby wybrać"
                        fluid
                        value={value}
                        selection
                        options={evaluationDifficultyList}
                        onChange={(event, { value }) => {
                            $("#questionEvalDifficultyDropdown").removeClass("is-invalid");
                            onEvaluationDifficultySelected(event, value);
                            this.setState({ value: value });
                        }}
                    />
                </Form.Group>
            </Fragment>
        );
    }
}

export default EvaluationDifficulty;
