import React, { Fragment } from "react";
import { Form } from "react-bootstrap";
const EmailInput = (props) => {
    const { value, onChangeValue, id, name, disabled } = props;
    return (
        <Fragment>
            <Form.Group>
                <Form.Label>
                    Adres e-mail<span className="required"> *</span>
                </Form.Label>
                <Form.Control disabled={disabled ? true : false} id={id} type="email" placeholder="Podaj adres e-mail" name={name} value={value} onChange={onChangeValue} className="req" autoComplete="on" />
            </Form.Group>
        </Fragment>
    );
};

export default EmailInput;
