import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Container, Row, Col, Button, Form, Table, Modal } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import NotAllowed from "../../other/NotAllowed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp, faList, faSearch, faThLarge } from "@fortawesome/free-solid-svg-icons";
import $ from "jquery";
//import { Link } from "react-router-dom";

class ArrangeTest extends Component {
    state = {
        approvedQuestionsList: null,
        disallowPermission: false,
        changedValues: false,
        goToQuestionValueNumber: "",
        goToQuestionValueName: "",
        viewList: "tiles",
        subtestType: null,
        testType: null,
        showWarningModal: false,
    };

    getApprovedQuestionsList = async () => {
        const { fetchURL, onShowAlert, testType, subtestType } = this.props;
        const passData = {
            testType: testType,
            subtestType: subtestType,
        };

        try {
            const response = await fetch(`${fetchURL}/approved-questions-list-${testType}`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response
                    .json()
                    .then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                            this.setState({ disallowPermission: true });
                        } else if (fetchedData.status === false) {
                            onShowAlert({ variant: "danger", message: fetchedData.message });
                        } else {
                            const approvedQuestionsList = fetchedData.approvedQuestionsList;
                            this.setState({ approvedQuestionsList: approvedQuestionsList });
                        }
                    })
                    .then(() => {
                        this.setState({ changedValues: false, subtestType: subtestType, testType: testType, showWarningModal: false });
                        this.props.onCancelChangeValuesInForms();
                    });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
        }
    };

    saveChanges = async (testType) => {
        const { fetchURL, onShowAlert } = this.props;
        const { subtestType, approvedQuestionsList } = this.state;
        const passData = {
            subtestType: subtestType,
            testType: testType,
            approvedQuestionsList: approvedQuestionsList,
        };
        try {
            const response = await fetch(`${fetchURL}/save-${testType}-test-arrangement`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ disallowPermission: true });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    } else {
                        onShowAlert({ variant: "success", message: fetchedData.message, fading: 2000 });
                        this.setState({ changedValues: false, showWarningModal: false });
                        this.props.onCancelChangeValuesInForms();
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.saveChanges, 4000);
        }
    };

    handleMoveQuestion = (index, option) => {
        const { approvedQuestionsList } = this.state;
        const list = [...approvedQuestionsList];
        const movedQuestion = list[option === "down" ? index + 1 : index - 1];
        list[option === "down" ? index + 1 : index - 1] = list[index];
        list[index] = movedQuestion;
        this.setState({ approvedQuestionsList: list, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    handleScrollToQuestionNumber = (e) => {
        e.preventDefault();
        const { goToQuestionValueNumber } = this.state;
        const index = parseInt(goToQuestionValueNumber, 10);
        let myElement;
        let topPos;
        if (index) {
            myElement = document.getElementById(`question${index}`);

            if (myElement) {
                topPos = myElement.offsetTop;
                /* } else {
            myElement = document.getElementById(`question1`);

            topPos = myElement.offsetTop - 16;
        } */

                document.getElementById("questionsList").scrollTop = topPos;
            }
        }
    };

    handleScrollToQuestionName = (e) => {
        e.preventDefault();
        const { approvedQuestionsList, goToQuestionValueName } = this.state;
        let myElement;
        let topPos;
        const searchedPhrase = goToQuestionValueName.toLowerCase();
        approvedQuestionsList.forEach((e, index) => {
            if (e.question_name.toLowerCase().includes(searchedPhrase)) {
                if (index + 1) {
                    myElement = document.getElementById(`question${index + 1}`);
                    if (myElement) {
                        topPos = myElement.offsetTop;
                        document.getElementById("questionsList").scrollTop = topPos;
                    }
                }
            }
        });
    };

    componentDidUpdate() {
        const height = $(window).outerHeight() - $(`#homeContainer>nav`).outerHeight() - $("#arrangeTestTitle").outerHeight() - $("#arrangeTestNavbar").outerHeight() - 16;
        $(`#questionsList`).css("height", height);
        if (this.props.subtestType !== this.state.subtestType) {
            this.getApprovedQuestionsList();
        }
        if (this.props.testType !== this.state.testType) this.setState({ testType: this.props.testType });
    }

    componentDidMount() {
        this.getApprovedQuestionsList();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { disallowPermission, changedValues, goToQuestionValueNumber, goToQuestionValueName, viewList, approvedQuestionsList, subtestType, testType, showWarningModal } = this.state;
        const { currentPage, onChangeNavAndPage } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                <Container fluid id="arrangeTestTitle">
                    <Row className="py-3">
                        <NavDetails header={`Ułóż test ${testType === "pilot" ? "pilotażowy" : testType === "final" ? "normalizacyjny i ostateczny" : ""} ${subtestType}`} hideBackButton />
                        <Col className="text-right"></Col>
                    </Row>
                </Container>
                {disallowPermission ? (
                    <NotAllowed />
                ) : approvedQuestionsList ? (
                    approvedQuestionsList.length > 0 ? (
                        <Fragment>
                            <Container fluid id="arrangeTestNavbar" className="pb-3">
                                <Fragment>
                                    <Row>
                                        <Col lg={2} md={3} xs={4} className="pt-lg-3">
                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    if (testType === "pilot") this.saveChanges(testType);
                                                    else {
                                                        this.saveChanges("norm");
                                                        this.saveChanges("final");
                                                    }
                                                }}
                                                disabled={changedValues ? false : true}
                                                className="w-100"
                                            >
                                                Zapisz zmiany
                                            </Button>
                                        </Col>
                                        <Col lg={2} md={4} xs={4}>
                                            <Form onSubmit={this.handleScrollToQuestionNumber}>
                                                <Form.Label>Przejdź do pytania</Form.Label>
                                                <div className="input-group mb-3">
                                                    <Form.Control
                                                        placeholder="Wpisz nr pytania"
                                                        aria-label="Przejdź do pytania"
                                                        aria-describedby="scrollToQuestionNumberInput"
                                                        value={goToQuestionValueNumber}
                                                        onChange={(e) => this.setState({ goToQuestionValueNumber: e.target.value })}
                                                        id="goToQuestionNumber"
                                                        name="goToQuestionNumber"
                                                    />
                                                    <div className="input-group-append">
                                                        <Button variant="outline-primary" id="scrollToQuestionNumberInput" type="submit">
                                                            <FontAwesomeIcon icon={faSearch} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Col>
                                        <Col lg={3} md={4} xs={4}>
                                            <Form onSubmit={this.handleScrollToQuestionName}>
                                                <Form.Label className="invisible">Przejdź do pytania</Form.Label>
                                                <div className="input-group mb-3">
                                                    <Form.Control
                                                        placeholder="Wpisz nazwę pytania"
                                                        aria-label="Przejdź do pytania"
                                                        aria-describedby="scrollToQuestionNameInput"
                                                        value={goToQuestionValueName}
                                                        onChange={(e) => this.setState({ goToQuestionValueName: e.target.value })}
                                                        id="goToQuestionName"
                                                        name="goToQuestionName"
                                                    />
                                                    <div className="input-group-append">
                                                        <Button variant="outline-primary" id="scrollToQuestionNameInput" type="submit">
                                                            <FontAwesomeIcon icon={faSearch} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </Col>
                                        <Col lg={{ span: 2, offset: 3 }} md={{ span: 3, offset: 2 }} xs={4} className="pt-lg-3">
                                            <Col xs={12}>
                                                {testType && (
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="w-100"
                                                        onClick={() => window.open(`/test-preview?testType=${testType.toUpperCase()}&subtestType=${subtestType}`, "_blank")}
                                                    >
                                                        Podgląd testu
                                                    </Button>
                                                )}
                                            </Col>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                variant="outline-secondary"
                                                className={`d-inline-block mr-2 ${viewList === "tiles" ? "active" : ""}`}
                                                onClick={() => this.setState({ viewList: "tiles" })}
                                            >
                                                <FontAwesomeIcon icon={faThLarge} />
                                            </Button>
                                            <Button
                                                variant="outline-secondary"
                                                className={`d-inline-block mr-2 ${viewList === "list" ? "active" : ""}`}
                                                onClick={() => this.setState({ viewList: "list" })}
                                            >
                                                <FontAwesomeIcon icon={faList} />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Fragment>
                            </Container>
                            {viewList === "tiles" && (
                                <Container className="arrange-questions-tiles">
                                    <Row>
                                        <Col xs={12} id="questionsList" className="p-3">
                                            <Row>
                                                {approvedQuestionsList.map((e, i) => (
                                                    <Fragment key={i}>
                                                        <Col xs={12} className="arrange-question" id={`question${i + 1}`}>
                                                            <div className="arrange-question-container m-2 p-3">
                                                                <Row>
                                                                    <Col xs={6}>
                                                                        <p className="arrange-question-name">Pytanie {i + 1}.</p>
                                                                    </Col>
                                                                    <Col xs={6} className="text-right">
                                                                        <Button variant="link" size="sm" disabled={i !== 0 ? false : true} onClick={() => this.handleMoveQuestion(i, "up")}>
                                                                            <FontAwesomeIcon icon={faArrowUp} />
                                                                        </Button>

                                                                        <Button
                                                                            variant="link"
                                                                            size="sm"
                                                                            disabled={i + 1 !== approvedQuestionsList.length ? false : true}
                                                                            onClick={() => this.handleMoveQuestion(i, "down")}
                                                                        >
                                                                            <FontAwesomeIcon icon={faArrowDown} />
                                                                        </Button>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <p className="mb-0">
                                                                            Nazwa: <b>{e.question_name}</b>
                                                                        </p>
                                                                    </Col>
                                                                    <Col md={6} xs={12}>
                                                                        <p className="mb-0">Typ pytania: {e.question_type_name}</p>
                                                                        <p className="mb-0">Kategoria pytania: {e.question_category_name}</p>
                                                                    </Col>
                                                                    <Col md={6} xs={12}>
                                                                        <p className="mb-0">Średnia ocen poziomu trudności: {e.qd_average}</p>
                                                                        <p className="mb-0">Ostateczny poziom trudności: {e.admin_diff_name}</p>
                                                                    </Col>
                                                                    <Col xs={12}>
                                                                        <Button
                                                                            variant="outline-secondary"
                                                                            size="sm"
                                                                            className="my-2"
                                                                            onClick={
                                                                                !changedValues
                                                                                    ? () =>
                                                                                          onChangeNavAndPage(
                                                                                              { nav: "questions", subpage: "boardPreview", params: { questionID: e.question_id } },
                                                                                              currentPageParams,
                                                                                              null
                                                                                          )
                                                                                    : () => this.setState({ showWarningModal: true })
                                                                            }
                                                                        >
                                                                            Podgląd planszy
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                ))}
                                            </Row>
                                        </Col>
                                        <Col xs={12} className="mb-3"></Col>
                                    </Row>
                                </Container>
                            )}
                            {viewList === "list" && (
                                <Container className="arrange-questions-list">
                                    <Row>
                                        <Col xs={12} id="approvedQuestionsList" className="p-3">
                                            <Table size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>L.p.</th>
                                                        <th>Nazwa pytania</th>
                                                        <th>Typ pytania</th>
                                                        <th>Kategoria pyt.</th>
                                                        <th>Śr. ocen poz. trudności</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {approvedQuestionsList.map((e, i) => (
                                                        <Fragment key={i}>
                                                            <tr>
                                                                <td>{i + 1}</td>
                                                                <td>{e.question_name}</td>
                                                                <td>{e.question_type_name}</td>
                                                                <td>{e.question_category_name}</td>
                                                                <td className="text-center">{e.qd_average.toFixed(2)}</td>
                                                                <td>
                                                                    <Button variant="link" size="sm" disabled={i !== 0 ? false : true} onClick={() => this.handleMoveQuestion(i, "up")}>
                                                                        <FontAwesomeIcon icon={faArrowUp} />
                                                                    </Button>

                                                                    <Button
                                                                        variant="link"
                                                                        size="sm"
                                                                        disabled={i + 1 !== approvedQuestionsList.length ? false : true}
                                                                        onClick={() => this.handleMoveQuestion(i, "down")}
                                                                    >
                                                                        <FontAwesomeIcon icon={faArrowDown} />
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <Row></Row>
                                        </Col>
                                        <Col xs={12} className="mb-3"></Col>
                                    </Row>
                                </Container>
                            )}
                        </Fragment>
                    ) : (
                        <p className="text-center h6">Lista jest pusta.</p>
                    )
                ) : (
                    <LoadingProgressBar />
                )}

                {showWarningModal && (
                    <Modal show={showWarningModal} onHide={() => this.setState({ showWarningModal: false })}>
                        <Modal.Body>Uwaga! Masz niezapisane zmiany. Wejście w podgląd planszy lub testu będzie skutkowało przywróceniem do poprzedniej wersji przed zapisaniem.</Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline-secondary"
                                onClick={() => onChangeNavAndPage({ nav: "arrangingTests", subpage: "testPreview", params: { subtestType: this.state.subtestType } }, currentPageParams, null)}
                            >
                                Pokaż podgląd
                            </Button>
                            <Button variant="primary" onClick={() => this.setState({ showWarningModal: false })}>
                                Zamknij
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default ArrangeTest;
