import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";
const LogoutModal = (props) => {
    const { show, onClose, onLogout } = props;
    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                
                    <Modal.Header closeButton>
                        <Modal.Title>Wyloguj</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Czy na pewno chcesz się wylogować?</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="outline-secondary"
                            onClick={onClose}
                            type="button"
                        >
                            Anuluj
                        </Button>
                        <Button
                            variant="primary"
                            type="button"
                            onClick={onLogout}
                        >
                            Tak, wyloguj
                        </Button>
                    </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default LogoutModal;
