import React, { Fragment, Component } from "react";
import { Col, Row } from "react-bootstrap";
import TestQItem from "./TestQItem";
import $ from "jquery";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";

class TestQuestion extends Component {
    state = {};

    setHeight = () => {
        if ($(window).width() >= 1200) {
            let containerHeight = $(window).outerHeight() - $("#testFooter").outerHeight();
            $(".button-arrow").css("height", `${containerHeight}px`);
            $(".question-container").css("height", `${containerHeight}px`);

            const heightBtnCol = $(".button-arrow").height();
            const heightBtn = $(".button-arrow button").height();
            $(".button-arrow>button").css("margin-top", `${(heightBtnCol - heightBtn) / 2}px`);

            const testContainerHeight = containerHeight;
            const answerHeight = testContainerHeight / 2 - 32;
            $(`.answerCol`).css("max-height", answerHeight);
            $(`.answer`).css("max-height", answerHeight - 32);

            const width = $("#questionCol").width();
            $("#questionCol").height((3 * width) / 4);
            const widthA = $(".answerCol").width();
            $(".answer").height((3 * widthA) / 4);
            const marginTop = (testContainerHeight - $(`#questionCol`).outerHeight()) / 2;
            $(`#questionCol`).css("margin-top", `${marginTop}px`);

            $("#answersContainer").css("margin-top", (testContainerHeight - 2 * $(`.answerCol`).outerHeight()) / 2);
        } else {
            $(".button-arrow").css("height", "auto");
            $(".question-container").css("height", "auto");
            $(".button-arrow>button").css("margin-top", `auto`);
            $(`.answerCol`).css("max-height", "auto");
            const width = $("#questionCol").width();
            const widthA = $(".answerCol").width();
            $("#questionCol").height((3 * width) / 4);
            $(".answer").height((3 * widthA) / 4);
        }
    };

    componentDidUpdate() {
        this.setHeight();
        window.addEventListener("resize", this.setHeight);
    }

    componentDidMount() {
        this.setHeight();
        window.addEventListener("resize", this.setHeight);
    }

    render() {
        const { onShowAlert, fetchURL, questionData, subtestType, onSelectAnswer, frontURL } = this.props;

        return (
            <Fragment>
                {questionData ? (
                    <Row>
                        <Col xl={5} xs={12} className="p-4" id="questionCol">
                            <div id="question" className="test-qitem text-center">
                                <TestQItem
                                    questionData={{
                                        qitem_type_code: questionData.question_qitem_type_code,
                                        qitem_string: questionData.question_qitem_string,
                                        qitem_file_name: questionData.question_qitem_file_name,
                                    }}
                                    fetchURL={fetchURL}
                                    onShowAlert={onShowAlert}
                                    frontURL={frontURL}
                                    onSelectAnswer={() => {}}
                                />
                            </div>
                        </Col>
                        <Col xl={7} xs={12} id="answersContainer">
                            <Row>
                                <Col md={6} xs={12} className="answerCol p-3">
                                    <div className="answer test-qitem" id="A">
                                        <TestQItem
                                            questionData={{
                                                qitem_type_code: questionData.answer_a_qitem_type_code,
                                                qitem_string: questionData.answer_a_qitem_string,
                                                qitem_file_name: questionData.answer_a_qitem_file_name,
                                                checkedAnswer: questionData.checkedAnswer,
                                            }}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            id={"A"}
                                            subtestType={subtestType}
                                            onSelectAnswer={onSelectAnswer}
                                            frontURL={frontURL}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} xs={12} className="answerCol p-3">
                                    <div className="answer test-qitem" id="B">
                                        <TestQItem
                                            questionData={{
                                                qitem_type_code: questionData.answer_b_qitem_type_code,
                                                qitem_string: questionData.answer_b_qitem_string,
                                                qitem_file_name: questionData.answer_b_qitem_file_name,
                                                checkedAnswer: questionData.checkedAnswer,
                                            }}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            id={"B"}
                                            subtestType={subtestType}
                                            onSelectAnswer={onSelectAnswer}
                                            frontURL={frontURL}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} xs={12} className="answerCol p-3">
                                    <div className="answer test-qitem" id="C">
                                        <TestQItem
                                            questionData={{
                                                qitem_type_code: questionData.answer_c_qitem_type_code,
                                                qitem_string: questionData.answer_c_qitem_string,
                                                qitem_file_name: questionData.answer_c_qitem_file_name,
                                                checkedAnswer: questionData.checkedAnswer,
                                            }}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            id={"C"}
                                            subtestType={subtestType}
                                            onSelectAnswer={onSelectAnswer}
                                            frontURL={frontURL}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} xs={12} className="answerCol p-3">
                                    <div className="answer test-qitem" id="D">
                                        <TestQItem
                                            questionData={{
                                                qitem_type_code: questionData.answer_d_qitem_type_code,
                                                qitem_string: questionData.answer_d_qitem_string,
                                                qitem_file_name: questionData.answer_d_qitem_file_name,
                                                checkedAnswer: questionData.checkedAnswer,
                                            }}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            id={"D"}
                                            subtestType={subtestType}
                                            onSelectAnswer={onSelectAnswer}
                                            frontURL={frontURL}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                ) : (
                    <LoadingProgressBar />
                )}
            </Fragment>
        );
    }
}

export default TestQuestion;
