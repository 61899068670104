import React, { Fragment, Component } from "react";
import QuestionsList from "../../pages/questions/QuestionsList";
import AddEditQuestion from "../../pages/questions/AddEditQuestion";
import QuestionDetails from "../../pages/questions/QuestionDetails";
import QuestionPreview from "../../pages/questions/QuestionPreview";

class Questions extends Component {
    state = {
        selectedQuestionStat: "all",
        selectedSubtestType: "all",
        selectedQuestionCat: "all",
        selectedQuestionType: "all",
        NOR: 25, //NOR = NumberOfRecords
    };
    render() {
        const { selectedQuestionStat, selectedSubtestType, selectedQuestionCat, selectedQuestionType, NOR } = this.state;
        const {
            fetchURL,
            onChangeValuesInForms,
            onCancelChangeValuesInForms,
            onChangeNavAndPage,
            currentPage,
            onBackToPreviousPage,
            onShowAlert,
            appParams,
            questionsList,
            onStoreDataList,
            onDownloadQuestionsList,
            onRefreshEvaluationList,
        } = this.props;
        return (
            <Fragment>
                {currentPage.nav === "questions" && (
                    <Fragment>
                        {currentPage.subpage === "questionsList" && (
                            <QuestionsList
                                fetchURL={fetchURL}
                                onChangeNavAndPage={onChangeNavAndPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                appParams={appParams}
                                questionsList={questionsList}
                                onStoreDataList={onStoreDataList}
                                onDownloadQuestionsList={onDownloadQuestionsList}
                                onRefreshEvaluationList={onRefreshEvaluationList}
                                selectedQuestionStat={selectedQuestionStat}
                                selectedSubtestType={selectedSubtestType}
                                selectedQuestionCat={selectedQuestionCat}
                                selectedQuestionType={selectedQuestionType}
                                NOR={NOR}
                                onSubtestTypeSelected={(event, value) => this.setState({ selectedSubtestType: value })}
                                onQuestionTypeSelected={(event, value) => this.setState({ selectedQuestionType: value })}
                                onQuestionStatSelected={(event, value) => this.setState({ selectedQuestionStat: value })}
                                onQuestionCatSelected={(event, value) => this.setState({ selectedQuestionCat: value })}
                                onChangeNORValue={(event, { value }) => this.setState({ NOR: value })}
                                onResetFilters={() => this.setState({ selectedSubtestType: "all", selectedQuestionType: "all", selectedQuestionStat: "all", selectedQuestionCat: "all" })}
                            />
                        )}
                        {(currentPage.subpage === "addQuestion" || currentPage.subpage === "editQuestion") && (
                            <AddEditQuestion
                                fetchURL={fetchURL}
                                onChangeValuesInForms={onChangeValuesInForms}
                                onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                                onChangeNavAndPage={onChangeNavAndPage}
                                onBackToPreviousPage={onBackToPreviousPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                            />
                        )}
                        {currentPage.subpage === "questionDetails" && <QuestionDetails onBackToPreviousPage={onBackToPreviousPage} fetchURL={fetchURL} onChangeNavAndPage={onChangeNavAndPage} currentPage={currentPage} onShowAlert={onShowAlert} />}
                        {currentPage.subpage === "boardPreview" && <QuestionPreview onBackToPreviousPage={onBackToPreviousPage} fetchURL={fetchURL} onChangeNavAndPage={onChangeNavAndPage} currentPage={currentPage} onShowAlert={onShowAlert} />}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Questions;
