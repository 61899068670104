import React, { Fragment } from "react";
import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import BtnNav from "./BtnNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

const MainNavbar = (props) => {
    const { changedValuesInFormes, currentPage, onChangeNavAndPage, onShowConfirmModal, loggedUserPrivilages, onShowLogoutModal, appParams } = props;

    const checkUserPriv = (priv) => {
        for (let i = 0; i < loggedUserPrivilages.length; i++) if (loggedUserPrivilages[i] === priv) return true;
        return false;
    };

    const checkAppParam = (param) => {
        for (let i = 0; i < appParams.length; i++) if (appParams[i].param_code === param && appParams[i].param_value) return true;
        return false;
    };

    return (
        <Fragment>
            <Navbar expand="xl">
                <Container fluid className="px-0">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            {checkUserPriv("QUESTIONS_VIEW") && (
                                <BtnNav
                                    navName="questions"
                                    pageName="questionsList"
                                    title="Pytania"
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {checkUserPriv("RATING_VIEW") && checkAppParam("QUESTIONS_EVALUATE") && (
                                <BtnNav
                                    navName="evaluations"
                                    pageName="evaluationsList"
                                    title="Ocenianie"
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {checkUserPriv("ACCEPTING_VIEW") && (
                                <BtnNav
                                    navName="approvals"
                                    pageName="approvalsList"
                                    title="Zatwierdzanie"
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {((checkUserPriv("ARRANGE_PILOTAGE_TEST_VIEW") && checkAppParam("PILOT_TEST_EDIT")) ||
                                (checkUserPriv("ARRANGE_FINAL_TEST") && (checkAppParam("NORM_TEST_EDIT") || checkAppParam("FINAL_TEST_EDIT")))) && (
                                <NavDropdown title="Układanie testów" id="arrangingTestsDropdown" className={currentPage === "arrangingTests" ? "active" : ""}>
                                    {checkUserPriv("ARRANGE_PILOTAGE_TEST_VIEW") && checkAppParam("PILOT_TEST_EDIT") && (
                                        <>
                                            <NavDropdown.Item
                                                id="arrangingPJMPilotageTestNav"
                                                onClick={() => {
                                                    changedValuesInFormes
                                                        ? onShowConfirmModal({ nav: "arrangingTests", subpage: "arrangingPJMPilotageTest", params: "" })
                                                        : onChangeNavAndPage({ nav: "arrangingTests", subpage: "arrangingPJMPilotageTest", params: "" }, null, null);
                                                }}
                                            >
                                                Ułóż test pilotażowy PJM
                                            </NavDropdown.Item>
                                            <NavDropdown.Item
                                                id="arrangingPLPilotageTestNav"
                                                onClick={() => {
                                                    changedValuesInFormes
                                                        ? onShowConfirmModal({ nav: "arrangingTests", subpage: "arrangingPLPilotageTest", params: "" })
                                                        : onChangeNavAndPage({ nav: "arrangingTests", subpage: "arrangingPLPilotageTest", params: "" }, null, null);
                                                }}
                                            >
                                                Ułóż test pilotażowy PL
                                            </NavDropdown.Item>
                                        </>
                                    )}
                                    {checkUserPriv("ARRANGE_FINAL_TEST") && (checkAppParam("NORM_TEST_EDIT") || checkAppParam("FINAL_TEST_EDIT")) && (
                                        <>
                                            <NavDropdown.Item
                                                id="arrangingPJMFinalTestNav"
                                                onClick={() => {
                                                    changedValuesInFormes
                                                        ? onShowConfirmModal({ nav: "arrangingTests", subpage: "arrangingPJMFinalTest", params: "" })
                                                        : onChangeNavAndPage({ nav: "arrangingTests", subpage: "arrangingPJMFinalTest", params: "" }, null, null);
                                                }}
                                            >
                                                Ułóż test normalizacyjny i ostateczny PJM
                                            </NavDropdown.Item>
                                            <NavDropdown.Item
                                                id="arrangingPLFinalTestNav"
                                                onClick={() => {
                                                    changedValuesInFormes
                                                        ? onShowConfirmModal({ nav: "arrangingTests", subpage: "arrangingPLFinalTest", params: "" })
                                                        : onChangeNavAndPage({ nav: "arrangingTests", subpage: "arrangingPLFinalTest", params: "" }, null, null);
                                                }}
                                            >
                                                Ułóż test normalizacyjny i ostateczny PL
                                            </NavDropdown.Item>
                                        </>
                                    )}
                                </NavDropdown>
                            )}
                            {checkUserPriv("PILOTAGE_TEST_VIEW") && checkAppParam("PILOT_TEST_FILL") && (
                                <BtnNav
                                    navName="allTests"
                                    pageName="pilotageTestsList"
                                    title="Pilotaż"
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}

                            {checkUserPriv("STANDARDIZATION_TEST_VIEW") && checkAppParam("NORM_TEST_FILL") && (
                                <BtnNav
                                    navName="allTests"
                                    pageName="standardizationList"
                                    title="Normalizacja"
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                            {checkUserPriv("FINAL_TEST_VIEW") && checkAppParam("FINAL_TEST_FILL") && (
                                <BtnNav
                                    navName="allTests"
                                    pageName="finalTestsList"
                                    title="Badania"
                                    currentPage={currentPage}
                                    changedValuesInFormes={changedValuesInFormes}
                                    onShowConfirmModal={onShowConfirmModal}
                                    onChangeNavAndPage={onChangeNavAndPage}
                                />
                            )}
                        </Nav>
                        <Nav>
                            {checkUserPriv("ADMIN_PANEL") && (
                                <NavDropdown title="Panel admina" id="adminPanelDropdown" className={currentPage === "adminPanel" ? "active" : ""}>
                                    <NavDropdown.Item
                                        id="settingsNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "adminPanel", subpage: "settings", params: "" })
                                                : onChangeNavAndPage({ nav: "adminPanel", subpage: "settings", params: "" }, null, null);
                                        }}
                                    >
                                        Ustawienia
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        id="usersListNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "adminPanel", subpage: "usersList", params: "" })
                                                : onChangeNavAndPage({ nav: "adminPanel", subpage: "usersList", params: "" }, null, null);
                                        }}
                                    >
                                        Lista użytkowników
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        id="allTestsListNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "allTests", subpage: "allTestsList", params: "" })
                                                : onChangeNavAndPage({ nav: "allTests", subpage: "allTestsList", params: "" }, null, null);
                                        }}
                                    >
                                        Lista wszystkich testów
                                    </NavDropdown.Item>
                                    {/*  <NavDropdown.Item
                                        id="statisticsNav"
                                        onClick={() => {
                                            changedValuesInFormes
                                                ? onShowConfirmModal({ nav: "adminPanel", subpage: "statisticsView", params: "" })
                                                : onChangeNavAndPage({ nav: "adminPanel", subpage: "statisticsView", params: "" }, null, null);
                                        }}
                                    >
                                        Statystyki
                                    </NavDropdown.Item> */}
                                </NavDropdown>
                            )}

                            <NavDropdown title={<FontAwesomeIcon icon={faUser} />} id="adminPanelDropdown">
                                <NavDropdown.Item
                                    id="myAccountNav"
                                    onClick={() => {
                                        changedValuesInFormes
                                            ? onShowConfirmModal({ nav: "myAccount", subpage: "myAccount", params: "" })
                                            : onChangeNavAndPage({ nav: "myAccount", subpage: "myAccount", params: "" }, null, null);
                                    }}
                                >
                                    Moje konto
                                </NavDropdown.Item>
                                <NavDropdown.Item id="logoutNav" onClick={onShowLogoutModal}>
                                    Wyloguj
                                </NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Fragment>
    );
};

export default MainNavbar;
