import React, { Fragment } from "react";
import { Alert, Button } from "react-bootstrap";

const AlertUsingCookies = (props) => {
    const { onAcceptCookies } = props;
    return (
        <Fragment>
            <Alert className="fixed-bottom small m-2 border" variant="secondary">
                Na tej stronie są używane ciasteczka. Używamy informacji zapisanych za pomocą plików cookies w celu zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu.
                <Button className="ml-2" size="sm" variant="dark" onClick={onAcceptCookies}>
                    OK
                </Button>
            </Alert>
        </Fragment>
    );
};

export default AlertUsingCookies;
