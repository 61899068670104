import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../navigation/NavDetails";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import { Checkbox, Dropdown } from "semantic-ui-react";

class SettingsList extends Component {
    state = {
        settingsData: this.props.settingsData ? this.props.settingsData : null,
        userRolesDropdown: null,
        loading: this.props.settingsData ? false : true,
        changedValues: false,
    };

    getSettingsData = async () => {
        const { fetchURL, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/settings-data`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.setState({ settingsData: fetchedData.settingsData });
                        this.props.onStoreDataList(fetchedData.settingsData);
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getSettingsData(), 4000);
        }
    };

    getUserRoles = async () => {
        const { fetchURL, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/user-roles-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const dropdownData = fetchedData.userRolesDropdown;
                        for (let i = 0; i < dropdownData.length; i++) {
                            if (dropdownData[i].value === "SUPERADMIN") dropdownData.splice(i, 1);
                            if (dropdownData[i].value === "ADMIN") dropdownData.splice(i, 1);
                        }
                        this.setState({ userRolesDropdown: dropdownData });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUserRoles(), 4000);
        }
    };

    saveChanges = async () => {
        const { fetchURL, onShowAlert, onChangeAppParams } = this.props;
        const { settingsData } = this.state;
        onShowAlert({ variant: "info", message: "Aktualizuję dane..." });
        const passData = {
            settingsData: settingsData,
        };
        try {
            const response = await fetch(`${fetchURL}/save-settings-changes`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.props.onCancelChangeValuesInForms();
                        onShowAlert({ variant: "success", message: fetchedData.message, fading: 2000 });
                        onChangeAppParams(settingsData);
                        this.setState({ changedValues: false, loading: true }, () => {
                            //konwertowanie wartości true/false na 'Y'/'N'
                            /* const convertSettingsData = settingsData;
                            convertSettingsData.forEach((e) => {
                                e.param_value = typeof e.param_value === "boolean" && e.param_value === true ? "Y" : typeof e.param_value === "boolean" && e.param_value === false ? "N" : e.param_value;
                            }); */
                            this.getAllData();
                        });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUserRoles(), 4000);
        }
    };

    getAllData = async () => {
        try {
            await this.getUserRoles();
            this.setState({ loading: false });
        } catch (err) {
            this.props.onShowAlert({ variant: "danger", message: err });
            this.setState({ loading: false });
        }
    };

    handleChangeParamValue = (event, data, index, option) => {
        const { settingsData } = this.state;
        const list = [...settingsData];
        if (option === "checkbox") list[index].param_value = data.checked;
        else list[index].param_value = data.value;
        this.setState({ settingsData: list, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    componentDidMount() {
        this.getAllData();
    }

    render() {
        const { settingsData, loading, userRolesDropdown, changedValues } = this.state;
        return (
            <Fragment>
                <Container fluid>
                    <Row className="py-3">
                        <NavDetails header="Ustawienia" hideBackButton />
                        <Col></Col>
                    </Row>
                </Container>
                <Container>
                    {!loading ? (
                        <Fragment>
                            <Row>
                                <Col xs={12}>
                                    <p className="h4 my-4">Aktywne zakładki i funkcje</p>
                                </Col>
                                <Col xs={12}>
                                    {settingsData.map((e, i) => (
                                        <Row key={i}>
                                            {typeof e.param_value === "boolean" ? (
                                                <Col xs={12} className="mb-3">
                                                    <Checkbox label={e.param_name} onChange={(event, data) => this.handleChangeParamValue(event, data, i, "checkbox")} checked={e.param_value} />
                                                </Col>
                                            ) : (
                                                <Col md={6} xs={12}>
                                                    <Form.Label>{e.param_name}</Form.Label>
                                                    <Dropdown
                                                        defaultValue={e.param_value}
                                                        id="userRolesListDropdown"
                                                        className="mb-3"
                                                        placeholder="Kliknij, aby wybrać rolę"
                                                        fluid
                                                        selection
                                                        options={userRolesDropdown}
                                                        onChange={(event, data) => this.handleChangeParamValue(event, data, i, "dropdown")}
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    {!changedValues ? (
                                        <Button variant="primary" disabled>
                                            Zapisz zmiany
                                        </Button>
                                    ) : (
                                        <Button variant="primary" onClick={() => this.saveChanges()}>
                                            Zapisz zmiany
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Fragment>
                    ) : (
                        <LoadingProgressBar />
                    )}
                </Container>
            </Fragment>
        );
    }
}

export default SettingsList;
