import React from "react";
import $ from "jquery";

const LoadingSpinner = () => {
    const width = $(window).width();
    const height = $(window).height();
    const styleWidth = (width - 40) / 2;
    const styleHeight = (height - 40) / 2;

    return (
        <div className="loading-spinner" style={{ width: width, height: height }}>
            <div className="sk-chase" style={{ top: styleHeight, left: styleWidth }}>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    );
};

export default LoadingSpinner;
