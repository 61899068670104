import React, { Fragment } from "react";
import { Alert } from "react-bootstrap";

const AlertNoPermissionList = () => {
    return (
        <Fragment>
            <Alert variant="danger" className="w-100 alert-small-info">
                Nie masz uprawnień do wyświetlenia listy
            </Alert>
        </Fragment>
    );
};

export default AlertNoPermissionList;
