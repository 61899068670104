import React, { Component, Fragment } from "react";
import { Col, Container, Row, Form, Image, Button, Alert } from "react-bootstrap";
import NameInput from "./../../forms/NameInput";
import MonthDropdown from "../../forms/MonthDropdown";
import $ from "jquery";
import ObjectGrades from "../../forms/ObjectGrades";
import ObjectHearingLoss from "../../forms/ObjectHearingLoss";
import ObjectProsthesis from "../../forms/ObjectProsthesis";

class TestForm extends Component {
    state = {
        objectName: "",
        objectBirthYear: "",
        objectBirthMonth: "default",
        objectClass: "default",
        objectHearingLoss: "default",
        objectProsthesis: "default",
        changedValues: false,
        alertInvalidYear: "",
        alertInvalidName: "",
    };

    validateName = (name) => {
        const regExp = /^([A-Z]|\u0141)([a-z]|\u0142|\u0144|\u00f3|\u0107|\u0105|\u017A|\u017C|\u0119|\u015B){2,}$/;
        if (regExp.test(name)) {
            return true;
        } else {
            return false;
        }
    };

    handleChangeCheckbox = (event, data) => {
        const value = data.checked;
        this.setState({ objectDeafness: value ? "deaf" : "hearing", changedValues: true });
        $(`#deafnessCheckbox`).removeClass("is-invalid");
    };

    handleCheckValidation = (e) => {
        const { objectName, objectBirthYear, objectBirthMonth, objectClass, objectHearingLoss, objectProsthesis, alertInvalidYear } = this.state;
        const { onShowAlert, testOption, testType, subtestType } = this.props;
        e.preventDefault();
        onShowAlert("");
        let errorMsg = "";
        if (!objectName) {
            errorMsg += "Imię osoby badanej jest puste. ";
            $(`#objectName`).addClass("is-invalid");
        } else {
            if (!this.validateName(objectName.replace(/\s+/g, ""))) {
                errorMsg += "Imię osoby badanej jest nieprawidłowe";
                $(`#objectName`).addClass("is-invalid");
            }
        }
        if (!objectBirthYear) {
            errorMsg += "Rok urodzenia osoby badanej jest pusty. ";
            $(`#objectBirthYear`).addClass("is-invalid");
        } else if (alertInvalidYear) {
            errorMsg += "Nieprawidłowa wartość roku urodzenia. ";
            $(`#objectBirthYear`).addClass("is-invalid");
        }
        if (!objectBirthMonth || objectBirthMonth === "default") {
            errorMsg += "Miesiąc urodzenia osoby badanej jest pusty. ";
            $(`#objectBirthMonth`).addClass("is-invalid");
        }
        if (!objectClass || objectClass === "default") {
            errorMsg += "Klasa osoby badanej jest pusta. ";
            $(`#objectClass`).addClass("is-invalid");
        }

        if ((!objectHearingLoss || objectHearingLoss === "default") && (testOption === "PJM" || testOption === "PJM-PL")) {
            errorMsg += "Stopień niedosłuchu osoby badanej jest pusty. ";
            $(`#objectHearingLoss`).addClass("is-invalid");
        }
        if ((!objectProsthesis || objectProsthesis === "default") && (testOption === "PJM" || testOption === "PJM-PL")) {
            errorMsg += "Protezowanie osoby badanej jest puste. ";
            $(`#objectProsthesis`).addClass("is-invalid");
        }

        if (errorMsg) {
            onShowAlert({
                variant: "danger",
                message: (
                    <>
                        <p>W formularzu wystąpiły następujące błędy:</p>
                        <p>{errorMsg}</p>
                    </>
                ),
            });
        } else {
            this.setState({ changedValues: false }, () => {
                if (this.props.pathname === "/test") {
                    const passData = {
                        objectData: {
                            objectName: objectName,
                            objectBirthYear: objectBirthYear,
                            objectBirthMonth: objectBirthMonth,
                            objectClass: objectClass,
                            objectHearingLoss: objectHearingLoss,
                            objectProsthesis: objectProsthesis,
                        },
                        testType: testType,
                        subtestType: subtestType,
                        testOption: testOption,
                        test1: this.props.test1,
                        test2: this.props.test2,
                    };
                    //console.log(passData);
                    this.createTest(passData);
                } else this.props.onChangeTestView("testInstruction");
            });
        }
    };

    createTest = async (passData) => {
        const { fetchURL, onShowAlert, testOption } = this.props;
        try {
            const response = await fetch(`${fetchURL}/create-test`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else
                        this.props.onChangeTestView(
                            testOption === "PJM-PL" ? "testChapter" : "testInstruction",
                            fetchedData.testObjectID,
                            fetchedData.testURL,
                            fetchedData.testID1,
                            fetchedData.testID2
                        );
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: `Nie udało się wysłać danych. (${err})` });
            setTimeout(this.createTest(passData), 4000);
        }
    };

    componentDidMount() {
        $("#mainLogo").css({ height: "13rem", marginTop: `${Math.round((15 * $(window).outerHeight()) / 100)}px` });
        $(window).on("resize", function () {
            $("#mainLogo").css({ height: "13rem", marginTop: `${Math.round((15 * $(window).outerHeight()) / 100)}px` });
        });
    }

    render() {
        const { objectName, objectBirthYear, objectBirthMonth, objectClass, objectHearingLoss, objectProsthesis, changedValues, alertInvalidYear, alertInvalidName } = this.state;
        const { subtestType, fetchURL, onShowAlert, testOption } = this.props;

        return (
            <Fragment>
                <Container>
                    <Form onSubmit={this.handleCheckValidation}>
                        <Row>
                            <Col xs={12} className="text-center mb-3">
                                <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="mb-3" />
                                <h1 className="h3 my-4">Przeprowadź test {testOption === "PJM-PL" ? "PJM + PL" : testOption}</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} xs={12}>
                                <NameInput
                                    value={objectName}
                                    onChangeValue={(e) => {
                                        this.setState({ [e.target.name]: e.target.value, changedValues: true });
                                        if (!this.validateName(e.target.value)) this.setState({ alertInvalidName: "Imię powinno zaczynać się od wielkiej litery i składać się z min. 3 znaków." });
                                        else this.setState({ alertInvalidName: "" });
                                    }}
                                    req
                                    labelTxt="Imię osoby badanej"
                                    placeholderTxt="Wpisz imię osoby"
                                    name="objectName"
                                    id="objectName"
                                    classStyle={testOption === "PL" ? "secondary" : ""}
                                />
                                {alertInvalidName && (
                                    <Alert variant="warning" className="alert-small-info mt-2">
                                        {alertInvalidName}
                                    </Alert>
                                )}
                            </Col>

                            <Col lg={3} xs={6}>
                                <Form.Group>
                                    <Form.Label>
                                        Rok urodzenia osoby badanej <span className="required">*</span>
                                    </Form.Label>

                                    <Form.Control
                                        type="text"
                                        placeholder="Wpisz rok urodzenia"
                                        name="objectBirthYear"
                                        id="objectBirthYear"
                                        value={objectBirthYear}
                                        onChange={(e) => {
                                            $(`#objectBirthYear`).removeClass("is-invalid");
                                            this.setState({ [e.target.name]: e.target.value, changedValues: true }, () => {
                                                const { objectBirthYear } = this.state;
                                                const regExpNumberLength = /^\d{4}$/;
                                                const regExpYear = /^(19|20){1}[0-9]{1}[0-9]{1}$/;
                                                if (!regExpNumberLength.test(objectBirthYear)) this.setState({ alertInvalidYear: "Nieprawidłowa wpisana wartość. Rok musi się składać z 4 cyfr." });
                                                else if (!regExpYear.test(objectBirthYear)) this.setState({ alertInvalidYear: "Nieprawidłowy rok. Dozwolony zakres: 1900-2099." });
                                                else this.setState({ alertInvalidYear: "" });
                                            });
                                        }}
                                        className={testOption === "PL" ? "req secondary" : "req"}
                                    />
                                    {alertInvalidYear && (
                                        <Alert variant="warning" className="alert-small-info mt-2">
                                            {alertInvalidYear}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={3} xs={6}>
                                <MonthDropdown
                                    value={objectBirthMonth}
                                    onSelectMonth={(value) => this.setState({ objectBirthMonth: value, changedValues: true })}
                                    classStyle={testOption === "PL" ? "secondary" : ""}
                                />
                            </Col>
                            <Col lg={4} xs={12}>
                                <ObjectGrades
                                    onChangeValues={(value) => this.setState({ objectClass: value, changedValues: true })}
                                    testType={this.props.testType}
                                    objectClass={objectClass}
                                    fetchURL={fetchURL}
                                    onShowAlert={onShowAlert}
                                    classStyle={testOption === "PL" ? "secondary" : ""}
                                />
                            </Col>

                            {(testOption === "PJM-PL" || testOption === "PJM") && (
                                <Fragment>
                                    <Col lg={4} xs={12}>
                                        <ObjectHearingLoss
                                            onSelectedHearingLoss={(event, value) => this.setState({ objectHearingLoss: value, changedValues: true })}
                                            objectHearingLoss={objectHearingLoss}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            req
                                            classStyle={testOption === "PL" ? "secondary" : ""}
                                        />
                                    </Col>
                                    <Col lg={4} xs={12}>
                                        <ObjectProsthesis
                                            onSelectedProsthesis={(event, value) => this.setState({ objectProsthesis: value, changedValues: true })}
                                            objectProsthesis={objectProsthesis}
                                            fetchURL={fetchURL}
                                            onShowAlert={onShowAlert}
                                            req
                                            classStyle={testOption === "PL" ? "secondary" : ""}
                                        />
                                    </Col>
                                </Fragment>
                            )}
                        </Row>
                        <Row>
                            <Col lg={{ span: 4, offset: 4 }} xs={12} className="text-center my-3">
                                <Button variant={subtestType === "PL" ? "secondary" : "primary"} disabled={changedValues ? false : true} type="submit">
                                    Rozpocznij test
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </Fragment>
        );
    }
}

export default TestForm;
