import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import QuestionsListTable from "./QuestionsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import QuestionStatus from "./../../forms/QuestionStatus";
import QuestionCategories from "./../../forms/QuestionCategories";
import SubtestTypes from "../../forms/SubtestTypes";
import QuestionTypes from "../../forms/QuestionTypes";
import RowsDropdown from "../../forms/RowsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";

class QuestionsList extends Component {
    state = {
        showNOR: this.props.NOR,
        displayNOR: this.props.NOR,
        questionsList: null,
        selectedQuestionStat: this.props.selectedQuestionStat,
        selectedSubtestType: this.props.selectedSubtestType,
        selectedQuestionCat: this.props.selectedQuestionCat,
        selectedQuestionType: this.props.selectedQuestionType,
    };

    handleDownloadData = () => {
        this.setState({ questionsList: null }, () => this.getQuestionsList());
    };

    getQuestionsList = async () => {
        const { fetchURL, onShowAlert, onStoreDataList } = this.props;
        try {
            const response = await fetch(`${fetchURL}/questions-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const questionsList = fetchedData.questionsList;
                        onStoreDataList(questionsList);
                        this.setState({ questionsList: questionsList });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.getQuestionsList(), 4000);
        }
    };

    componentDidMount() {
        if (!this.props.questionsList) {
            this.getQuestionsList();
        } else this.setState({ questionsList: this.props.questionsList });
    }

    componentDidUpdate() {
        if (this.props.NOR !== this.state.showNOR) this.setState({ showNOR: this.props.NOR, displayNOR: this.props.NOR });
        if (this.props.selectedQuestionStat !== this.state.selectedQuestionStat) this.setState({ selectedQuestionStat: this.props.selectedQuestionStat });
        if (this.props.selectedSubtestType !== this.state.selectedSubtestType) this.setState({ selectedSubtestType: this.props.selectedSubtestType });
        if (this.props.selectedQuestionCat !== this.state.selectedQuestionCat) this.setState({ selectedQuestionCat: this.props.selectedQuestionCat });
        if (this.props.selectedQuestionType !== this.state.selectedQuestionType) this.setState({ selectedQuestionType: this.props.selectedQuestionType });
    }

    render() {
        const { questionsList, displayNOR, showNOR, selectedQuestionStat, selectedSubtestType, selectedQuestionCat, selectedQuestionType } = this.state;
        const {
            fetchURL,
            onChangeNavAndPage,
            currentPage,
            appParams,
            onRefreshEvaluationList,
            onSubtestTypeSelected,
            onQuestionTypeSelected,
            onQuestionStatSelected,
            onQuestionCatSelected,
            onChangeNORValue,
            onResetFilters,
            onStoreSubtestTypes,
        } = this.props;

        let filteredQuestionList = null;
        if (questionsList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredQuestionList = questionsList.filter(
                (e) =>
                    (selectedQuestionStat === "all" || e.question_status_code === selectedQuestionStat) &&
                    (selectedQuestionType === "all" || e.question_type_code === selectedQuestionType) &&
                    (selectedSubtestType === "all" || e.subtest_type_code === selectedSubtestType) &&
                    (selectedQuestionCat === "all" || e.question_category_code === selectedQuestionCat)
            );
        } else filteredQuestionList = null;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                <Container fluid className="second-bar">
                    <Fragment>
                        <Row>
                            <Col>
                                {appParams.map(
                                    (e, i) =>
                                        e.param_code === "QUESTIONS_CREATE" &&
                                        e.param_value === true && (
                                            <Fragment key={i}>
                                                <Button
                                                    className="my-4"
                                                    variant="primary"
                                                    type="button"
                                                    onClick={() => onChangeNavAndPage({ nav: "questions", subpage: "addQuestion", params: "" }, currentPageParams, null)}
                                                >
                                                    Dodaj nowe pytanie
                                                </Button>
                                            </Fragment>
                                        )
                                )}
                            </Col>
                            <Col className="text-center">
                                <p className="h3 my-4" style={{ lineHeight: "38px" }}>
                                    Lista pytań
                                </p>
                            </Col>
                            <Col className="text-right"></Col>
                        </Row>

                        <Row>
                            <Col lg={2} md={3} xs={5}>
                                <SubtestTypes defaultValue={selectedSubtestType} onSubtestTypeSelected={onSubtestTypeSelected} fetchURL={fetchURL} onStoreSubtestTypes={onStoreSubtestTypes} />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <QuestionTypes defaultValue={selectedQuestionType} onQuestionTypeSelected={onQuestionTypeSelected} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <QuestionStatus defaultValue={selectedQuestionStat} onQuestionStatSelected={onQuestionStatSelected} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <QuestionCategories defaultValue={selectedQuestionCat} onQuestionCatSelected={onQuestionCatSelected} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={1} md={2} xs={3}>
                                <RowsDropdown onChangeValue={onChangeNORValue} defaultValue={displayNOR} />
                            </Col>

                            <Col lg={{ span: 2, offset: 1 }} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                <OverlayTrigger key="resetFiltersTop" placement="top" overlay={<Tooltip id="resetFilters">Resetuj filtry</Tooltip>}>
                                    <Button variant="outline-primary" className="mr-1" onClick={() => onResetFilters()}>
                                        <FontAwesomeIcon icon={faRedoAlt} />
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger key="refreshDataTop" placement="top" overlay={<Tooltip id="refreshData">Odśwież dane</Tooltip>}>
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
                <Container fluid className="content-body">
                    {!questionsList ? (
                        <LoadingProgressBar />
                    ) : (
                        <Fragment>
                            {filteredQuestionList.length > 0 ? (
                                <Fragment>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                <QuestionsListTable
                                                    appParams={appParams}
                                                    questionsList={filteredQuestionList}
                                                    displayNOR={displayNOR}
                                                    onChangeNavAndPage={onChangeNavAndPage}
                                                    fetchURL={fetchURL}
                                                    onRefreshList={this.handleDownloadData}
                                                    onRefreshEvaluationList={onRefreshEvaluationList}
                                                />
                                                <div className="light-border"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            Załadowano {displayNOR > filteredQuestionList.length ? filteredQuestionList.length : displayNOR} z {filteredQuestionList.length} pytań.
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredQuestionList.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">to wszystko</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    załaduj więcej
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Fragment>
                            ) : (
                                <Row>
                                    <Col className="text-center mt-3 font-weight-bold">Lista jest pusta.</Col>
                                </Row>
                            )}
                        </Fragment>
                    )}
                </Container>
            </Fragment>
        );
    }
}

export default QuestionsList;
