import React, { Component, Fragment } from "react";
import SettingsList from "../../pages/SettingsList";

class AdminPanel extends Component {
    state = {};
    render() {
        const { fetchURL, onChangeValuesInForms, onCancelChangeValuesInForms, currentPage, onShowAlert, onChangeAppParams, settingsData, onStoreDataList } = this.props;
        return (
            <Fragment>
                {currentPage.nav === "adminPanel" && (
                    <Fragment>
                        {currentPage.subpage === "settings" && (
                            <SettingsList
                                fetchURL={fetchURL}
                                onChangeValuesInForms={onChangeValuesInForms}
                                onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                                onShowAlert={onShowAlert}
                                onChangeAppParams={onChangeAppParams}
                                settingsData={settingsData}
                                onStoreDataList={onStoreDataList}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AdminPanel;
