import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const PasswordInput = (props) => {
    const { value1, value2, onChangeValue, name, classN, repeatPassInputVisible } = props;
    return (
        <Form.Group>
            <Form.Label>
                Hasło <span className="required"> *</span>
            </Form.Label>
            <Row>
                <Col>
                    <Form.Control
                        type="password"
                        id="password"
                        placeholder="Wpisz hasło"
                        name={name}
                        value={value1}
                        onChange={(e) => onChangeValue(e)}
                        autoComplete={value2 ? "new-password" : "nope"}
                        className={classN ? `req pass${classN}` : "req pass d-inline-block"}
                    />
                </Col>
                {repeatPassInputVisible && (
                    <Col>
                        <Form.Control
                            type="password"
                            id="repeatPassword"
                            placeholder="Jeszcze raz wpisz hasło"
                            name={`repeat_${name}`}
                            value={value2}
                            onChange={(e) => onChangeValue(e)}
                            autoComplete={"new-password"}
                            className={classN ? `req pass ${classN}` : "req pass d-inline-block"}
                        />
                    </Col>
                )}
            </Row>
        </Form.Group>
    );
};

export default PasswordInput;
