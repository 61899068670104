import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Container, Row, Col, Button, Table, Form } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import QItemFile from "../../forms/QItemFile";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import ApprovalStatuses from "../../forms/ApprovalStatuses";
import EvaluationDifficulty from "../../forms/EvaluationDifficulty";

class DoApproval extends Component {
    state = {
        showDeleteQuestionModal: false,
        questionData: null,
        questionAndAnswers: [],
        loadingQuestion: true,
        loadingQItems: true,
        approvalQuestionDetails: null,
        judgeComments: null,
        selectedApprovalQuestionStatuses: null,
        selectedEvalDifficulty: null,
        changedValues: false,
    };

    getQItemData = async (qItemID, itemIndex, item) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            qItemID: qItemID,
        };
        try {
            const response = await fetch(`${fetchURL}/question-item-details`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const qItemData = fetchedData.qItemData;

                        /* if (qItemData[0].qitem_type_code !== "WORD") {
                            const blob = qItemData[0].qitem_blob;
                            const buffer = Buffer.from(blob);
                            const bufferBase64 = buffer.toString("base64");
                            qItemData[0].bufferBase64 = bufferBase64;
                        } */

                        const { questionAndAnswers } = this.state;
                        const list = [...questionAndAnswers];
                        list[itemIndex] = qItemData[0];
                        this.setState({ questionAndAnswers: list });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loadingQItems: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loadingQItems: false });
            setTimeout(this.getQItemData(qItemID, itemIndex, item), 4000);
        }
    };

    getAcceptList = async (questionID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            questionID: questionID,
        };
        try {
            const response = await fetch(`${fetchURL}/approval-question-details`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const approvalQuestionDetails = fetchedData.approvalQuestionDetails;
                        this.setState({ approvalQuestionDetails: approvalQuestionDetails });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych. " + err });
            this.setState({ loadingQuestion: false });
            setTimeout(this.getAcceptList(questionID), 4000);
        }
    };

    getJudgeComments = async (questionID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            questionID: questionID,
        };
        try {
            const response = await fetch(`${fetchURL}/question-judge-comment`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ noPermission: true, loadingQuestion: false, loadingQItems: false });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    } else {
                        this.setState({ judgeComments: fetchedData.commentsList });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych. " + err });
            this.setState({ loadingQuestion: false });
            setTimeout(this.getJudgeComments(questionID), 4000);
        }
    };

    getQuestionData = async (questionID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            questionID: questionID,
        };
        try {
            const response = await fetch(`${fetchURL}/question-details`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const questionDetails = fetchedData.questionDetails;
                        this.setState(
                            {
                                questionData: questionDetails,
                                loadingQuestion: false,
                                selectedApprovalQuestionStatuses: questionDetails[0].question_status_code,
                                selectedEvalDifficulty: questionDetails[0].eval_diff_code,
                            },
                            () => {
                                this.getAllQItemData(questionDetails);
                                this.getAcceptList(questionID);
                                this.getJudgeComments(questionID);
                            }
                        );
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych. " + err });
            this.setState({ loadingQuestion: false });
            setTimeout(this.getQuestionData(questionID), 4000);
        }
    };

    handleCheckValidation = () => {
        const { selectedApprovalQuestionStatuses, selectedEvalDifficulty } = this.state;
        const { onShowAlert } = this.props;
        onShowAlert("");
        let errorMsg = "";
        if (!selectedApprovalQuestionStatuses || selectedApprovalQuestionStatuses === "EVALUATE") {
            errorMsg += "Brak wybranego statusu. ";
            $("#questionApprovalStatusesDropdown").addClass("is-invalid");
        }
        if (!selectedEvalDifficulty) {
            errorMsg += "Brak wybranego ostatecznego poziomu trudności pytania. ";
            $("#questionEvalDifficultyDropdown").addClass("is-invalid");
        }

        if (errorMsg) onShowAlert({ variant: "danger", message: errorMsg });
        else this.doApproval();
    };

    doApproval = async () => {
        const { fetchURL, onShowAlert, currentPage, onChangeNavAndPage, onCancelChangeValuesInForms } = this.props;
        onShowAlert({ variant: "info", message: "Zatwierdzam pytanie..." });
        const passData = {
            questionID: currentPage.params.questionID,
            evalDifficulty: this.state.selectedEvalDifficulty,
            questionStatus: this.state.selectedApprovalQuestionStatuses,
        };
        try {
            const response = await fetch(`${fetchURL}/do-approval`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onChangeNavAndPage({ nav: "approvals", subpage: "approvalsList", params: "" }, null, "approvalsList");
                        this.props.onDownloadQuestionsList();
                        onCancelChangeValuesInForms();
                        onShowAlert({ variant: "success", message: fetchedData.message, fading: 2000 });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych. " + err });
            this.setState({ loadingQuestion: false });
            setTimeout(this.doApproval(), 4000);
        }
    };

    getAllQItemData = async (questionDetails) => {
        try {
            await this.getQItemData(questionDetails[0].question_qitem_id, 0, "question");
            await this.getQItemData(questionDetails[0].answer_a_qitem_id, 1, "questionAndAnswers");
            await this.getQItemData(questionDetails[0].answer_b_qitem_id, 2, "questionAndAnswers");
            await this.getQItemData(questionDetails[0].answer_c_qitem_id, 3, "questionAndAnswers");
            await this.getQItemData(questionDetails[0].answer_d_qitem_id, 4, "questionAndAnswers");
            this.setState({ loadingQItems: false });
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        this.getQuestionData(this.props.currentPage.params.questionID);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { questionData, loadingQuestion, loadingQItems, questionAndAnswers, approvalQuestionDetails, judgeComments, changedValues, selectedApprovalQuestionStatuses, selectedEvalDifficulty } =
            this.state;
        const { onBackToPreviousPage, fetchURL, onCancelChangeValuesInForms } = this.props;
        return (
            <Fragment>
                <Container fluid>
                    <Row className="py-3">
                        <NavDetails
                            header="Zatwierdzanie pytania"
                            changedValues={changedValues}
                            onChangeView={() => this.setState({ loading: false }, () => onBackToPreviousPage())}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                        />
                        <Col></Col>
                    </Row>

                    <Row>
                        <Col xs={2}>
                            <div className="sticky-top" style={{ top: "1rem" }}>
                                <div className="flex-column">
                                    <div className="p-3 approval-sidebar">
                                        <p className="mb-3 h5">Zatwierdzanie pytania</p>
                                        {loadingQuestion ? (
                                            <LoadingProgressBar />
                                        ) : (
                                            <Fragment>
                                                <ApprovalStatuses
                                                    defaultValue={selectedApprovalQuestionStatuses}
                                                    onApprovalStatusSelected={(event, value) => {
                                                        this.setState({ selectedApprovalQuestionStatuses: value, changedValues: true });
                                                        this.props.onChangeValuesInForms();
                                                    }}
                                                    fetchURL={fetchURL}
                                                />
                                                <EvaluationDifficulty
                                                    defaultValue={selectedEvalDifficulty}
                                                    onEvaluationDifficultySelected={(event, value) => {
                                                        this.setState({ selectedEvalDifficulty: value, changedValues: true });
                                                        this.props.onChangeValuesInForms();
                                                    }}
                                                    fetchURL={fetchURL}
                                                    labelTxt="Ostateczny stopień trudności"
                                                />
                                                {!changedValues ? (
                                                    <Button variant="primary" disabled>
                                                        Zapisz
                                                    </Button>
                                                ) : (
                                                    <Button variant="primary" onClick={this.handleCheckValidation}>
                                                        Zapisz
                                                    </Button>
                                                )}
                                            </Fragment>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={8}>
                            {!loadingQuestion ? (
                                <Fragment>
                                    <Row>
                                        <Col xs={12}>
                                            <p className="h4 mb-3">Dane</p>
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <Table size="sm">
                                                <tbody>
                                                    <tr>
                                                        <td>Nazwa pytania</td>
                                                        <td>{questionData[0].question_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Część testu</td>
                                                        <td>{questionData[0].subtest_type_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Typ pytania</td>
                                                        <td>{questionData[0].question_type_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Kategoria pytania</td>
                                                        <td>{questionData[0].question_category_name}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                        <Col lg={6} xs={12}>
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <td>Komentarz edytora treści</td>
                                                        <td>{questionData[0].question_comment ? questionData[0].question_comment : <span className="opacity-4">brak</span>}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Fragment>
                            ) : (
                                <LoadingProgressBar />
                            )}
                            <Row>
                                <Col xs={12}>
                                    <p className="h4 my-3">Plansza</p>
                                </Col>
                                {loadingQItems ? (
                                    <Col xs={12} style={{ minHeight: "118px" }}>
                                        <LoadingProgressBar />
                                    </Col>
                                ) : (
                                    <Fragment>
                                        <Col xs={12}>
                                            <p className="mb-3">
                                                Poprawna odpowiedź: <b className="text-success">Odpowiedź {questionData[0].question_answer}</b>
                                            </p>
                                        </Col>

                                        {questionAndAnswers.map((e, i) => (
                                            <Fragment key={i}>
                                                <Col lg={i === 0 ? 4 : 2} md={i === 0 ? 12 : 6} xs={i === 0 ? 12 : 6}>
                                                    <Form.Label>{i === 0 ? "Pytanie" : i === 1 ? "Odpowiedź A" : i === 2 ? "Odpowiedź B" : i === 3 ? "Odpowiedź C" : "Odpowiedź D"}:</Form.Label>
                                                    {questionAndAnswers[i].qitem_type_code === "WORD" ? (
                                                        <p className={"test-word h4 mb-3 approval-word"}>{questionAndAnswers[i].qitem_string}</p>
                                                    ) : (
                                                        <QItemFile
                                                            fileType={questionAndAnswers[i].qitem_type_code}
                                                            fetchURL={fetchURL}
                                                            selectedExistingFile={[questionAndAnswers[i]]}
                                                            id="Question"
                                                            qItemDetails
                                                            onNoExistingFile={() =>
                                                                this.props.onShowAlert({
                                                                    variant: "warning",
                                                                    message: "Jeden lub więcej plików w tym pytaniu są niepoprawnie załadowane lub nie istnieją.",
                                                                    fading: 2500,
                                                                })
                                                            }
                                                        />
                                                    )}
                                                </Col>
                                            </Fragment>
                                        ))}
                                    </Fragment>
                                )}
                            </Row>
                            <Row>
                                <Col xs={12} className="mt-1">
                                    <p className="h4 my-3">Podsumowanie ocen</p>
                                </Col>
                                {!approvalQuestionDetails ? (
                                    <LoadingProgressBar />
                                ) : (
                                    approvalQuestionDetails.map((e, i) => (
                                        <Fragment key={i}>
                                            <Col xs={6} lg={4}>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>Liczba ocen 1:</td>
                                                            <td>{e.qd_1}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Liczba ocen 2:</td>
                                                            <td>{e.qd_2}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Liczba ocen 3:</td>
                                                            <td>{e.qd_3}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Średnia ocen</td>
                                                            <td>{e.qd_average.toFixed(2)}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col xs={6} lg={4}>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr>
                                                            <td>Tak:</td>
                                                            <td>{e.qr_yes}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Nie:</td>
                                                            <td>{e.qr_no}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Warunkowo:</td>
                                                            <td>{e.qr_cond}</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Fragment>
                                    ))
                                )}
                            </Row>
                            <Row>
                                <Col xs={12} className="mt-1">
                                    <p className="h4 my-3">Oceny sędziów</p>
                                </Col>
                                {!judgeComments ? (
                                    <LoadingProgressBar />
                                ) : judgeComments.length === 0 ? (
                                    <Col xs={12}>
                                        <p className="mb-3 opacity-4">Brak ocen sędziów</p>
                                    </Col>
                                ) : (
                                    judgeComments.map((e, i) => (
                                        <Fragment key={i}>
                                            <Col xs={12} className="mx-3 my-1 p-3 judge-comment">
                                                <Row>
                                                    <Col xs={12}>
                                                        <p className="mb-2">
                                                            <FontAwesomeIcon icon={faUserCircle} />
                                                            <span className="ml-2">{e.user_fullname}</span>
                                                        </p>
                                                    </Col>
                                                    <Col xs={6} lg={4}>
                                                        <p className="mb-2">
                                                            Kwalifikacja:
                                                            <b>
                                                                <span className={e.eval_result_code === "YES" ? "text-success" : e.eval_result_code === "NO" ? "text-danger" : "text-warning"}>
                                                                    {" "}
                                                                    {e.eval_result_name}
                                                                </span>
                                                            </b>
                                                        </p>
                                                    </Col>
                                                    <Col xs={6} lg={4}>
                                                        <p className="mb-2">
                                                            Ocena poziomu trudności: <b>{e.eval_diff_name}</b>
                                                        </p>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <p className="mb-2">Komentarz: {e.eval_comment ? <i>{e.eval_comment}</i> : <span className="opacity-4">brak</span>}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Fragment>
                                    ))
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default DoApproval;
