import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../navigation/NavDetails";
import { Container, Row, Col } from "react-bootstrap";

class StatisticsView extends Component {
    render() {
        return (
            <Fragment>
                <Container fluid>
                    <Row className="py-3">
                        <NavDetails header="Statystyki" hideBackButton />
                        <Col></Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col className="text-center">
                            <p className="h5 mt-3">Zakładka w przygotowaniu</p>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default StatisticsView;
