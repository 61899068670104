import React, { Fragment, Component } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Login from "../pages/Login";
import Register from "../pages/Register";
import RememberPass from "../pages/RememberPass";
import Presentation from "../pages/Presentation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import { faAdjust } from "@fortawesome/free-solid-svg-icons";

const setHeight = () => {
    if ($(window).width() >= 768) {
        const height = $(window).outerHeight() - Math.round($("#euLogo").outerHeight()) - 2;
        $("#notLoggedContainer").css("height", height);
        $("#notLoggedContainer").css("min-height", "41rem");
    } else $("#notLoggedContainer").css("height", "auto");
};

class NotLoggedPages extends Component {
    state = {
        showSubPage: null,
        contrast: false,
        fontSize: 1,
    };

    handleContactToAdmin = () => {
        this.setState({ showSubPage: "contactToAdmin" });
        this.props.onHideAlertInactiveUser();
    };

    handleAdjustContrast = () => {
        if ($("#notLogged").hasClass("contrast")) {
            $("#notLogged").removeClass("contrast");
            localStorage.removeItem("contrast");
            this.setState({ contrast: false });
        } else {
            $("#notLogged").addClass("contrast");
            localStorage.setItem("contrast", true);
            this.setState({ contrast: true });
        }
    };

    handleChangeFontSize = (enlarge) => {
        const fontSize = this.state.fontSize;
        let newSize = 1;
        if (enlarge) newSize = fontSize >= 2 ? fontSize : fontSize + 0.5;
        else newSize = fontSize <= 1 ? fontSize : fontSize - 0.5;

        localStorage.setItem("fontSize", newSize);
        this.setState({ fontSize: newSize });
    };

    componentDidMount() {
        setHeight();
        $("#euLogo").on("load", function () {
            setHeight();
        });

        $(window).on("resize", function () {
            $("#notLogged").css({ minHeight: `${$(window).outerHeight()}px` });
            setHeight();
        });

        $("#notLogged").css({ minHeight: `${$(window).outerHeight()}px` });

        if (localStorage.getItem("contrast")) {
            $("#notLogged").addClass("contrast");
            this.setState({ contrast: true });
        } else this.setState({ contrast: false });

        if (localStorage.getItem("fontSize")) this.setState({ fontSize: localStorage.getItem("fontSize") });
    }

    render() {
        const { showSubPage, fontSize } = this.state;
        const { fetchURL, appParams, onLoginSucces, onShowAlert } = this.props;

        return (
            <div id="notLogged">
                {!showSubPage ? (
                    <Container fluid id="notLoggedContainer">
                        <Row className="pt-5">
                            <Col lg={{ span: 6, offset: 3 }} className="text-center">
                                <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="my-3" style={{ height: "16rem" }} alt="logo testu leksykalnego PJM PL" />
                                <p className="my-3" style={{ fontSize: `${1.125 * fontSize}rem` }}>
                                    Test Leksyka PJM-PL to narzędzie diagnostyczne przygotowywane w ramach projektu grantowego „Inkubator Innowacji Społecznych Generator Dostępności”, który jest
                                    częścią Programu Operacyjnego Wiedza Edukacja Rozwój 2014–2020 współfinansowanego ze środków Europejskiego Funduszu Społecznego.
                                </p>
                                <Button variant="outline-primary" onClick={() => this.setState({ showSubPage: "presentation" })}>
                                    Dowiedz się więcej o Teście Leksykalnym
                                </Button>
                                <hr className="my-3" />
                                <Button variant="primary" size="lg" className="w-xl-50 w-100 d-block mx-auto mb-md-3 mb-4" onClick={() => this.setState({ showSubPage: "login" })}>
                                    Zaloguj się
                                </Button>
                                <Button variant="secondary" size="lg" className=" w-xl-50 w-100 d-block mx-auto mb-md-3 mb-5" onClick={() => this.setState({ showSubPage: "register" })}>
                                    Zarejestruj się
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <Container id="notLoggedContainer">
                        {showSubPage && (
                            <Fragment>
                                <Row className="pt-5">
                                    {showSubPage === "login" && (
                                        <Login
                                            fetchURL={fetchURL}
                                            onChangeSubpage={(page) => this.setState({ showSubPage: page, alert: "" })}
                                            onLoginSucces={onLoginSucces}
                                            onGoToMainPage={() => this.setState({ showSubPage: null })}
                                            onShowAlert={onShowAlert}
                                            fontSize={fontSize}
                                        />
                                    )}
                                    {showSubPage === "register" && (
                                        <Register
                                            fetchURL={fetchURL}
                                            appParams={appParams}
                                            onChangeSubpage={(page) => this.setState({ showSubPage: page, alert: "" })}
                                            onGoToMainPage={() => this.setState({ showSubPage: null })}
                                            onShowAlert={onShowAlert}
                                            fontSize={fontSize}
                                        />
                                    )}
                                    {showSubPage === "rememberPass" && (
                                        <RememberPass
                                            fetchURL={fetchURL}
                                            onChangeSubpage={(page) => this.setState({ showSubPage: page, alert: "" })}
                                            onGoToMainPage={() => this.setState({ showSubPage: null })}
                                            onShowAlert={onShowAlert}
                                            fontSize={fontSize}
                                        />
                                    )}
                                    {showSubPage === "presentation" && (
                                        <Presentation
                                            onChangeSubpage={(page) => this.setState({ showSubPage: page, alert: "" })}
                                            onGoToMainPage={() => this.setState({ showSubPage: null })}
                                            onShowAlert={onShowAlert}
                                            fontSize={fontSize}
                                        />
                                    )}
                                </Row>
                            </Fragment>
                        )}
                    </Container>
                )}
                <Container fluid>
                    <Col xl={{ span: 6, offset: 3 }} lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }} xs={{ span: 8, offset: 2 }} className="text-center">
                        <Image
                            fluid
                            src={`images/logotypy_poziom${this.state.contrast ? "_white" : ""}.png`}
                            className="w-100 d-none d-md-inline pt-3"
                            style={{ maxWidth: "945px" }}
                            alt="logotypy funduszy europejskich i generatora dostępności"
                        />
                        <Image
                            fluid
                            src={`images/logotypy_pion${this.state.contrast ? "_white" : ""}.png`}
                            className="w-75 d-xl-none d-lg-none d-md-none"
                            alt="logotypy funduszy europejskich i generatora dostępności"
                        />
                    </Col>
                </Container>
                <Container fluid>
                    <Row>
                        <Col className="text-center mb-3">
                            <Button variant="outline-primary" size="sm" className="mx-3" onClick={this.handleAdjustContrast}>
                                <FontAwesomeIcon icon={faAdjust} />
                            </Button>
                            <Button variant="outline-primary" size="sm" className="mx-1" onClick={() => this.handleChangeFontSize(true)} disabled={fontSize >= 2 ? true : false} id="fontBig">
                                <svg width="25px" height="20px">
                                    <g>
                                        <g>
                                            <path
                                                d="M12.9,20v-1.5l2.3-0.2l-1.5-4.4H5.9l-1.5,4.4l2.2,0.2V20H0.4v-1.5l1.2-0.3C2,18.1,2.1,18,2.3,17.7L8.3,0h3.2
			l6.2,17.7c0.1,0.3,0.3,0.4,0.7,0.5l1.2,0.3V20C19.6,20,12.9,20,12.9,20z M9.9,2.1l-3.3,9.8H13L9.9,2.1z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                d="M20.1,9.5h-0.5c-0.3,0-0.6-0.3-0.6-0.6V0.6C19,0.3,19.3,0,19.6,0h0.5c0.3,0,0.6,0.3,0.6,0.6v8.3
			C20.7,9.2,20.5,9.5,20.1,9.5z"
                                            />
                                            <path
                                                d="M15.1,5V4.5c0-0.3,0.3-0.6,0.6-0.6H24c0.3,0,0.6,0.3,0.6,0.6V5c0,0.3-0.3,0.6-0.6,0.6h-8.2
			C15.4,5.6,15.1,5.3,15.1,5z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </Button>
                            <Button variant="outline-primary" size="sm" className="mx-1" onClick={() => this.handleChangeFontSize(false)} disabled={fontSize <= 1 ? true : false} id="fontSmall">
                                <svg width="25px" height="20px">
                                    <g>
                                        <g>
                                            <path
                                                d="M12.9,20v-1.5l2.3-0.2l-1.5-4.4H5.9l-1.5,4.4l2.2,0.2V20H0.4v-1.5l1.2-0.3C2,18.1,2.1,18,2.3,17.7L8.3,0h3.2
			l6.2,17.7c0.1,0.3,0.3,0.4,0.7,0.5l1.2,0.3V20C19.6,20,12.9,20,12.9,20z M9.9,2.1l-3.3,9.8H13L9.9,2.1z"
                                            />
                                        </g>
                                        <g>
                                            <path
                                                d="M15.1,5V4.5c0-0.3,0.3-0.6,0.6-0.6H24c0.3,0,0.6,0.3,0.6,0.6V5c0,0.3-0.3,0.6-0.6,0.6h-8.2
			C15.4,5.6,15.1,5.3,15.1,5z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default NotLoggedPages;
