import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";

class UserRoles extends Component {
    state = { userRolesList: [] };

    getUserRoles = async () => {
        const { fetchURL, defaultValue, onShowAlert, noAdminRoles, filter } = this.props;
        try {
            const response = await fetch(`${fetchURL}/user-roles-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    if (fetchedData.status === true) {
                        let userRolesDropdown = filter ? fetchedData.userRolesDropdownSearch : fetchedData.userRolesDropdown;
                        if (defaultValue === "all") {
                            userRolesDropdown.unshift({
                                key: 0,
                                value: "all",
                                text: "Wszystkie",
                            });
                        }

                        if (noAdminRoles) {
                            userRolesDropdown = userRolesDropdown.filter((e) => e.value !== "SUPERADMIN" /* && e.value !== "ADMIN" */);
                        }

                        this.setState({ userRolesList: userRolesDropdown });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: response.status });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." });
            setTimeout(this.getUserRoles(), 4000);
        }
    };

    componentDidMount() {
        this.getUserRoles();
    }

    render() {
        const { userRolesList } = this.state;
        const { value, onUserRolesSelected, req, multiple } = this.props;
        return (
            <Fragment>
                <Form.Group>
                    <Form.Label>Role użytkowników {req && <span className="required">*</span>}</Form.Label>

                    <Dropdown
                        value={value}
                        id="userRolesDropdown"
                        className={req ? "form-dropdown mb-3 req" : "form-dropdown mb-3"}
                        placeholder="Kliknij i wybierz"
                        fluid
                        selection
                        multiple={multiple ? true : false}
                        options={userRolesList}
                        onChange={(event, { value }) => {
                            onUserRolesSelected(event, value);
                        }}
                    />
                </Form.Group>
            </Fragment>
        );
    }
}

export default UserRoles;
