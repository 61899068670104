import React, { Fragment, Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NotAllowed from "../../other/NotAllowed";
import TestView from "./TestView";
import TestForm from "./TestForm";
import queryString from "query-string";
import AlertBasic from "../../alerts/AlertBasic";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TestInstructionPL from "./TestInstructionPL";
import TestInstructionPJM from "./TestInstructionPJM";
import $ from "jquery";

class Test extends Component {
    state = {
        alert: "",
        noPermission: false,
        subtestType: "PJM",
        //subtestType: null,
        pathname: null,
        testView: "loading",
        testCode: null,
        testType: null,
        test1: null,
        test2: null,
        testOption: null,
        testURL: null,
        testObjectID: null,
        savedCurrentPos1: null,
        savedCurrentPos2: null,
        testID1: null,
        testID2: null,
        doneTestMessage: "",
        previewInfoAlert: "",
        testStatus: null,
    };

    fetchMainData = async () => {
        const { fetchURL } = this.props;

        try {
            const response = await fetch(`${fetchURL}`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ disallowPermission: true, loading: false });
                    } else if (fetchedData.status && fetchedData.loginSuccess) {
                        const { location } = this.props;
                        const locSearch = queryString.parse(this.props.location.search);

                        //ścieżka kierująca do poglądu testu
                        if (location.pathname === "/test-preview") {
                            this.setState({
                                previewInfoAlert: {
                                    variant: "info",
                                    message: (
                                        <>
                                            <p>To jest podgląd testu. Wszelkie działania i zmiany nie zostaną zapisane.</p>
                                            <p>Test składa się tylko z zatwierdzonych pytań, które przeszły pozytywną weryfikację.</p>
                                        </>
                                    ),
                                },
                            });
                            if (locSearch.search === "" || locSearch.testType === null || locSearch.subtestType === null) {
                                this.setState({ alert: { variant: "danger", message: "Link do testu jest nieprawidłowy." } });
                            } else {
                                this.setState({
                                    subtestType: locSearch.subtestType,
                                    pathname: location.pathname,
                                    testCode: `${locSearch.testType}_${locSearch.subtestType}`,
                                    testType: locSearch.testType,
                                    testOption: locSearch.subtestType,
                                    savedCurrentPos1: 0,
                                    savedCurrentPos2: 0,
                                });
                                this.getNewTestData(locSearch.testType, locSearch.subtestType, location.pathname, null);
                            }
                        } else {
                            //ścieżka kierująca do nowego testu
                            if (location.pathname === "/test" && locSearch.testType && locSearch.subtestType) {
                                this.setState({
                                    subtestType: locSearch.subtestType,
                                    testType: locSearch.testType,
                                    pathname: `/test`,
                                    testCode: `${locSearch.testType}_${locSearch.subtestType}`,
                                    testOption: locSearch.testOption ? locSearch.testOption : locSearch.subtestType,
                                    savedCurrentPos1: 0,
                                    savedCurrentPos2: 0,
                                });
                                this.getNewTestData(locSearch.testType, locSearch.subtestType, location.pathname, locSearch.testOption ? locSearch.testOption : null);
                            } else {
                                const regExp = /^(\/test\/)(\w*)/;
                                if (regExp.test(location.pathname)) {
                                    this.getExistingTestData(location.pathname.replace("/test/", ""));
                                } else this.setState({ alert: { variant: "danger", message: "Link do testu jest nieprawidłowy." } });
                            }
                        }
                    } else {
                        this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych." } });
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych. " + err } });
            setTimeout(this.fetchMainData, 4000);
        }
    };

    getAppParameters = async () => {
        const { fetchURL } = this.props;
        this.setState({ alert: "" });
        try {
            const response = await fetch(`${fetchURL}/app-parameters`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.fetchMainData();
                    } else {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych." } });
            setTimeout(this.getAppParameters(), 4000);
        }
    };

    getExistingTestData = async (url) => {
        const { fetchURL } = this.props;
        const passData = {
            testURL: url,
        };
        try {
            const url = `${fetchURL}/get-existing-test-data`;
            const response = await fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === "done") this.setState({ doneTestMessage: fetchedData.message, testView: "doneTest", testOption: fetchedData.testOption });
                    else if (fetchedData.status === false) this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    else {
                        this.setState({
                            testObjectID: fetchedData.testObjectID,
                            test1: fetchedData.test1,
                            test2: fetchedData.test2,
                            testView: fetchedData.testOption === "PJM-PL" ? "testChapter" : fetchedData.testStatus === "PAUSED" ? "testView" : "testInstruction",
                            testOption: fetchedData.testOption,
                            subtestType: fetchedData.subtestType,
                            testType: fetchedData.testType,
                            pathname: `/test`,
                            savedCurrentPos1: fetchedData.currentQuestionIndex1,
                            savedCurrentPos2: fetchedData.currentQuestionIndex2,
                            testID1: fetchedData.testID1,
                            testID2: fetchedData.testID2,
                            testURL: fetchedData.testURL,
                            testStatus: fetchedData.testStatus,
                        });
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych. " + err } });
            setTimeout(this.getExistingTestData(url), 4000);
        }
    };

    getNewTestData = async (testType, subtestType, pathname, testOption) => {
        const { fetchURL } = this.props;
        const passData = {
            testType: testType,
            subtestType: subtestType,
            testOption: testOption,
        };
        try {
            const url = `${fetchURL}/${pathname === "/test-preview" ? "get-new-test-data-preview" : "get-new-test-data"}`;
            const response = await fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true });
                    else if (fetchedData.status === false) this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    else {
                        const test1 = fetchedData.test1;
                        const test2 = fetchedData.test2;
                        test1.forEach((e) => (e.checkedAnswer = null));
                        if (test2) test2.forEach((e) => (e.checkedAnswer = null));
                        this.setState({ test1: test1, testView: "formView", test2: test2 });
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych. " + err } });
            setTimeout(this.getNewTestData(testType, subtestType, pathname), 4000);
        }
    };

    goToTestList = async () => {
        const { fetchURL } = this.props;
        const passData = {
            testType: this.state.testType,
        };

        try {
            const url = `${fetchURL}/go-to-test-list`;
            const response = await fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    else $(window.location).attr("href", this.props.frontURL);
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych. " + err } });
            //setTimeout(this.goToTestList(), 4000);
        }
    };

    handleShowAlert = (alert) => this.setState({ alert: alert });

    handleCheckSelectedAnswer = (answerID, currentQuestionIndex) => {
        const { test1, test2, testOption, subtestType } = this.state;
        const list = testOption === "PJM-PL" && subtestType === "PL" ? [...test2] : [...test1];
        list[currentQuestionIndex].checkedAnswer = answerID;
        if (testOption === "PJM-PL" && subtestType === "PL") this.setState({ test2: list });
        else this.setState({ test1: list });
    };

    componentDidMount() {
        this.getAppParameters();
    }

    handleTabClose = (e) => {
        // Cancel the event
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        e.returnValue = "Wprowadzone zmiany mogą nie zostać zapisane.";
    };

    componentDidUpdate() {
        if (this.state.alert) {
            const height = $("#basicAlert").outerHeight();
            $("#previewInfoAlert").css("transform", `translate(-50%, ${2 * 16 + height}px)`);
        } else {
            $("#previewInfoAlert").css("transform", `translate(-50%, 1rem)`);
        }
        const { testView } = this.state;
        if (testView !== "finishTest" && testView !== "testPaused" && testView !== "testCanceled") {
            window.addEventListener("beforeunload", this.handleTabClose);
        } else {
            window.removeEventListener("beforeunload", this.handleTabClose);
        }
    }

    handleKeyboardInput = (e) => {
        const code = e.keyCode ? e.keyCode : e.which;

        if (code === 83 && this.state.testView === "testInstruction") {
            $("body").removeClass("hide-cursor");
            this.setState({ testView: "testView" });
        }
    };

    componentWillMount() {
        window.addEventListener("keydown", this.handleKeyboardInput.bind(this));
    }

    render() {
        const {
            noPermission,
            alert,
            subtestType,
            testView,
            pathname,
            test1,
            test2,
            testType,
            testOption,
            testObjectID,
            savedCurrentPos1,
            savedCurrentPos2,
            testID1,
            testID2,
            testURL,
            previewInfoAlert,
            testStatus,
        } = this.state;
        const { fetchURL, frontURL } = this.props;
        //console.log(testView, testObjectID, testURL, testID1, testID2);
        return (
            <Fragment>
                {alert && (
                    <AlertBasic
                        variant={alert.variant}
                        mssg={alert.message}
                        onCloseAlert={() => this.setState({ alert: "" })}
                        fading={alert.fading}
                        contactBtn={alert.contactBtn}
                        onShowContactModal={() => this.setState({ showContactModal: true, alert: "" })}
                        id="basicAlert"
                    />
                )}
                {previewInfoAlert && (
                    <AlertBasic variant={previewInfoAlert.variant} mssg={previewInfoAlert.message} onCloseAlert={() => this.setState({ previewInfoAlert: "" })} id="previewInfoAlert" />
                )}
                {noPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        {testView === "formView" && subtestType && testType ? (
                            <TestForm
                                pathname={pathname}
                                subtestType={subtestType}
                                testType={testType}
                                fetchURL={fetchURL}
                                onShowAlert={this.handleShowAlert}
                                onChangeTestView={(view, testObjectID, testURL, testID1, testID2) => {
                                    this.setState({ testView: view, testObjectID: testObjectID, testURL: testURL, testID1: testID1, testID2: testID2 });
                                }}
                                testOption={testOption}
                                test1={test1}
                                test2={test2}
                            />
                        ) : testView === "testInstruction" && subtestType ? (
                            <Container fluid>
                                {subtestType === "PL" ? (
                                    <TestInstructionPL onChangeTestView={(view) => this.setState({ testView: view })} frontURL={frontURL} />
                                ) : (
                                    <TestInstructionPJM onChangeTestView={(view) => this.setState({ testView: view })} frontURL={frontURL} />
                                )}
                            </Container>
                        ) : testView === "testChapter" && subtestType ? (
                            <Fragment>
                                <Row>
                                    <Col className="text-center">
                                        <p className="h1 my-4">{subtestType === "PJM" ? "Część I - PJM" : "Część II - PL"}</p>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    testView: testStatus === "PAUSED" && subtestType === "PJM" && testOption === "PJM-PL" ? "testView" : "testInstruction",
                                                    //nie pokazuj instrukcji jeśli test jest wznawiany na etapie PJM przy opcji testu PJM-PL
                                                })
                                            }
                                            variant={subtestType === "PL" ? "secondary" : "primary"}
                                        >
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Fragment>
                        ) : testView === "testView" && pathname && subtestType ? (
                            <TestView
                                test={testOption === "PJM-PL" && subtestType === "PL" ? test2 : test1}
                                fetchURL={fetchURL}
                                pathname={pathname}
                                onShowAlert={this.handleShowAlert}
                                onChangeTestView={(view) => this.setState({ testView: view })}
                                subtestType={subtestType}
                                testType={testType}
                                testOption={testOption}
                                testObjectID={testObjectID}
                                onCheckSelectedAnswer={this.handleCheckSelectedAnswer}
                                savedCurrentPos={testOption === "PJM-PL" && subtestType === "PL" ? savedCurrentPos2 : savedCurrentPos1}
                                frontURL={frontURL}
                                testID={testOption === "PJM-PL" && subtestType === "PL" ? testID2 : testID1}
                                onNextTest={() => {
                                    //console.log("next test");
                                    this.setState({ testView: "testChapter", subtestType: "PL" });
                                }}
                            />
                        ) : testView === "finishPreview" || testView === "finishTest" || testView === "testPaused" || testView === "testCanceled" ? (
                            <Container className="text-center">
                                <Fragment>
                                    <p className="pt-5 pb-3 h4">
                                        {testView === "finishPreview"
                                            ? "Podgląd testu został zakończony."
                                            : testView === "finishTest"
                                            ? "Test został zakończony."
                                            : testView === "testPaused"
                                            ? "Test został przerwany."
                                            : testView === "testCanceled"
                                            ? "Test został anulowany."
                                            : ""}
                                    </p>
                                    {testView === "testPaused" && (
                                        <>
                                            <p className="mb-3">Wygenerowany link do testu:</p>
                                            <p className="mb-3">
                                                <a href={`${frontURL}/test/${testURL}`}>https://test-leksyka.pl/test/{testURL}</a>
                                            </p>
                                        </>
                                    )}
                                    {testView === "finishTest" || testView === "testPaused" || testView === "testCanceled" ? (
                                        <Button
                                            onClick={() => {
                                                this.goToTestList();
                                            }}
                                            variant={testOption === "PL" ? "secondary" : "primary"}
                                        >
                                            Wróć na listę testów
                                        </Button>
                                    ) : (
                                        <Button onClick={() => window.close()} variant={testOption === "PL" ? "secondary" : "primary"}>
                                            Zamknij
                                        </Button>
                                    )}
                                </Fragment>
                            </Container>
                        ) : testView === "loading" ? (
                            <div style={{ marginTop: "10rem" }}>
                                <LoadingProgressBar />
                            </div>
                        ) : testView === "doneTest" ? (
                            <Container className="text-center">
                                <Row>
                                    <Col className="mt-5">
                                        <p className="h4 my-4">{this.state.doneTestMessage}</p>
                                        <Button
                                            onClick={() => {
                                                this.goToTestList();
                                            }}
                                            variant={testOption === "PL" ? "secondary" : "primary"}
                                        >
                                            Wróć na listę testów
                                        </Button>
                                    </Col>
                                </Row>
                            </Container>
                        ) : (
                            <></>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Test;
