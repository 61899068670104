import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../navigation/NavDetails";
import { Container, Row, Col, Button, Table, Modal } from "react-bootstrap";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import NameInput from "../forms/NameInput";
import $ from "jquery";
import NotAllowed from "../other/NotAllowed";

class MyAccount extends Component {
    state = {
        userData: this.props.myAccountData ? this.props.myAccountData : null,
        userLogs: null,
        loading: true,
        changedValues: false,
        showResetPassModal: false,
        user_name: this.props.myAccountData ? this.props.myAccountData[0].user_name : "",
        user_surname: this.props.myAccountData ? this.props.myAccountData[0].user_surname : "",
        user_institution: this.props.myAccountData ? this.props.myAccountData[0].user_institution : "",
        user_position: this.props.myAccountData ? this.props.myAccountData[0].user_position : "",
        noPermission: false,
    };

    getMyAccountData = async () => {
        const { fetchURL, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/my-account-data`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ noPermission: true, loading: false });
                    } else if (fetchedData.status === true) {
                        const userData = fetchedData.userData;
                        this.props.onStoreDataList(userData);
                        this.setState({
                            userData: userData,
                            user_position: !userData[0].user_position ? "" : userData[0].user_position,
                            user_name: userData[0].user_name,
                            user_surname: userData[0].user_surname,
                            user_institution: !userData[0].user_institution ? "" : userData[0].user_institution,
                        });
                        this.getUserLogs();
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getMyAccountData(), 4000);
        }
    };

    getUserLogs = async () => {
        const { fetchURL, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/my-account-logs`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ noPermission: true, loading: false });
                    } else if (fetchedData.status === true) {
                        this.setState({ userLogs: fetchedData.userLogs, loading: false });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUserLogs(), 4000);
        }
    };

    saveChanges = async () => {
        const { fetchURL, onShowAlert } = this.props;
        const { userData, user_name, user_surname, user_institution, user_position } = this.state;
        onShowAlert({ variant: "info", message: "Aktualizuję Twoje dane..." });
        const passData = {
            userData: userData,
            userName: user_name,
            userSurname: user_surname,
            userInstitution: user_institution,
            userPosition: user_position,
        };
        try {
            const response = await fetch(`${fetchURL}/save-user-changes`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ noPermission: true, loading: false });
                    } else if (fetchedData.status === true) {
                        this.setState({ changedValues: false, loading: true }, () => {
                            onShowAlert({ variant: "success", message: fetchedData.message, fading: 2000 });
                            this.props.onCancelChangeValuesInForms();
                            this.getMyAccountData();
                        });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.getUserRoles(), 4000);
        }
    };

    checkValidation = () => {
        const { user_name, user_surname, user_institution, user_position } = this.state;
        const { onShowAlert } = this.props;
        let errorMsg = "";
        if (!user_name) {
            errorMsg += 'Pole "Imię" jest puste. ';
            $("#user_name").addClass("is-invalid");
        }
        if (!user_surname) {
            errorMsg += 'Pole "Nazwisko" jest puste. ';
            $("#user_surname").addClass("is-invalid");
        }
        if (!user_institution) {
            errorMsg += 'Pole "Nazwa ośrodka" jest puste. ';
            $("#user_institution").addClass("is-invalid");
        }
        if (!user_position) {
            errorMsg += 'Pole "Stanowisko" jest puste. ';
            $("#user_position").addClass("is-invalid");
        }

        if (errorMsg) onShowAlert({ variant: "danger", message: errorMsg });
        else this.saveChanges();
    };

    handleChangeData = (e) => {
        this.setState({ [e.target.name]: e.target.value, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    resetPassword = async (passData) => {
        const { fetchURL, onShowAlert } = this.props;
        onShowAlert({ variant: "info", message: "Resetuję hasło..." });
        try {
            const response = await fetch(`${fetchURL}/reset-password`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                onShowAlert({ variant: "info", message: "Resetowanie hasła..." });
                await response.json().then((data) => {
                    //console.log(data);
                    if (data.status === "noPermission") {
                        this.setState({ noPermission: true });
                    } else if (data.status === true) {
                        onShowAlert({ variant: "success", message: data.message, fading: 2000 });
                        this.props.onShowNotLoggedPages();
                    } else onShowAlert({ variant: "danger", message: data.message });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "success", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." });
            setTimeout(this.resetPassword(passData), 4000);
        }
    };

    testowyMail = async (passData) => {
        const { fetchURL, onShowAlert } = this.props;
        onShowAlert({ variant: "info", message: "wysyłam maila..." });
        try {
            const response = await fetch(`${fetchURL}/testowyMail`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                onShowAlert({ variant: "info", message: "Resetowanie hasła..." });
                await response.json().then((data) => {
                    //console.log(data);
                    if (data.status === "noPermission") {
                        this.setState({ noPermission: true });
                    } else if (data.status === true) {
                        onShowAlert({ variant: "success", message: data.message });
                    } else onShowAlert({ variant: "danger", message: data.message });
                });
            }
        } catch (err) {
            onShowAlert({ variant: "success", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." });
        }
    };

    componentDidMount() {
        if (!this.props.myAccountData) this.getMyAccountData();
        else this.getUserLogs();
    }

    render() {
        const { userData, changedValues, userLogs, user_name, user_surname, user_institution, showResetPassModal, noPermission, user_position } = this.state;

        return (
            <Fragment>
                {noPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        <Container fluid>
                            <Row className="py-3">
                                <NavDetails header="Moje konto" hideBackButton />
                                <Col></Col>
                            </Row>
                        </Container>
                        <Container>
                            {userData ? (
                                <Fragment>
                                    <Row>
                                        <Col lg={8} md={6} xs={12}>
                                            <p className="h4 my-4">Dane podstawowe</p>
                                            <Row>
                                                <Col lg={6} xs={12}>
                                                    <NameInput
                                                        value={user_name}
                                                        onChangeValue={this.handleChangeData}
                                                        req
                                                        labelTxt="Imię"
                                                        placeholderTxt="Wpisz imię"
                                                        name="user_name"
                                                        id="user_name"
                                                    />
                                                </Col>
                                                <Col lg={6} xs={12}>
                                                    <NameInput
                                                        value={user_surname}
                                                        onChangeValue={this.handleChangeData}
                                                        req
                                                        labelTxt="Nazwisko"
                                                        placeholderTxt="Wpisz nazwisko"
                                                        name="user_surname"
                                                        id="user_surname"
                                                    />
                                                </Col>
                                                <Col xs={12} lg={8}>
                                                    <NameInput
                                                        value={user_institution}
                                                        onChangeValue={this.handleChangeData}
                                                        req
                                                        labelTxt="Nazwa ośrodka"
                                                        placeholderTxt="Wpisz nazwę ośrodka"
                                                        name="user_institution"
                                                        id="user_institution"
                                                    />
                                                </Col>
                                                <Col xs={12} lg={4}>
                                                    <NameInput
                                                        value={user_position}
                                                        onChangeValue={this.handleChangeData}
                                                        req
                                                        labelTxt="Stanowisko"
                                                        placeholderTxt="Wpisz nazwę stanowiska"
                                                        name="user_position"
                                                        id="user_position"
                                                    />
                                                </Col>
                                                <Col xs={12}>
                                                    {!changedValues ? (
                                                        <Button variant="outline-primary" disabled>
                                                            Zapisz zmiany
                                                        </Button>
                                                    ) : (
                                                        <Button variant="outline-primary" onClick={this.checkValidation}>
                                                            Zapisz zmiany
                                                        </Button>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={4} md={6} xs={12}>
                                            <p className="h4 my-4">Dane informacyjne</p>
                                            <p className="mb-2">E-mail: {userData[0].user_email}</p>
                                            <p className="mb-2">Stanowisko: {userData[0].user_position}</p>
                                            <p className="mb-2">Role: {userData[0].user_roles_list}</p>
                                            <p className="mb-2">Status: {userData[0].user_stat_name}</p>
                                            <p className="mb-2">Data ost. logowania: {userData[0].last_login_date}</p>
                                            <p className="mb-2">Data rejestracji: {userData[0].create_date}</p>
                                            <Button variant="outline-secondary" onClick={() => this.setState({ showResetPassModal: true })}>
                                                Zresetuj hasło
                                            </Button>
                                        </Col>
                                        <Col xs={12}>
                                            <p className="h4 my-4">Lista modyfikacji</p>
                                            {!userLogs ? (
                                                <LoadingProgressBar />
                                            ) : userLogs.length > 0 ? (
                                                <Fragment>
                                                    <Fragment>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>L.p.</th>
                                                                    <th>Modyfikacje</th>
                                                                    <th>Data modyfikacji</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {userLogs.map((e, i) => (
                                                                    <tr key={i}>
                                                                        <td>{i + 1}.</td>
                                                                        <td>{e.user_log_desc}</td>
                                                                        <td>{e.user_log_date}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Fragment>
                                                </Fragment>
                                            ) : (
                                                <span className="opacity-4">Brak danych</span>
                                            )}
                                        </Col>
                                    </Row>
                                </Fragment>
                            ) : (
                                <LoadingProgressBar />
                            )}
                        </Container>

                        {showResetPassModal && (
                            <Modal show={showResetPassModal} onHide={() => this.setState({ showResetPassModal: false })}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Reset hasła</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    Czy na pewno chcesz zresetować hasło? Nastąpi automatyczne wylogowanie z aplikacji, a na zarejestrowany adres e-mail ({userData[0].user_email}) zostanie wysłany
                                    link do ustawienia nowego hasła.
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="outline-secondary" type="button" onClick={() => this.resetPassword({ email: userData[0].user_email })}>
                                        Tak, zresetuj hasło
                                    </Button>
                                    <Button variant="primary" type="button" onClick={() => this.setState({ showResetPassModal: false })}>
                                        Nie, zostań
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default MyAccount;
