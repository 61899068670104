import React, { Fragment, Component } from "react";
import NotLoggedPages from "./NotLoggedPages";
import LoggedPages from "./LoggedPages";
import $ from "jquery";
import LoadingSpinner from "../spinners/LoadingSpinner";
import { Alert, Button } from "react-bootstrap";
import ContactWithAdminModal from "../modals/ContactWithAdminModal";
import NotAllowed from "../other/NotAllowed";
import AlertUsingCookies from "../alerts/AlertUsingCookies";

class Main extends Component {
    state = {
        loggedUserPrivilages: [],
        cookieMaxAge: null,
        onShowContactModal: false,
        loading: true,
        loggedIn: false,
        alert: "",
        sendOnThisEmail: "",
        appParams: null,
        contactFormData: null,
        disallowPermission: false,
        showCookieAlert: false,
        redirectTo: null,
    };

    handleLogout = async () => {
        const { fetchURL } = this.props;
        this.setState({ alert: "" });
        try {
            const response = await fetch(`${fetchURL}/logout`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status) {
                        this.setState({
                            alert: { variant: "success", message: fetchedData.message, fading: 2000 },
                            loggedIn: false,
                            cookieMaxAge: null,
                            loggedUserPrivilages: null,
                        });
                    } else {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message, fading: 2000 } });
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się wylogować." } });
            setTimeout(this.handleLogout, 4000);
        }
    };

    fetchMainData = async () => {
        const { fetchURL } = this.props;

        try {
            const response = await fetch(`${fetchURL}`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ disallowPermission: true, loading: false });
                    } else if (fetchedData.status && fetchedData.loginSuccess) {
                        this.setState(
                            {
                                loggedIn: true,
                                loggedUserPrivilages: fetchedData.userData.userPrivilages,
                                cookieMaxAge: fetchedData.expDate,
                                redirectTo: fetchedData.showTestList ? fetchedData.showTestList : null,
                            },
                            () => {
                                if (this.state.loggedIn && this.state.cookieMaxAge) {
                                    //jeżeli nastąpiło zalogowanie oraz na serwerze jest ustawiony czas maksymalny trwania sesji
                                    this.startSession(true);
                                }
                                this.setState({ loading: false });
                            }
                        );
                    } else if (fetchedData.status && !fetchedData.loginSuccess) {
                        this.setState({ loggedIn: false, loading: false });
                    } else {
                        this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych." } });
                    }

                    if (fetchedData.cookieAlertText) {
                        this.handleShowAlert({ variant: "info", message: fetchedData.cookieAlertText, fading: 3000 });
                        this.setState({ loading: false });
                        this.resetCookieAlertText();
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych." } });
            setTimeout(this.fetchMainData, 4000);
        }
    };

    resetCookieAlertText = async () => {
        try {
            await fetch(`${this.props.fetchURL}/reset-cookie-alert-text`, {
                credentials: "include",
            });
        } catch (err) {
            this.handleShowAlert({ variant: "danger", message: "Nie udało się wysłać danych. " + err });
        }
    };

    getAppParameters = async (downloadAgain) => {
        const { fetchURL } = this.props;
        this.setState({ alert: "" });
        try {
            const response = await fetch(`${fetchURL}/app-parameters`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const appParams = fetchedData.appParams;
                        appParams.forEach((e) => {
                            e.param_value = e.param_value === "Y" ? true : e.param_value === "N" ? false : e.param_value;
                        });
                        this.setState({ appParams: appParams });
                        //pobranie głównych danych z serwera
                        if (!downloadAgain) this.fetchMainData();
                    } else {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych." } });
            setTimeout(this.getAppParameters(downloadAgain), 4000);
        }
    };

    checkAlertCookies = async () => {
        if (!localStorage.getItem("confirmCookies")) {
            this.setState({ showCookieAlert: true });
        }
    };

    componentDidMount() {
        //pobranie parametrów aplikacji odpowiedzialnych za wyświetlanie odpowiednich elementów/widoków aplikacji
        this.checkAlertCookies();
        this.getAppParameters(false);
    }

    componentDidUpdate() {
        if (!this.state.loggedIn) {
            $("#showNotLoggedPages").fadeIn(700);
        } else {
            $("#showNotLoggedPages").fadeOut();
        }

        const { alert } = this.state;
        if (alert) {
            if (alert.fading) {
                $(".alert-basic.alert-" + alert.variant)
                    .delay(alert.fading)
                    .fadeOut(400);
                setTimeout(() => this.setState({ alert: "" }), alert.fading + 400);
            }
        }
    }

    handleShowAlert = (alert) => this.setState({ alert: alert });

    render() {
        const { alert, resetPassError, loading, loggedIn, loggedUserPrivilages, sendOnThisEmail, appParams, showContactModal, contactFormData, disallowPermission, showCookieAlert, redirectTo } =
            this.state;
        const { fetchURL } = this.props;
        return (
            <Fragment>
                {alert && (
                    <Alert className="alert-basic" variant={alert.variant} onClose={() => this.setState({ alert: "" })} dismissible>
                        {alert.message}
                        {alert.contactBtn && (
                            <Button
                                variant={`outline-${alert.variant}`}
                                onClick={() => this.setState({ showContactModal: true, contactFormData: alert.contactBtn }, () => this.setState({ alert: "" }))}
                                className="d-block mt-2"
                            >
                                Kontakt z administracją
                            </Button>
                        )}
                    </Alert>
                )}
                {loading || !appParams ? (
                    <LoadingSpinner />
                ) : (
                    <Fragment>
                        {loggedIn ? (
                            <LoggedPages
                                fetchURL={fetchURL}
                                loggedUserPrivilages={loggedUserPrivilages}
                                onLogout={this.handleLogout}
                                onShowAlert={this.handleShowAlert}
                                appParams={appParams}
                                onChangeAppParams={(settingsData) => {
                                    this.setState({ appParams: settingsData });
                                }}
                                onShowNotLoggedPages={() => {
                                    this.setState({ loggedIn: false, cookieMaxAge: null, loggedUserPrivilages: null });
                                }}
                                redirectTo={redirectTo}
                            />
                        ) : disallowPermission ? (
                            <NotAllowed />
                        ) : (
                            <Fragment>
                                <div id="showNotLoggedPages" style={{ display: "none" }}>
                                    <NotLoggedPages
                                        fetchURL={fetchURL}
                                        sendOnThisEmail={sendOnThisEmail}
                                        resetPassError={resetPassError}
                                        onHideAlertInactiveUser={() => this.setState({ mssgAlertDisabledUser: "" })}
                                        appParams={appParams}
                                        onLoginSucces={(priv) => {
                                            this.setState({
                                                loggedIn: true,
                                                loggedUserPrivilages: priv,
                                            });
                                        }}
                                        onShowAlert={this.handleShowAlert}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {showContactModal && (
                    <ContactWithAdminModal
                        contactAdminData={contactFormData}
                        show={showContactModal}
                        onClose={() => this.setState({ showContactModal: false, contactFormData: null })}
                        fetchURL={fetchURL}
                    />
                )}
                {showCookieAlert && (
                    <AlertUsingCookies
                        onAcceptCookies={() => {
                            this.setState({ showCookieAlert: false });
                            localStorage.setItem("confirmCookies", true);
                        }}
                    />
                )}
            </Fragment>
        );
    }
}

export default Main;
