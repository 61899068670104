import React, { Fragment, Component } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { faArrowLeft, faArrowRight, faCheckCircle, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotAllowed from "../../other/NotAllowed";
import TestQuestion from "./TestQuestion";
import $ from "jquery";

class TestView extends Component {
    state = {
        noPermission: false,
        currentQuestionIndex: this.props.savedCurrentPos,
        showFinishTestModal: false,
        showPauseTestModal: false,
        disableBtn: false,
    };

    updateQuestionAndChangeTestView = async (option) => {
        const { fetchURL, onShowAlert, test, testID } = this.props;
        const { currentQuestionIndex } = this.state;
        const passData = {
            questionID: test[currentQuestionIndex].question_id,
            checkedAnswer: test[currentQuestionIndex].checkedAnswer,
            testID: testID,
        };
        //console.log(passData);
        try {
            // możliwość tylko cofnięcia się do poprzedniego pytania, bez zaznaczonej odpowiedzi
            if (option === "previous" && !test[currentQuestionIndex].checkedAnswer) {
                let index = currentQuestionIndex;
                if (index > 0) {
                    index -= 1;
                    this.setState({ currentQuestionIndex: index }, () => this.updateTestPosition());
                }
            } else if (option === "pause" && !test[currentQuestionIndex].checkedAnswer) this.updateTestStatus("PAUSED", "testPaused");
            else if (option === "cancel") this.updateTestStatus("CANCELED", "testCanceled");
            else {
                //przejście do następnego lub poprzedniego pytania z zapisaniem w bazie zaznaczonej odpowiedzi
                //console.log("send-checked-test-answer");
                const url = `${fetchURL}/send-checked-test-answer`;
                const response = await fetch(url, {
                    credentials: "include",
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                });
                if (response.status === 200) {
                    await response.json().then((fetchedData) => {
                        //console.log(fetchedData);
                        if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                        else {
                            if (option === "next") {
                                let index = currentQuestionIndex;
                                if (index < test.length - 1) {
                                    index += 1;
                                    this.setState({ currentQuestionIndex: index }, () => this.updateTestPosition());
                                }
                            } else if (option === "previous") {
                                let index = currentQuestionIndex;
                                if (index > 0) {
                                    index -= 1;
                                    this.setState({ currentQuestionIndex: index }, () => this.updateTestPosition());
                                }
                            } else if (option === "finish") this.updateTestStatus("DONE", "finishTest");
                            else if (option === "pause") this.updateTestStatus("PAUSED", "testPaused");
                        }
                    });
                }
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych. " + err } });
            setTimeout(this.updateQuestionAndChangeTestView(option), 4000);
        }
    };

    updateTestPosition = async () => {
        const { fetchURL, onShowAlert, testID } = this.props;
        const { currentQuestionIndex } = this.state;
        const passData = {
            testID: testID,
            currentQuestionIndex: currentQuestionIndex,
        };
        try {
            const url = `${fetchURL}/update-test-position`;
            const response = await fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else this.setState({ disableBtn: false });
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych. " + err } });
            setTimeout(this.updateTestPosition(), 4000);
        }
    };

    updateTestStatus = async (status, view) => {
        const { fetchURL, onShowAlert, testID, subtestType, testOption } = this.props;
        const passData = {
            testID: testID,
            status: status,
        };
        try {
            const url = `${fetchURL}/update-test-status`;
            const response = await fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    //console.log(testOption, subtestType, status, view);
                    if (fetchedData.status === "noPermission" || fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else if (testOption === "PJM-PL" && subtestType === "PJM" && status !== "PAUSED" && status !== "CANCELED") this.props.onNextTest();
                    else this.props.onChangeTestView(view);
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych. " + err } });
            setTimeout(this.updateTestStatus(status), 4000);
        }
    };

    handleShowNextQuestion = () => {
        let index = this.state.currentQuestionIndex;
        if (index < this.props.test.length - 1) {
            index += 1;
            this.setState({ currentQuestionIndex: index });
        }
    };

    handleShowPreviousQuestion = () => {
        let index = this.state.currentQuestionIndex;
        if (index > 0) {
            index -= 1;
            this.setState({ currentQuestionIndex: index });
        }
    };

    componentDidMount() {
        $(`#loadingTest`).css("height", $(window).height());
        $("#loadingTest").delay(1000).fadeOut(300);
    }

    render() {
        const { noPermission, currentQuestionIndex, showFinishTestModal, showPauseTestModal, disableBtn } = this.state;
        const { fetchURL, subtestType, test, onCheckSelectedAnswer, pathname, frontURL, testOption, onShowAlert } = this.props;

        return (
            <Fragment>
                {noPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        <Container fluid>
                            <Fragment>
                                <div id="testContainer" className="test">
                                    <div id="loadingTest">
                                        <div className="progressbar">
                                            <div className={subtestType === "PJM" ? "progressbar-value pjm" : subtestType === "PL" ? "progressbar-value pl" : "progressbar-value pjm-pl"}></div>
                                        </div>
                                    </div>
                                    <Fragment>
                                        <Row>
                                            <Col lg={1} className="text-center button-arrow">
                                                {test && subtestType && (
                                                    <Fragment>
                                                        {currentQuestionIndex > 0 && (
                                                            <Button
                                                                variant={`outline-${subtestType === "PJM" ? "primary" : "secondary"}`}
                                                                onClick={() => (pathname === "/test" ? this.updateQuestionAndChangeTestView("previous") : this.handleShowPreviousQuestion())}
                                                            >
                                                                <FontAwesomeIcon icon={faArrowLeft} />
                                                            </Button>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </Col>
                                            <Col lg={10} className="question-container">
                                                {test && (
                                                    <Fragment>
                                                        {test.map((e, i) => (
                                                            <Fragment key={i}>
                                                                {i === currentQuestionIndex && (
                                                                    <TestQuestion
                                                                        onShowAlert={onShowAlert}
                                                                        fetchURL={fetchURL}
                                                                        questionData={e}
                                                                        subtestType={subtestType}
                                                                        onSelectAnswer={(id) => onCheckSelectedAnswer(id, currentQuestionIndex)}
                                                                        frontURL={frontURL}
                                                                    />
                                                                )}
                                                            </Fragment>
                                                        ))}
                                                    </Fragment>
                                                )}
                                            </Col>
                                            <Col lg={1} className="text-center button-arrow">
                                                {test && (
                                                    <Fragment>
                                                        {currentQuestionIndex < test.length - 1 ? (
                                                            <Button
                                                                variant={`outline-${subtestType === "PJM" ? "primary" : "secondary"}`}
                                                                disabled={!test[currentQuestionIndex].checkedAnswer || disableBtn ? true : false}
                                                                onClick={() => {
                                                                    if (!disableBtn) {
                                                                        this.setState({ disableBtn: true }, () => {
                                                                            if (pathname === "/test") this.updateQuestionAndChangeTestView("next");
                                                                            else this.handleShowNextQuestion();
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faArrowRight} />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                variant={`${subtestType === "PJM" ? "primary" : "secondary"}`}
                                                                disabled={!test[currentQuestionIndex].checkedAnswer ? true : false}
                                                                onClick={
                                                                    testOption === "PJM-PL" && subtestType === "PJM"
                                                                        ? () => {
                                                                              this.updateQuestionAndChangeTestView("finish");
                                                                          }
                                                                        : () => this.setState({ showFinishTestModal: true })
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faCheckCircle} />
                                                            </Button>
                                                        )}
                                                    </Fragment>
                                                )}
                                            </Col>
                                        </Row>
                                    </Fragment>
                                </div>
                                <div id="testFooter">
                                    <Row>
                                        {test && (
                                            <Col className="text-center">
                                                {test.map((e, i) => (
                                                    <Fragment key={i}>
                                                        <div className="d-inline-block m-2">
                                                            <div className={`question-dot${currentQuestionIndex === i ? " filled" : ""}`}></div>
                                                        </div>
                                                    </Fragment>
                                                ))}
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        <Col className="mb-3">
                                            {subtestType && pathname === "/test" && (
                                                <Button variant={`outline-${subtestType === "PJM" ? "primary" : "secondary"}`} onClick={() => this.setState({ showPauseTestModal: true })}>
                                                    <FontAwesomeIcon icon={faPause} />
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </Fragment>
                        </Container>
                    </Fragment>
                )}

                {showFinishTestModal && (
                    <Modal show={showFinishTestModal} onHide={() => this.setState({ showFinishTestModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Zakończenie testu</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Czy na pewno chcesz zakończyć ten test?</Modal.Body>
                        <Modal.Footer>
                            <Button variant={`outline-${subtestType === "PJM" ? "primary" : "secondary"}`} type="button" onClick={() => this.setState({ showFinishTestModal: false })}>
                                Nie, zostań
                            </Button>
                            <Button
                                variant={subtestType === "PJM" ? "primary" : "secondary"}
                                type="button"
                                onClick={
                                    this.props.pathname === "/test-preview"
                                        ? () => this.setState({ showFinishTestModal: false }, () => this.props.onChangeTestView("finishPreview"))
                                        : () => {
                                              this.setState({ alert: { variant: "info", message: "Kończenie testu..." } });
                                              this.updateQuestionAndChangeTestView("finish");
                                          }
                                }
                            >
                                Tak, zakończ
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}

                {showPauseTestModal && (
                    <Modal show={showPauseTestModal} onHide={() => this.setState({ showPauseTestModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Przerwanie testu</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="mb-3">
                                Czy na pewno chcesz przerwać ten test? Jeśli nie - kliknij krzyżyk. Jeśli tak - wybierz, czy chcesz w przyszłości kontynuować test, czy rozpocząć go od nowa.
                            </p>
                            {/* <p>Rekomendujemy, aby wznowienie testu przebiegało w następujących warunkach: </p> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant={`outline-${subtestType === "PJM" ? "primary" : "secondary"}`}
                                type="button"
                                onClick={
                                    this.props.pathname === "/test-preview"
                                        ? () => this.setState({ showPauseTestModal: false }, () => this.props.onChangeTestView("updateTestStatus"))
                                        : () => {
                                              this.setState({ alert: { variant: "info", message: "Anuluję test..." } });
                                              this.updateQuestionAndChangeTestView("cancel");
                                          }
                                }
                            >
                                Anuluj test
                            </Button>
                            <Button
                                variant={`outline-${subtestType === "PJM" ? "primary" : "secondary"}`}
                                type="button"
                                onClick={
                                    this.props.pathname === "/test-preview"
                                        ? () => this.setState({ showPauseTestModal: false }, () => this.props.onChangeTestView("updateTestStatus"))
                                        : () => {
                                              this.setState({ alert: { variant: "info", message: "Wstrzymuję test..." } });
                                              this.updateQuestionAndChangeTestView("pause");
                                          }
                                }
                            >
                                Wstrzymaj test
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default TestView;
