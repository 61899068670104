import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import $ from "jquery";
import AlertNoPermissionList from "../alerts/AlertNoPermissionList";
import LoadingProgressBar from "../spinners/LoadingProgressBar";

class ObjectGrades extends Component {
    state = { classesArray: null, noPermission: false, loading: true };

    getGradesList = async () => {
        const { fetchURL, onShowAlert, testType } = this.props;
        const passData = {
            testType: testType,
        };
        try {
            const response = await fetch(`${fetchURL}/get-grades-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    // //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true, loading: false });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else this.setState({ loading: false, classesArray: fetchedData.gradesArray });
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych." } });
            setTimeout(this.getGradesList(), 4000);
        }
    };

    componentDidMount() {
        this.getGradesList();
    }

    render() {
        const { classesArray, noPermission, loading } = this.state;
        const { objectClass, onChangeValues, classStyle } = this.props;

        return (
            <Fragment>
                <Form.Label>
                    Klasa osoby badanej <span className="required">*</span>
                </Form.Label>
                {loading ? (
                    <LoadingProgressBar />
                ) : noPermission ? (
                    <AlertNoPermissionList />
                ) : (
                    <>
                        <Form.Control
                            as="select"
                            placeholder="Kliknij i wybierz"
                            custom
                            className={`form-dropdown mb-3 req ${classStyle}`}
                            onChange={(e) => {
                                onChangeValues(e.target.value);
                            }}
                            onClick={() => $("#objectClass").removeClass("is-invalid")}
                            value={objectClass}
                            id="objectClass"
                        >
                            <option value="default">Kliknij i wybierz</option>
                            {classesArray.map((e, i) => (
                                <Fragment key={i}>
                                    <option value={e.value}>{e.text}</option>
                                </Fragment>
                            ))}
                        </Form.Control>
                    </>
                )}
            </Fragment>
        );
    }
}

export default ObjectGrades;
