import React, { Component, Fragment } from "react";
import EvaluationResults from "../../forms/EvaluationResults";
import EvaluationDifficulty from "../../forms/EvaluationDifficulty";
import DescrInput from "../../forms/DescrInput";
import $ from "jquery";
import { Button, Col, Container, Row } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import QuestionPreview from "../questions/QuestionPreview";

class DoEvaluation extends Component {
    state = {
        loading: true,
        selectedEvalResult: null,
        selectedEvalDifficulty: null,
        evalComment: "",
        changedValues: false,
    };

    handleCheckValidation = () => {
        const { onShowAlert } = this.props;
        const { selectedEvalResult, evalComment, selectedEvalDifficulty } = this.state;
        onShowAlert("");
        let errorMsg = "";
        if (!selectedEvalResult || selectedEvalResult === "default") {
            errorMsg += `Pole "Kwalifikacja pytania" jest puste. `;
            $("#questionEvalResultsDropdown").addClass("is-invalid");
        } else {
            if (selectedEvalResult !== "YES" && !evalComment) {
                errorMsg += `Pole "Komentarz do oceny" musi być uzupełnione, jeśli kwalifikacja została została wybrana na "Nie" lub "Warunkowo". `;
                $("#evalComment").addClass("is-invalid");
            }
        }
        if (!selectedEvalDifficulty || selectedEvalDifficulty === "default") {
            errorMsg += `Pole "Stopień trudności pytania" jest puste. `;
            $("#questionEvalDifficultyDropdown").addClass("is-invalid");
        }

        if (evalComment && evalComment.length > 255) {
            errorMsg += `Komentarz jest za długi (zawiera więcej niż 255 znaków). `;
            $("#evalComment").addClass("is-invalid");
        }

        if (errorMsg) {
            onShowAlert({ variant: "danger", message: errorMsg });
        } else {
            this.doEvaluation();
        }
    };

    doEvaluation = async () => {
        const { fetchURL, onShowAlert, currentPage, onChangeNavAndPage } = this.props;
        const { selectedEvalResult, selectedEvalDifficulty, evalComment } = this.state;
        const passData = {
            questionID: currentPage.params.questionID,
            evaluationResult: selectedEvalResult,
            evaluationDifficulty: selectedEvalDifficulty,
            evaluationComment: evalComment,
            option: currentPage.subpage,
        };
        onShowAlert({ variant: "info", message: "Oceniam pytanie..." });
        try {
            const response = await fetch(`${fetchURL}/do-evaluation`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onShowAlert({
                            variant: "success",
                            message: currentPage.subpage === "doEvaluation" ? "Poprawnie wykonano ocenę pytania." : "Poprawnie wykonano edycję oceny pytania.",
                            fading: 2000,
                        });
                        onChangeNavAndPage({ nav: "evaluations", subpage: "evaluationsList", params: "" }, null, "evaluationsList");
                        this.props.onDownloadQuestionsList();
                        this.props.onDownloadApprovalsList();
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: response.status });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych. " + err });
            this.setState({ loading: false });
            setTimeout(this.doEvaluation(), 4000);
        }
    };

    getEvalData = async (questionID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            questionID: questionID,
        };
        try {
            const response = await fetch(`${fetchURL}/get-evaluation-data`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const getEvalData = fetchedData.getEvalData[0];
                        this.setState({
                            selectedEvalResult: getEvalData.eval_result_code,
                            selectedEvalDifficulty: getEvalData.eval_diff_code,
                            evalComment: getEvalData.eval_comment ? getEvalData.eval_comment : "",
                        });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: response.status });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych. " + err });
            this.setState({ loading: false });
            setTimeout(this.getEvalData(questionID), 4000);
        }
    };

    componentDidMount() {
        if (this.props.currentPage.subpage === "editEvaluation") {
            this.getEvalData(this.props.currentPage.params.questionID);
        }
        //setTimeout(() => {
        else this.setState({ selectedEvalResult: "default", selectedEvalDifficulty: "default" });
        //}, 100);
    }

    render() {
        const { evalComment, changedValues, selectedEvalResult, selectedEvalDifficulty } = this.state;
        const { fetchURL, onShowAlert, currentPage, onBackToPreviousPage } = this.props;
        return (
            <Fragment>
                <Container fluid>
                    <QuestionPreview fetchURL={fetchURL} onShowAlert={onShowAlert} questionID={currentPage.params.questionID} currentPage={currentPage} onBackToPreviousPage={onBackToPreviousPage} />

                    <Row id="evaluationFrame">
                        <Col>
                            <Row>
                                {selectedEvalResult && selectedEvalDifficulty ? (
                                    <Fragment>
                                        <Col xs={3}>
                                            <EvaluationResults
                                                defaultValue={selectedEvalResult}
                                                labelTxt="Kwalifikacja pytania"
                                                req
                                                onEvaluationResultSelected={(event, value) => {
                                                    this.setState({ selectedEvalResult: value, changedValues: true });
                                                    this.props.onChangeValuesInForms();
                                                }}
                                                fetchURL={fetchURL}
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <EvaluationDifficulty
                                                defaultValue={selectedEvalDifficulty}
                                                req
                                                onEvaluationDifficultySelected={(event, value) => {
                                                    this.setState({ selectedEvalDifficulty: value, changedValues: true });
                                                    this.props.onChangeValuesInForms();
                                                }}
                                                fetchURL={fetchURL}
                                                labelTxt="Stopień trudności"
                                            />
                                        </Col>
                                        <Col xs={4}>
                                            <DescrInput
                                                rows={1}
                                                value={evalComment}
                                                onChangeValue={(e) => {
                                                    this.setState({ [e.target.name]: e.target.value, changedValues: true });
                                                    this.props.onChangeValuesInForms();
                                                    $("#evalComment").removeClass("is-invalid");
                                                }}
                                                labelTxt="Komentarz do oceny"
                                                name="evalComment"
                                                placeholderTxt="Wpisz komentarz"
                                                id="evalComment"
                                            />
                                        </Col>
                                        <Col xs={2} className="text-center">
                                            <Button style={{ marginTop: "2rem" }} variant="primary" onClick={this.handleCheckValidation} disabled={!changedValues ? true : false} className="w-100">
                                                {currentPage.subpage === "editEvaluation" ? "Zatwierdź" : "Oceń"}
                                            </Button>
                                        </Col>
                                    </Fragment>
                                ) : (
                                    <Col className="text-center">
                                        <LoadingProgressBar />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default DoEvaluation;
