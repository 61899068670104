import React, { Fragment } from "react";
import { Alert, Row, Col, Button } from "react-bootstrap";
import $ from "jquery";

var hideAlertTimeout;
const AlertBasic = (props) => {
    const { variant, mssg, onCloseAlert, fading, contactBtn, onShowContactModal, clrTimeout, id } = props;

    if (fading) {
        $(".alert-basic.alert-" + variant)
            .delay(fading)
            .fadeOut(400);
        hideAlertTimeout = setTimeout(() => {
            onCloseAlert();
        }, fading + 400);
    }

    if (clrTimeout) {
        clearTimeout(hideAlertTimeout);
    }

    return (
        <Fragment>
            <Row>
                <Col>
                    <Alert className="alert-basic" variant={variant} onClose={onCloseAlert} dismissible id={id ? id : ""}>
                        {mssg}
                        {contactBtn && (
                            <Button variant={`outline-${variant}`} onClick={onShowContactModal} className="d-block mt-2">
                                Kontakt z administracją
                            </Button>
                        )}
                    </Alert>
                </Col>
            </Row>
        </Fragment>
    );
};

export default AlertBasic;
