import React, { Fragment, Component } from "react";
import EvaluationsList from "../../pages/evaluations/EvaluationsList";
import DoEvaluation from "../../pages/evaluations/DoEvaluation";

class Evaluations extends Component {
    state = {
        selectedSubtestType: "all",
        selectedQuestionCat: "all",
        selectedQuestionType: "all",
        selectedEvalResult: "all",
    };
    render() {
        const { selectedSubtestType, selectedQuestionCat, selectedQuestionType, selectedEvalResult } = this.state;
        const { fetchURL, onChangeValuesInForms, onCancelChangeValuesInForms, onChangeNavAndPage, currentPage, onBackToPreviousPage, onShowAlert, evaluationsList, onStoreDataList, onDownloadApprovalsList, onDownloadQuestionsList } = this.props;

        return (
            <Fragment>
                {currentPage.nav === "evaluations" && (
                    <Fragment>
                        {currentPage.subpage === "evaluationsList" && (
                            <EvaluationsList
                                fetchURL={fetchURL}
                                onChangeNavAndPage={onChangeNavAndPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                evaluationsList={evaluationsList}
                                onStoreDataList={onStoreDataList}
                                onSubtestTypeSelected={(event, value) => this.setState({ selectedSubtestType: value })}
                                onQuestionTypeSelected={(event, value) => this.setState({ selectedQuestionType: value })}
                                onQuestionCatSelected={(event, value) => this.setState({ selectedQuestionCat: value })}
                                onEvaluationResultSelected={(event, value) => this.setState({ selectedEvalResult: value })}
                                selectedSubtestType={selectedSubtestType}
                                selectedQuestionCat={selectedQuestionCat}
                                selectedQuestionType={selectedQuestionType}
                                selectedEvalResult={selectedEvalResult}
                                onResetFilters={() => this.setState({ selectedSubtestType: "all", selectedQuestionCat: "all", selectedQuestionType: "all", selectedEvalResult: "all" })}
                            />
                        )}
                        {(currentPage.subpage === "doEvaluation" || currentPage.subpage === "editEvaluation") && (
                            <DoEvaluation
                                fetchURL={fetchURL}
                                onChangeValuesInForms={onChangeValuesInForms}
                                onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                                onChangeNavAndPage={onChangeNavAndPage}
                                onBackToPreviousPage={onBackToPreviousPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                onDownloadQuestionsList={onDownloadQuestionsList}
                                onDownloadApprovalsList={onDownloadApprovalsList}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Evaluations;
