import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertBasic from "../alerts/AlertBasic";

class QuestionCategories extends Component {
    state = {
        questionCategoriesList: [],
        selectedSubtestType: this.props.subtestType ? this.props.subtestType : null,
        value: this.props.defaultValue ? this.props.defaultValue : null,
    };

    getQuestionCategories = async () => {
        const { fetchURL, defaultValue } = this.props;
        const { selectedSubtestType } = this.state;
        try {
            const response = await fetch(`${fetchURL}/question-categories`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        let questionCatList = fetchedData.questionCatList;
                        if (defaultValue === "all") {
                            questionCatList.unshift({
                                key: 0,
                                value: "all",
                                text: "Wszystkie",
                            });
                        }

                        //jeśli elementy dropdownu muszą zostać wyfiltrowane o wybraną część testu (dla testu PJM są filmiki i obrazki, dla PL są słowa i obrazki)
                        if (selectedSubtestType) {
                            questionCatList = questionCatList.filter(
                                (e) => selectedSubtestType === "PJM" || (selectedSubtestType === "PL" && (e.value === "NOUN" || e.value === "VERB" || e.value === "ADJECTIVE" || e.value === "NUMERAL"))
                            );
                        }

                        this.setState({ questionCategoriesList: questionCatList });
                    } else if (fetchedData.status === false) {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status } });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.getQuestionCategories(), 4000);
        }
    };

    componentDidMount() {
        this.getQuestionCategories();
    }

    componentDidUpdate() {
        if (this.props.defaultValue !== this.state.value) this.setState({ value: this.props.defaultValue });
        if (this.props.subtestType)
            if (this.props.subtestType !== this.state.selectedSubtestType) {
                this.setState({ selectedSubtestType: this.props.subtestType, value: null }, () => this.getQuestionCategories());
            }
    }

    render() {
        const { questionCategoriesList, alert, value } = this.state;
        const { onQuestionCatSelected } = this.props;
        return (
            <Fragment>
                {alert && <AlertBasic variant={alert.variant} mssg={alert.message} onCloseAlert={() => this.setState({ alert: "" })} fading={alert.fading} />}

                <Form.Group>
                    <Form.Label>Kategoria pytania</Form.Label>

                    <Dropdown
                        id="questionCatDropdown"
                        className="mb-3"
                        placeholder="Wszystkie"
                        fluid
                        value={value}
                        selection
                        options={questionCategoriesList}
                        onChange={(event, { value }) => {
                            onQuestionCatSelected(event, value);
                            this.setState({ value: value });
                        }}
                    />
                </Form.Group>
            </Fragment>
        );
    }
}

export default QuestionCategories;
