import React, { Component, Fragment } from "react";
import { Col, Image, Row } from "react-bootstrap";
import Indexes from "./Indexes";
import MainData from "./MainData";
import PJM_PL from "./PJM_PL";
import TypesTable from "./TypesTable";

class Report extends Component {
    render() {
        const { subtestTypeCode } = this.props;
        return (
            <Row id="report">
                <Col>
                    <Row>
                        <Col className="text-center">
                            <Image src="images/logo_TL_PJM_PL_horizontal.svg" className="w-25 mb-5" />
                            <p className="h3 mb-4">RAPORT Z WYNIKÓW PRZEPROWADZONEGO BADANIA</p>
                        </Col>
                    </Row>
                    <MainData basicData={this.props.basicData} subtestTypeCode={subtestTypeCode} />
                    <Row>
                        <Col xs={12}>
                            <hr />
                        </Col>

                        <PJM_PL detailedData={this.props.detailedData} subtestTypeCode={subtestTypeCode} />
                        <Col xs={12}>
                            <hr />
                        </Col>
                        {subtestTypeCode.includes("PJM") && (
                            <Fragment>
                                <TypesTable
                                    title="POLSKI JĘZYK MIGOWY"
                                    subtitle={subtestTypeCode === "PJM" ? " – części mowy i rodzaje zadań" : ""}
                                    number={2}
                                    classStyle="text-primary"
                                    detailedData={this.props.detailedData.filter(
                                        (e) => (e.result_group.includes("PJM_CATEGORY") || e.result_group.includes("PJM_TYPE")) && e.hearing_loss_code === "LOSS"
                                    )}
                                />
                                <Col xs={12}>
                                    <hr />
                                </Col>
                                <Indexes />
                            </Fragment>
                        )}

                        {subtestTypeCode.includes("PL") && (
                            <Fragment>
                                <Col style={{ marginTop: subtestTypeCode === "PL" ? "0rem" : "5rem" }}>
                                    <Row>
                                        <TypesTable
                                            title="JĘZYK POLSKI"
                                            subtitle="(na tle uczniów z wadą słuchu)"
                                            number={subtestTypeCode === "PL" ? 2 : 3}
                                            classStyle="text-secondary"
                                            detailedData={this.props.detailedData.filter(
                                                (e) => (e.result_group.includes("PL_CATEGORY") || e.result_group.includes("PL_TYPE")) && e.hearing_loss_code === "LOSS"
                                            )}
                                        />
                                    </Row>
                                </Col>
                                <Col xs={12}>
                                    <hr />
                                </Col>
                                {subtestTypeCode === "PL" && <Indexes />}
                                <Col style={{ marginTop: subtestTypeCode === "PL" ? "7rem" : "0rem" }}>
                                    <Row>
                                        <TypesTable
                                            title="JĘZYK POLSKI"
                                            subtitle="(na tle uczniów słyszących)"
                                            number={subtestTypeCode === "PL" ? 3 : 4}
                                            classStyle="text-secondary"
                                            detailedData={this.props.detailedData.filter(
                                                (e) => (e.result_group.includes("PL_CATEGORY") || e.result_group.includes("PL_TYPE")) && e.hearing_loss_code === "HEAR"
                                            )}
                                        />
                                    </Row>
                                </Col>
                            </Fragment>
                        )}
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default Report;
