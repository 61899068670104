import React from "react";

const LoadingSpinnerSm = (props) => {
    const { classN } = props;
    return (
        <div className={classN ? `${classN} loading-spinner-sm` : "loading-spinner-sm"}>
            <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
            </div>
        </div>
    );
};

export default LoadingSpinnerSm;
