import React, { Component, Fragment } from "react";
import { Button } from "react-bootstrap";
import $ from "jquery";
class Upload extends Component {
    state = { selectedFile: null, file: null, showFile: false, fileName: null };

    onChangeHandler = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
            file: URL.createObjectURL(event.target.files[0]),
        });
    };

    uploadFile = async (data) => {
        await fetch("https://api.test-leksyka.pl/upload", {
            method: "POST",
            body: data,
        }).then((res) => res.json());
        //console.log(JSON.stringify(res));
    };

    onClickHandler = (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("file", this.state.selectedFile);
        //console.log(data);
        this.uploadFile(data);
    };

    showFile = (fileName) => {
        this.setState({ showFile: fileName });
    };

    uploadTmpFile = (e) => {
        //console.log(e.target);

        if (e.target.files && e.target.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $("#blah").attr("src", e.target.result);
            };

            reader.readAsDataURL(e.target.files[0]); // convert to base64 string
        }
    };

    uploadPreviewFile = async (e) => {
        e.preventDefault();
        const file = e.target.files[0];
        const data = new FormData();
        data.append("file", file);
        try {
            const response = await fetch(`http://localhost:3006/upload-preview-file`, {
                method: "POST",
                body: data,
            });
            //console.log(response);
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    //this.setState({ fileName: fetchedData.name });
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    moveFile = async () => {
        const passData = {
            fileName: this.state.fileName,
        };
        try {
            const response = await fetch(`http://localhost:3006/move-file`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        /* const { showFile } = this.state; */
        return (
            <Fragment>
                {/* <h3 className="my-4">Upload file</h3>
                <form onSubmit={this.onClickHandler}>
                    <input type="file" name="picture" onChange={this.onChangeHandler} />
                    <button type="submit">upload</button>
                </form>
                {this.state.file && <img src={this.state.file} width="200" alt="" />}
                <Button onClick={() => this.setState({ showFile: true })}>Pokaż plik</Button>
                {showFile && (
                    <Fragment>
                        <video controls src={`questions/MVI_1016_1.mp4`}></video>
                        <video controls src={`questions/MVI_1016_1_1.mp4`}></video>
                    </Fragment>
                )} */}
                <h4 className="mb-3">Upload image</h4>
                <input type="file" name="picture" onChange={(e) => this.uploadTmpFile(e)} />
                <img id="blah" src="images/logo192.png" alt="placeholder" />
                {this.state.fileName && (
                    <Fragment>
                        <img src={`tmp-files/${this.state.fileName}`} width="200" alt="" />
                        <Button variant="secondary" onClick={() => this.moveFile()}>
                            Upload
                        </Button>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Upload;
