import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Button, OverlayTrigger, Table, Tooltip } from "react-bootstrap";

const TestDetailsTable = (props) => {
    const { testQADetails, onChangeNavAndPage, currentPageParams } = props;
    return (
        <Fragment>
            <Table bordered hover size="sm" className="table-list mb-0">
                <thead>
                    <tr>
                        <th>L.p.</th>
                        <th>Nazwa pytania</th>
                        <th>Typ pytania</th>
                        <th>Kategoria pytania</th>
                        <th>Wybrana odpowiedź</th>
                        <th>Poprawna odpowiedź</th>
                        <th>Akcje</th>
                    </tr>
                </thead>
                <tbody>
                    {testQADetails.map((p, i) => (
                        <tr key={i}>
                            <td>{i + 1}</td>
                            <td>{p.question_name}</td>
                            <td>{p.question_type_name}</td>
                            <td>{p.question_category_name}</td>
                            <td>
                                <b>
                                    {p.checked_answer ? (
                                        <span className={p.checked_answer === p.correct_answer ? "text-success" : "text-danger"}>{p.checked_answer}</span>
                                    ) : (
                                        <span className="opacity-4">Brak</span>
                                    )}
                                </b>
                            </td>
                            <td>{p.correct_answer}</td>
                            <td>
                                <OverlayTrigger placement="top" overlay={<Tooltip id={`questionDetails${i}`}>Pokaż planszę</Tooltip>}>
                                    <span className="d-inline-block">
                                        <Button
                                            variant="outline-primary"
                                            onClick={() =>
                                                onChangeNavAndPage(
                                                    { nav: "questions", subpage: "boardPreview", params: { questionID: p.question_id, checkedAnswer: p.checked_answer } },
                                                    currentPageParams,
                                                    null
                                                )
                                            }
                                            className="mx-1 btn-list"
                                            size="sm"
                                        >
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                    </span>
                                </OverlayTrigger>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Fragment>
    );
};

export default TestDetailsTable;
