import React, { Component, Fragment } from "react";
import AllTestsList from "../../pages/tests/AllTestsList";
import FinalTestsList from "../../pages/tests/FinalTestsList";
import NormTestsList from "../../pages/tests/NormTestsList";
import PilotageTestsList from "../../pages/tests/PilotageTestsList";
import TestDetails from "../../pages/tests/TestDetails";

class AllTests extends Component {
    state = {
        selectedTestStatus: "all",
        selectedSubtestType: "all",
        selectedHearingLoss: "all",
        selectedProsthesis: "all",
        selectedTestType: "all",
        NOR: 100, //NOR = NumberOfRecords
    };
    render() {
        const {
            fetchURL,
            onBackToPreviousPage,
            onChangeNavAndPage,
            currentPage,
            onShowAlert,
            appParams,
            pilotageTestsList,
            onStoreDataList,
            onStoreTestList,
            normTestsList,
            finalTestsList,
        } = this.props;
        return (
            <Fragment>
                {currentPage.nav === "allTests" && (
                    <Fragment>
                        {currentPage.subpage === "pilotageTestsList" && (
                            <PilotageTestsList
                                fetchURL={fetchURL}
                                onChangeNavAndPage={onChangeNavAndPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                appParams={appParams}
                                pilotageTestsList={pilotageTestsList}
                                onStoreDataList={onStoreDataList}
                                selectedTestStatus={this.state.selectedTestStatus}
                                selectedSubtestType={this.state.selectedSubtestType}
                                selectedHearingLoss={this.state.selectedHearingLoss}
                                selectedProsthesis={this.state.selectedProsthesis}
                                NOR={this.state.NOR}
                                onSelectedTestStatus={(event, value) => this.setState({ selectedTestStatus: value })}
                                onSelectedSubtestType={(event, value) => this.setState({ selectedSubtestType: value })}
                                onSelectedHearingLoss={(event, value) => this.setState({ selectedHearingLoss: value })}
                                onSelectedProsthesis={(event, value) => this.setState({ selectedProsthesis: value })}
                                onChangeNORValue={(event, { value }) => this.setState({ NOR: value })}
                                onResetFilters={() => this.setState({ selectedTestStatus: "all", selectedSubtestType: "all", selectedHearingLoss: "all", selectedProsthesis: "all" })}
                            />
                        )}
                        {currentPage.subpage === "standardizationList" && (
                            <NormTestsList
                                fetchURL={fetchURL}
                                onChangeNavAndPage={onChangeNavAndPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                appParams={appParams}
                                normTestsList={normTestsList}
                                onStoreDataList={this.props.onStoreNormDataList}
                                selectedTestStatus={this.state.selectedTestStatus}
                                selectedSubtestType={this.state.selectedSubtestType}
                                selectedHearingLoss={this.state.selectedHearingLoss}
                                selectedProsthesis={this.state.selectedProsthesis}
                                NOR={this.state.NOR}
                                onSelectedTestStatus={(event, value) => this.setState({ selectedTestStatus: value })}
                                onSelectedSubtestType={(event, value) => this.setState({ selectedSubtestType: value })}
                                onSelectedHearingLoss={(event, value) => this.setState({ selectedHearingLoss: value })}
                                onSelectedProsthesis={(event, value) => this.setState({ selectedProsthesis: value })}
                                onChangeNORValue={(event, { value }) => this.setState({ NOR: value })}
                                onResetFilters={() => this.setState({ selectedTestStatus: "all", selectedSubtestType: "all", selectedHearingLoss: "all", selectedProsthesis: "all" })}
                            />
                        )}
                        {currentPage.subpage === "finalTestsList" && (
                            <FinalTestsList
                                fetchURL={fetchURL}
                                onChangeNavAndPage={onChangeNavAndPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                appParams={appParams}
                                finalTestsList={finalTestsList}
                                onStoreDataList={this.props.onStoreFinalDataList}
                                selectedTestStatus={this.state.selectedTestStatus}
                                selectedSubtestType={this.state.selectedSubtestType}
                                selectedHearingLoss={this.state.selectedHearingLoss}
                                selectedProsthesis={this.state.selectedProsthesis}
                                NOR={this.state.NOR}
                                onSelectedTestStatus={(event, value) => this.setState({ selectedTestStatus: value })}
                                onSelectedSubtestType={(event, value) => this.setState({ selectedSubtestType: value })}
                                onSelectedHearingLoss={(event, value) => this.setState({ selectedHearingLoss: value })}
                                onSelectedProsthesis={(event, value) => this.setState({ selectedProsthesis: value })}
                                onChangeNORValue={(event, { value }) => this.setState({ NOR: value })}
                                onResetFilters={() => this.setState({ selectedTestStatus: "all", selectedSubtestType: "all", selectedHearingLoss: "all", selectedProsthesis: "all" })}
                            />
                        )}
                        {currentPage.subpage === "allTestsList" && (
                            <AllTestsList
                                fetchURL={fetchURL}
                                currentPage={currentPage}
                                onChangeNavAndPage={onChangeNavAndPage}
                                onShowAlert={onShowAlert}
                                onStoreDataList={onStoreTestList}
                                selectedTestStatus={this.state.selectedTestStatus}
                                selectedSubtestType={this.state.selectedSubtestType}
                                selectedHearingLoss={this.state.selectedHearingLoss}
                                selectedProsthesis={this.state.selectedProsthesis}
                                selectedTestType={this.state.selectedTestType}
                                NOR={this.state.NOR}
                                onSelectedTestStatus={(event, value) => this.setState({ selectedTestStatus: value })}
                                onSelectedSubtestType={(event, value) => this.setState({ selectedSubtestType: value })}
                                onSelectedHearingLoss={(event, value) => this.setState({ selectedHearingLoss: value })}
                                onSelectedProsthesis={(event, value) => this.setState({ selectedProsthesis: value })}
                                onSelectedTestType={(event, value) => this.setState({ selectedTestType: value })}
                                onChangeNORValue={(event, { value }) => this.setState({ NOR: value })}
                                onResetFilters={() => this.setState({ selectedTestStatus: "all", selectedSubtestType: "all", selectedHearingLoss: "all", selectedProsthesis: "all" })}
                            />
                        )}
                        {currentPage.subpage === "testDetails" && (
                            <TestDetails
                                fetchURL={fetchURL}
                                onChangeNavAndPage={onChangeNavAndPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                appParams={appParams}
                                pilotageTestsList={pilotageTestsList}
                                onStoreDataList={onStoreDataList}
                                onBackToPreviousPage={onBackToPreviousPage}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AllTests;
