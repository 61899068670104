import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, Tooltip, OverlayTrigger } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import SubtestTypes from "../../forms/SubtestTypes";
import TestTypes from "../../forms/TestTypes";
import RowsDropdown from "../../forms/RowsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import ObjectHearingLoss from "../../forms/ObjectHearingLoss";
import ObjectProsthesis from "../../forms/ObjectProsthesis";
import TestStatuses from "../../forms/TestStatuses";
import TestsListTable from "./TestsListTable";

class AllTestsList extends Component {
    state = {
        showNOR: this.props.NOR, //NOR = NumberOfRecords
        displayNOR: this.props.NOR,
        allTestsList: this.props.allTestsList ? this.props.allTestsList : null,
        originalTests: null,
        searchedPhrase: "",
        loading: !this.props.allTestsList ? true : false,
        selectedTestStatus: this.props.selectedTestStatus,
        selectedSubtestType: this.props.selectedSubtestType,
        selectedTestType: this.props.selectedTestType,
        selectedHearingLoss: this.props.selectedHearingLoss,
        selectedProsthesis: this.props.selectedProsthesis,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalTests } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredTests = originalTests;
            //console.log(filteredTests)
            filteredTests = filteredTests.filter(
                (el) =>
                    (el.question_ident_number && el.question_ident_number.toString().toLowerCase().includes(searchedPhrase)) ||
                    el.user_surname.toString().toLowerCase().includes(searchedPhrase) ||
                    el.user_name.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.user_nickname && el.user_nickname.toString().toLowerCase().includes(searchedPhrase)) //el.user_nickname może być nullem
            );
            this.setState({ allTestsList: filteredTests });
        } else {
            this.setState({ allTestsList: originalTests });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getAllTestsList();
    };

    getAllTestsList = async () => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            testType: "all",
        };
        try {
            const response = await fetch(`${fetchURL}/all-tests-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const allTestsList = fetchedData.testList;
                        this.setState({ allTestsList: allTestsList, originalTests: allTestsList }, () => {
                            this.setState({ loading: false });
                        });
                        this.props.onStoreDataList(allTestsList);
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.getAllTestsList(), 4000);
        }
    };

    componentDidUpdate() {
        if (this.props.NOR !== this.state.showNOR) this.setState({ showNOR: this.props.NOR, displayNOR: this.props.NOR });
        if (this.props.selectedTestStatus !== this.state.selectedTestStatus) this.setState({ selectedTestStatus: this.props.selectedTestStatus });
        if (this.props.selectedSubtestType !== this.state.selectedSubtestType) this.setState({ selectedSubtestType: this.props.selectedSubtestType });
        if (this.props.selectedTestType !== this.state.selectedTestType) this.setState({ selectedTestType: this.props.selectedTestType });
        if (this.props.selectedProsthesis !== this.state.selectedProsthesis) this.setState({ selectedProsthesis: this.props.selectedProsthesis });
        if (this.props.selectedHearingLoss !== this.state.selectedHearingLoss) this.setState({ selectedHearingLoss: this.props.selectedHearingLoss });
    }

    componentDidMount() {
        this.getAllTestsList("PILOT");
    }

    render() {
        const { allTestsList, loading, displayNOR, showNOR, selectedHearingLoss, selectedSubtestType, selectedTestStatus, selectedProsthesis, selectedTestType } = this.state;
        const { fetchURL, onChangeNavAndPage, currentPage, appParams, onShowAlert } = this.props;

        let filteredTests = null;
        if (allTestsList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredTests = allTestsList.filter(
                (e) =>
                    (selectedTestStatus === "all" || e.tests_status_code === selectedTestStatus) &&
                    (selectedSubtestType === "all" || e.subtests_type_code === selectedSubtestType) &&
                    (selectedTestType === "all" || e.test_type_code === selectedTestType) &&
                    (selectedHearingLoss === "all" || e.to_hl_code === selectedHearingLoss) &&
                    (selectedProsthesis === "all" || e.to_prosthesis_code === selectedProsthesis)
            );
        }
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };
        return (
            <Fragment>
                <Container fluid className="second-bar">
                    <Fragment>
                        <Row>
                            <Col className="text-center">
                                <p className="h3 my-4" style={{ lineHeight: "38px" }}>
                                    Lista wszystkich badań
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} md={3} xs={5}>
                                <TestStatuses defaultValue={selectedTestStatus} onSelectedTestStatus={this.props.onSelectedTestStatus} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <TestTypes defaultValue={selectedTestType} onTestTypeSelected={this.props.onSelectedTestType} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <SubtestTypes defaultValue={selectedSubtestType} onSubtestTypeSelected={this.props.onSelectedSubtestType} fetchURL={fetchURL} bothTests />
                            </Col>
                            <Col lg={2} md={3} xs={5}>
                                <ObjectHearingLoss defaultValue={selectedHearingLoss} onSelectedHearingLoss={this.props.onSelectedHearingLoss} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={3} md={4} xs={6}>
                                <ObjectProsthesis defaultValue={selectedProsthesis} onSelectedProsthesis={this.props.onSelectedProsthesis} fetchURL={fetchURL} />
                            </Col>
                            <Col lg={1} md={2} xs={3}>
                                <RowsDropdown onChangeValue={this.props.onChangeNORValue} defaultValue={this.props.NOR} />
                            </Col>

                            <Col lg={2} md={3} xs={12} className="text-left mb-3">
                                <OverlayTrigger key="resetFiltersTop" placement="top" overlay={<Tooltip id="resetFilters">Resetuj filtry</Tooltip>}>
                                    <Button variant="outline-primary" className="mr-1" onClick={() => this.props.onResetFilters()}>
                                        <FontAwesomeIcon icon={faRedoAlt} />
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger key="refreshDataTop" placement="top" overlay={<Tooltip id="refreshData">Odśwież dane</Tooltip>}>
                                    <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync">
                                        <FontAwesomeIcon icon={faSync} />
                                    </Button>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Fragment>
                </Container>
                <Container fluid className="content-body">
                    {loading || !filteredTests ? (
                        <LoadingProgressBar />
                    ) : (
                        <Fragment>
                            {filteredTests.length > 0 ? (
                                <Fragment>
                                    <Row>
                                        <Col>
                                            <p className="mb-3">
                                                Załadowano {displayNOR > filteredTests.length ? filteredTests.length : displayNOR} z {filteredTests.length} badań.
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="table-responsive table-fixed-head">
                                                <TestsListTable
                                                    appParams={appParams}
                                                    testList={filteredTests}
                                                    displayNOR={displayNOR}
                                                    onChangeNavAndPage={onChangeNavAndPage}
                                                    fetchURL={fetchURL}
                                                    onRefreshList={this.handleDownloadData}
                                                    currentPageParams={currentPageParams}
                                                    onShowAlert={onShowAlert}
                                                    testType="all"
                                                />
                                                <div className="light-border"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="rest-info py-3">
                                        <Col>
                                            Załadowano {displayNOR > filteredTests.length ? filteredTests.length : displayNOR} z {filteredTests.length} badań.
                                        </Col>
                                        <Col className="text-center">
                                            {displayNOR >= filteredTests.length ? (
                                                <p className="opacity-4">
                                                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                    <span className="px-3">to wszystko</span>
                                                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                </p>
                                            ) : (
                                                <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                    załaduj więcej
                                                </Button>
                                            )}
                                        </Col>
                                        <Col></Col>
                                    </Row>
                                </Fragment>
                            ) : (
                                <Row>
                                    <Col className="text-center mt-3 font-weight-bold">Lista jest pusta.</Col>
                                </Row>
                            )}
                        </Fragment>
                    )}
                </Container>
            </Fragment>
        );
    }
}

export default AllTestsList;
