import React, { Fragment } from "react";
import { Col, Row } from "react-bootstrap";

const MainData = (props) => {
    const { basicData } = props;
    return (
        <Row>
            <Col xs={12}>
                <hr />
            </Col>
            <Col xs={{ span: 5, offset: 1 }}>
                <p className="mb-1">
                    Imię badanego: <span className="bold">{basicData.to_name}</span>
                </p>
                <p className="mb-1">
                    Rok urodzenia: <span className="bold">{basicData.to_birth_year}</span>
                </p>
                <p className="mb-1">
                    Miesiąc urodzenia: <span className="bold">{basicData.to_birth_month}</span>
                </p>
                <p className="mb-1">
                    Klasa osoby badanej<sup>1</sup>: <span className="bold">{basicData.to_grade_name}</span>
                </p>
                {props.subtestTypeCode.includes("PJM") && (
                    <Fragment>
                        <p className="mb-1">
                            Stopień niedosłuchu: <span className="bold">{basicData.to_hl_name}</span>
                        </p>
                        <p className="mb-1">
                            Protezowanie: <span className="bold">{basicData.to_prosthesis_name}</span>
                        </p>
                    </Fragment>
                )}
            </Col>
            <Col xs={4} className="text-right">
                <p className="mb-1">
                    Rodzaj badania: <span className="bold">{props.subtestTypeCode}</span>
                </p>
                <p className="mb-1">
                    Data badania: <span className="bold">{basicData.test_date}</span>
                </p>
                <p className="mb-1">
                    Diagnosta: <span className="bold">{basicData.diagnostician_fullname}</span>
                </p>
            </Col>
        </Row>
    );
};

export default MainData;
