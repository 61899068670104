import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import AlertNoPermissionList from "../alerts/AlertNoPermissionList";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import $ from "jquery";

class ObjectProsthesis extends Component {
    state = { noPermission: false, prosthesisList: null };

    getProsthesisList = async () => {
        const { fetchURL, onShowAlert, testType, defaultValue } = this.props;
        const passData = {
            testType: testType,
        };
        try {
            const response = await fetch(`${fetchURL}/get-prosthesis-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission") this.setState({ noPermission: true, loading: false });
                    else if (fetchedData.status === false) onShowAlert({ variant: "danger", message: fetchedData.message });
                    else {
                        const prosthesisList = fetchedData.prosthesisList;
                        if (defaultValue === "all") {
                            prosthesisList.unshift({
                                key: 0,
                                value: "all",
                                text: "Wszystkie",
                            });
                        }
                        this.setState({ loading: false, prosthesisList: prosthesisList });
                    }
                });
            }
        } catch (err) {
            this.setState({ alert: { variant: "danger", message: "Nie udało się pobrać danych." } });
            setTimeout(this.getProsthesisList(), 4000);
        }
    };

    componentDidMount() {
        this.getProsthesisList();
    }

    render() {
        const { prosthesisList, noPermission, loading } = this.state;
        const { objectProsthesis, req, classStyle, defaultValue, onSelectedProsthesis } = this.props;
        return (
            <Fragment>
                <Form.Label>Protezowanie {req && <span className="required">*</span>}</Form.Label>
                {noPermission ? (
                    <AlertNoPermissionList />
                ) : loading || !prosthesisList ? (
                    <LoadingProgressBar />
                ) : (
                    <>
                        <Form.Control
                            as="select"
                            placeholder="Kliknij i wybierz"
                            custom
                            className={`form-dropdown mb-3 req ${classStyle}`}
                            onChange={(e) => {
                                onSelectedProsthesis(e, e.target.value);
                            }}
                            onClick={() => $("#objectProsthesis").removeClass("is-invalid")}
                            value={objectProsthesis ? objectProsthesis : defaultValue}
                            id="objectProsthesis"
                        >
                            <option value="default">Kliknij i wybierz</option>
                            {prosthesisList.map((e, i) => (
                                <Fragment key={i}>
                                    <option value={e.value}>{e.text}</option>
                                </Fragment>
                            ))}
                        </Form.Control>
                    </>
                )}
            </Fragment>
        );
    }
}

export default ObjectProsthesis;
