import React, { Fragment, Component } from "react";
import ApprovalsList from "../../pages/approvals/ApprovalsList";
import DoApproval from "../../pages/approvals/DoApproval";

class Approvals extends Component {
    state = {
        selectedSubtestType: "all",
        selectedQuestionCat: "all",
        selectedQuestionType: "all",
        selectedEvalResult: "all",
        selectedApprovalQuestionStatuses: "all",
        NOR: 25, //NOR = NumberOfRecords
    };
    render() {
        const { NOR, selectedSubtestType, selectedQuestionCat, selectedQuestionType, selectedEvalResult, selectedApprovalQuestionStatuses } = this.state;
        const {
            fetchURL,
            onChangeValuesInForms,
            onCancelChangeValuesInForms,
            onBackToPreviousPage,
            onChangeNavAndPage,
            currentPage,
            onShowAlert,
            appParams,
            approvalsList,
            onStoreDataList,
            onDownloadQuestionsList,
        } = this.props;
        return (
            <Fragment>
                {currentPage.nav === "approvals" && (
                    <Fragment>
                        {currentPage.subpage === "approvalsList" && (
                            <ApprovalsList
                                selectedSubtestType={selectedSubtestType}
                                selectedQuestionCat={selectedQuestionCat}
                                selectedQuestionType={selectedQuestionType}
                                selectedEvalResult={selectedEvalResult}
                                selectedApprovalQuestionStatuses={selectedApprovalQuestionStatuses}
                                fetchURL={fetchURL}
                                onChangeNavAndPage={onChangeNavAndPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                appParams={appParams}
                                approvalsList={approvalsList}
                                onStoreDataList={onStoreDataList}
                                NOR={NOR}
                                onApprovalStatusSelected={(event, value) => this.setState({ selectedApprovalQuestionStatuses: value })}
                                onSubtestTypeSelected={(event, value) => this.setState({ selectedSubtestType: value })}
                                onQuestionTypeSelected={(event, value) => this.setState({ selectedQuestionType: value })}
                                onQuestionCatSelected={(event, value) => this.setState({ selectedQuestionCat: value })}
                                onEvaluationResultSelected={(event, value) => this.setState({ selectedEvalResult: value })}
                                onChangeNORValue={(event, { value }) => this.setState({ NOR: value })}
                                onResetFilters={() =>
                                    this.setState({
                                        selectedSubtestType: "all",
                                        selectedQuestionCat: "all",
                                        selectedQuestionType: "all",
                                        selectedEvalResult: "all",
                                        selectedApprovalQuestionStatuses: "all",
                                    })
                                }
                            />
                        )}
                        {currentPage.subpage === "doApproval" && (
                            <DoApproval
                                fetchURL={fetchURL}
                                onChangeValuesInForms={onChangeValuesInForms}
                                onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                                onChangeNavAndPage={onChangeNavAndPage}
                                onBackToPreviousPage={onBackToPreviousPage}
                                currentPage={currentPage}
                                onShowAlert={onShowAlert}
                                onDownloadQuestionsList={onDownloadQuestionsList}
                            />
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Approvals;
