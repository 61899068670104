import React, { Fragment } from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmModal = (props) => {
    const { show, onClose, confirmRoute, onChangeView} = props;
    return (
        <Fragment>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Potwierdzenie opuszczenia widoku</Modal.Title>
                </Modal.Header>
                <Modal.Body>Nastąpiły zmiany w formularzu. Czy jesteś pewien, że chcesz opuścić ten widok?</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={onClose}>
                        Anuluj
                    </Button>
                    <Button variant="primary" type="button" onClick={onChangeView.bind(this, confirmRoute)}>
                        Tak
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ConfirmModal;
