import { faImage, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Alert, Button, Col, Form, Row, Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import LoadingProgressBar from "../spinners/LoadingProgressBar";
import $ from "jquery";
import LoadingSpinnerSm from "../spinners/LoadingSpinnerSm";

class QItemFile extends Component {
    state = {
        showChooseExistingQItemModal: false,
        alert: "",
        alertNoElementsInList: "",
        loading: true,
        filesDropdown: null,
        chosenFileID: null,
        tooBigFile: false,
        loadingBtn: false,
        showZoomImgModal: false,
        resetFileData: false,
        finishedErasing: false,
        fileExists: null,
    };

    getExistingFile = async (fileType) => {
        const { fetchURL } = this.props;
        this.setState({ alert: "", alertNoElementsInList: "", loading: true });
        const passData = {
            fileType: fileType,
        };
        try {
            const response = await fetch(`${fetchURL}/files-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        this.setState({ loading: false, filesDropdown: fetchedData.filesDropdown });
                    } else if (fetchedData.status === "warning") {
                        this.setState({ alertNoElementsInList: fetchedData.message, loading: false });
                    } else {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message }, loading: false });
                    }
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status }, loading: false });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.getExistingFile(fileType), 4000);
        }
    };

    getChosenFileDetails = async (fileID) => {
        const { fetchURL } = this.props;
        this.setState({ alert: "", alertNoElementsInList: "", loading: true });
        const passData = {
            fileID: fileID,
        };
        try {
            const response = await fetch(`${fetchURL}/get-file-details`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const fileData = fetchedData.fileData;
                        this.props.onSelectedFile(fileData, "existing");

                        this.setState({ showChooseExistingQItemModal: false, loadingBtn: false });
                        $(`#QItemFile${this.props.id} .file-placeholder`).removeClass("is-invalid");
                    } else {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status }, loading: false });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.getChosenFileDetails(fileID), 4000);
        }
    };

    checkIfFileExists = async (fileName) => {
        const { fetchURL } = this.props;
        const passData = {
            fileName: fileName,
        };
        try {
            const response = await fetch(`${fetchURL}/check-if-file-exists`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    this.setState({ fileExists: fetchedData.fileExists }, () => {
                        if (fetchedData.fileExists === false) {
                            //zablokuj edycję formularza, jeśli choć jeden plik nie istnieje w filesystemie
                            this.props.onNoExistingFile();
                        }
                    });
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status }, loading: false });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.checkIfFileExists(fileName), 4000);
        }
    };

    handleUploadNewFile = (event) => {
        const newFile = event.target.files[0];
        const { id } = this.props;

        if (newFile) {
            /* 16777216 - 16 MB */
            if (newFile.size <= 16777216) {
                this.setState({ tooBigFile: false });
                if (this.props.fileType === "IMAGE") {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        $(`.newFile${id}`).attr("src", e.target.result);
                    };
                    reader.readAsDataURL(newFile);
                }
                this.props.onSelectedFile(newFile, "new");
            } else {
                this.setState({ tooBigFile: true });
            }
        }
    };

    handleSelectChosenFile = () => {
        this.setState({ loadingBtn: true, fileExists: true }, () => this.getChosenFileDetails(this.state.chosenFileID));
    };

    componentDidUpdate() {
        const { id, subtestType, qItemDetails } = this.props;
        if (subtestType !== this.state.selectedSubtestType) {
            this.setState({ selectedSubtestType: subtestType, value: null }, () => this.getQuestionTypes());
        }
        if (this.state.showZoomImgModal) $(".zoom-image-modal .modal-dialog img").css("max-height", $(window).innerHeight() - 150);

        //brak kontrolek do otwarzania podglądu
        if (document.getElementById(`vidPrev${id}`)) document.getElementById(`vidPrev${id}`).controls = false;
        //brak kontrolek do otwarzania podglądu (z wyjątkiem szczegółów pytania)
        if (document.getElementById(`video${id}`) && !qItemDetails) document.getElementById(`video${id}`).controls = false;
    }

    componentDidMount() {
        if (this.props.selectedExistingFile) {
            this.checkIfFileExists(this.props.selectedExistingFile[0].qitem_file_name);
        }
    }

    render() {
        const { showChooseExistingQItemModal, alert, loading, filesDropdown, alertNoElementsInList, tooBigFile } = this.state;
        const { fileType, selectedExistingFile, id, selectedNewFile, defaultValue } = this.props;

        return (
            <Fragment>
                <Row className="mb-3">
                    <Col xs={12} id={`QItemFile${id}`} style={{ height: "11rem" }}>
                        {selectedExistingFile && selectedExistingFile !== "default" ? (
                            <div className="loaded-file">
                                {this.state.fileExists ? (
                                    fileType === "IMAGE" ? (
                                        <>
                                            <img src={`questions/${selectedExistingFile[0].qitem_file_name}`} alt={`${selectedExistingFile[0].qitem_file_name}`} />
                                            {/* <div className="loaded-file-zoom" onClick={() => this.setState({ showZoomImgModal: true })}>
                                                <div className="loaded-file-zoom-icon">
                                                    <FontAwesomeIcon icon={faSearchPlus} />
                                                </div>
                                            </div> */}
                                        </>
                                    ) : (
                                        <video controls src={`questions/${selectedExistingFile[0].qitem_file_name}`} id={`video${id}`}></video>
                                    )
                                ) : (
                                    <span className="p-2">Plik nie istnieje</span>
                                )}
                            </div>
                        ) : selectedNewFile ? (
                            <div className="loaded-file">
                                {fileType === "IMAGE" ? (
                                    <>
                                        <img src="img-loader.gif" className={`newFile${id}`} alt={selectedNewFile.name} />
                                        {/* <div className="loaded-file-zoom" onClick={() => this.setState({ showZoomImgModal: true })}>
                                            <div className="loaded-file-zoom-icon">
                                                <FontAwesomeIcon icon={faSearchPlus} />
                                            </div>
                                        </div> */}
                                    </>
                                ) : (
                                    <video width="100%" controls id={`vidPrev${id}`}>
                                        <source src={URL.createObjectURL(selectedNewFile)} id={`video${id}`} type="video/mp4" />
                                        Your browser does not support HTML5 video.
                                    </video>
                                )}
                            </div>
                        ) : (
                            <div className="file-placeholder">
                                <div className="center">
                                    <FontAwesomeIcon icon={fileType === "VIDEO" ? faVideo : faImage} />
                                </div>
                            </div>
                        )}
                        {!this.props.qItemDetails && ( //pokaż przyciski do załadowania nowego Question Item
                            <div className="qitem-file">
                                <p className="w-100 pb-1">
                                    {selectedExistingFile && selectedExistingFile !== "default" ? (
                                        selectedExistingFile[0].qitem_file_name
                                    ) : selectedNewFile ? (
                                        selectedNewFile.name
                                    ) : (
                                        <span className="opacity-4">Brak wybranego pliku</span>
                                    )}
                                </p>
                                <div className="qitem-file-buttons">
                                    <Button
                                        variant="outline-primary"
                                        onClick={() => {
                                            this.setState({ showChooseExistingQItemModal: true });
                                            this.getExistingFile(fileType);
                                        }}
                                        className="w-100 mb-2"
                                    >
                                        Wybierz istniejący plik
                                    </Button>

                                    <div className="input-group mb-3">
                                        <div className="choose-file">
                                            <input
                                                type="file"
                                                className="choose-file-input btn-outline-secondary"
                                                id={`chooseFile${id}`}
                                                name="qitem_file"
                                                onChange={this.handleUploadNewFile}
                                                accept={fileType === "IMAGE" ? "image/x-png,image/jpeg" : "video/mp4"}
                                            />
                                            <label className="choose-file-label btn btn-outline-secondary" htmlFor={`chooseFile${id}`} aria-describedby="chooseFileAddon">
                                                Wybierz nowy plik
                                            </label>
                                        </div>
                                    </div>

                                    {tooBigFile && <Alert variant="danger">Plik nie może ważyć więcej niż 16MB.</Alert>}
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>

                {/* {showZoomImgModal && (
                    <Modal show={showZoomImgModal} onHide={() => this.setState({ showZoomImgModal: false })} className="zoom-image-modal">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            {selectedNewFile ? (
                                <img src="img-loader.gif" className={`newFile${id}`} alt={selectedNewFile.name} />
                            ) : (
                                <img src={`data:${selectedExistingFile[0].qitem_file_type};base64,${selectedExistingFile[0].bufferBase64}`} alt={`${selectedExistingFile[0].qitem_file_name}`} />
                            )}
                        </Modal.Body>
                    </Modal>
                )} */}

                {showChooseExistingQItemModal && (
                    <Modal show={showChooseExistingQItemModal} onHide={() => this.setState({ showChooseExistingQItemModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Wybierz istniejący plik</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Wybierz plik, który został wcześniej załadowany do bazy danych.</p>
                            {alert && (
                                <Alert variant={alert.variant} onClose={() => this.setState({ alert: "" })} dismissible>
                                    {alert.message}
                                </Alert>
                            )}
                            {loading ? (
                                <LoadingProgressBar />
                            ) : (
                                <Fragment>
                                    {alertNoElementsInList ? (
                                        <Alert variant="warning">{alertNoElementsInList}</Alert>
                                    ) : (
                                        <Form.Group>
                                            <Form.Label>Lista plików</Form.Label>
                                            <Dropdown
                                                defaultValue={defaultValue}
                                                id="chooseExistingFileDropdown"
                                                className="mb-3"
                                                placeholder="Kliknij, aby wybrać plik"
                                                fluid
                                                selection
                                                options={filesDropdown}
                                                onChange={(event, { value }) => {
                                                    this.setState({ chosenFileID: value });
                                                }}
                                            />
                                        </Form.Group>
                                    )}
                                </Fragment>
                            )}
                        </Modal.Body>
                        {!alertNoElementsInList && !alert && (
                            <Modal.Footer>
                                {this.state.chosenFileID ? (
                                    <Button variant="primary" type="button" onClick={this.handleSelectChosenFile}>
                                        {this.state.loadingBtn ? <LoadingSpinnerSm /> : "Wybierz"}
                                    </Button>
                                ) : (
                                    <Button variant="primary" disabled>
                                        Wybierz
                                    </Button>
                                )}
                            </Modal.Footer>
                        )}
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default QItemFile;
