import React, { Fragment, Component } from "react";
import queryString from "query-string";
import { Container, Row, Col, Image, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

class ConfirmAccount extends Component {
    state = {
        alert: "",
    };

    confirmAccount = async (passData) => {
        this.setState({ alert: "" });
        //console.log(passData);
        const { fetchURL } = this.props;
        try {
            const response = await fetch(`${fetchURL}/confirm-account`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((data) => {
                    //console.log(data);
                    if (data.status === true) {
                        this.setState({ alert: { variant: "success", message: data.message } });
                    } else {
                        this.setState({ alert: { variant: "danger", message: data.message } });
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.confirmAccount(passData), 4000);
        }
    };

    componentDidMount() {
        const { location } = this.props;
        //console.log(location);
        const parsedLocationSearch = queryString.parse(location.search);
        if (location.search === "" || parsedLocationSearch.url === null) {
            this.setState({
                alert: { variant: "danger", message: "Link do aktywacji hasła jest nieprawidłowy. Jeśli próbujesz aktywować konto, a pojawia się ten komunikat, skontaktuj się z nami." },
            });
        } else {
            const postDataURL = {
                url: `${location.pathname}${location.search}`,
                pathname: `${location.pathname}`,
                generatedString: parsedLocationSearch.url,
            };
            this.confirmAccount(postDataURL);
        }
    }
    render() {
        const { alert } = this.state;
        return (
            <Fragment>
                <Container>
                    <Row className="mt-5">
                        <Col lg={{ span: 6, offset: 3 }} className="text-center">
                            <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="my-3" style={{ height: "16rem" }} />
                        </Col>
                        <Col lg={{ span: 6, offset: 3 }}>{alert && <Alert variant={alert.variant}>{alert.message}</Alert>}</Col>

                        <Col xs={12} className="text-center">
                            <Link className="btn btn-outline-secondary" to="/">
                                wróć na stronę główną
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default ConfirmAccount;
