import React, { Fragment } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

const TooltipWithDisabledButton = (props) => {
    const { mssgTooltip, btnTxt, tooltipID, size, variant, className } = props;
    return (
        <Fragment>
            <OverlayTrigger overlay={<Tooltip id={tooltipID}>{mssgTooltip}</Tooltip>}>
                <span className={className ? "" : "d-inline-block"}>
                    <Button disabled={true} variant={variant ? variant : "primary"} size={size} style={{ pointerEvents: "none" }} className={className ? className : ""}>
                        {btnTxt}
                    </Button>
                </span>
            </OverlayTrigger>
        </Fragment>
    );
};

export default TooltipWithDisabledButton;
