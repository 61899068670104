import React from "react";

const LoadingProgressBar = () => {
    return (
        <div className="text-center">
            <p className="loading-data">ładuję dane</p>
            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div>
    );
};

export default LoadingProgressBar;
