import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, OverlayTrigger, Tooltip } from "react-bootstrap";
import EvaluationsListTable from "./EvaluationsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import QuestionCategories from "./../../forms/QuestionCategories";
import SubtestTypes from "../../forms/SubtestTypes";
import QuestionTypes from "../../forms/QuestionTypes";
import EvaluationResults from "../../forms/EvaluationResults";
//import RowsDropdown from "../../forms/RowsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import NotAllowed from "../../other/NotAllowed";

class EvaluationsList extends Component {
    state = {
        showNOR: 25, //NOR = NumberOfRecords
        displayNOR: 25,
        evaluationsList: this.props.evaluationsList ? this.props.evaluationsList : null,
        originalEvaluations: null,
        searchedPhrase: "",
        loading: this.props.evaluationsList ? false : true,

        disallowPermission: false,
    };

    handleSearch = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();
        const { originalEvaluations } = this.state;
        this.setState({ searchedPhrase: searchedPhrase });
        if (searchedPhrase !== "") {
            let filteredEvaluations = originalEvaluations;
            //console.log(filteredEvaluations)
            filteredEvaluations = filteredEvaluations.filter(
                (el) =>
                    (el.evaluation_ident_number && el.evaluation_ident_number.toString().toLowerCase().includes(searchedPhrase)) ||
                    el.user_surname.toString().toLowerCase().includes(searchedPhrase) ||
                    el.user_name.toString().toLowerCase().includes(searchedPhrase) ||
                    (el.user_nickname && el.user_nickname.toString().toLowerCase().includes(searchedPhrase)) //el.user_nickname może być nullem
            );
            this.setState({ evaluationsList: filteredEvaluations });
        } else {
            this.setState({ evaluationsList: originalEvaluations });
        }
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getEvaluationsList();
    };

    getEvaluationsList = async () => {
        const { fetchURL, onShowAlert } = this.props;

        try {
            const response = await fetch(`${fetchURL}/evaluations-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ disallowPermission: true, loading: false });
                    } else if (fetchedData.status === true) {
                        const evaluationsList = fetchedData.evaluationsList;
                        evaluationsList.forEach((e) => {
                            e.eval_result_code = e.eval_result_code ? e.eval_result_code : "NONE";
                        });
                        this.setState(
                            {
                                evaluationsList: evaluationsList,
                                originalEvaluations: evaluationsList,
                            },
                            () => {
                                this.setState({ loading: false });
                            }
                        );
                        this.props.onStoreDataList(evaluationsList);
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getEvaluationsList, 4000);
        }
    };

    componentDidMount() {
        if (!this.props.evaluationsList) this.getEvaluationsList();
    }

    render() {
        const { evaluationsList, loading, displayNOR, showNOR, disallowPermission } = this.state;
        const {
            fetchURL,
            onChangeNavAndPage,
            selectedSubtestType,
            selectedQuestionCat,
            selectedQuestionType,
            selectedEvalResult,
            onSubtestTypeSelected,
            onQuestionTypeSelected,
            onQuestionCatSelected,
            onEvaluationResultSelected,
            onResetFilters,
        } = this.props;

        let filteredEvaluationList = null;
        if (evaluationsList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredEvaluationList = evaluationsList.filter(
                (e) =>
                    (selectedQuestionType === "all" || e.question_type_code === selectedQuestionType) &&
                    (selectedSubtestType === "all" || e.subtest_type_code === selectedSubtestType) &&
                    (selectedQuestionCat === "all" || e.question_category_code === selectedQuestionCat) &&
                    (selectedEvalResult === "all" || e.eval_result_code === selectedEvalResult)
            );
        }

        return (
            <Fragment>
                {disallowPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        <Container fluid className="second-bar">
                            <Row>
                                <Col></Col>
                                <Col className="text-center">
                                    <p className="h3 my-4" style={{ lineHeight: "38px" }}>
                                        Ocenianie pytań
                                    </p>
                                </Col>
                                <Col className="text-right">
                                    <Button variant="outline-primary" className="mx-1 my-4" onClick={() => window.open(`/test-preview?testType=PILOT&subtestType=PJM`, "_blank")}>
                                        Podgląd testu PJM
                                    </Button>
                                    <Button variant="outline-secondary" className="mx-1 my-4" onClick={() => window.open(`/test-preview?testType=PILOT&subtestType=PL`, "_blank")}>
                                        Podgląd testu PL
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={2} md={3} xs={5}>
                                    <SubtestTypes defaultValue={selectedSubtestType} onSubtestTypeSelected={onSubtestTypeSelected} fetchURL={fetchURL} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <QuestionTypes defaultValue={selectedQuestionType} onQuestionTypeSelected={onQuestionTypeSelected} fetchURL={fetchURL} />
                                </Col>

                                <Col lg={2} md={3} xs={5}>
                                    <QuestionCategories defaultValue={selectedQuestionCat} onQuestionCatSelected={onQuestionCatSelected} fetchURL={fetchURL} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <EvaluationResults defaultValue={selectedEvalResult} onEvaluationResultSelected={onEvaluationResultSelected} fetchURL={fetchURL} labelTxt="Kwalifikacje pytań" />
                                </Col>

                                <Col lg={{ span: 2, offset: 2 }} xs={12} className="text-left text-lg-right mt-lg-3 mt-lg-0 mb-3">
                                    <OverlayTrigger key="resetFiltersTop" placement="top" overlay={<Tooltip id="resetFilters">Resetuj filtry</Tooltip>}>
                                        <Button variant="outline-primary" className="mr-1" onClick={() => onResetFilters()}>
                                            <FontAwesomeIcon icon={faRedoAlt} />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger key="refreshDataTop" placement="top" overlay={<Tooltip id="refreshData">Odśwież dane</Tooltip>}>
                                        <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync">
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading || !filteredEvaluationList ? (
                                <LoadingProgressBar />
                            ) : (
                                <Fragment>
                                    {filteredEvaluationList.length > 0 ? (
                                        <Fragment>
                                            <Row>
                                                <Col>
                                                    <div className="table-responsive table-fixed-head">
                                                        <EvaluationsListTable
                                                            evaluationsList={filteredEvaluationList}
                                                            displayNOR={displayNOR}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            fetchURL={fetchURL}
                                                            onRefreshList={this.handleDownloadData}
                                                        />
                                                        <div className="light-border"></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="rest-info py-3">
                                                <Col>
                                                    Załadowano {displayNOR > filteredEvaluationList.length ? filteredEvaluationList.length : displayNOR} z {filteredEvaluationList.length} pytań.
                                                </Col>
                                                <Col className="text-center">
                                                    {displayNOR >= filteredEvaluationList.length ? (
                                                        <p className="opacity-4">
                                                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                            <span className="px-3">to wszystko</span>
                                                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                        </p>
                                                    ) : (
                                                        <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                            załaduj więcej
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </Fragment>
                                    ) : (
                                        <Row>
                                            <Col className="text-center mt-3 font-weight-bold">Lista jest pusta.</Col>
                                        </Row>
                                    )}
                                </Fragment>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default EvaluationsList;
