import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertBasic from "../alerts/AlertBasic";

class TestTypes extends Component {
    state = { testTypeList: [] };

    getTestTypes = async () => {
        const { fetchURL, defaultValue } = this.props;
        try {
            const response = await fetch(`${fetchURL}/test-types`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const testTypeList = fetchedData.testTypes;
                        if (defaultValue === "all") {
                            testTypeList.unshift({
                                key: 0,
                                value: "all",
                                text: "Wszystkie",
                            });
                        }

                        this.setState({ testTypeList: testTypeList });
                    } else if (fetchedData.status === false) {
                        this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                    }
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status } });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            setTimeout(this.getTestTypes(), 4000);
        }
    };

    componentDidMount() {
        this.getTestTypes();
    }

    render() {
        const { testTypeList, alert } = this.state;
        const { defaultValue, onTestTypeSelected, req, id } = this.props;

        return (
            <Fragment>
                {alert && <AlertBasic variant={alert.variant} mssg={alert.message} onCloseAlert={() => this.setState({ alert: "" })} fading={alert.fading} />}

                <Form.Group>
                    <Form.Label>Typ testu {req && <span className="required">*</span>}</Form.Label>

                    <Dropdown
                        value={defaultValue}
                        id={id ? id : "questionTestTypesDropdown"}
                        className={req ? "form-dropdown mb-3 req" : "form-dropdown mb-3"}
                        placeholder="Wszystkie"
                        fluid
                        selection
                        options={testTypeList}
                        onChange={(event, { value }) => {
                            onTestTypeSelected(event, value);
                        }}
                    />
                </Form.Group>
            </Fragment>
        );
    }
}

export default TestTypes;
