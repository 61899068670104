import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Main from "./components/structure/Main";
import ResetPass from "./components/pages/ResetPass";
import ConfirmAccount from "./components/pages/ConfirmAccount";
import NoMatch from "./components/pages/NoMatch";
import Test from "./components/pages/tests/Test";
import Upload from "./components/Upload";
import $ from "jquery";
import { Image } from "react-bootstrap";

class App extends Component {
    render() {
        //const url = "http://localhost:3006";
        const url = "https://api.test-leksyka.pl";

        //const frontURL = "http://localhost:3000";
        const frontURL = "https://test-leksyka.pl";

        // Firefox 1.0+
        var isFirefox = typeof InstallTrigger !== "undefined";

        // Safari 3.0+ "[object HTMLElementConstructor]"
        var isSafari =
            /constructor/i.test(window.HTMLElement) ||
            (function (p) {
                return p.toString() === "[object SafariRemoteNotification]";
            })(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification));

        const height = $(window).height();
        const width = $(window).width();

        return (
            <Fragment>
                {isFirefox || isSafari ? (
                    <div style={{ position: "absolute", top: 0, left: 0, width: width, height: height, backgroundColor: "white" }}>
                        <div style={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%, -50%)" }} className="text-center">
                            <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="mb-5" style={{ height: "12rem" }} alt="logo testu leksykalnego pjm pl" />
                            <h1>Nieobsługiwana przeglądarka</h1>
                            <p className="m3 mt-4" style={{ fontSize: "1.5rem" }}>
                                Aplikacja Test Leksyka nie jest w pełni obsługiwana przez {isFirefox ? "przeglądarkę Firefox" : isSafari ? "przeglądarkę Safari" : "tę przeglądarkę"}. Prosimy o
                                skorzystanie z innej przeglądarki celem poprawnego uruchomienia aplikacji.
                            </p>
                            <p style={{ fontSize: "1rem" }}>(zalecane przeglądarki: Chrome, Opera, Microsoft Edge)</p>
                        </div>
                    </div>
                ) : (
                    <Router>
                        <Switch>
                            <Route exact path="/" component={() => <Main fetchURL={url} />} />
                            <Route path="/reset-pass">
                                <Route path="/:url" render={(routeProps) => <ResetPass fetchURL={url} {...routeProps} />} />
                            </Route>
                            <Route path="/reset-pass-blocked-account">
                                <Route path="/:url" render={(routeProps) => <ResetPass fetchURL={url} {...routeProps} />} />
                            </Route>
                            <Route path="/created-user">
                                <Route path="/:url" render={(routeProps) => <ResetPass fetchURL={url} {...routeProps} />} />
                            </Route>

                            <Route path="/confirm">
                                <Route path="/:url" render={(routeProps) => <ConfirmAccount fetchURL={url} {...routeProps} />} />
                            </Route>
                            <Route path="/test">
                                <Route path="/:url" component={(routeProps) => <Test fetchURL={url} {...routeProps} frontURL={frontURL} />} />
                            </Route>
                            <Route path="/test-upload">
                                <Route path="/:url" component={() => <Upload fetchURL={url} />} />
                            </Route>
                            <Route path="/test-preview">
                                <Route path="/:url" component={(routeProps) => <Test fetchURL={url} {...routeProps} frontURL={frontURL} />} />
                            </Route>

                            <Route component={NoMatch} />
                        </Switch>
                    </Router>
                )}
            </Fragment>
        );
    }
}

export default App;
