import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Container, Row, Col, Modal, Button, Dropdown, Table } from "react-bootstrap";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import LoadingSpinnerSm from "../../spinners/LoadingSpinnerSm";

class QuestionDetails extends Component {
    state = {
        showDeleteQuestionModal: false,
        questionData: null,
        questionAndAnswers: [],
        loadingQuestion: true,
        loadingQItems: true,
    };

    getQItemData = async (qItemID, itemIndex, item) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            qItemID: qItemID,
        };
        try {
            const url = `${fetchURL}/question-item-details`;
            const response = await fetch(url, {
                credentials: "include",
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const qItemData = fetchedData.qItemData;

                        /*  if (qItemData[0].qitem_type_code !== "WORD") {
                            const blob = qItemData[0].qitem_blob;
                            const buffer = Buffer.from(blob);
                            const bufferBase64 = buffer.toString("base64");
                            qItemData[0].bufferBase64 = bufferBase64;
                        } */

                        const { questionAndAnswers } = this.state;
                        const list = [...questionAndAnswers];
                        list[itemIndex] = qItemData[0];
                        this.setState({ questionAndAnswers: list });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loadingQItems: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loadingQItems: false });
            setTimeout(this.getQItemData(qItemID, itemIndex, item), 4000);
        }
    };

    getQuestionData = async (questionID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            questionID: questionID,
        };
        try {
            const response = await fetch(`${fetchURL}/question-details`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const questionDetails = fetchedData.questionDetails;
                        this.setState({ questionData: questionDetails, loadingQuestion: false }, () => this.getAllQItemData(questionDetails));
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych. " + err });
            this.setState({ loadingQuestion: false });
            setTimeout(this.getQuestionData(questionID), 4000);
        }
    };

    deleteQuestion = async () => {
        const { fetchURL, onChangeNavAndPage, onShowAlert } = this.props;
        onShowAlert({ variant: "info", message: "Usuwam pytanie..." });
        this.setState({ showDeleteQuestionModal: false });
        const passData = {
            questionID: this.state.questionData[0].question_id,
        };
        try {
            const response = await fetch(`${fetchURL}/delete-question`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onShowAlert({ variant: "success", message: `Pomyślnie usunięto pytanie ${this.state.questionData[0].question_name}.`, fading: 2000 });
                        onChangeNavAndPage({ nav: "questions", subpage: "questionsList", params: "" }, null);
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            setTimeout(this.deleteQuestion(), 4000);
        }
    };

    getAllQItemData = async (questionDetails) => {
        try {
            await this.getQItemData(questionDetails[0].question_qitem_id, 0, "question");
            await this.getQItemData(questionDetails[0].answer_a_qitem_id, 1, "questionAndAnswers");
            await this.getQItemData(questionDetails[0].answer_b_qitem_id, 2, "questionAndAnswers");
            await this.getQItemData(questionDetails[0].answer_c_qitem_id, 3, "questionAndAnswers");
            await this.getQItemData(questionDetails[0].answer_d_qitem_id, 4, "questionAndAnswers");
            this.setState({ loadingQItems: false });
        } catch (err) {
            console.log(err);
        }
    };

    componentDidMount() {
        this.getQuestionData(this.props.currentPage.params.questionID);
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { showDeleteQuestionModal, questionData, loadingQuestion, questionAndAnswers } = this.state;
        const { onBackToPreviousPage, currentPage, onChangeNavAndPage } = this.props;
        const currentPageParams = { nav: currentPage.nav, subpage: currentPage.subpage, params: currentPage.params };

        return (
            <Fragment>
                <Container fluid>
                    <Row className="py-3">
                        <NavDetails header="Szczegóły pytania" onChangeView={() => this.setState({ loading: false }, () => onBackToPreviousPage())} />
                        <Col className="text-right">
                            {loadingQuestion ? (
                                <div className="float-right">
                                    <LoadingSpinnerSm />
                                </div>
                            ) : (
                                (questionData[0].question_status_code === "NEW" || questionData[0].question_status_code === "EVALUATE") && (
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            Akcje
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                as="button"
                                                onClick={() =>
                                                    onChangeNavAndPage({ nav: "questions", subpage: "editQuestion", params: { questionID: questionData[0].question_id } }, currentPageParams, null)
                                                }
                                            >
                                                Edytuj
                                            </Dropdown.Item>
                                            {questionData[0].question_status_code === "NEW" && (
                                                <Dropdown.Item as="button" onClick={() => this.setState({ showDeleteQuestionModal: true })}>
                                                    Usuń
                                                </Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )
                            )}
                        </Col>
                    </Row>
                </Container>
                <Container>
                    {!loadingQuestion && questionData ? (
                        <Fragment>
                            <Row>
                                <Col xs={12}>
                                    <p className="h4 mb-3">Dane</p>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>Nazwa pytania</td>
                                                <td>{questionData[0].question_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Część testu</td>
                                                <td>{questionData[0].subtest_type_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Typ pytania</td>
                                                <td>{questionData[0].question_type_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Kategoria pytania</td>
                                                <td>{questionData[0].question_category_name}</td>
                                            </tr>
                                            <tr>
                                                <td>Status pytania</td>
                                                <td>
                                                    <span
                                                        className={
                                                            questionData[0].question_status_code === "EVALUATE"
                                                                ? "text-info"
                                                                : questionData[0].question_status_code === "ACCEPTED"
                                                                ? "text-success"
                                                                : questionData[0].question_status_code === "REJECTED" || questionData[0].question_status_code === "REJECTED"
                                                                ? "text-danger"
                                                                : ""
                                                        }
                                                    >
                                                        {questionData[0].question_status_name}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Trudność pytania</td>
                                                <td>
                                                    {questionData[0].eval_diff_code === 1 ? (
                                                        "Łatwe pytanie"
                                                    ) : questionData[0].eval_diff_code === 2 ? (
                                                        "Średnie pytanie"
                                                    ) : questionData[0].eval_diff_code === 3 ? (
                                                        "Trudne pytanie"
                                                    ) : (
                                                        <span className="opacity-4">brak</span>
                                                    )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Data utworzenia</td>
                                                <td>{questionData[0].create_date}</td>
                                            </tr>
                                            <tr>
                                                <td>Autor pytania</td>
                                                <td>{questionData[0].create_user_fullname}</td>
                                            </tr>
                                            <tr>
                                                <td>Data modyfikacji</td>
                                                <td>{questionData[0].modify_date ? questionData[0].modify_date : <span className="opacity-4">brak</span>}</td>
                                            </tr>
                                            <tr>
                                                <td>Edytor pytania</td>
                                                <td>{questionData[0].modify_user_fullname ? questionData[0].modify_user_fullname : <span className="opacity-4">brak</span>}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col lg={6} xs={12}>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>Komentarz dla sędziego</td>
                                                <td>{questionData[0].question_comment ? questionData[0].question_comment : <span className="opacity-4">brak</span>}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Fragment>
                    ) : (
                        <LoadingProgressBar />
                    )}
                    <Row>
                        <Col xs={12}>
                            <p className="h4 my-3">Plansza</p>
                        </Col>
                        <Col xs={12} lg={6}>
                            {questionAndAnswers.map((e, i) => (
                                <Fragment key={i}>
                                    <p className="mb-2">
                                        Nazwa {i === 0 ? "pytania" : i === 1 ? "odpowiedzi A" : i === 2 ? "odpowiedzi B" : i === 3 ? "odpowiedzi C" : "odpowiedzi D"}:{" "}
                                        <b>{e.qitem_file_name ? e.qitem_file_name : e.qitem_string.toUpperCase()}</b>
                                    </p>
                                </Fragment>
                            ))}
                        </Col>
                        <Col xs={12} lg={6}>
                            <Button
                                variant="primary"
                                className="my-3 mt-lg-0"
                                onClick={() => onChangeNavAndPage({ nav: "questions", subpage: "boardPreview", params: { questionID: questionData[0].question_id } }, currentPageParams, null)}
                            >
                                Pokaż widok planszy
                            </Button>
                        </Col>
                    </Row>
                </Container>
                {showDeleteQuestionModal && (
                    <Modal show={showDeleteQuestionModal} onHide={() => this.setState({ showDeleteQuestionModal: false })}>
                        <Modal.Header closeButton>
                            <Modal.Title>Usunięcie pytania</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Czy na pewno chcesz usunąć pytanie {questionData[0].question_name}?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="outline-secondary" type="button" onClick={() => this.deleteQuestion()}>
                                Tak, usuń
                            </Button>
                            <Button variant="primary" type="button" onClick={() => this.setState({ showDeleteQuestionModal: false })}>
                                Nie, anuluj
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default QuestionDetails;
