import React, { Fragment } from "react";
import UsersList from "../../pages/users/UsersList";
import AddEditUser from "../../pages/users/AddEditUser";
import UserDetails from "../../pages/users/UserDetails";
const Users = (props) => {
    const { fetchURL, onChangeValuesInForms, onCancelChangeValuesInForms, currentPage, onShowAlert, onChangeNavAndPage, onBackToPreviousPage, appParams, usersList, onStoreDataList, onEraseUsersList } = props;
    return (
        <Fragment>
            {currentPage.nav === "adminPanel" && (
                <Fragment>
                    {currentPage.subpage === "usersList" && (
                        <UsersList
                            fetchURL={fetchURL}
                            onChangeValuesInForms={onChangeValuesInForms}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            usersList={usersList}
                            onStoreDataList={onStoreDataList}
                        />
                    )}
                    {currentPage.subpage === "userDetails" && (
                        <UserDetails
                            fetchURL={fetchURL}
                            onChangeValuesInForms={onChangeValuesInForms}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                        />
                    )}
                    {(currentPage.subpage === "addUser" || currentPage.subpage === "editUser") && (
                        <AddEditUser
                            fetchURL={fetchURL}
                            onChangeValuesInForms={onChangeValuesInForms}
                            onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                            currentPage={currentPage}
                            onShowAlert={onShowAlert}
                            onChangeNavAndPage={onChangeNavAndPage}
                            onBackToPreviousPage={onBackToPreviousPage}
                            appParams={appParams}
                            onEraseUsersList={onEraseUsersList}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Users;
