/* import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import React, { Fragment } from "react";
import $ from "jquery";
import { Button, Image } from "react-bootstrap";

const NotAllowed = () => {
    const height = $(window).height();
    const width = $(window).width();
    return (
        <Fragment>
            <div style={{ position: "absolute", top: 0, left: 0, width: width, height: height, backgroundColor: "white" }}>
                <div style={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%, -50%)" }} className="text-center">
                    {/* <img src="https://media2.giphy.com/media/2sdLxid6Y31kNFVPvr/giphy.gif" alt="Itysl 104 GIF by Vulture.com" />

                    <h3 className="mt-4">
                        Próbujesz wejść na widok aplikacji, który jest dla Ciebie niedozwolony. Niestety nie zrobisz nic więcej. <FontAwesomeIcon icon={faSadTear} />
                        Odśwież stronę, może będzie lepiej.
                    </h3> */}
                    <Image id="mainLogo" fluid src="images/logo_TL_PJM_PL.svg" className="mb-5" style={{ height: "12rem" }} />
                    <h1>Zablokowane wejście</h1>
                    <p className="m3 mt-4">
                        Próbujesz wejść na widok aplikacji, który jest dla Ciebie niedozwolony ze względu na <br />
                        <b>wyłączony widok</b> lub <b>niewystarczające uprawnienie</b> do korzystania z tego widoku.
                    </p>
                    <Button size="lg" className="mt-3" variant="primary" onClick={() => window.location.reload()}>
                        Odśwież stronę
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default NotAllowed;
