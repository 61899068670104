import React, { Fragment, Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NotAllowed from "../../other/NotAllowed";
import NavDetails from "../../navigation/NavDetails";
import $ from "jquery";
import QuestionItemPreview from "./QuestionItemPreview";

class Test extends Component {
    state = {
        correctAnswer: null,
        qItemQuestionID: null,
        qItemAnswersIDs: null,
        noPermission: false,

        loadedQ: false,
        loadedA: false,
        loadedB: false,
        loadedC: false,
        loadedD: false,
    };

    setHeight = () => {
        const view = this.props.currentPage.subpage;
        if ($(window).width() >= 1200) {
            $("#questionCol").height((3 * $(`#questionCol`).width()) / 4);
            $(".answerCol").height((3 * $(`.answerCol`).width()) / 4);

            if (view === "doEvaluation" || view === "editEvaluation") {
                //console.log($(`#homeContainer > nav`).outerHeight(), $("#testNavbar").outerHeight(), $(".question-container").height(), $("#evaluationFrame").outerHeight());
                const heightQuestionContainer = $(`#homeContainer > nav`).outerHeight() + $("#testNavbar").outerHeight() + $(".question-container").height() + $("#evaluationFrame").outerHeight();
                if (heightQuestionContainer < $(window).outerHeight()) {
                    let containerHeight = $(window).outerHeight() - $(`#homeContainer > nav`).outerHeight() - $("#testNavbar").outerHeight() - $("#evaluationFrame").outerHeight();
                    $(".question-container").css("height", `${containerHeight}px`);
                }
                $(`#answersContainer>.row`).css("margin-top", `${($(`.question-container`).height() - $(`.answerCol`).outerHeight() * 2) / 2}px`);
            } else {
                if ($(`#homeContainer > nav`).outerHeight() + $("#testNavbar").outerHeight() + $(".question-container").height() < $(window).outerHeight()) {
                    let containerHeight = $(window).outerHeight() - $(`#homeContainer > nav`).outerHeight() - $("#testNavbar").outerHeight();
                    $(".question-container").css("height", `${containerHeight}px`);
                    $(`#answersContainer>.row`).css("margin-top", `${($(`.question-container`).height() - $(`.answerCol`).outerHeight() * 2) / 2}px`);
                }
            }
            $(`#questionCol`).css("margin-top", `${($(".question-container").height() - $("#questionCol").outerHeight()) / 2}px`);
        } else {
            $(".question-container").css("height", "auto");
            $(`.answerCol`).css("max-height", "auto");
            const width = $("#questionCol").width();
            $("#questionCol").height((3 * width) / 4);
        }
    };

    handleFinishedLoading = (finishedLoadingID) => {
        if (finishedLoadingID === 0) this.setState({ loadedQ: true });
        else if (finishedLoadingID === 1) this.setState({ loadedA: true });
        else if (finishedLoadingID === 2) this.setState({ loadedB: true });
        else if (finishedLoadingID === 3) this.setState({ loadedC: true });
        else if (finishedLoadingID === 4) this.setState({ loadedD: true });
    };

    getQuestionData = async (questionID) => {
        const { fetchURL, onShowAlert, currentPage } = this.props;
        const passData = {
            questionID: questionID,
        };

        try {
            const response = await fetch(
                `${fetchURL}${
                    currentPage.subpage === "doEvaluation" || currentPage.subpage === "editEvaluation"
                        ? "/evaluation-question-details"
                        : currentPage.subpage === "arrangeTestQuestionPreview"
                        ? "/arrange-test-question-details"
                        : "/question-details"
                }`,
                {
                    credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(passData),
                }
            );

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ noPermission: true });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    } else {
                        const questionDetails = fetchedData.questionDetails;
                        const answersIDsArray = [
                            questionDetails[0].answer_a_qitem_id,
                            questionDetails[0].answer_b_qitem_id,
                            questionDetails[0].answer_c_qitem_id,
                            questionDetails[0].answer_d_qitem_id,
                        ];
                        this.setState({ qItemQuestionID: questionDetails[0].question_qitem_id, qItemAnswersIDs: answersIDsArray, correctAnswer: questionDetails[0].question_answer });
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: response.status });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz. " + err });
            setTimeout(this.getQuestionData(questionID), 4000);
        }
    };

    componentDidMount() {
        const height = $(window).height() - $(`#homeContainer > nav`).outerHeight();
        $(`#loadingTest`).css({ height: height, position: "absolute", top: "58px" });
        $(".loading-spinner").css({ width: $(window).width(), height: $("#loadingTest").height() });
        $(".sk-chase").css({ left: ($(window).width() - 40) / 2, top: ($("#loadingTest").height() - 40) / 2 });
        $(".loading-spinner-text").css({ left: ($(window).width() - 145) / 2, top: ($("#loadingTest").height() - 80) / 2 });
        this.getQuestionData(this.props.currentPage.params.questionID);
    }
    componentDidUpdate() {
        const { loadedA, loadedB, loadedC, loadedD, loadedQ, qItemAnswersIDs } = this.state;

        window.addEventListener("resize", this.setHeight);

        if (loadedA && loadedB && loadedC && loadedD && loadedQ && qItemAnswersIDs) {
            this.setHeight();
            $("#loadingTest").delay(400).fadeOut(600);
        }
    }

    render() {
        const { noPermission, correctAnswer, qItemQuestionID, qItemAnswersIDs } = this.state;
        const { onShowAlert, fetchURL, currentPage, onBackToPreviousPage } = this.props;
        const view = currentPage.subpage;
        const correctAnswerL2N = correctAnswer === "A" ? 0 : correctAnswer === "B" ? 1 : correctAnswer === "C" ? 2 : 3; //transform letter to number
        const checkedAnswerL2N =
            currentPage.params.checkedAnswer === "A"
                ? 0
                : currentPage.params.checkedAnswer === "B"
                ? 1
                : currentPage.params.checkedAnswer === "C"
                ? 2
                : currentPage.params.checkedAnswer === "D"
                ? 3
                : null; //transform letter to number
        return (
            <Fragment>
                {noPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        {view !== "testPreview" && view !== "normalView" && (
                            <Container fluid id="testNavbar" className="border-bottom">
                                <Row className="py-3">
                                    <NavDetails
                                        header={view === "boardPreview" ? "Podgląd planszy" : view === "doEvaluation" || view === "editEvaluation" ? "Oceń pytanie" : ""}
                                        onChangeView={() => this.setState({ loading: false }, () => onBackToPreviousPage())}
                                    />
                                    <Col></Col>
                                </Row>
                            </Container>
                        )}

                        <Fragment>
                            <div id="questionPreviewContainer">
                                <div id="loadingTest">
                                    <div>Ładuję pytanie</div>
                                    <div className="loading-spinner">
                                        <div className="loading-spinner-text position-relative text-primary">Ładuję pytanie</div>
                                        <div className="sk-chase">
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                            <div className="sk-chase-dot"></div>
                                        </div>
                                    </div>
                                </div>
                                <Container fluid>
                                    <Row>
                                        <Col lg={{ span: 10, offset: 1 }} xs={12} className="question-container">
                                            <Row>
                                                <Col xl={5} xs={12} className="p-4" id="questionCol">
                                                    {qItemQuestionID && (
                                                        <div id="question" className="test-qitem text-center">
                                                            <QuestionItemPreview
                                                                questionID={qItemQuestionID}
                                                                fetchURL={fetchURL}
                                                                onShowAlert={onShowAlert}
                                                                view={view}
                                                                finishedLoadingID={0}
                                                                onFinishedLoading={this.handleFinishedLoading}
                                                            />
                                                        </div>
                                                    )}
                                                </Col>
                                                <Col xl={7} xs={12} id="answersContainer">
                                                    {qItemAnswersIDs && (
                                                        <Row>
                                                            {qItemAnswersIDs.map((e, i) => (
                                                                <Fragment key={i}>
                                                                    <Col md={6} xs={12} className="answerCol p-3">
                                                                        <div className="answer test-qitem h-100 mx-auto">
                                                                            <QuestionItemPreview
                                                                                questionID={e}
                                                                                fetchURL={fetchURL}
                                                                                onShowAlert={onShowAlert}
                                                                                showAnswer={
                                                                                    (checkedAnswerL2N || checkedAnswerL2N === 0) && i === checkedAnswerL2N && checkedAnswerL2N === correctAnswerL2N
                                                                                        ? "correct"
                                                                                        : (checkedAnswerL2N || checkedAnswerL2N === 0) &&
                                                                                          i === checkedAnswerL2N &&
                                                                                          checkedAnswerL2N !== correctAnswerL2N
                                                                                        ? "wrong"
                                                                                        : i === correctAnswerL2N
                                                                                        ? "correct"
                                                                                        : false
                                                                                }
                                                                                view={view}
                                                                                finishedLoadingID={i + 1}
                                                                                onFinishedLoading={this.handleFinishedLoading}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Fragment>
                                                            ))}
                                                        </Row>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Fragment>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Test;
