import { faBan, faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { Button, Modal } from "react-bootstrap";
import LoadingSpinnerSm from "../../spinners/LoadingSpinnerSm";
import $ from "jquery";
import NotAllowed from "../../other/NotAllowed";

class TestQItem extends Component {
    state = {
        questionData: this.props.questionData,
        noPermission: false,
        showZoomImgModal: false,
        fileExists: null,
    };

    checkIfFileExists = async (fileName) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            fileName: fileName,
        };
        try {
            const response = await fetch(`${fetchURL}/check-if-file-exists`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    this.setState({ fileExists: fetchedData.fileExists }, () => {
                        if (this.state.fileExists === false)
                            onShowAlert({ variant: "warning", message: "Jeden lub więcej plików w tym pytaniu są niepoprawnie załadowane lub nie istnieją.", fading: 2500 });
                    });
                });
            } else {
                this.setState({ alert: { variant: "danger", message: response.status }, loading: false });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Błąd połączenia z serwerem. Spróbuję pobrać/wysłać dane jeszcze raz." } });
            //setTimeout(this.checkIfFileExists(fileName), 4000);
        }
    };

    resizeWordsFont = () => {
        let fontSize = 0;

        if ($("#question .test-qitem-word .test-word").text().length <= 5) fontSize = 7;
        else if ($("#question .test-qitem-word .test-word").text().length <= 7) fontSize = 6.5;
        else if ($("#question .test-qitem-word .test-word").text().length <= 10) fontSize = 6;
        else if ($("#question .test-qitem-word .test-word").text().length <= 12) fontSize = 5.5;
        else fontSize = 5;

        $("#question .test-qitem-word .test-word").css("font-size", `${fontSize}rem`);

        let longestWord = 0;
        $(".answer .test-qitem-word .test-word").each(function () {
            if ($(this).text().length > longestWord) longestWord = $(this).text().length;
        });

        if (longestWord <= 5) fontSize = 5.5;
        else if (longestWord <= 7) fontSize = 5;
        else if (longestWord <= 10) fontSize = 4.5;
        else if (longestWord <= 12) fontSize = 4;
        else fontSize = 3.5;

        $(".answer .test-qitem-word .test-word").css("font-size", `${fontSize}rem`);

        $(".test-word").addClass("center");
    };

    componentDidUpdate() {
        if (this.state.showZoomImgModal && this.state.questionData.qitem_type_code === "VIDEO") {
            $(".zoom-image-modal-loading").css("max-height", 1078);

            $(".loading-spinner").css({ width: $(".zoom-image-modal-loading").width(), height: $(".zoom-image-modal-loading").height() });
            $(".sk-chase").css({ left: ($(".zoom-image-modal-loading").width() - 40) / 2, top: ($(".zoom-image-modal-loading").height() - 40) / 2 });

            setTimeout(() => {
                $(".zoom-image-modal-loading").addClass("faded");
                $(".zoom-image-modal-loading").delay(200).addClass("d-none");
            }, 200);
        }
        $(".test-qitem-image").fadeIn();
        $(".test-qitem-video").fadeIn();

        this.handleHoverClickArea();
    }

    handleHoverClickArea = () => {
        const { id, subtestType } = this.props;

        $(`#clickArea${id}`).on("mouseenter", function () {
            $(`#${id}`).addClass(`answer-hover-${subtestType}`);
        });

        $(`#clickArea${id}`).on("mouseleave", function () {
            $(`#${id}`).removeClass(`answer-hover-${subtestType}`);
        });
    };

    handleClickArea = () => {
        const { id, subtestType, onSelectAnswer } = this.props;

        $("#A").removeClass(`checked-${subtestType}`);
        $("#B").removeClass(`checked-${subtestType}`);
        $("#C").removeClass(`checked-${subtestType}`);
        $("#D").removeClass(`checked-${subtestType}`);
        $(`#${id}`).addClass(`checked-${subtestType}`);
        onSelectAnswer(id);
    };

    componentDidMount() {
        const { questionData, id, subtestType } = this.props;
        if (questionData.qitem_type_code !== "WORD") this.checkIfFileExists(questionData.qitem_file_name);
        $(".test-qitem-word").fadeIn();
        this.resizeWordsFont();
        this.handleHoverClickArea();

        if (questionData.checkedAnswer && id === questionData.checkedAnswer) $(`#${id}`).addClass(`checked-${subtestType}`);
    }

    render() {
        const { questionData, noPermission, showZoomImgModal, fileExists } = this.state;
        const { id, frontURL } = this.props;
        return (
            <Fragment>
                {noPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        {!questionData ? (
                            <div className="test-qitem-loading h-100">
                                <LoadingSpinnerSm />
                            </div>
                        ) : (
                            <Fragment>
                                {questionData.qitem_type_code === "WORD" ? (
                                    <>
                                        <div className="test-qitem-word">
                                            <p className="test-word">{questionData.qitem_string}</p>
                                        </div>
                                        <div className={id ? "click-area" : ""} id={`clickArea${id ? id : ""}`} onClick={this.handleClickArea}></div>
                                    </>
                                ) : (
                                    <Fragment>
                                        {fileExists === true ? (
                                            <Fragment>
                                                {questionData.qitem_type_code === "IMAGE" ? (
                                                    <div className="test-qitem-image text-center">
                                                        <img src={`${frontURL}/questions/${questionData.qitem_file_name}`} alt={`${questionData.qitem_file_name}`} />
                                                        <div className={id ? "click-area" : ""} id={`clickArea${id ? id : ""}`} onClick={this.handleClickArea}></div>
                                                    </div>
                                                ) : (
                                                    <Fragment>
                                                        <video controls src={`${frontURL}/questions/${questionData.qitem_file_name}`} className="test-qitem-video"></video>
                                                        <div className={"click-area"} id={id ? `clickArea${id}` : ""} onClick={this.handleClickArea}></div>
                                                    </Fragment>
                                                )}
                                                {questionData.qitem_type_code === "VIDEO" && (
                                                    <Button variant="outline-dark" className="zoom-question-btn" onClick={() => this.setState({ showZoomImgModal: true })}>
                                                        <FontAwesomeIcon icon={faSearchPlus} />
                                                    </Button>
                                                )}
                                            </Fragment>
                                        ) : (
                                            fileExists === false && (
                                                <div className="test-qitem-no-file">
                                                    <div className="no-file">
                                                        <FontAwesomeIcon icon={faBan} />
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}

                {showZoomImgModal && (
                    <Modal show={showZoomImgModal} onHide={() => this.setState({ showZoomImgModal: false })} className="zoom-image-modal">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                            {questionData.qitem_type_code === "IMAGE" ? (
                                <div className="test-qitem-image text-center">
                                    <img src={`${frontURL}/questions/${questionData.qitem_file_name}`} alt={`${questionData.qitem_file_name}`} />
                                </div>
                            ) : (
                                questionData.qitem_type_code === "VIDEO" && (
                                    <Fragment>
                                        <div className="zoom-image-modal-loading">
                                            <div className="loading-spinner">
                                                <div className="sk-chase">
                                                    <div className="sk-chase-dot"></div>
                                                    <div className="sk-chase-dot"></div>
                                                    <div className="sk-chase-dot"></div>
                                                    <div className="sk-chase-dot"></div>
                                                    <div className="sk-chase-dot"></div>
                                                    <div className="sk-chase-dot"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <video controls src={`${frontURL}/questions/${questionData.qitem_file_name}`} className="zoom-modal-video"></video>
                                    </Fragment>
                                )
                            )}
                        </Modal.Body>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default TestQItem;
