import React, { Component, Fragment } from "react";
import { Modal, Form, Button, Alert, Row, Col } from "react-bootstrap";
import NameInput from "../forms/NameInput";
import DescrInput from "../forms/DescrInput";

class ContactWithAdminModal extends Component {
    state = {
        mail_txt: "",
        alert: "",
    };

    sendMail = async () => {
        const { fetchURL, contactAdminData } = this.props;
        this.setState({ alert: { variant: "info", message: "Wysyłanie maila..." } });
        const passData = {
            mailSender: contactAdminData.mailAddress,
            mailTitle: contactAdminData.mailTitle,
            mailTxt: this.state.mail_txt,
            mailSenderFullname: contactAdminData.mailSender,
        };
        try {
            const response = await fetch(`${fetchURL}/send-mail-from-user`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) this.setState({ alert: { variant: "success", message: fetchedData.message } });
                    else this.setState({ alert: { variant: "danger", message: fetchedData.message } });
                });
            }
        } catch (err) {
            this.setState({ loading: false, alert: { variant: "danger", message: "Nie udało się wysłać danych." } });
            setTimeout(this.sendMail(), 4000);
        }
    };

    render() {
        const { show, onClose, contactAdminData } = this.props;
        const { mail_txt, alert } = this.state;
        return (
            <Fragment>
                <Modal show={show} onHide={onClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Kontakt z administracją</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {alert && <Alert variant={alert.variant}>{alert.message}</Alert>}
                        {((alert && (alert.variant === "info" || alert.variant === "danger")) || !alert) && (
                            <Form onSubmit={this.handleSendMail} autoComplete="off">
                                <Row>
                                    <Col xs={12}>
                                        <NameInput value={contactAdminData.mailTitle} readOnly labelTxt="Tytuł wiadomości" name="mail_title" id="mailTitle" />
                                    </Col>
                                    <Col xs={12}>
                                        <DescrInput
                                            value={mail_txt}
                                            onChangeValue={(e) => this.setState({ [e.target.name]: e.target.value })}
                                            labelTxt="Treść wiadomości"
                                            name="mail_txt"
                                            req
                                            placeholderTxt="Wpisz treść wiadomości"
                                            id="mail_txt"
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {(alert && (alert.variant === "info" || alert.variant === "danger")) || !alert ? (
                            <Fragment>
                                <Button variant="outline-secondary" onClick={onClose} type="button">
                                    Anuluj
                                </Button>
                                <Button variant="primary" type="button" onClick={() => this.sendMail()}>
                                    Wyślij maila
                                </Button>
                            </Fragment>
                        ) : (
                            <Button variant="outline-primary" onClick={onClose} type="button">
                                Zamknij
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default ContactWithAdminModal;
