import React, { Fragment, Component } from "react";
import { Button, Row, Col, Container, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import ApprovalsListTable from "./ApprovalsListTable";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import QuestionCategories from "../../forms/QuestionCategories";
import SubtestTypes from "../../forms/SubtestTypes";
import QuestionTypes from "../../forms/QuestionTypes";
//import EvaluationResults from "../../forms/EvaluationResults";
//import RowsDropdown from "../../forms/RowsDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltLeft, faLongArrowAltRight, faRedoAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import NotAllowed from "../../other/NotAllowed";
import ApprovalStatuses from "../../forms/ApprovalStatuses";
import RowsDropdown from "../../forms/RowsDropdown";

class ApprovalsList extends Component {
    state = {
        showNOR: this.props.NOR,
        displayNOR: this.props.NOR,
        approvalsList: this.props.approvalsList ? this.props.approvalsList : null,
        originalApprovalsList: this.props.approvalsList ? this.props.approvalsList : null,
        loading: this.props.approvalsList ? false : true,

        selectedSubtestType: this.props.selectedSubtestType,
        selectedQuestionCat: this.props.selectedQuestionCat,
        selectedQuestionType: this.props.selectedQuestionType,
        selectedEvalResult: this.props.selectedEvalResult,
        selectedApprovalQuestionStatuses: this.props.selectedApprovalQuestionStatuses,

        disallowPermission: false,

        searchQD1: "",
        searchQD2: "",
        searchQD3: "",

        evalResultSearchPhrase: "",
    };

    handleSearch = (e, difficultySearch) => {
        const { originalApprovalsList, searchQD1, searchQD2, searchQD3 } = this.state;
        const searchedPhrase = e.target.value.toLowerCase();
        let QD1 = searchQD1;
        let QD2 = searchQD2;
        let QD3 = searchQD3;
        if (difficultySearch === "searchQD1") QD1 = searchedPhrase;
        else if (difficultySearch === "searchQD2") QD2 = searchedPhrase;
        else if (difficultySearch === "searchQD3") QD3 = searchedPhrase;
        this.setState({
            searchQD1: QD1,
            searchQD2: QD2,
            searchQD3: QD3,
            selectedSubtestType: "all",
            selectedQuestionCat: "all",
            selectedQuestionType: "all",
            selectedEvalResult: "all",
            selectedApprovalQuestionStatuses: "all",
            evalResultSearchPhrase: "",
        });
        if (!QD1 && !QD2 && !QD3) {
            this.setState({ approvalsList: originalApprovalsList });
        } else {
            let filteredApprovals = originalApprovalsList;
            filteredApprovals = filteredApprovals.filter((el) => (QD1 === "" || QD1 <= el.qd_1) && (QD2 === "" || QD2 <= el.qd_2) && (QD3 === "" || QD3 <= el.qd_3));

            this.setState({ approvalsList: filteredApprovals });
        }
    };

    handleSearchEvalResult = (e) => {
        const searchedPhrase = e.target.value.toLowerCase();

        this.setState(
            {
                searchQD1: "",
                searchQD2: "",
                searchQD3: "",
                selectedSubtestType: "all",
                selectedQuestionCat: "all",
                selectedQuestionType: "all",
                selectedEvalResult: "all",
                selectedApprovalQuestionStatuses: "all",
                evalResultSearchPhrase: searchedPhrase,
            },
            () => {
                if (!this.state.evalResultSearchPhrase) {
                    this.setState({ approvalsList: this.state.originalApprovalsList });
                } else {
                    let filteredApprovals = this.state.originalApprovalsList;
                    filteredApprovals = filteredApprovals.filter((el) => this.state.evalResultSearchPhrase === "" || this.state.evalResultSearchPhrase === el.qr_yes.toString());
                    this.setState({ approvalsList: filteredApprovals });
                }
            }
        );
    };

    handleDownloadData = () => {
        this.setState({ loading: true });
        this.getApprovalsList();
    };

    getApprovalsList = async () => {
        const { fetchURL, onShowAlert, onStoreDataList } = this.props;

        try {
            const response = await fetch(`${fetchURL}/approvals-list`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noPermission" || fetchedData.status === "noParamAvailable") {
                        this.setState({ disallowPermission: true, loading: false });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    } else {
                        const approvalsList = fetchedData.questionsForApprovalList;
                        this.setState(
                            {
                                approvalsList: approvalsList,
                                originalApprovalsList: approvalsList,
                            },
                            () => {
                                this.setState({ loading: false });
                            }
                        );
                        onStoreDataList(approvalsList);
                    }
                });
            } else {
                onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
                this.setState({ loading: false });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getApprovalsList, 4000);
        }
    };

    componentDidMount() {
        if (!this.props.approvalsList) this.getApprovalsList();
    }

    componentDidUpdate() {
        if (this.props.NOR !== this.state.showNOR) this.setState({ showNOR: this.props.NOR, displayNOR: this.props.NOR });
        if (this.props.selectedSubtestType !== this.state.selectedSubtestType) this.setState({ selectedSubtestType: this.props.selectedSubtestType });
        if (this.props.selectedQuestionCat !== this.state.selectedQuestionCat) this.setState({ selectedQuestionCat: this.props.selectedQuestionCat });
        if (this.props.selectedQuestionType !== this.state.selectedQuestionType) this.setState({ selectedQuestionType: this.props.selectedQuestionType });
        if (this.props.selectedEvalResult !== this.state.selectedEvalResult) this.setState({ selectedEvalResult: this.props.selectedEvalResult });
        if (this.props.selectedApprovalQuestionStatuses !== this.state.selectedApprovalQuestionStatuses)
            this.setState({ selectedApprovalQuestionStatuses: this.props.selectedApprovalQuestionStatuses });
    }

    render() {
        const { approvalsList, loading, displayNOR, disallowPermission, showNOR } = this.state;
        const {
            fetchURL,
            onChangeNavAndPage,
            appParams,
            selectedSubtestType,
            selectedQuestionCat,
            selectedQuestionType,
            //selectedEvalResult,
            selectedApprovalQuestionStatuses,
            onApprovalStatusSelected,
            onSubtestTypeSelected,
            onQuestionTypeSelected,
            onQuestionCatSelected,
            //onEvaluationResultSelected,
            onResetFilters,
            onChangeNORValue,
        } = this.props;
        let filteredApprovalList = null;
        if (approvalsList) {
            //wyfiltrowana lista uczestników o wydarzenia, status wpłat i status uczestnika
            filteredApprovalList = approvalsList.filter(
                (e) =>
                    (selectedQuestionType === "all" || e.question_type_code === selectedQuestionType) &&
                    (selectedSubtestType === "all" || e.subtest_type_code === selectedSubtestType) &&
                    (selectedApprovalQuestionStatuses === "all" || e.question_status_code === selectedApprovalQuestionStatuses) &&
                    (selectedQuestionCat === "all" || e.question_category_code === selectedQuestionCat)
            );
        }
        return (
            <Fragment>
                {disallowPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        <Container fluid className="second-bar">
                            <Row>
                                <Col className="text-center">
                                    <p className="h3 my-4" style={{ lineHeight: "38px" }}>
                                        Zatwierdzanie pytań
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={1} xs={12}>
                                    <Form.Label>Min. l. ocen 1</Form.Label>
                                    <Form.Control onChange={(e) => this.handleSearch(e, `searchQD1`)} value={this.state.searchQD1}></Form.Control>
                                </Col>
                                <Col lg={1} xs={12}>
                                    <Form.Label>Min. l. ocen 2</Form.Label>
                                    <Form.Control onChange={(e) => this.handleSearch(e, `searchQD2`)} value={this.state.searchQD2}></Form.Control>
                                </Col>
                                <Col lg={1} xs={12} className="border-right">
                                    <Form.Label>Min. l. ocen 3</Form.Label>
                                    <Form.Control onChange={(e) => this.handleSearch(e, `searchQD3`)} value={this.state.searchQD3}></Form.Control>
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <ApprovalStatuses defaultValue={selectedApprovalQuestionStatuses} onApprovalStatusSelected={onApprovalStatusSelected} fetchURL={fetchURL} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <SubtestTypes defaultValue={selectedSubtestType} onSubtestTypeSelected={onSubtestTypeSelected} fetchURL={fetchURL} />
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    <QuestionTypes defaultValue={selectedQuestionType} onQuestionTypeSelected={onQuestionTypeSelected} fetchURL={fetchURL} />
                                </Col>

                                <Col lg={2} md={3} xs={5}>
                                    <QuestionCategories defaultValue={selectedQuestionCat} onQuestionCatSelected={onQuestionCatSelected} fetchURL={fetchURL} />
                                </Col>

                                <Col lg={1} md={2} xs={12} className="text-left text-lg-right text-md-right mt-md-3 mb-3">
                                    <OverlayTrigger key="resetFiltersTop" placement="top" overlay={<Tooltip id="resetFilters">Resetuj filtry</Tooltip>}>
                                        <Button
                                            variant="outline-primary"
                                            className="mr-1"
                                            onClick={() => {
                                                this.setState({
                                                    searchQD1: "",
                                                    searchQD2: "",
                                                    searchQD3: "",
                                                    evalResultSearchPhrase: "",
                                                    approvalsList: this.state.originalApprovalsList,
                                                });
                                                onResetFilters();
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faRedoAlt} />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger key="refreshDataTop" placement="top" overlay={<Tooltip id="refreshData">Odśwież dane</Tooltip>}>
                                        <Button variant="outline-primary" onClick={this.handleDownloadData} className="btn-sync">
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    </OverlayTrigger>
                                </Col>
                                <Col lg={2} md={3} xs={5}>
                                    {/*  <EvaluationResults defaultValue={selectedEvalResult} onEvaluationResultSelected={onEvaluationResultSelected} fetchURL={fetchURL} labelTxt="Kwalifikacje pytań" /> */}
                                    <Form.Label>Liczba kwalifikacji "Tak":</Form.Label>
                                    <Form.Control onChange={this.handleSearchEvalResult} value={this.state.evalResultSearchPhrase}></Form.Control>
                                </Col>
                                <Col lg={1} md={2} xs={3}>
                                    <RowsDropdown onChangeValue={onChangeNORValue} defaultValue={displayNOR} />
                                </Col>
                            </Row>
                        </Container>
                        <Container fluid className="content-body">
                            {loading || !filteredApprovalList ? (
                                <LoadingProgressBar />
                            ) : (
                                <Fragment>
                                    {filteredApprovalList.length > 0 ? (
                                        <Fragment>
                                            <Row className="rest-info py-3">
                                                <Col>
                                                    Załadowano {displayNOR > filteredApprovalList.length ? filteredApprovalList.length : displayNOR} z {filteredApprovalList.length} rekordów.
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="table-responsive table-fixed-head">
                                                        <ApprovalsListTable
                                                            approvalsList={filteredApprovalList}
                                                            displayNOR={displayNOR}
                                                            onChangeNavAndPage={onChangeNavAndPage}
                                                            fetchURL={fetchURL}
                                                            onRefreshList={this.handleDownloadData}
                                                            appParams={appParams}
                                                        />
                                                        <div className="light-border"></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="rest-info py-3">
                                                <Col>
                                                    Załadowano {displayNOR > filteredApprovalList.length ? filteredApprovalList.length : displayNOR} z {filteredApprovalList.length} rekordów.
                                                </Col>
                                                <Col className="text-center">
                                                    {displayNOR >= filteredApprovalList.length ? (
                                                        <p className="opacity-4">
                                                            <FontAwesomeIcon icon={faLongArrowAltRight} />
                                                            <span className="px-3">to wszystko</span>
                                                            <FontAwesomeIcon icon={faLongArrowAltLeft} />
                                                        </p>
                                                    ) : (
                                                        <Button onClick={() => this.setState({ displayNOR: showNOR + displayNOR })} variant="outline-primary">
                                                            załaduj więcej
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </Fragment>
                                    ) : (
                                        <Row>
                                            <Col className="text-center mt-3 font-weight-bold">Lista jest pusta.</Col>
                                        </Row>
                                    )}
                                </Fragment>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default ApprovalsList;
