import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import AlertBasic from "../alerts/AlertBasic";
import $ from "jquery";

class ApprovalStatuses extends Component {
    state = { approvalStatusesList: [] };

    getApprovalStatuses = () => {
        let list = [
            {
                key: 1,
                value: "EVALUATE",
                text: "Brak",
            },
            {
                key: 2,
                value: "ACCEPTED",
                text: "Zatwierdzone",
            },
            {
                key: 3,
                value: "REJECTED",
                text: "Odrzucone",
            },
        ];
        if (this.props.defaultValue === "all") {
            list.unshift({
                key: 0,
                value: "all",
                text: "Wszystkie",
            });
        }
        this.setState({ approvalStatusesList: list });
    };

    componentDidMount() {
        this.getApprovalStatuses();
    }

    render() {
        const { approvalStatusesList, alert } = this.state;
        const { defaultValue, onApprovalStatusSelected, req } = this.props;
        return (
            <Fragment>
                {alert && <AlertBasic variant={alert.variant} mssg={alert.message} onCloseAlert={() => this.setState({ alert: "" })} fading={alert.fading} />}

                <Form.Group>
                    <Form.Label>Status pytania {req && <span className="required">*</span>}</Form.Label>

                    <Dropdown
                        value={defaultValue}
                        id="questionApprovalStatusesDropdown"
                        className={req ? "form-dropdown mb-3 req" : "form-dropdown mb-3"}
                        placeholder="Kliknij i wybierz"
                        fluid
                        selection
                        options={approvalStatusesList}
                        onChange={(event, { value }) => {
                            $("#questionApprovalStatusesDropdown").removeClass("is-invalid");
                            onApprovalStatusSelected(event, value);
                        }}
                    />
                </Form.Group>
            </Fragment>
        );
    }
}

export default ApprovalStatuses;
