import React, { Component } from "react";
import { Fragment } from "react";
import NavDetails from "../../navigation/NavDetails";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import NameInput from "../../forms/NameInput";
import DescrInput from "../../forms/DescrInput";
//import QuestionStatus from "../../forms/QuestionStatus";
import SubtestTypes from "../../forms/SubtestTypes";
import QuestionTypes from "../../forms/QuestionTypes";
import QuestionCategories from "../../forms/QuestionCategories";
import QItemFile from "../../forms/QItemFile";
import $ from "jquery";
import { Form, Radio } from "semantic-ui-react";
import TooltipWithDisabledButton from "../../other/TooltipWithDisabledButton";
import LoadingProgressBar from "../../spinners/LoadingProgressBar";
import NotAllowed from "../../other/NotAllowed";

class AddEditQuestion extends Component {
    state = {
        loading: true,
        appendedReq: false,
        questionData: [
            {
                question_id: this.props.currentPage.params.questionID ? this.props.currentPage.params.questionID : null,
                question_name: "",
                question_comment: "",
                subtest_type_code: "",
                question_type_code: "",
                question_category_code: "",
                question_status_code: "NEW",
            },
        ],

        //zerowy element tablicy jest pytaniem, a kolejne są odpowiedziami; odpowiednio 1-szy el. tablicy to odp. A, 2-gi el. tablicy to odp. B itd.
        questionAndAnswers: [
            {
                qitem_type_code: "",
                qitem_string: "",
                qitem_file_name: "",
                qitem_id: "",
                new_file: null,
            },
            {
                qitem_type_code: "",
                qitem_string: "",
                qitem_file_name: "",
                qitem_id: "",
                new_file: null,
            },
            {
                qitem_type_code: "",
                qitem_string: "",
                qitem_file_name: "",
                qitem_id: "",
                new_file: null,
            },
            {
                qitem_type_code: "",
                qitem_string: "",
                qitem_file_name: "",
                qitem_id: "",
                new_file: null,
            },
            {
                qitem_type_code: "",
                qitem_string: "",
                qitem_file_name: "",
                qitem_id: "",
                new_file: null,
            },
        ],

        correctAnswer: null,

        qitemQuestionTypeCode: null,
        qitemAnswerTypeCode: null,

        changedValues: false,
        eraseData: false, //zmienna pomocnicza do monitorowania czy nastąpiły zmiany w formularzu usuwające dane z pytania i odpowiedzi

        alertInsertingQuestionTxt: "",

        noPermission: false,
    };

    handleQuestionDataValue = (e) => {
        const { questionData } = this.state;
        const list = [...questionData];
        list[0][e.target.name] = e.target.value;
        this.setState({ questionData: list, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    handleDropdownData = (value, name) => {
        const { questionData } = this.state;
        const list = [...questionData];
        list[0][name] = value;
        this.setState({ questionData: list, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    handleCheckCorrectAnswer = (e, { value }) => {
        $(".ui.radio.checkbox").removeClass("is-invalid");
        this.setState({ correctAnswer: value, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    handleQuestionAndAnswersValue = (e, index) => {
        const { value, name } = e.target;
        const { questionAndAnswers } = this.state;
        const list = [...questionAndAnswers];
        list[index][name] = value;
        this.setState({ questionAndAnswers: list, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    /* blobToBuffer = (object) => {
        const blob = object;
        const buffer = Buffer.from(blob);
        const bufferBase64 = buffer.toString("base64");
        return bufferBase64;
    }; */

    handleNoExistingFile = (index) => {
        this.props.onShowAlert({ variant: "warning", message: "Jeden lub więcej plików w tym pytaniu są niepoprawnie załadowane lub nie istnieją.", fading: 2500 });
        const { questionAndAnswers } = this.state;
        let list = [...questionAndAnswers];
        list[index].qitem_id = null;
        list[index].qitem_file_name = "";
        //list[index].qitem_file_type = "";
        list[index].new_file = null;

        this.setState({ questionAndAnswers: list });
    };

    handleSelectedFile = (value, option, index) => {
        const { questionAndAnswers } = this.state;
        let list = [...questionAndAnswers];
        if (option === "existing") {
            list[index] = value[0];
            //list[index].bufferBase64 = this.blobToBuffer(list[index].qitem_blob);
        } else if (option === "new") {
            list[index].qitem_id = null;
            list[index].new_file = value;
        }
        this.setState({ questionAndAnswers: list, changedValues: true });
        this.props.onChangeValuesInForms();
    };

    handleCheckValidation = () => {
        const { questionData, questionAndAnswers, qitemQuestionTypeCode, qitemAnswerTypeCode, correctAnswer } = this.state;
        const { onShowAlert } = this.props;
        onShowAlert("");
        let errorMsg = "";

        if (!questionData[0].question_name) {
            errorMsg += "Nazwa pytania jest pusta. ";
            $("#questionName").addClass("is-invalid");
        }

        if (!questionData[0].question_status_code) {
            errorMsg += "Status pytania jest pusty. ";
            $("#questionStatDropdown").addClass("is-invalid");
        }

        if (!questionData[0].subtest_type_code) {
            errorMsg += "Część testu jest pusta. ";
            $("#questionSubtestTypesDropdown").addClass("is-invalid");
        } else {
            if (!questionData[0].question_type_code) {
                errorMsg += "Typ pytania jest pusty. ";
                $("#questionTypesDropdown").addClass("is-invalid");
            }
            if (!questionData[0].question_category_code) {
                errorMsg += "Kategoria pytania jest pusta. ";
                $("#questionCatDropdown").addClass("is-invalid");
            }
        }

        if (questionData[0].subtest_type_code && questionData[0].question_type_code && questionData[0].question_category_code) {
            if (qitemQuestionTypeCode === "WORD" && !questionAndAnswers[0].qitem_string) {
                errorMsg += "Treść słowa w pytaniu jest pusta. ";
                $("#questionQitemString").addClass("is-invalid");
            } else if ((qitemQuestionTypeCode === "IMAGE" || qitemQuestionTypeCode === "VIDEO") && !questionAndAnswers[0].qitem_id && !questionAndAnswers[0].new_file) {
                errorMsg += "Brak załadowanego pliku w pytaniu. ";
                $("#QItemFileQuestion .file-placeholder").addClass("is-invalid");
            }

            if (!correctAnswer) {
                errorMsg += "Brak wybranej poprawnej odpowiedzi. ";
                $(".ui.radio.checkbox").addClass("is-invalid");
            }

            if (qitemAnswerTypeCode === "WORD") {
                errorMsg += this.checkWordAnswer(1, "A");
                errorMsg += this.checkWordAnswer(2, "B");
                errorMsg += this.checkWordAnswer(3, "C");
                errorMsg += this.checkWordAnswer(4, "D");
            } else {
                errorMsg += this.checkFileAnswer(1, "A");
                errorMsg += this.checkFileAnswer(2, "B");
                errorMsg += this.checkFileAnswer(3, "C");
                errorMsg += this.checkFileAnswer(4, "D");

                //sprawdzanie, czy pliki są unikalne
            }
        }

        if (errorMsg) {
            onShowAlert({
                variant: "danger",
                message: (
                    <>
                        <p>Wystąpiły następujące błędy w formularzu:</p>
                        <p>{errorMsg}</p>
                    </>
                ),
            });
        } else {
            this.insertOrUpdateAll();
        }
    };

    insertOrUpdateAll = async () => {
        const { questionAndAnswers, qitemQuestionTypeCode, qitemAnswerTypeCode } = this.state;
        const { onShowAlert } = this.props;
        try {
            this.setState({ changedValues: false });
            onShowAlert({ variant: "info", message: this.props.currentPage.subpage === "addQuestion" ? "Dodaję pytanie..." : "Edytuję pytanie..." });

            //wstawianie planszy
            /* for (let i = 0; i <= 4; i++) { */
            if (!questionAndAnswers[0].qitem_id) {
                await this.insertQItem(qitemQuestionTypeCode, questionAndAnswers, 0);
            } else if (questionAndAnswers[0].qitem_type_code === "WORD") await this.updateQItemWord(0);
            if (!questionAndAnswers[1].qitem_id) {
                await this.insertQItem(qitemAnswerTypeCode, questionAndAnswers, 1);
            } else if (questionAndAnswers[1].qitem_type_code === "WORD") await this.updateQItemWord(1);
            if (!questionAndAnswers[2].qitem_id) {
                await this.insertQItem(qitemAnswerTypeCode, questionAndAnswers, 2);
            } else if (questionAndAnswers[2].qitem_type_code === "WORD") await this.updateQItemWord(2);
            if (!questionAndAnswers[3].qitem_id) {
                await this.insertQItem(qitemAnswerTypeCode, questionAndAnswers, 3);
            } else if (questionAndAnswers[3].qitem_type_code === "WORD") await this.updateQItemWord(3);
            if (!questionAndAnswers[4].qitem_id) {
                await this.insertQItem(qitemAnswerTypeCode, questionAndAnswers, 4);
            } else if (questionAndAnswers[4].qitem_type_code === "WORD") await this.updateQItemWord(4);
            /* } */

            await this.insertOrUpdateQuestion(this.state.questionData[0].question_id);
        } catch (err) {
            //console.log("error while inserting or updating");
        }
    };

    insertQItem = async (qItemType, item, itemIndex) => {
        const { fetchURL, onShowAlert } = this.props;
        const data = new FormData();
        data.append("file", item[itemIndex].new_file);
        const fetchOptions =
            qItemType === "WORD"
                ? {
                      credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                      method: "POST",
                      headers: {
                          "Content-Type": "application/json",
                      },
                      body: JSON.stringify({ word: item[itemIndex].qitem_string }),
                  }
                : {
                      credentials: "include",
                      method: "POST",
                      body: data,
                  };
        try {
            const response = await fetch(`${fetchURL}/insert-question-item-${qItemType === "WORD" ? "word" : "file"}`, fetchOptions);
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        //wstawianie pobranego id świeżo wstawionego questionAndAnswers item
                        const list = item;
                        list[itemIndex].qitem_id = fetchedData.qItemID;
                        this.setState({ questionsAndAnswers: list });
                    } else {
                        const { alertInsertingQuestionTxt } = this.state;
                        this.setState({ alertInsertingQuestionTxt: alertInsertingQuestionTxt + fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych. " + err });
            this.setState({ loading: false });
            setTimeout(this.insertQItem(data, item, itemIndex), 4000);
        }
    };

    updateQItemWord = async (qItemIndex) => {
        const { questionAndAnswers } = this.state;
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            qItemData: questionAndAnswers[qItemIndex],
        };
        try {
            const response = await fetch(`${fetchURL}/update-question-item-word`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (!fetchedData.status) {
                        const { alertInsertingQuestionTxt } = this.state;
                        this.setState({ alertInsertingQuestionTxt: alertInsertingQuestionTxt + fetchedData.message });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.updateQItemWord(qItemIndex), 4000);
        }
    };
    insertOrUpdateQuestion = async (questionID) => {
        const { questionData, correctAnswer, questionAndAnswers } = this.state;
        const { fetchURL, onShowAlert, onCancelChangeValuesInForms } = this.props;
        const passData = {
            questionID: questionID,
            questionData: questionData[0],
            correctAnswer: correctAnswer,
            qItemIDs: {
                queston_qitem_id: questionAndAnswers[0].qitem_id,
                answer_a_qitem_id: questionAndAnswers[1].qitem_id,
                answer_b_qitem_id: questionAndAnswers[2].qitem_id,
                answer_c_qitem_id: questionAndAnswers[3].qitem_id,
                answer_d_qitem_id: questionAndAnswers[4].qitem_id,
            },
        };
        try {
            const response = await fetch(`${fetchURL}/insert-or-update-question`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        onShowAlert({ variant: "success", message: fetchedData.message, fading: 3000 });
                        onCancelChangeValuesInForms();
                        this.props.onChangeNavAndPage({ nav: "questions", subpage: "questionsList", params: null }, null, "questionsList");
                    } else {
                        const { alertInsertingQuestionTxt } = this.state;
                        const alertTxt = alertInsertingQuestionTxt + fetchedData.message;
                        onShowAlert({
                            variant: "danger",
                            message: (
                                <>
                                    <p>Podczas {this.props.currentPage.subpage === "editQuestion" ? "edytowania" : "dodawania"} pytania do bazy danych wystąpiły następujące błędy.</p>
                                    <p>{alertTxt}</p>
                                </>
                            ),
                        });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się wysłać danych." });
            this.setState({ loading: false });
            setTimeout(this.insertOrUpdateQuestion(questionID), 4000);
        }
    };

    checkFileAnswer = (index, answer) => {
        const { questionAndAnswers } = this.state;
        if (!questionAndAnswers[index].qitem_id && !questionAndAnswers[index].new_file) {
            $(`#QItemFileAnswer${answer} .file-placeholder`).addClass("is-invalid");
            return `Brak załadowanego pliku w odpowiedzi ${answer}. `;
        } else return "";
    };

    checkWordAnswer = (index, answer) => {
        const { questionAndAnswers } = this.state;
        if (!questionAndAnswers[index].qitem_string) {
            $(`#answer${answer}QItemString`).addClass("is-invalid");
            return `Treść słowa w odpowiedzi ${answer} jest pusta. `;
        } else return "";
    };

    eraseDataInQuestionAndAnswers = () => {
        const { questionAndAnswers } = this.state;
        const list = [...questionAndAnswers];

        list.forEach((e) => {
            e.qitem_id = null;
            e.new_file = null;
            e.qitem_string = "";
        });
        this.setState({ questionAndAnswers: list, changedValues: true, eraseData: true });
        this.props.onChangeValuesInForms();
    };

    getQuestionData = async (questionID) => {
        const { fetchURL, onShowAlert } = this.props;
        const passData = {
            questionID: questionID,
        };
        try {
            const response = await fetch(`${fetchURL}/get-question-data`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passData),
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === true) {
                        const questionAndAnswers = fetchedData.questionAndAnswers;

                        this.setState({
                            questionData: fetchedData.questionData,
                            questionAndAnswers: questionAndAnswers,
                            loading: false,
                            correctAnswer: fetchedData.correctAnswer,
                            qitemQuestionTypeCode: fetchedData.qitemQuestionTypeCode,
                            qitemAnswerTypeCode: fetchedData.qitemAnswerTypeCode,
                        });
                    } else {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            this.setState({ loading: false });
            setTimeout(this.getQuestionData(questionID), 4000);
        }
    };

    checkAvailableParam = async () => {
        const { fetchURL, onShowAlert } = this.props;
        try {
            const response = await fetch(`${fetchURL}/add-question-view`, {
                credentials: "include", //include - bez tego nie będą pojawiać się ciasteczka w przeglądarce
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (response.status === 200) {
                await response.json().then((fetchedData) => {
                    //console.log(fetchedData);
                    if (fetchedData.status === "noParamAvailable" || fetchedData.status === "noPermission") {
                        this.setState({ noPermission: true, loading: false });
                    } else if (fetchedData.status === false) {
                        onShowAlert({ variant: "danger", message: fetchedData.message });
                    } else {
                        this.setState({ loading: false });
                    }
                });
            }
        } catch (err) {
            onShowAlert({ variant: "danger", message: "Nie udało się pobrać danych." });
            setTimeout(this.checkAvailableParam(), 4000);
        }
    };

    componentDidMount() {
        const { currentPage } = this.props;
        if (currentPage.subpage === "editQuestion") {
            this.getQuestionData(currentPage.params.questionID);
        } else {
            this.checkAvailableParam();
        }
    }

    componentDidUpdate() {
        const { questionData, appendedReq } = this.state;
        if (questionData)
            if (questionData[0].question_type_code && questionData[0].question_category_code && !appendedReq) {
                $("#answerA + label").append(`<span class="required"> *</span>`);
                $("#answerB + label").append(`<span class="required"> *</span>`);
                $("#answerC + label").append(`<span class="required"> *</span>`);
                $("#answerD + label").append(`<span class="required"> *</span>`);
                this.setState({ appendedReq: true });
            } else if (!questionData[0].question_type_code && !questionData[0].question_category_code && appendedReq) this.setState({ appendedReq: false });
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    render() {
        const { questionData, questionAndAnswers, changedValues, correctAnswer, qitemQuestionTypeCode, qitemAnswerTypeCode, loading, noPermission } = this.state;
        const { onBackToPreviousPage, onCancelChangeValuesInForms, fetchURL } = this.props;

        let qType = "";
        let qCat = "";
        if (questionData && questionData[0].question_type_code) {
            qType = questionData[0].question_type_code;
        }
        if (questionData && questionData[0].question_category_code) {
            qCat = questionData[0].question_category_code;
        }

        return (
            <Fragment>
                {noPermission ? (
                    <NotAllowed />
                ) : (
                    <Fragment>
                        <Container fluid>
                            <Row className="py-3">
                                <NavDetails
                                    header={this.props.currentPage.subpage === "editQuestion" ? "Edytuj pytanie" : "Dodaj nowe pytanie"}
                                    changedValues={changedValues}
                                    onChangeView={() => this.setState({ loading: false }, () => onBackToPreviousPage())}
                                    onCancelChangeValuesInForms={onCancelChangeValuesInForms}
                                />
                                <Col></Col>
                            </Row>
                        </Container>
                        <Container>
                            {loading ? (
                                <LoadingProgressBar />
                            ) : (
                                <Form>
                                    <Row>
                                        <Col xs={12}>
                                            <p className="h4 my-3">Dane</p>
                                        </Col>

                                        <Col lg={8} xs={12}>
                                            <NameInput
                                                value={questionData[0].question_name}
                                                onChangeValue={this.handleQuestionDataValue}
                                                req
                                                labelTxt="Nazwa pytania"
                                                placeholderTxt="Wpisz nazwę roboczą pytania"
                                                name="question_name"
                                                id="questionName"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* <Col lg={4} xs={6}>
                                            <QuestionStatus
                                                defaultValue={questionData[0].question_status_code}
                                                req
                                                fetchURL={fetchURL}
                                                onQuestionStatSelected={(event, value) => {
                                                    this.handleDropdownData(value, "question_status_code");
                                                }}
                                            />
                                        </Col> */}
                                        <Col lg={3} xs={6}>
                                            <SubtestTypes
                                                defaultValue={questionData[0].subtest_type_code}
                                                fetchURL={fetchURL}
                                                req
                                                onSubtestTypeSelected={(event, value) => {
                                                    this.handleDropdownData(value, "subtest_type_code");
                                                    this.handleDropdownData("", "question_type_code");
                                                    this.handleDropdownData("", "question_category_code");
                                                    this.eraseDataInQuestionAndAnswers();
                                                }}
                                            />
                                        </Col>
                                        <Col lg={5} xs={12}>
                                            {questionData[0].subtest_type_code ? (
                                                <QuestionTypes
                                                    defaultValue={questionData[0].question_type_code}
                                                    subtestType={questionData[0].subtest_type_code}
                                                    req
                                                    fetchURL={fetchURL}
                                                    onQuestionTypeSelected={(event, value) => {
                                                        this.eraseDataInQuestionAndAnswers();

                                                        this.handleDropdownData(value, "question_type_code");
                                                        let qitemQuestionTypeCode = null;
                                                        let qitemAnswerTypeCode = null;
                                                        if (value === "V4I") {
                                                            qitemQuestionTypeCode = "VIDEO";
                                                            qitemAnswerTypeCode = "IMAGE";
                                                        } else if (value === "I4V") {
                                                            qitemQuestionTypeCode = "IMAGE";
                                                            qitemAnswerTypeCode = "VIDEO";
                                                        } else if (value === "V4V") {
                                                            qitemQuestionTypeCode = "VIDEO";
                                                            qitemAnswerTypeCode = "VIDEO";
                                                        } else if (value === "W4I") {
                                                            qitemQuestionTypeCode = "WORD";
                                                            qitemAnswerTypeCode = "IMAGE";
                                                        } else if (value === "I4W") {
                                                            qitemQuestionTypeCode = "IMAGE";
                                                            qitemAnswerTypeCode = "WORD";
                                                        } else {
                                                            qitemQuestionTypeCode = "WORD";
                                                            qitemAnswerTypeCode = "WORD";
                                                        }
                                                        this.setState({ qitemQuestionTypeCode: qitemQuestionTypeCode, qitemAnswerTypeCode: qitemAnswerTypeCode });
                                                    }}
                                                />
                                            ) : (
                                                <Fragment>
                                                    <label className="form-label">Typ pytania</label>
                                                    <p className="opacity-4 mt-2">Najpierw wybierz część testu</p>
                                                </Fragment>
                                            )}
                                        </Col>
                                        <Col lg={4} xs={12}>
                                            {questionData[0].subtest_type_code ? (
                                                <QuestionCategories
                                                    defaultValue={questionData[0].question_category_code}
                                                    subtestType={questionData[0].subtest_type_code}
                                                    fetchURL={fetchURL}
                                                    onQuestionCatSelected={(event, value) => {
                                                        this.handleDropdownData(value, "question_category_code");
                                                    }}
                                                    req
                                                />
                                            ) : (
                                                <Fragment>
                                                    <label className="form-label">Kategoria pytania</label>
                                                    <p className="opacity-4 mt-2">Najpierw wybierz część testu</p>
                                                </Fragment>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <p className="h4 my-3">Plansza</p>
                                        </Col>
                                        <Col xs={12}>
                                            {questionData[0].subtest_type_code && questionData[0].question_type_code && questionData[0].question_category_code ? (
                                                qCat === "SYNONYM" && qType !== "V4V" ? (
                                                    <Alert variant="warning">Pytanie kategorii "Synonimy" może tylko być typu "Film - 4 filmy".</Alert>
                                                ) : (
                                                    <Fragment>
                                                        {qType === "W4I" || qType === "W4W" ? (
                                                            <Row>
                                                                <Col md={6} xs={12}>
                                                                    <NameInput
                                                                        value={questionAndAnswers[0].qitem_string}
                                                                        onChangeValue={(e) => this.handleQuestionAndAnswersValue(e, 0)}
                                                                        req
                                                                        labelTxt="Pytanie"
                                                                        placeholderTxt="Wpisz treść słowa"
                                                                        name="qitem_string"
                                                                        id="questionQitemString"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        ) : (
                                                            <Fragment>
                                                                <label className="form-label">
                                                                    Pytanie <span className="required">*</span>
                                                                </label>
                                                                <QItemFile
                                                                    fileType={qitemQuestionTypeCode}
                                                                    fetchURL={fetchURL}
                                                                    onSelectedFile={(value, option) => this.handleSelectedFile(value, option, 0)}
                                                                    selectedExistingFile={questionAndAnswers[0].qitem_id ? [questionAndAnswers[0]] : null}
                                                                    selectedNewFile={questionAndAnswers[0].new_file}
                                                                    id="Question"
                                                                    onNoExistingFile={() => this.handleNoExistingFile(0)}
                                                                />
                                                            </Fragment>
                                                        )}

                                                        <Row>
                                                            <Col lg={6} xs={12}>
                                                                <Form.Field>
                                                                    <Radio
                                                                        label="Odpowiedź A"
                                                                        name="answerA"
                                                                        value="A"
                                                                        checked={correctAnswer === "A"}
                                                                        onChange={this.handleCheckCorrectAnswer}
                                                                        id="answerA"
                                                                    />
                                                                </Form.Field>
                                                                {qType === "I4W" || qType === "W4W" ? (
                                                                    <NameInput
                                                                        value={questionAndAnswers[1].qitem_string}
                                                                        onChangeValue={(e) => this.handleQuestionAndAnswersValue(e, 1)}
                                                                        req
                                                                        placeholderTxt="Wpisz treść słowa"
                                                                        name="qitem_string"
                                                                        id="answerAQItemString"
                                                                    />
                                                                ) : (
                                                                    <QItemFile
                                                                        fileType={qitemAnswerTypeCode}
                                                                        id="AnswerA"
                                                                        fetchURL={fetchURL}
                                                                        onSelectedFile={(value, option) => this.handleSelectedFile(value, option, 1)}
                                                                        selectedExistingFile={questionAndAnswers[1].qitem_id ? [questionAndAnswers[1]] : null}
                                                                        selectedNewFile={questionAndAnswers[1].new_file}
                                                                        onNoExistingFile={() => this.handleNoExistingFile(1)}
                                                                    />
                                                                )}
                                                            </Col>
                                                            <Col lg={6} xs={12}>
                                                                <Form.Field>
                                                                    <Radio
                                                                        label="Odpowiedź B"
                                                                        name="answerB"
                                                                        value="B"
                                                                        checked={correctAnswer === "B"}
                                                                        onChange={this.handleCheckCorrectAnswer}
                                                                        id="answerB"
                                                                    />
                                                                </Form.Field>
                                                                {qType === "I4W" || qType === "W4W" ? (
                                                                    <NameInput
                                                                        value={questionAndAnswers[2].qitem_string}
                                                                        onChangeValue={(e) => this.handleQuestionAndAnswersValue(e, 2)}
                                                                        req
                                                                        placeholderTxt="Wpisz treść słowa"
                                                                        name="qitem_string"
                                                                        id="answerBQItemString"
                                                                    />
                                                                ) : (
                                                                    <QItemFile
                                                                        ref="answerB"
                                                                        fileType={qitemAnswerTypeCode}
                                                                        id="AnswerB"
                                                                        fetchURL={fetchURL}
                                                                        onSelectedFile={(value, option) => this.handleSelectedFile(value, option, 2)}
                                                                        selectedExistingFile={questionAndAnswers[2].qitem_id ? [questionAndAnswers[2]] : null}
                                                                        selectedNewFile={questionAndAnswers[2].new_file}
                                                                        onNoExistingFile={() => this.handleNoExistingFile(2)}
                                                                    />
                                                                )}
                                                            </Col>
                                                            <Col lg={6} xs={12}>
                                                                <Form.Field>
                                                                    <Radio
                                                                        label="Odpowiedź C"
                                                                        name="answerC"
                                                                        value="C"
                                                                        checked={correctAnswer === "C"}
                                                                        onChange={this.handleCheckCorrectAnswer}
                                                                        id="answerC"
                                                                    />
                                                                </Form.Field>
                                                                {qType === "I4W" || qType === "W4W" ? (
                                                                    <NameInput
                                                                        value={questionAndAnswers[3].qitem_string}
                                                                        onChangeValue={(e) => this.handleQuestionAndAnswersValue(e, 3)}
                                                                        req
                                                                        placeholderTxt="Wpisz treść słowa"
                                                                        name="qitem_string"
                                                                        id="answerCQItemString"
                                                                    />
                                                                ) : (
                                                                    <QItemFile
                                                                        ref="answerC"
                                                                        fileType={qitemAnswerTypeCode}
                                                                        id="AnswerC"
                                                                        fetchURL={fetchURL}
                                                                        onSelectedFile={(value, option) => this.handleSelectedFile(value, option, 3)}
                                                                        selectedExistingFile={questionAndAnswers[3].qitem_id ? [questionAndAnswers[3]] : null}
                                                                        selectedNewFile={questionAndAnswers[3].new_file}
                                                                        onNoExistingFile={() => this.handleNoExistingFile(3)}
                                                                    />
                                                                )}
                                                            </Col>
                                                            <Col lg={6} xs={12}>
                                                                <Form.Field>
                                                                    <Radio
                                                                        label="Odpowiedź D"
                                                                        name="answerD"
                                                                        value="D"
                                                                        checked={correctAnswer === "D"}
                                                                        onChange={this.handleCheckCorrectAnswer}
                                                                        id="answerD"
                                                                    />
                                                                </Form.Field>
                                                                {qType === "I4W" || qType === "W4W" ? (
                                                                    <NameInput
                                                                        value={questionAndAnswers[4].qitem_string}
                                                                        onChangeValue={(e) => this.handleQuestionAndAnswersValue(e, 4)}
                                                                        req
                                                                        placeholderTxt="Wpisz treść słowa"
                                                                        name="qitem_string"
                                                                        id="answerDQItemString"
                                                                    />
                                                                ) : (
                                                                    <QItemFile
                                                                        ref="answerD"
                                                                        eraseData
                                                                        fileType={qitemAnswerTypeCode}
                                                                        id="AnswerD"
                                                                        fetchURL={fetchURL}
                                                                        onSelectedFile={(value, option) => this.handleSelectedFile(value, option, 4)}
                                                                        selectedExistingFile={questionAndAnswers[4].qitem_id ? [questionAndAnswers[4]] : null}
                                                                        selectedNewFile={questionAndAnswers[4].new_file}
                                                                        onNoExistingFile={() => this.handleNoExistingFile(4)}
                                                                    />
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </Fragment>
                                                )
                                            ) : (
                                                <Fragment>
                                                    <p className="opacity-4">Wybierz część testu oraz typ i kategorię pytania.</p>
                                                </Fragment>
                                            )}
                                        </Col>
                                        <Col xs={12} className="mt-3">
                                            <DescrInput
                                                value={questionData[0].question_comment}
                                                onChangeValue={this.handleQuestionDataValue}
                                                labelTxt="Komentarz"
                                                name="question_comment"
                                                placeholderTxt="Wpisz komentarz dla sędziego"
                                                id="question_comment"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {!changedValues ? (
                                                <TooltipWithDisabledButton
                                                    mssgTooltip="W formularzu nie nastąpiły żadne zmiany!"
                                                    btnTxt={this.props.currentPage.subpage === "editQuestion" ? "Edytuj" : "Dodaj"}
                                                    tooltipID="addNewQuestionBtn"
                                                    size="lg"
                                                />
                                            ) : (
                                                <Button variant="primary" onClick={this.handleCheckValidation} size="lg">
                                                    {this.props.currentPage.subpage === "editQuestion" ? "Edytuj" : "Dodaj"}
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Container>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default AddEditQuestion;
